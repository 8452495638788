import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import { produce } from "immer";
import { useWeb3React } from "@web3-react/core";
import Toast from "components/Toast/Toast";
import {
  Modal,
  ModalBody,
  Text,
  Button,
  Input,
  Flex,
  Box
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import useToast from "hooks/useToast";
import { getFormDetailsErrors } from "./helpers";
import { FormDetailsState } from "./types";

interface Props {
  presaleAddress: string;
  onDismiss?: () => void;
}

interface Tokenomic {
  name: string;
  value: number;
}

const FormError: React.FC = ({ children }) => (
  <Text color="failure" mb="4px">
    {children}
  </Text>
);

const FormErrors: React.FC<{ errors: string[] }> = ({ errors }) => {
  return (
    <Box mt="8px">
      {errors.map((error) => {
        return <FormError key={error}>{error}</FormError>;
      })}
    </Box>
  );
};

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0.5rem;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
`;

const DetailsModal: React.FC<Props> = ({ presaleAddress, onDismiss }) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const [fieldsState, setFieldsState] = useState<{ [key: string]: boolean }>(
    {}
  );

  const updateValue = (key: string, value: string | number) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value
    }));

    // Keep track of what fields the user has attempted to edit
    setFieldsState((prevFieldsState) => ({
      ...prevFieldsState,
      [key]: true
    }));
  };

  const handleChangeLogo = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    updateValue("logo", value);
  };


  const handleChangeDescription = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = evt.currentTarget;
    updateValue("description", value);
  };

  const handleChangeWeb = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    updateValue("web", value);
  };

  const [Tokenomics, setTokenomics] = useState<Tokenomic[]>([]);
  const { toastError, toastSuccess } = useToast();

  const [state, setState] = useState<FormDetailsState>({
    logo: "",
    description: "",
    tokenomic: Tokenomics,
    web: ""
  });

  const { logo, description ,web } = state;
  const formErrors = getFormDetailsErrors(state, Tokenomics, t);

  const handleSubmit = (event) => {
    event.preventDefault();
  };


  return (
    <Modal title={t("Edit details")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
        <form onSubmit={handleSubmit}>
          <Flex justifyContent="center" flexDirection="column">
            <Label htmlFor="logo">{t("Logo")}</Label>
            <Input
              id="logo"
              name="logo"
              value={logo}
              placeholder="https://jamonswap.png"
              onChange={handleChangeLogo}
              required
            />
            {formErrors.logo && fieldsState.logo && (
              <FormErrors errors={formErrors.logo} />
            )}
          </Flex>
          <Flex justifyContent="center" flexDirection="column" mt="1rem">
            <Label htmlFor="description">{t("Description")}</Label>
            <textarea
              id="description"
              name="description"
              value={description}
              onChange={handleChangeDescription}
              maxLength={300}
              required
            />
            {formErrors.description && fieldsState.description && (
              <FormErrors errors={formErrors.description} />
            )}
          </Flex>
          <Flex justifyContent="center" flexDirection="column" mt="1rem">
            <Label htmlFor="tokenomics">{t("Tokenomics")}</Label>
            <Flex flexDirection="column">
              {Tokenomics.map((p, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <Flex alignItems="center" marginY="0.2rem">
                      <Flex width="70%">
                        <Input
                          onChange={(e) => {
                            const name = e.target.value;
                            setTokenomics((currentTokenomics) =>
                              produce(currentTokenomics, (v) => {
                                // eslint-disable-next-line no-param-reassign
                                v[index].name = name;
                                updateValue("tokenomic", name)
                                
                              })
                            );
                          }}
                          value={p.name}
                          placeholder="name"
                          scale="sm"
                          required
                        />
                      </Flex>
                      <Flex width="30%" marginX="0.5rem">
                        <Input
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setTokenomics((currentTokenomics) =>
                              produce(currentTokenomics, (v) => {
                                if(Number(inputValue)>100){
                                  // eslint-disable-next-line no-param-reassign
                                  v[index].value = 100;
                                  updateValue("tokenomic", 100)
                                }else{
                                  // eslint-disable-next-line no-param-reassign
                                  v[index].value = Number(inputValue);
                                  updateValue("tokenomic", Number(inputValue))
                                }
                                
                              })
                            );
                          }}
                          value={p.value}
                          placeholder="value"
                          scale="sm"
                          maxLength={3}
                          required
                        />
                      </Flex>
                      <Button
                        onClick={() => {
                          setTokenomics((currentTokenomics) =>
                            currentTokenomics.filter((x) => x.name !== p.name)
                          );
                        }}
                        scale="xs"
                      >
                        x
                      </Button>
                    </Flex>
                  </div>
                );
              })}
              {formErrors.tokenomic && fieldsState.tokenomic && (
                <FormErrors errors={formErrors.tokenomic} />
              )}
              {/* <Flex justifyContent="center" marginY="1rem">{JSON.stringify(Tokenomics, null, 2)}</Flex> */}
            </Flex>
            <Flex justifyContent="center">
              <Button
                onClick={() => {
                  setTokenomics((currentTokenomics) => [
                    ...currentTokenomics,
                    {
                      name: "",
                      value: 0
                    }
                  ]);
                }}
                disabled={Tokenomics.length > 9}
                scale="sm"
                mt="0.5rem"
              >
                {t("Add")}
              </Button>
            </Flex>
          </Flex>
          <Flex justifyContent="center" flexDirection="column" mt="1rem">
            <Label htmlFor="web">{t("URL Web")}</Label>
            <Input
              id="web"
              name="web"
              value={web}
              placeholder="https://jamonswap.png"
              onChange={handleChangeWeb}
              required
            />
            {formErrors.web && fieldsState.web && (
              <FormErrors errors={formErrors.web} />
            )}
          </Flex>
          <Flex justifyContent="center" flexDirection="column" mt="1rem">
            <Button type="submit" value="Submit">
              {t("Confirm")}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
