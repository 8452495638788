import React, { useMemo, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  useMatchBreakpoints,
  Text,
  Flex,
  Box,
  Skeleton,
} from "@pancakeswap/uikit";
import { Link } from "react-router-dom";
import { useAllTokenData } from "state/info/hooks";
import { TokenData } from "state/info/types";
import { CurrencyLogo } from "views/Info/components/CurrencyLogo";
import { formatAmount } from "views/Info/utils/formatInfoNumbers";
import Percent from "views/Info/components/Percent";
import { useTranslation } from "contexts/Localization";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CardWrapper = styled(Link)`
  display: inline-block;
  min-width: 190px;
  margin-left: 16px;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const TopMoverCard = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.card};
  padding: 10px;
`;

const DataCard = ({ tokenData }: { tokenData: TokenData }) => {
  const { isMobile } = useMatchBreakpoints();

  const tokenPrice = tokenData.priceUSD;
  const formatToken =
    tokenPrice % 1 === 0
      ? tokenPrice.toFixed(0)
      : tokenPrice < 0.01
      ? tokenPrice.toFixed(4)
      : tokenPrice.toFixed(2);

  return (
    <>
      {isMobile ? (
        <CardWrapper to={`/info/token/${tokenData.address}`}>
          <TopMoverCard>
            <Flex flexDirection="row" alignItems="center">
              {/* wrapped in a box because of alignment issues between img and svg */}
              <CurrencyLogo address={tokenData.address} size="24px" />
              <Text color="#FFFFFF" fontSize="0.7rem" mx="0.2rem">
                {tokenData.symbol === "WMATIC" ? "MATIC" : tokenData.symbol}
              </Text>
              <Flex alignItems="center" flexDirection="row">
                <Text color="#FFFFFF" fontSize="0.7rem" mr="0.2rem">
                  ${formatToken}
                </Text>
                <Percent fontSize="0.7rem" value={tokenData.priceUSDChange} />
              </Flex>
            </Flex>
          </TopMoverCard>
        </CardWrapper>
      ) : (
        <CardWrapper to={`/info/token/${tokenData.address}`}>
          <TopMoverCard>
            <Flex flexDirection="row" alignItems="center">
              {/* wrapped in a box because of alignment issues between img and svg */}
              <CurrencyLogo address={tokenData.address} size="24px" />
              <Text color="#FFFFFF" fontSize="0.875rem" mx="0.5rem">
                {tokenData.symbol === "WMATIC" ? "MATIC" : tokenData.symbol}
              </Text>
              <Flex alignItems="center" flexDirection="row">
                <Text color="#FFFFFF" fontSize="0.875rem" mr="0.5rem">
                  ${formatToken}
                </Text>
                <Percent fontSize="0.875rem" value={tokenData.priceUSDChange} />
              </Flex>
            </Flex>
          </TopMoverCard>
        </CardWrapper>
      )}
    </>
  );
};

const TopMoversTokens: React.FC = () => {
  const { isMobile } = useMatchBreakpoints();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 5,
    },
    tabletMax: {
      breakpoint: { max: 1500, min: 1199 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 499 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 2,
    },
  };

  const allTokens = useAllTokenData();

  const { t } = useTranslation();

  const topPriceIncrease = useMemo(() => {
    return Object.values(allTokens)
      .sort(({ data: a }, { data: b }) => {
        // eslint-disable-next-line no-nested-ternary
        return a && b
          ? Math.abs(a?.priceUSDChange) > Math.abs(b?.priceUSDChange)
            ? -1
            : 1
          : -1;
      })
      .slice(0, Math.min(20, Object.values(allTokens).length));
  }, [allTokens]);

  const increaseRef = useRef<HTMLDivElement>(null);
  const moveLeftRef = useRef<boolean>(true);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (increaseRef.current) {
        if (
          increaseRef.current.scrollLeft ===
          increaseRef.current.scrollWidth - increaseRef.current.clientWidth
        ) {
          moveLeftRef.current = false;
        } else if (increaseRef.current.scrollLeft === 0) {
          moveLeftRef.current = true;
        }
        increaseRef.current.scrollTo(
          moveLeftRef.current
            ? increaseRef.current.scrollLeft + 1
            : increaseRef.current.scrollLeft - 1,
          0
        );
      }
    }, 30);

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  if (
    topPriceIncrease.length === 0 ||
    !topPriceIncrease.some((entry) => entry.data)
  ) {
    return null;
  }

  return (
    <>
      <Carousel
        responsive={responsive}
        infinite
        arrows={false}
        autoPlay
        swipeable
        autoPlaySpeed={5000}
        customTransition="transform 10000ms linear"
      >
        {topPriceIncrease.map((entry) =>
          entry.data &&
          entry.data?.address !==
            "0x5fc6485035ae3715920c3d7a55baf0eb68c9d9ed" ? (
            <DataCard
              key={`top-card-token-${entry.data?.address}`}
              tokenData={entry.data}
            />
          ) : null
        )}
      </Carousel>
    </>
  );
};

export default TopMoversTokens;
