import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "contexts/Localization";
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { 
Container, 
Box,  
List, 
ListItem, 
Link, 
Typography,
Divider 
} from "@mui/material";
import { Colors, Fonts } from "components/Style";
import menuLogo from "img/Menu/logo.png"
import coin from "img/coin.png"
import SocialIcons from "./icons"



const FooterContainer = styled(Container)`
    background: ${Colors.BACKGROUND2};
    color: ${Colors.TEXT2};
    margin-top: 1rem;
    padding-bottom: 4rem;
    border-top: 1px solid #b34a56;
`
const LinkItem = styled(Link)`
    color: ${Colors.TEXT1};
    font-size: ${Fonts.TEXT1};
    font-weight: normal;
    font-family: poppins;
`

const Title = styled(Typography)`
    color: ${Colors.TEXT2};
    font-size: ${Fonts.TEXT1};
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
    font-family: poppins;
`

const FooterMobile: React.VFC = () => {
    const { t } = useTranslation();
    const jamonPriceUSD = useCakeBusdPrice();

    return (
        <FooterContainer maxWidth={false}>
            <Container maxWidth="xl">
                <List sx={{justifyContent:"center"}}>
                    <ListItem sx={{justifyContent:"center"}}>
                        <NavLink to="/">
                            <Box
                                component="img"
                                sx={{
                                    width: 200,
                                }}
                                alt="footer-logo"
                                src={menuLogo}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center", marginBottom:"1rem", color: "#FFFFFF"}}>
                        <Box
                            component="img"
                            sx={{
                            height: 18,
                            width: 18,
                            mr:1,
                            }}
                            alt="coin-logo"
                            src={coin}
                        />
                        {jamonPriceUSD?.toFixed(6)}
                    </ListItem>
                    <Divider sx={{background: Colors.BORDER1, mb:"1rem"}}/>
                    <ListItem sx={{justifyContent:"center"}}>
                        <Title>{t("About")}</Title>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Contact")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/brand-and-logos" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Brand")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us/social-accounts-and-communities" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Community")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/tokenomics/jamon-y-jshare" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Jamon Token")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <Title>{t("Help")}</Title>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us/customer-support" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Customer Support")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/click-here-for-help/troubleshooting-errors" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Troubleshooting")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/guides/get-started" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Guides")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <Title>{t("Developers")}</Title>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://github.com/jamonswap" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Github")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Documentation")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_Jamonswap.pdf" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Audits")}
                        </LinkItem>
                    </ListItem>
                    <ListItem sx={{justifyContent:"center"}}>
                        <LinkItem href="https://jamonswap.gitbook.io/docs/products/api" underline="none" target="_blank" rel="noopener noreferrer">
                            {t("Api")}
                        </LinkItem>
                    </ListItem>
                </List>
                <Divider sx={{background: Colors.BORDER1, mb:"1rem"}}/>
                <Box>
                    <SocialIcons/>
                </Box>
            </Container>
        </FooterContainer>    
    );
}

export default FooterMobile;