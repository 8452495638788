import React, { useCallback, useEffect, useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { BigNumber as BigJS } from "bignumber.js";
import { TransactionResponse } from "@ethersproject/providers";
import {
  Currency,
  currencyEquals,
  ETHER,
  TokenAmount,
  WETH,
} from "@pancakeswap/sdk";
import { Grid, Divider, Paper } from "@mui/material";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { Colors } from "components/Style";
import {
  Button,
  Text,
  Flex,
  CardBody,
  useModal,
  useMatchBreakpoints,
  NotificationDot,
  HistoryIcon,
  WarningIcon,
} from "@pancakeswap/uikit";
import GlobalSettings from "components/NavMenu/GlobalSettings";
import TransactionsModal from "components/App/Transactions/TransactionsModal";
import { RouteComponentProps } from "react-router-dom";
import { useIsTransactionUnsupported } from "hooks/Trades";
import { useTranslation } from "contexts/Localization";
import UnsupportedCurrencyFooter from "components/UnsupportedCurrencyFooter";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useDispatch } from "react-redux";
import { serializeTokens } from "config/constants/tokens";
import { useERC20 } from "hooks/useContract";
import { AppDispatch } from "../../state";
import { LightCard } from "../../components/Card";
import { AutoColumn } from "../../components/Layout/Column";
import TransactionConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/TransactionConfirmationModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import { DoubleCurrencyLogo } from "../../components/Logo";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row, { RowBetween } from "../../components/Layout/Row";
import ConnectWalletButton from "../../components/ConnectWalletButton";

import { ROUTER_ADDRESS } from "../../config/constants";
import { PairState } from "../../hooks/usePairs";
import { useCurrency } from "../../hooks/Tokens";
import {
  ApprovalState,
  useApproveCallback,
} from "../../hooks/useApproveCallback";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { Field, resetMintState } from "../../state/mint/actions";
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from "../../state/mint/hooks";

import { useTransactionAdder } from "../../state/transactions/hooks";
import {
  useGasPrice,
  useIsExpertMode,
  useUserSlippageTolerance,
} from "../../state/user/hooks";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from "../../utils";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { wrappedCurrency } from "../../utils/wrappedCurrency";
import Dots from "../../components/Loader/Dots";
import ConfirmAddModalBottom from "./ConfirmAddModalBottom";
import { currencyId } from "../../utils/currencyId";
import PoolPriceBar from "./PoolPriceBar";
import { StyledPageAddLiquidity, AppBody, TitleAddPool } from "../Style";

const serializedTokens = serializeTokens();

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const gasPrice = useGasPrice();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />);

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      dispatch(resetMintState());
    }
  }, [dispatch, currencyIdA, currencyIdB]);

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId])))
  );

  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  // txn values
  const deadline = useTransactionDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState<string>("");

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? "0"),
    };
  }, {});

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    ROUTER_ADDRESS
  );
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    ROUTER_ADDRESS
  );

  const addTransaction = useTransactionAdder();

  const raisingTokenContract = useERC20(serializedTokens.jamonV2.address);

  async function onAdd() {
    const response1 = await raisingTokenContract.allowance(
      account,
      "0xBcE5B2EB71fAe34213aE71403dd81cc3a4400954"
    );
    const currentAllowance = new BigJS(response1.toString());
    if (currentAllowance.gt(0)) {
      const disable = await raisingTokenContract.approve(
        "0xBcE5B2EB71fAe34213aE71403dd81cc3a4400954",
        "0"
      );
      await disable.wait();
    }

    if (!chainId || !library || !account) return;
    const router = getRouterContract(chainId, library, account);

    const {
      [Field.CURRENCY_A]: parsedAmountA,
      [Field.CURRENCY_B]: parsedAmountB,
    } = parsedAmounts;
    if (
      !parsedAmountA ||
      !parsedAmountB ||
      !currencyA ||
      !currencyB ||
      !deadline
    ) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage
      )[0],
    };

    let estimate;
    let method: (...args: any) => Promise<TransactionResponse>;
    let args: Array<string | string[] | number>;
    let value: BigNumber | null;
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)
          ?.address ?? "", // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[
          tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(), // token min
        amountsMin[
          tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(), // eth min
        account,
        deadline.toHexString(),
      ];
      value = BigNumber.from(
        (tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString()
      );
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? "",
        wrappedCurrency(currencyB, chainId)?.address ?? "",
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ];
      value = null;
    }

    setAttemptingTxn(true);
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
          gasPrice,
        }).then((response) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
              3
            )} ${currencies[Field.CURRENCY_A]?.symbol} and ${parsedAmounts[
              Field.CURRENCY_B
            ]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          });

          setTxHash(response.hash);
        })
      )
      .catch((err) => {
        setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err);
        }
      });
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <Flex alignItems="center">
        <Row>
          <WarningIcon color="yellow" />
          <Text small my="8px" ml="4px">
            {t(
              "if the token has a sell tax it will affect the amount of liquidity it provides."
            )}
          </Text>
        </Row>
        <Text fontSize="48px" marginRight="10px">
          {`${currencies[Field.CURRENCY_A]?.symbol}/${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </Text>
        <DoubleCurrencyLogo
          currency0={currencies[Field.CURRENCY_A]}
          currency1={currencies[Field.CURRENCY_B]}
          size={30}
        />
      </Flex>
    ) : (
      <AutoColumn>
        <Flex alignItems="center">
          <Text fontSize="48px" marginRight="10px">
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </Flex>
        <Row>
          <Text fontSize="24px">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${
              currencies[Field.CURRENCY_B]?.symbol
            } Pool Tokens`}
          </Text>
        </Row>
        <Row>
          <WarningIcon color="yellow" />
          <Text small my="8px" ml="4px">
            {t(
              "if the token has a sell tax it will affect the amount of liquidity it provides."
            )}
          </Text>
        </Row>
        <Text small textAlign="left" my="8px">
          {t(
            "Output is estimated. If the price changes by more than %slippage%% your transaction will revert.",
            {
              slippage: allowedSlippage / 100,
            }
          )}
        </Text>
      </AutoColumn>
    );
  };

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
  };

  const pendingText = t(
    "Supplying %amountA% %symbolA% and %amountB% %symbolB%",
    {
      amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? "",
      symbolA: currencies[Field.CURRENCY_A]?.symbol ?? "",
      amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? "",
      symbolB: currencies[Field.CURRENCY_B]?.symbol ?? "",
    }
  );

  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {
      const newCurrencyIdA = currencyId(currencyA_);
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`);
      } else if (currencyIdB) {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
      } else {
        history.push(`/add/${newCurrencyIdA}`);
      }
    },
    [currencyIdB, history, currencyIdA]
  );
  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      const newCurrencyIdB = currencyId(currencyB_);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          history.push(`/add/${newCurrencyIdB}`);
        }
      } else {
        history.push(`/add/${currencyIdA || "MATIC"}/${newCurrencyIdB}`);
      }
    },
    [currencyIdA, history, currencyIdB]
  );

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput("");
    }
    setTxHash("");
  }, [onFieldAInput, txHash]);

  const addIsUnsupported = useIsTransactionUnsupported(
    currencies?.CURRENCY_A,
    currencies?.CURRENCY_B
  );

  const [onPresentAddLiquidityModal] = useModal(
    <TransactionConfirmationModal
      title={noLiquidity ? t("You are creating a pool") : t("You will receive")}
      customOnDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={() => (
        <ConfirmationModalContent
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      )}
      pendingText={pendingText}
      currencyToAdd={pair?.liquidityToken}
    />,
    true,
    true,
    "addLiquidityModal"
  );

  const widthPosition = isMobile || isTablet ? "90%" : "70%";

  return (
    <StyledPageAddLiquidity isMobile={isMobile} isTablet={isTablet}>
      <AppBody isMobile={isMobile} isTablet={isTablet}>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          marginBottom="0.5rem"
        >
          <Grid item xs={9} md={10}>
            <TitleAddPool isMobile={isMobile} isTablet={isTablet}>
              {t("Add Liquidity")}
            </TitleAddPool>
          </Grid>
          <Grid item xs={0} md={0}>
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
          </Grid>
          <Grid item xs={0} md={0}>
            <Button
              variant="text"
              p={0}
              onClick={onPresentTransactionsModal}
              ml="16px"
            >
              <HistoryIcon color="textSubtle" width="24px" />
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ background: Colors.BORDER1, mb: "1rem" }} />
        <CardBody>
          <AutoColumn gap="20px">
            {noLiquidity && (
              <Flex justifyContent="center">
                <Paper
                  sx={{
                    background: "transparent",
                    border: "1px solid #167F8B",
                    width: { widthPosition },
                    padding: "1rem",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Flex flexDirection="row">
                      <HelpCenterRoundedIcon
                        sx={{ color: "#FEBF32", mr: "0.5rem" }}
                      />
                      <Text
                        color={Colors.TURQUOISE7}
                        textAlign="center"
                        fontSize="0.875rem"
                        fontFamily="Poppins"
                      >
                        {t("You are the first liquidity provider.")}
                      </Text>
                    </Flex>
                  </Grid>
                </Paper>
              </Flex>
            )}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? "");
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <Flex justifyContent="center">
              <AddBoxRoundedIcon width="16px" sx={{ color: Colors.TEXT2 }} />
            </Flex>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? "");
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] &&
              currencies[Field.CURRENCY_B] &&
              pairState !== PairState.INVALID && (
                <>
                  <LightCard padding="5px" borderRadius="20px">
                    <RowBetween padding="1rem">
                      <Text fontSize="14px" color={Colors.TURQUOISE7}>
                        {noLiquidity
                          ? t("Initial prices and pool share")
                          : t("Prices and pool share")}
                      </Text>
                    </RowBetween>{" "}
                    <LightCard padding="1rem" borderRadius="20px">
                      <PoolPriceBar
                        currencies={currencies}
                        poolTokenPercentage={poolTokenPercentage}
                        noLiquidity={noLiquidity}
                        price={price}
                      />
                    </LightCard>
                  </LightCard>
                </>
              )}

            {addIsUnsupported ? (
              <Button disabled mb="4px">
                {t("Unsupported Asset")}
              </Button>
            ) : !account ? (
              <ConnectWalletButton />
            ) : (
              <AutoColumn gap="md">
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid && (
                    <RowBetween>
                      {approvalA !== ApprovalState.APPROVED && (
                        <Button
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          width={
                            approvalB !== ApprovalState.APPROVED
                              ? "48%"
                              : "100%"
                          }
                        >
                          {approvalA === ApprovalState.PENDING ? (
                            <Dots>
                              {t("Enabling %asset%", {
                                asset: currencies[Field.CURRENCY_A]?.symbol,
                              })}
                            </Dots>
                          ) : (
                            t("Enable %asset%", {
                              asset: currencies[Field.CURRENCY_A]?.symbol,
                            })
                          )}
                        </Button>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <Button
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          width={
                            approvalA !== ApprovalState.APPROVED
                              ? "48%"
                              : "100%"
                          }
                        >
                          {approvalB === ApprovalState.PENDING ? (
                            <Dots>
                              {t("Enabling %asset%", {
                                asset: currencies[Field.CURRENCY_B]?.symbol,
                              })}
                            </Dots>
                          ) : (
                            t("Enable %asset%", {
                              asset: currencies[Field.CURRENCY_B]?.symbol,
                            })
                          )}
                        </Button>
                      )}
                    </RowBetween>
                  )}
                <Button
                  variant={
                    !isValid &&
                    !!parsedAmounts[Field.CURRENCY_A] &&
                    !!parsedAmounts[Field.CURRENCY_B]
                      ? "danger"
                      : "primary"
                  }
                  onClick={() => {
                    if (expertMode) {
                      onAdd();
                    } else {
                      onPresentAddLiquidityModal();
                    }
                  }}
                  disabled={
                    !isValid ||
                    approvalA !== ApprovalState.APPROVED ||
                    approvalB !== ApprovalState.APPROVED
                  }
                >
                  {error ?? t("Supply")}
                </Button>
              </AutoColumn>
            )}
          </AutoColumn>
        </CardBody>
      </AppBody>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn
            style={
              isMobile || isTablet
                ? {
                    minWidth: "20rem",
                    width: "100%",
                    maxWidth: "400px",
                    marginTop: "22rem",
                  }
                : {
                    minWidth: "20rem",
                    width: "100%",
                    maxWidth: "400px",
                    marginTop: "8rem",
                  }
            }
          >
            <MinimalPositionCard
              showUnwrapped={oneCurrencyIsWETH}
              pair={pair}
            />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </StyledPageAddLiquidity>
  );
}
