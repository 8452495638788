// TODO PCS refactor ternaries
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { formatDistanceToNowStrict } from "date-fns";
import {
  Text,
  Flex,
  Box,
  Radio,
  useModal,
  Skeleton,
  LinkExternal,
  AutoRenewIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  InfoIcon,
  RemoveIcon,
  useMatchBreakpoints,
  Button,
  ButtonMenu
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { useJamonLimitContract } from "hooks/useContract";
import { ToastDescriptionWithTx } from "components/Toast";
import useToast from "hooks/useToast";
import { formatAmount } from "views/Info/utils/formatInfoNumbers";
import { getBscScanLink } from "utils";
import truncateHash from "utils/truncateHash";
import { Transaction, TransactionType } from "state/info/types";
import { ITEMS_PER_INFO_TABLE_PAGE } from "config/constants/info";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";
import { useTranslation } from "contexts/Localization";
import { getBalanceNumber } from "utils/formatBalance";
import { useCurrency } from "hooks/Tokens";
import {
  ClickableColumnHeader,
  TableWrapper,
  PageButtons,
  Arrow,
  Break
} from "./shared";
import OrderDetailsModal from "./OrderDetailsModal";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 2em;
`;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: repeat(7, 10em) 0.6fr;
  padding: 0 24px;
  @media screen and (max-width: 1799px) {
    grid-template-columns: 15% 18% 18% 18% 18% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 15% 18% 18% 18% 18% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 20% 25% 20% 20% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 25% 30% 25% 5%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 40% 40% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(6) {
      display: none;
    }

  }
`;

const WrappedIcon = styled(RemoveIcon)`
  cursor: pointer;
`;

const DetailsIcon = styled(InfoIcon)`
  cursor: pointer;
`;

const RadioGroup = styled(Flex)`
  align-items: center;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
`;

const SORT_FIELD = {
  amountUSD: "amountUSD",
  timestamp: "timestamp",
  sender: "sender",
  amountToken0: "amountToken0",
  amountToken1: "amountToken1"
};

const TableLoader: React.FC = () => {
  const loadingRow = (
    <ResponsiveGrid>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </ResponsiveGrid>
  );
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  );
};

export interface Order {
  transactionHash: string;
  orderId: string;
  targetPrice: BigNumber;
  executionPrice: BigNumber;
  amountIn: BigNumber;
  tokenIn: string;
  tokenOut: string;
  deadline: number;
  status: number;
  cancelOrder: (orderId: string) => void;
}

export interface OrdersProps {
  orders: Order[];
}

const DataRow: React.FC<Order> = ({
  transactionHash,
  orderId,
  targetPrice,
  executionPrice,
  amountIn,
  tokenIn,
  tokenOut,
  deadline,
  status,
  cancelOrder
}) => {
  const { t } = useTranslation();
  /*  const abs0 = Math.abs(transaction.amountToken0)
  const abs1 = Math.abs(transaction.amountToken1)
  const outputTokenSymbol = transaction.amountToken0 < 0 ? transaction.token0Symbol : transaction.token1Symbol
  const inputTokenSymbol = transaction.amountToken1 < 0 ? transaction.token0Symbol : transaction.token1Symbol */
  const { isDesktop } = useMatchBreakpoints();
  const [detailsModal] = useModal(
    <OrderDetailsModal
      transactionHash={transactionHash}
      orderId={orderId}
      targetPrice={targetPrice}
      executionPrice={executionPrice}
      amountIn={amountIn}
      tokenIn={tokenIn}
      tokenOut={tokenOut}
      deadline={deadline}
      status={status}
      cancelOrder={cancelOrder}
    />
  );

  const currencyIn = useCurrency(tokenIn);
  const currencyOut = useCurrency(tokenOut);
  let statusLabel: string;
  let canCancel: boolean;

  switch (status) {
    case 1:
      statusLabel = "Filled";
      break;
    case 2:
      statusLabel = "Expired";
      break;
    case 3:
      statusLabel = "Cancelled";
      break;
    case 4:
      statusLabel = "Failed";
      break;
    default:
      statusLabel = "Open";
      canCancel = true;
      break;
  }

  const date = formatDistanceToNowStrict(parseInt(deadline.toString(), 10) * 1000).split(" ");

  return (
    <ResponsiveGrid>
      <LinkExternal href={getBscScanLink(transactionHash, "transaction")}>
        <Text>{truncateHash(orderId)}</Text>
      </LinkExternal>
      <Text>
        {getBalanceNumber(amountIn, currencyIn?.decimals)} {currencyIn?.symbol}
      </Text>
      <Text>
        {currencyIn?.symbol} <ArrowForwardIcon marginY={-1} />{" "}
        {currencyOut?.symbol}
      </Text>
      <Text>
        {getBalanceNumber(targetPrice, currencyOut?.decimals).toFixed(6)}
      </Text>
      <Text>
        {executionPrice
          ? getBalanceNumber(executionPrice, currencyOut?.decimals).toFixed(6)
          : " - "}
      </Text>
      <Text>
        {canCancel ? <Text> {date[0]} {t(date[1])} </Text>
          : 
        " - "}
      </Text>
      <Text>{t(statusLabel)}</Text>
      {isDesktop ? (
        canCancel ? (
          <WrappedIcon color="red" onClick={() => cancelOrder(orderId)} />
        ) : (
          <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
        )
      ) : (
        <Text>
          {" "}
          <DetailsIcon color="primary" onClick={() => detailsModal()} />
        </Text>
      )}
    </ResponsiveGrid>
  );
};

const OrdersTable = ({ orders, fecthOrder }) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = useToast();
  const [pendingTx, setPendingTx] = useState(false);
  const gasPrice = BIG_TEN.times(BIG_TEN.pow(BIG_NINE)).toString();

  const contract = useJamonLimitContract();

  const handleCancelClick = async (orderId: string) => {
    setPendingTx(true);
    try {
      const tx = await contract.cancelOrder(orderId, {
        gasPrice
      });
      const receipt = await tx.wait();
      if (receipt.status) {
        toastSuccess(
          t("Remove Order!"),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t("The order has been successfully removed.")}
          </ToastDescriptionWithTx>
        );
        fecthOrder();
        setPendingTx(false);
      }
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setPendingTx(false);
    }
  };

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  const [txFilter, setTxFilter] = useState<any | undefined>(undefined)

  const sortedTransactions = useMemo(() => {
    const toBeAbsList = [SORT_FIELD.amountToken0, SORT_FIELD.amountToken1]
    return orders.length>0
      ? orders
          .slice().reverse()
          .sort((a, b) => {
            if (a && b) {
              const firstField = a[sortField as keyof Transaction]
              const secondField = b[sortField as keyof Transaction]
              const [first, second] = toBeAbsList.includes(sortField)
                ? [Math.abs(firstField as number), Math.abs(secondField as number)]
                : [firstField, secondField]
              return first > second ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
            }
            return -1
          })
          .filter((x) => {
            return txFilter === undefined || x.type === txFilter
          })
          .slice(ITEMS_PER_INFO_TABLE_PAGE * (page - 1), page * ITEMS_PER_INFO_TABLE_PAGE)
      : []
  }, [orders, page, sortField, sortDirection, txFilter])

  // Update maxPage based on amount of items & applied filtering
  useEffect(() => {
    if (orders) {
      const filteredTransactions = orders.filter((tx) => {
        return txFilter === undefined || tx.type === txFilter
      })
      if (filteredTransactions.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE))
      } else {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE) + 1)
      }
    }
  }, [orders, txFilter])

  const handleFilter = useCallback(
    (newFilter: TransactionType) => {
      if (newFilter !== txFilter) {
        setTxFilter(newFilter)
        setPage(1)
      }
    },
    [txFilter],
  )
 
  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  ) 

  useEffect(() => {
    if (orders) {
      if (orders.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
        setMaxPage(Math.floor(orders.length / ITEMS_PER_INFO_TABLE_PAGE));
      } else {
        setMaxPage(Math.floor(orders.length / ITEMS_PER_INFO_TABLE_PAGE) + 1);
      }
    }
  }, [orders]);

  return (
    <Wrapper>
      <TableWrapper>
        <ResponsiveGrid>
          <Text
            color="secondary"
            fontSize="12px"
            bold
            textTransform="uppercase"
          >
            {t("Order ID")}
          </Text>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /* onClick={() => handleSort(SORT_FIELD.amountUSD)} */
            textTransform="uppercase"
          >
            {t("Amount")} {/* {arrow(SORT_FIELD.amountUSD)} */}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /* onClick={() => handleSort(SORT_FIELD.amountToken0)} */
            textTransform="uppercase"
          >
            {t("Swap")} {/* {arrow(SORT_FIELD.amountToken0)} */}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /*  onClick={() => handleSort(SORT_FIELD.sender)} */
            textTransform="uppercase"
          >
            {t("Target Price")} {/* {arrow(SORT_FIELD.sender)} */}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /* onClick={() => handleSort(SORT_FIELD.amountToken1)} */
            textTransform="uppercase"
          >
            {t("Execution Price")} {/* {arrow(SORT_FIELD.amountToken1)} */}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /* onClick={() => handleSort(SORT_FIELD.timestamp)} */
            textTransform="uppercase"
          >
            {t("Deadline")} {/* {arrow(SORT_FIELD.timestamp)} */}
          </ClickableColumnHeader>
          <ClickableColumnHeader
            color="secondary"
            fontSize="12px"
            bold
            /* onClick={() => handleSort(SORT_FIELD.timestamp)} */
            textTransform="uppercase"
          >
            {t("Status")} {/* {arrow(SORT_FIELD.timestamp)} */}
          </ClickableColumnHeader>
          <Text />
        </ResponsiveGrid>
        <Break />

        {sortedTransactions ? (
          <>
            {sortedTransactions.map((rowdata, index) => {
              if (rowdata) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <DataRow
                      transactionHash={rowdata.transaction_hash}
                      orderId={rowdata.orderId}
                      targetPrice={rowdata.targetPrice}
                      executionPrice={rowdata.executionPrice}
                      amountIn={rowdata.amountIn}
                      tokenIn={rowdata.tokenIn}
                      tokenOut={rowdata.tokenOut}
                      deadline={rowdata.deadline}
                      status={rowdata.orderStatus}
                      cancelOrder={handleCancelClick}
                    />
                    <Break />
                  </React.Fragment>
                );
              }
              return null;
            })}
          </>
        ) : (
          ""
        )}
        {/* {orders.length === 0 ? (           
              <Flex justifyContent="center">
                <Text>{t('No Orders')}</Text>
              </Flex>
            ) : undefined} */}
        
        <PageButtons>
          <Arrow
            onClick={() => {
              setPage(page === 1 ? page : page - 1);
            }}
          >
            <ArrowBackIcon color={page === 1 ? "textDisabled" : "primary"} />
          </Arrow>

          <Text>{t("Page %page% of %maxPage%", { page, maxPage })}</Text>
          <Arrow
            onClick={() => {
              setPage(page === maxPage ? page : page + 1);
            }}
          >
            <ArrowForwardIcon
              color={page === maxPage ? "textDisabled" : "primary"}
            />
          </Arrow>
        </PageButtons>

        {/* <React.Fragment key="1">
          <DataRow data={data} />
          <Break />
        </React.Fragment> */}
      </TableWrapper>
    </Wrapper>
  );
};

export default OrdersTable;
