import { serializeTokens } from "./tokens";
import { SerializedFarmConfig } from "./types";

const serializedTokens = serializeTokens();

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    lpSymbol: "JAMON-MATIC LP",
    lpAddresses: {
      80001: "0x3Db864f337AfE5C19255b1c2e066809920078c33",
      137: "0xAfde692EFf6cCff458e7F04cC9f6bd3A22ACB5F2"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.wmatic
  }
];

export default priceHelperLps;
