import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { useTranslation } from "contexts/Localization";
import {
  Flex,
  Box,
  Heading,
  Card,
  CardBody,
  CardHeader,
  Button,
  AutoRenewIcon,
  IconButton,
  MinusIcon,
  AddIcon,
  Input,
  BalanceInput,
  useModal,
  useMatchBreakpoints
} from "@pancakeswap/uikit";
import { PairState } from "hooks/usePairs";
import { Token } from "@pancakeswap/sdk";
import { BIG_TEN } from "utils/bigNumber";
import useToast from "hooks/useToast";
import { usePresaleContract } from "hooks/useContract";
import { PublicPresaleData } from "../../types";
import LockTable from "./LockTable";
import DetailsModal from "./DetailsModal";
import LockModal from "./LockModal";

const IconButtonWrapper = styled.div`
  padding: 0.5em;
  display: flex;
  background: #7b2d2d;
  border-radius: 0 14px 14px 0;
`;

interface OwnerPanelProps {
  presaleAddress: string;
  publicData: PublicPresaleData;
  baseToken: Token;
  saleToken: Token;
}

const OwnerPanel: React.FC<OwnerPanelProps> = ({
  presaleAddress,
  publicData,
  baseToken,
  saleToken
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const { toastSuccess } = useToast();
  const [isLoadingSetWhitelist, setLoadingSetWhitelist] = useState(false);
  const [isLoadingSetMax, setLoadingSetMax] = useState(false);
  const [isLoadingUpdateList, setLoadingUpdateList] = useState(false);
  const [Whitelist, setWhitelist] = useState("");
  const [Max, setMax] = useState("");

  const { status, whitelist } = publicData;

  const contract = usePresaleContract(presaleAddress);

  const handleChangeList = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    setWhitelist(value);
  };

  const handleChangeMax = (value: string) => {
    setMax(value);
  };

  const setWhiteList = async (set: boolean) => {
    setLoadingSetWhitelist(true);
    try {
      const tx = await contract.setWhitelistFlag(set);
      await tx.wait();
      publicData.fetchPresaleData();
      setLoadingSetWhitelist(false);
    } catch (error) {
      console.error(error);
      setLoadingSetWhitelist(false);
    }
  };

  const setMax4User = async () => {
    setLoadingSetMax(true);
    try {
      const maxUser = new BigNumber(Max).times(BIG_TEN.pow(baseToken.decimals));
      const tx = await contract.updateMaxSpendLimit(maxUser.toString());
      await tx.wait();
      publicData.fetchPresaleData();
      setLoadingSetMax(false);
    } catch (error) {
      console.error(error);
      setLoadingSetMax(false);
    }
  };

  const updateWhitelist = async (set: boolean) => {
    setLoadingUpdateList(true);
    try {
      const list = JSON.parse(Whitelist);
      const tx = await contract.editWhitelist(list, set);
      await tx.wait();
      publicData.fetchPresaleData();
      setLoadingUpdateList(false);
    } catch (error) {
      console.error(error);
      setLoadingUpdateList(false);
    }
  };

  const claimTokens = async () => {
    try {
      const tx = await contract.ownerWithdrawTokens();
      await tx.wait();
      publicData.fetchPresaleData();
    } catch (error) {
      console.error(error);
    }
  };

  const [onPresentDetailsModal] = useModal(
    <DetailsModal presaleAddress={presaleAddress} />
  );

  const handleLockSuccess = (amount: string) => {
    toastSuccess(t("Locked %amount% %sym%", { amount, sym: saleToken.symbol }));
  };

  const [onPresentLockModal] = useModal(
    <LockModal token={saleToken} onSuccess={handleLockSuccess} />
  );

  return (
    <Box mb="24px">
      <Card marginBottom={20}>
        <CardHeader>
          <Flex justifyContent="space-between" flexDirection={isMobile || isTablet ? "column" : "row"}>
            <Heading as="h3" scale="md">
              {t("Owner functions")}
            </Heading>
            <Flex flexDirection="row" alignItems="center" justifyContent="center" mt={isMobile || isTablet ? "1rem" : ""}>
              {saleToken && (
                <Button onClick={onPresentLockModal} scale={isMobile || isTablet ? "xs" : "sm"} mr="0.5em" >
                  {" "}
                  {t("Lock %sym%", { sym: saleToken.symbol })}
                </Button>
              )}
              <Button onClick={onPresentDetailsModal} scale={isMobile || isTablet ? "xs" : "sm"}>
                {" "}
                {t("Edit info")}
              </Button>
              
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex justifyContent="space-between" alignItems={isMobile || isTablet ? "center" : ""} flexDirection={isMobile || isTablet ? "column" : "row"}>
            <Flex mr={isMobile || isTablet ? "" : "0.5rem"} marginY={isMobile || isTablet ? "0.5rem" : ""}>
              <Button
                isLoading={isLoadingSetWhitelist}
                onClick={() => setWhiteList(!whitelist)}
                endIcon={
                  isLoadingSetWhitelist ? (
                    <AutoRenewIcon spin color="currentColor" />
                  ) : null
                }
                scale={isMobile || isTablet ? "sm" : "md"}
              >
                {whitelist ? t("Disable whitelist") : t("Enable whitelist")}
              </Button>
            </Flex>
            <Flex mr="0.5rem" marginY={isMobile || isTablet ? "0.5rem" : ""}>
              <BalanceInput
                id="max4user"
                decimals={2}
                placeholder="Max per wallet"
                value={Max}
                onUserInput={handleChangeMax}
                style={{
                  padding: "0 16px",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0"
                }}
              />
              <Button
                isLoading={isLoadingSetMax}
                onClick={setMax4User}
                disabled={Max === "" || Max === "0"}
                style={{
                  borderBottomLeftRadius: "0",
                  borderTopLeftRadius: "0"
                }}
                endIcon={
                  isLoadingSetMax ? (
                    <AutoRenewIcon spin color="currentColor" />
                  ) : null
                }
              >
                {t("Set max")}
              </Button>
            </Flex>
            <Flex width={isMobile || isTablet ? "100%" : "50%"} marginY={isMobile || isTablet ? "0.5rem" : ""}>
              <Input
                id="whitelist"
                name="whitelist"
                value={Whitelist}
                scale="lg"
                placeholder={`["0x0..a","0x0..b","0x0..c",...]`}
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0"
                }}
                onChange={handleChangeList}
              />
              <IconButtonWrapper>
                <IconButton
                  variant="light"
                  mr="6px"
                  scale="sm"
                  disabled={isLoadingUpdateList}
                  onClick={() => updateWhitelist(false)}
                >
                  <MinusIcon color="primary" width="14px" />
                </IconButton>
                <IconButton
                  variant="light"
                  scale="sm"
                  disabled={isLoadingUpdateList}
                  onClick={() => updateWhitelist(true)}
                >
                  <AddIcon color="primary" width="14px" />
                </IconButton>
              </IconButtonWrapper>
            </Flex>
          </Flex>
          <Flex mt="1em">
            {status === "Failed" && (
              <Button onClick={claimTokens}>{t("Withdrawn Tokens")}</Button>
            )}
          </Flex>
          <LockTable
            presaleAddress={presaleAddress}
            publicData={publicData}
            saleToken={saleToken}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default OwnerPanel;
