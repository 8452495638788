import { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import limitOrderAbi from "config/abi/JamonSpotLimit.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getJamonLimitAddress } from "utils/addressHelpers";

const useGetPublicLimitOrdersData = () => {
  const address = getJamonLimitAddress();
  const { fastRefresh } = useRefresh();

  const [state, setState] = useState({    
  totalOrders: 0,
  creditPrice: BIG_ZERO
  });

  const fetchLimitOrderData = useCallback(async () => {
    const orderLimitCalls = [
      {
        address,
        name: "totalOrders",
      },
     {
        address,
        name: "creditPrice",
      } 
    ];

    const [totalOrders, creditPrice ] = await multicall(
      limitOrderAbi,
      orderLimitCalls
    ); 



    setState((prev) => ({
      ...prev,
      totalOrders: Number(totalOrders[0]), 
      creditPrice: new BigNumber(creditPrice[0].toString())
    }));
  }, [address]);

  useEffect(() => {
    fetchLimitOrderData();
  }, [fetchLimitOrderData, fastRefresh]);

  return { ...state, fetchLimitOrderData }

};

export default useGetPublicLimitOrdersData