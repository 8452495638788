import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "contexts/Localization";
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { 
Container, 
Box, 
Grid, 
List, 
ListItem, 
Link, 
Typography,
Divider 
} from "@mui/material";
import { Colors, Fonts } from "components/Style";
import logoFooter from "img/Footer/logoFooter.png";
import coin from "img/coin.png"
import SocialIcons from "./icons"



const FooterContainer = styled(Container)`
    background: ${Colors.BACKGROUND2};
    color: ${Colors.TEXT2};
    margin-top: 1rem;
    border-top: 1px solid #b34a56;
`
const LinkItem = styled(Link)`
    color: ${Colors.TEXT1};
    font-size: ${Fonts.TEXT1};
    font-weight: normal;
    font-family: poppins;
`

const Title = styled(Typography)`
    color: ${Colors.TEXT2};
    font-size: ${Fonts.TITLE};
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
    font-family: poppins;
`

const Footer: React.VFC = () => {
    const { t } = useTranslation();
    const jamonPriceUSD = useCakeBusdPrice();

    return (
        <FooterContainer maxWidth={false}>
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{justifyContent:"center", textAlign:"center", mb:"1rem"}}>
                    <Grid item xs={3}>
                        <List>
                            <ListItem>
                                <Title>{t("About")}</Title>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Contact")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/brand-and-logos" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Brand")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us/social-accounts-and-communities" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Community")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/tokenomics/jamon-y-jshare" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Jamon Token")}
                                </LinkItem>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={3}>
                        <List>
                            <ListItem>
                                <Title>{t("Help")}</Title>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/contact-us/customer-support" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Customer Support")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/click-here-for-help/troubleshooting-errors" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Troubleshooting")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/guides/get-started" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Guides")}
                                </LinkItem>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={2}>
                        <List>
                            <ListItem>
                                <Title>{t("Developers")}</Title>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://github.com/jamonswap" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Github")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Documentation")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_Jamonswap.pdf" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Audits")}
                                </LinkItem>
                            </ListItem>
                            <ListItem>
                                <LinkItem href="https://jamonswap.gitbook.io/docs/products/api" underline="none" target="_blank" rel="noopener noreferrer">
                                    {t("Api")}
                                </LinkItem>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={2}>
                        <NavLink to="/">
                            <Box
                                component="img"
                                sx={{
                                    width: 180,
                                }}
                                alt="footer-logo"
                                src={logoFooter}
                            />
                        </NavLink>
                    </Grid>
                </Grid>
                <Divider sx={{background: Colors.BORDER1, mb:"1rem"}}/>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex"} }}>
                    <SocialIcons/>
                    <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}}}>
                    <Link
                        href="/swap?outputCurrency=0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2"
                        rel="noopener noreferrer"
                        underline="none"
                        sx={{ color: "#FEBF32" }}
                    >
                        <Grid container>
                        <Grid item xs>
                            <Box
                            component="img"
                            sx={{
                            height: 18,
                            width: 18,
                            mr:2,
                            }}
                            alt="coin-logo"
                            src={coin}
                            />
                        </Grid>
                        <Grid item mt={0.3} color="#FFFFFF">
                            {jamonPriceUSD?.toFixed(6)}
                        </Grid>
                        </Grid>
                    </Link>
                    </Box>
                </Box>
            </Container>
        </FooterContainer>    
    );
}

export default Footer;