import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Text,
  useMatchBreakpoints,
  Skeleton,
  Flex,
  Box,
  Button
} from "@pancakeswap/uikit";
import {
  SerializedToken,
  SerializedPoolRewadsConfig
} from "config/constants/types";
import { useTranslation } from "contexts/Localization";
import { TokenPairImage } from "components/TokenImage";
import useToast from "hooks/useToast";
import { getBalanceNumber, getBalanceAmount } from "utils/formatBalance";
import { BIG_ZERO } from "utils/bigNumber";
import { useJShareVaultContract } from "hooks/useContract";
import { usePairPrice } from "hooks/useBUSDPrice";
import BigNumber from "bignumber.js";
import { WalletJShareStakeData } from "views/Pools/types";
import Balance from "components/Balance";
import useGetWalletJShareRewards from "views/Pools/V2/hooks/useGetWalletJShareRewards";

import {
  CellContent,
  StyledCell
} from "../../../../components/PoolsTable/Cells/BaseCell";

interface JamonRowProps {
  account: string;
  walletData: WalletJShareStakeData;
  config: SerializedPoolRewadsConfig;
  userDataLoaded: boolean;
}

const StyledRow = styled.div<{ isMobile?: boolean }>`
  background-color: transparent;
  display: ${({ isMobile }) => (isMobile ? "inline-block" : "flex")};
  align-items: ${({ isMobile }) => (isMobile ? "inline-block" : "flex-start")};
  justify-content: space-between;
  cursor: pointer;
`;

const AprLabelContainer = styled(Flex)`
  &:hover {
    opacity: 0.5;
  }
`;

const PairRow: React.FC<JamonRowProps> = ({
  account,
  walletData,
  config,
  userDataLoaded
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints();
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const token = config.lpToken;

  const contract = useJShareVaultContract();

  const rewards = useGetWalletJShareRewards(token.address);
  const hasEarnings = rewards.PendingReward.gt(0);

  const pairPrice = usePairPrice(token.address);
  const earnedInUSD = rewards.PendingReward.times(pairPrice);

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  const handleHarvest = async () => {
    try {
      setPendingTx(true);
      const tx = await contract.harvestToken(token.address);
      await tx.wait();

      toastSuccess(t("Success!"), t("You have successfully removed stake."));
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      setPendingTx(false);
    }
  };

  return (
    <>
      <StyledRow role="row">
        <StyledCell role="cell" isMobile={isMobile}>
          {isMobile ? (
            ""
          ) : (
            <TokenPairImage
              primaryToken={config.basetoken}
              secondaryToken={config.quoteToken}
              height={32}
              width={32}
              mr="16px"
            />
          )}
          <CellContent>
            <Text bold={!isMobile} small={isMobile}>
              {t("%symbol%", { symbol: token.symbol })}
            </Text>
          </CellContent>
        </StyledCell>
        <StyledCell role="cell" isMobile={isMobile}>
          <CellContent>
            <Text fontSize="12px" color="textSubtle" textAlign="left">
              {t("Earned")}
            </Text>
            {!userDataLoaded && account ? (
              <Skeleton width="80px" height="16px" />
            ) : (
              <>
                <Flex>
                  <Box mr="8px" height="32px">
                    <Balance
                      mt="4px"
                      bold={!isMobile}
                      fontSize={isMobile ? "12px" : "16px"}
                      color={hasEarnings ? "primary" : "textDisabled"}
                      decimals={hasEarnings ? 10 : 1}
                      value={getBalanceNumber(rewards.PendingReward)}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </CellContent>
        </StyledCell>
        {isMobile ? (
          ""
        ) : (
          <StyledCell role="cell" isMobile={isMobile}>
            <CellContent>
              <Text fontSize="12px" color="textSubtle" textAlign="left">
                {t("USD")}
              </Text>
              <AprLabelContainer
                alignItems="center"
                justifyContent="space-between" /* {...props} */
              >
                {hasEarnings ? (
                  <Balance
                    mt="4px"
                    bold={!isMobile}
                    fontSize={isMobile ? "12px" : "16px"}
                    color={hasEarnings ? "primary" : "textDisabled"}
                    decimals={hasEarnings ? 4 : 1}
                    value={getBalanceNumber(earnedInUSD)}
                  />
                ) : (
                  <Skeleton width="80px" height="16px" />
                )}
              </AprLabelContainer>
            </CellContent>
          </StyledCell>
        )}

        <StyledCell isMobile={isMobile}>
          <Button scale={isMobile || isTablet ? "sm" : "md"} disabled={!hasEarnings} onClick={handleHarvest}>
            <Text color={hasEarnings ? "background" : "textDisabled"} bold fontSize={isMobile || isTablet ? "0.7rem" : "1rem"}>{t("Harvest")}</Text>
          </Button>
        </StyledCell>
      </StyledRow>
    </>
  );

  return null;
};

export default PairRow;
