import React from "react";
import { useTranslation } from "contexts/Localization";
import { getBscScanLink } from "utils";
import {
  Flex,
  Link,
  TimerIcon,
  Text,
  Skeleton,
} from "@pancakeswap/uikit";
import useCurrentBlockTimestamp from "hooks/useCurrentBlockTimestamp";
import getTimePeriods from "utils/getTimePeriods";
import { PublicPresaleData } from "../types";

interface TimerProps {
  data: PublicPresaleData;
}

const Timer: React.FC<TimerProps> = ({ data }) => {
  const { t } = useTranslation();
  const { status, startAt, endAt } = data;

  const blockTimestamp = useCurrentBlockTimestamp();
  const now = () => {
    if (blockTimestamp) return blockTimestamp.toNumber() * 1000;
    return Date.now();
  };

  const secondsUntilStart = (startAt * 1000) - now();
  const secondsUntilEnd = now() - (endAt * 1000);

  const countdownToUse =
    status === "ComingSoon" ? secondsUntilStart/1000 : secondsUntilEnd/1000;
  const timeUntil = getTimePeriods(countdownToUse);
  const suffix = status === "ComingSoon" ? t("STARTS IN") : t("ENDS IN");
  return (
    <Flex justifyContent="center" mb="24px">
      {status === "NotExist" ? (
        <Skeleton animation="pulse" variant="rect" width="100%" height="48px" />
      ) : status !== "Failed" && status !== "Completed" ? (
        <>
          {countdownToUse !== 0 ? (
            <Flex alignItems="center">
              <TimerIcon />
              <Text bold mr="6px">
                {suffix}
              </Text>
              <Text>
                {t("%day%d %hour%h %minute%m", {
                  day: timeUntil.days,
                  hour: timeUntil.hours,
                  minute: timeUntil.minutes,
                })}
              </Text>
            </Flex>
          ) : (
            <Text>{t("Starting")}</Text>
          )}
        </>
      ) : (
        ""
      )}
    </Flex>
  );
};

export default Timer;
