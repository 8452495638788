import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Button,
  BalanceInput,
  WarningIcon,
  Flex
} from "@pancakeswap/uikit";
import {
    PublicJV2StakeData,
    WalletJV2StakeData
} from "views/Pools/types";
import { useTranslation } from "contexts/Localization";
import { useJamonVaultContract, useERC20 } from "hooks/useContract";
import { getBalanceAmount } from "utils/formatBalance";
import ApproveConfirmButtons, {
  ButtonArrangement
} from "components/ApproveConfirmButtons";

import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { DEFAULT_TOKEN_DECIMAL } from "config";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";

interface Props {
  publicData: PublicJV2StakeData;
  walletData: WalletJV2StakeData;
  token: string;
  reStake: boolean;
  userCurrencyBalance: BigNumber;
  onSuccess: (amount: BigNumber) => void;
  onDismiss?: () => void;
}

const multiplierValues = [1];

// Default value for transaction setting, tweak based on BSC network congestion.
const gasPrice = BIG_TEN.times(BIG_TEN.pow(BIG_NINE)).toString();

const StakeJV2Modal: React.FC<Props> = ({
  token,
  userCurrencyBalance,
  onDismiss,
  onSuccess
}) => {
  const contract = useJamonVaultContract();
  const [value, setValue] = useState("");
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const valueWithTokenDecimals = new BigNumber(value).times(
    DEFAULT_TOKEN_DECIMAL
  );
  const raisingTokenContract = useERC20(token);

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await raisingTokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        return currentAllowance.gt(0);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      return raisingTokenContract.approve(
        contract.address,
        ethers.constants.MaxUint256);
    },
    onConfirm: () => {
        return contract.stake(valueWithTokenDecimals.toString());
      
    },
    onSuccess: async () => {
      onSuccess(valueWithTokenDecimals);
      onDismiss();
    }
  });

  return (
    <Modal title={t("Stake JAMON")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
      <Flex justifyContent="pace-between" mb="18px">
          <Text color="primary">
            <WarningIcon color="primary" mb={-1} />{" "}
            {t(
              " The Jamon stake has a penalty during the first year of stake of 1% per month, being an initial 12% and reducing 1% per month elapsed."
            )}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb="8px">
          <Text>{t("Commit")}:</Text>
          <Flex flexGrow={1} justifyContent="flex-end">
            <Text>JAMON</Text>
          </Flex>
        </Flex>
        <BalanceInput
          value={value}
          onUserInput={setValue}
          isWarning={valueWithTokenDecimals.isGreaterThan(userCurrencyBalance)}
          decimals={18}
          mb="8px"
        />
        <Text color="textSubtle" textAlign="right" fontSize="12px" mb="16px">
          {t("Balance: %balance%", {
            balance: getBalanceAmount(userCurrencyBalance, 18).toString()
          })}
        </Text>
        <Flex justifyContent="end" mb="16px">
          {multiplierValues.map((multiplierValue, index) => (
            <Button
              key={multiplierValue}
              scale="xs"
              variant="primary"
              onClick={() =>
                setValue(
                  getBalanceAmount(
                    userCurrencyBalance.times(multiplierValue)
                  ).toString()
                )
              }
              mr={index < multiplierValues.length - 1 ? "8px" : 0}
            >
              {multiplierValue * 100}%
            </Button>
          ))}
        </Flex>
        <ApproveConfirmButtons
          buttonArrangement={ButtonArrangement.SEQUENTIAL}
          isApproveDisabled={isConfirmed || isConfirming || isApproved}
          isApproving={isApproving}
          isConfirmDisabled={
            !isApproved ||
            isConfirmed ||
            valueWithTokenDecimals.isNaN() ||
            valueWithTokenDecimals.eq(0)
          }
          isConfirming={isConfirming}
          onApprove={handleApprove}
          onConfirm={handleConfirm}
        />
      </ModalBody>
    </Modal>
  );
};

export default StakeJV2Modal;
