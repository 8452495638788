import React from "react";
import styled from "styled-components";
import {
  Text,
  useMatchBreakpoints,
  Skeleton,
  Flex,
  Box,
  Button
} from "@pancakeswap/uikit";
import { SerializedToken } from "config/constants/types";
import { useTranslation } from "contexts/Localization";
import { TokenImage } from "components/TokenImage";
import useToast from "hooks/useToast";
import { getBalanceNumber } from "utils/formatBalance";
import { useERC20, useJShareVaultContract } from "hooks/useContract";
import { WalletJShareStakeData } from "views/Pools/types";
import Balance from "components/Balance";
import useGetWalletJShareRewards from "views/Pools/V2/hooks/useGetWalletJShareRewards";
import BigNumber from "bignumber.js";
import { serializeTokens } from "config/constants/tokens";

import {
  CellContent,
  StyledCell
} from "../../../../components/PoolsTable/Cells/BaseCell";

const serializedTokens = serializeTokens();

interface JamonRowProps {
  account: string;
  walletData: WalletJShareStakeData;
  token: SerializedToken;
  userDataLoaded: boolean;
}

const StyledRow = styled.div<{ isMobile?: boolean }>`
  background-color: transparent;
  display: ${({ isMobile }) => (isMobile ? "inline-block" : "flex")};
  align-items: ${({ isMobile }) => (isMobile ? "inline-block" : "flex-start")};
  justify-content: space-between;
  cursor: pointer;
`;

const AprLabelContainer = styled(Flex)`
  &:hover {
    opacity: 0.5;
  }
`;

const TokenRow: React.FC<JamonRowProps> = ({
  account,
  walletData,
  token,
  userDataLoaded
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints();
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const contract = useJShareVaultContract();

  const rewards = useGetWalletJShareRewards(token.address);
  const hasEarnings = rewards.PendingReward.gt(0);

  const raisingTokenContract = useERC20(serializedTokens.jshare.address);

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  const handleHarvest = async () => {
    try {
      setPendingTx(true);
      const response = await raisingTokenContract.allowance(
        account,
        contract.address
      );
      const currentAllowance = new BigNumber(response.toString());
      if (currentAllowance.gt(0)) {
        const disable = await raisingTokenContract.approve(
          contract.address,
          "0"
        );
        await disable.wait();
      }
      const tx = await contract.harvestToken(token.address);
      await tx.wait();

      toastSuccess(t("Success!"), t("You have successfully harvest tokens."));
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      setPendingTx(false);
    }
  };

  return (
    <>
      <StyledRow role="row">
        <StyledCell role="cell" isMobile={isMobile}>
          {isMobile ? (
            ""
          ) : (
            <TokenImage token={token} height={32} width={32} mr="16px" />
          )}

          <Text bold={!isMobile} small={isMobile} maxWidth="30px" mr="2rem">
            {t("%symbol%", { symbol: token.symbol })}
          </Text>
        </StyledCell>
        <StyledCell role="cell" isMobile={isMobile}>
          <CellContent>
            <Text fontSize="12px" color="textSubtle">
              {t("Earned")}
            </Text>
            {!userDataLoaded && account ? (
              <Skeleton width="80px" height="16px" />
            ) : (
              <>
                <Flex>
                  <Box>
                    <Balance
                      mt="4px"
                      bold={!isMobile}
                      fontSize={isMobile ? "12px" : "16px"}
                      color={hasEarnings ? "primary" : "textDisabled"}
                      decimals={hasEarnings ? 4 : 1}
                      value={getBalanceNumber(rewards.PendingReward)}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </CellContent>
        </StyledCell>
        {isMobile || isTablet ? (
          ""
        ) : (
          <StyledCell role="cell" isMobile={isMobile}>
            <CellContent>
              <Text fontSize="12px" color="textSubtle">
                {t("USD")}
              </Text>
              <AprLabelContainer
                alignItems="center"
                justifyContent="space-between" /* {...props} */
              >
                {hasEarnings ? (
                  <Skeleton width="80px" />
                ) : (
                  <Text color="textDisabled" lineHeight="1rem">
                    0
                  </Text>
                )}
              </AprLabelContainer>
            </CellContent>
          </StyledCell>
        )}
        <StyledCell isMobile={isMobile}>
          <Button
            scale={isMobile || isTablet ? "sm" : "md"}
            disabled={!hasEarnings}
            onClick={handleHarvest}
          >
            <Text
              color={hasEarnings ? "background" : "textDisabled"}
              bold
              fontSize={isMobile || isTablet ? "0.7rem" : "1rem"}
            >
              {t("Harvest")}
            </Text>
          </Button>
        </StyledCell>
      </StyledRow>
    </>
  );
};

export default TokenRow;
