import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import limitOrderAbi from "config/abi/JamonSpotLimit.json";
import { getJamonLimitAddress } from "utils/addressHelpers";


const useGetWalletLimitOrderData = () => {
  const { slowRefresh } = useRefresh();
  const [state, setState] = useState({
    credits: 0,
    isPendingTx: false
  });

  const { account } = useWeb3React();
  const address = getJamonLimitAddress();

  const setPendingTx = (status: boolean) =>
    setState((prevState) => ({
      ...prevState,
      isPendingTx: status
    }));

  const fetchLimitOrderData = useCallback(async () => {
    const orderLimitCalls = [
      {
        address,
        name: "credits",
        params: [account]
      }
    ];

    const [
      Credits
    ] = await multicall(limitOrderAbi, orderLimitCalls);

    setState((prev) => ({
      ...prev,
      credits: Number(Credits[0]), 
    }));
  }, [account, address]);

  useEffect(() => {
    if (account) {
        fetchLimitOrderData();
    }
  }, [account, fetchLimitOrderData, slowRefresh]);

  return { ...state, setPendingTx, fetchLimitOrderData };
};

export default useGetWalletLimitOrderData;
