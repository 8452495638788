import { serializeTokens } from "./tokens";
import { SerializedPoolConfig, PoolCategory } from "./types";

const serializedTokens = serializeTokens();

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.jamonV2,
    earningToken: serializedTokens.jstlp,
    contractAddress: {
      80001: "0x418F264B2D623a3eBb3Ac2e47440021ba7ce6d3B",
      137: "0x4D9E580B86cFD6Eb2f518b89545b8C76722Bc626"
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: "0.01",
    sortOrder: 1,
    isFinished: true
  }
  /*   {
    sousId: 1,
    stakingToken: serializedTokens.dsg,
    earningToken: serializedTokens.jamon,
    contractAddress: {
      80001: '0x4026Da3Fc9EDD38773c2951dA825498Fb47D453A',
      137: '0x4026Da3Fc9EDD38773c2951dA825498Fb47D453A',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.55',
    sortOrder: 999,
    isFinished: true,
  },    */
];

export default pools;
