import React from "react";
import { Currency, Pair } from "@pancakeswap/sdk";
import {
  Button,
  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  HelpIcon,
  useTooltip
} from "@pancakeswap/uikit";
import styled from "styled-components";
import { useTranslation } from "contexts/Localization";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCurrencyBalance } from "../../state/wallet/hooks";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import { CurrencyLogo, DoubleCurrencyLogo } from "../Logo";

import { RowBetween } from "../Layout/Row";
import { Input as NumericalInput, InputNumber, TargetInput } from "./NumericalInput";

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) =>
    selected ? "0.75rem 0.5rem 0.75rem 1rem" : "0.75rem 0.75rem 0.75rem 1rem"};
`;
const CurrencySelectButton = styled(Button).attrs({
  variant: "secondary",
  scale: "sm"
})`
  padding: 1.2rem 0.5rem;
`;
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
`;
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: "20px";
  z-index: 1;
`;
const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`;
interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: string;
  onCurrencySelect: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
}

interface TargetPricePanelProps {
  value: string;
  onUserInput: (value: string) => void;
  label?: string;
  id: string;
}

interface CreditsProps {
  value: number;
  onUserInput: (value: number) => void;
  label?: string;
  id: string;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const { t } = useTranslation();

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />
  );
  return (
    <Box id={id}>
      <Flex mb="6px" alignItems="center" justifyContent="space-between">
        <CurrencySelectButton
          className="open-currency-select-button"
          selected={!!currency}
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal();
            }
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {pair ? (
              <DoubleCurrencyLogo
                currency0={pair.token0}
                currency1={pair.token1}
                size={16}
                margin
              />
            ) : currency ? (
              <CurrencyLogo
                currency={currency}
                size="24px"
                style={{ marginRight: "8px" }}
              />
            ) : null}
            {pair ? (
              <Text id="pair" bold>
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair" bold color="#ABAFC4">
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length
                    )}`
                  : currency?.symbol) || t("Select a currency")}
              </Text>
            )}
            {!disableCurrencySelect && <ChevronDownIcon />}
          </Flex>
        </CurrencySelectButton>
        {account && (
          <Text
            onClick={onMax}
            color="primary"
            fontSize="14px"
            style={{ display: "inline", cursor: "pointer" }}
          >
            {!hideBalance && !!currency
              ? t("Balance: %balance%", {
                  balance:
                    selectedCurrencyBalance?.toSignificant(6) ?? t("Loading")
                })
              : " -"}
          </Text>
        )}
      </Flex>
      <InputPanel>
        <Container>
          <LabelRow>
            <RowBetween>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val);
                }}
              />
            </RowBetween>
          </LabelRow>
          <InputRow selected={disableCurrencySelect}>
            {account && currency && showMaxButton && label !== "To" && (
              <Button onClick={onMax} scale="xs" variant="primary">
                MAX
              </Button>
            )}
          </InputRow>
        </Container>
      </InputPanel>
    </Box>
  );
}

export function CurrencyOutputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const { t } = useTranslation();

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />
  );
  return (
    <Box id={id}>
      <Flex mb="6px" alignItems="center" justifyContent="space-between">
        <CurrencySelectButton
          className="open-currency-select-button"
          selected={!!currency}
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal();
            }
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {pair ? (
              <DoubleCurrencyLogo
                currency0={pair.token0}
                currency1={pair.token1}
                size={16}
                margin
              />
            ) : currency ? (
              <CurrencyLogo
                currency={currency}
                size="24px"
                style={{ marginRight: "8px" }}
              />
            ) : null}
            {pair ? (
              <Text id="pair" bold>
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair" bold>
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length
                    )}`
                  : currency?.symbol) || t("Select a currency")}
              </Text>
            )}
            {!disableCurrencySelect && <ChevronDownIcon />}
          </Flex>
        </CurrencySelectButton>
        {account && (
          <Text
            onClick={onMax}
            color="textSubtle"
            fontSize="14px"
            style={{ display: "inline", cursor: "pointer" }}
          >
            {!hideBalance && !!currency
              ? t("Balance: %balance%", {
                  balance:
                    selectedCurrencyBalance?.toSignificant(6) ?? t("Loading")
                })
              : " -"}
          </Text>
        )}
      </Flex>
      {/*   <InputPanel>
        <Container>
          <LabelRow>
            <RowBetween>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
            </RowBetween>
          </LabelRow>
         <InputRow selected={disableCurrencySelect}>
            {account && currency && showMaxButton && label !== 'To' && (
              <Button onClick={onMax} scale="xs" variant="secondary">
                MAX
              </Button>
            )}
          </InputRow> 
        </Container>
      </InputPanel> */}
    </Box>
  );
}

export function TargePriceInput({
  value,
  onUserInput,
  label,
  id
}: TargetPricePanelProps) {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();

  return (
    <Box id={id}>
      <Flex mb="6px" alignItems="center" justifyContent="space-between">
        {/*  <CurrencySelectButton
          className="open-currency-select-button"
          selected={!!currency}
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal()
            }
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
            ) : currency ? (
              <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
            ) : null}
            {pair ? (
              <Text id="pair" bold>
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair" bold>
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length,
                    )}`
                  : currency?.symbol) || t('Select a currency')}
              </Text>
            )}
            {!disableCurrencySelect && <ChevronDownIcon />}
          </Flex>
        </CurrencySelectButton> */}
        <Text
          color="textSubtle"
          fontSize="14px"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {t("Target price")}
        </Text>
      </Flex>
      <InputPanel>
        <Container>
          <LabelRow>
            <RowBetween>
              <TargetInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val);
                }}
              />
            </RowBetween>
          </LabelRow>
          <InputRow selected>{/* <Text> </Text> */}</InputRow>
        </Container>
      </InputPanel>
    </Box>
  );
}



export function CreditsInput({ value, onUserInput, label, id }: CreditsProps) {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();


  const { targetRef, tooltip, tooltipVisible } = useTooltip(t("1 credit equals 7 days, 2 credits equals 14 days..."),{
    placement: "bottom"
  });
  

  return (
    <Box id={id} mt={3}>
      {account && (
        <>
          {" "}
          <Flex mb="6px" alignItems="center" justifyContent="space-between">            
            <Text
              color="textSubtle"
              fontSize="14px"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {t("Number of Credits")}
            </Text>
            {tooltipVisible && tooltip}
            <Box ref={targetRef} ml="0.5rem">
                <HelpIcon color="primary" />
            </Box>
          </Flex>
          <InputPanel>
            <Container>
              <LabelRow>
                <RowBetween>
                  <InputNumber
                    className="token-amount-input"
                    value={value}
                    onUserInput={(val) => {
                      onUserInput(val);
                    }}
                  />
                </RowBetween>
              </LabelRow>
              <InputRow selected>{/* <Text> </Text> */}</InputRow>
            </Container>
          </InputPanel>{" "}
        </>
      )}
    </Box>
  );
}
