import React from "react";
import { useTranslation } from "contexts/Localization";
import { Token } from "@pancakeswap/sdk";
import { Flex, Box, Text, Skeleton } from "@pancakeswap/uikit";
import { getFullDisplayBalance } from "utils/formatBalance";
import { SecondaryLabel } from "../styles";
import { RoundData } from "../../types";

interface RoundsDetailsProps {
  rounds: RoundData[];
  saleToken: Token;
  baseToken: Token;
}

const RoundDetails: React.FC<RoundsDetailsProps> = ({
  rounds,
  saleToken,
  baseToken
}) => {
  const { t } = useTranslation();
  const baseSymbol = baseToken.symbol === "WMATIC" ? "MATIC" : baseToken.symbol;
  return (
    <Box mt="16px">
      {" "}
      {rounds.map(({ startDate, endDate, amount, price, hardcap }, index) => (
        <Box mb="20px">
          <Flex justifyContent="center">
            <SecondaryLabel>
              {index === 0
                ? t("Early Access")
                : t(`Round %number%`, { number: index })}
            </SecondaryLabel>
          </Flex>
          <hr />
          <Flex justifyContent="space-between">
            <SecondaryLabel>{t("starts")}</SecondaryLabel>
            <Text ml="10px" mt={-2}>
              {startDate ? (
                new Date(startDate * 1000).toLocaleString()
              ) : (
                <Skeleton width="100%" />
              )}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <SecondaryLabel>{t("ends")}</SecondaryLabel>
            <Text ml="10px" mt={-2}>
              {endDate ? new Date(endDate * 1000).toLocaleString() : <Skeleton width="100%" />}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <SecondaryLabel>{t("amount")}</SecondaryLabel>
            <Text ml="10px" mt={-2}>
              {amount && saleToken ? (
                t("%amount% %sym%", {
                  amount: getFullDisplayBalance(amount, saleToken.decimals),
                  sym: saleToken.symbol
                })
              ) : (
                <Skeleton width="100%" />
              )}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <SecondaryLabel>{t("price")}</SecondaryLabel>
            <Text ml="10px" mt={-2}>
              {price && baseToken ? (
                t("%amount% per %sym%", {
                  amount: getFullDisplayBalance(price, saleToken.decimals),
                  sym: baseSymbol
                })
              ) : (
                <Skeleton width="100%" />
              )}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <SecondaryLabel>{t("hardcap")}</SecondaryLabel>
            <Text ml="10px" mt={-2}>
              {hardcap && baseToken ? (
                t("%amount% %sym%", {
                  amount: getFullDisplayBalance(hardcap, baseToken.decimals, 2),
                  sym: baseSymbol
                })
              ) : (
                <Skeleton width="100%" />
              )}
            </Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

export default RoundDetails;
