import BigNumber from 'bignumber.js'
import { serializeTokens } from './tokens'
import { SerializedBonusConfig } from './types'

// id 0xe3a04ece34d611b431aca56b8d3a48aefc2f3360bfe714618e7ac44529823b7a

const serializedTokens = serializeTokens()

const bonus: SerializedBonusConfig[] = [  
  {
    id: '0x2638e3a0949f38336b6bd18262aa586574f9576e9f9c767d4d2ccbc73f04d684',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x3Db864f337AfE5C19255b1c2e066809920078c33',
      137: '0xAfde692EFf6cCff458e7F04cC9f6bd3A22ACB5F2',
    },
    name: 'MATIC-JAMON',
    multiplier: 180,
    hardcap: new BigNumber("30000"),
    startBlock: 26192799,
    endBlock: 29648799,
    basetoken: serializedTokens.wmatic,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    id: '0x62657a4a60b8588ed7658078ff54055a05b13346d7fb3fd5c495c66c017a38aa',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x301495B8F5E021CC3fa8E058d966448E13Db3bcA',
      137: '0xc4cc91123dfDbb5275AD5931AF0Be44413f42766',
    },
    name: 'USDC-JAMON',
    multiplier: 180,
    hardcap: new BigNumber("30000"),
    startBlock: 26192799,
    endBlock: 29648799,
    basetoken: serializedTokens.usdc,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    id: '0xc4b8244ccd5b190dbecb890911d10ad892fbb76164e66dde057883f0f50646f5',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0xbc50f3E288aC6eBa868a7f53F784650bAEB01bA5',
    },
    name: 'LINKv2-JAMON',
    multiplier: 180,
    hardcap: new BigNumber("30000"),
    startBlock: 26192799,
    endBlock: 29648799,
    basetoken: serializedTokens.link677,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    id: '0xc7eba168f4bddc4622ed97301e19617b26a6cd41360e38edcf3fbbed8d016bd6',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0xfF809F74F1B4Da103019f2338e1F8a9c0461Ee3C',
    },
    name: 'USDT-JAMON',
    multiplier: 160,
    hardcap: new BigNumber("50000"),
    startBlock: 26814646,
    endBlock: 29648799,
    basetoken: serializedTokens.usdt,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    id: '0x6e5e625807a3b9cbede529125fb38e7e2c3ecc85450267a61b9e8a63e75d1f53',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x7954006f16b4e6c7cb4073bc1d382cd3d32ce4c5',
    },
    name: 'MATIC-USDC',
    multiplier: 180,
    hardcap: new BigNumber("30000"),
    startBlock: 26192799,
    endBlock: 29648799,
    basetoken: serializedTokens.wmatic,
    quoteToken: serializedTokens.usdc,
  },
  {
    id: '0xc8618d574a39b7a38624a200b42224a00ed97054efcd7b54e13052a6475ebeb3',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x9178202c98AFDD021C1e7792FD6A7593d245063D',
    },
    name: 'MATIC-JSHARE',
    multiplier: 140,
    hardcap: new BigNumber("10000"),
    startBlock: 26278310,
    endBlock: 29648799,
    basetoken: serializedTokens.wmatic,
    quoteToken: serializedTokens.jshare,
  },
  {
    id: '0x725d0bfceef2c2c4451f7e7c29fd3ca33ccea15521fc3e8183a97db553d83b39',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x56F6F2De33634cB3c77bd3701d60D5a3a5c12d36',
    },
    name: 'WBTC-JAMON',
    multiplier: 140,
    hardcap: new BigNumber("10000"),
    startBlock: 26278310,
    endBlock: 29648799,
    basetoken: serializedTokens.wbtc,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    id: '0x55a7842853f92c2331098069ac913e24590970111f0c5a27cfa15c26cf01493a',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x92De3094455057428010347B11cA2102C7dFB63E',
    },
    name: 'JUSD-MATIC',
    multiplier: 180,
    hardcap: new BigNumber("100000"),
    startBlock: 27330588,
    endBlock: 29758799,
    basetoken: serializedTokens.jusd,
    quoteToken: serializedTokens.wmatic,
  },
  {
    id: '0x1c6399533f7586ccccde0143bd273aa8aff88377327ac7fd7460554b109bf412',
    isActive: false,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x8F35B2089e45DF3bf95726C39A0b2208b5997E5c',
    },
    name: 'WETH-JAMON',
    multiplier: 140,
    hardcap: new BigNumber("10000"),
    startBlock: 26278310,
    endBlock: 29648799,
    basetoken: serializedTokens.weth,
    quoteToken: serializedTokens.jamonV2,
  },
 /*  {
    id: '0xf33d9c1d011d5dd8fd79a77cf5a2a633f7c29d031daa8dd98daf923e9eeef6ac',
    isActive: true,
    stLPReawrd: true,
    lpAddress: {
      80001: '0x482012FFF165De4259f670B19d7764204a346f1E',
      137: '0x410C91C4a0f30Aa2CD20B796f8fAE8f92095b46c',
    },
    name: 'DSG-MATIC',
    multiplier: 120,
    hardcap: new BigNumber("10000"),
    startBlock: 26278310,
    endBlock: 29648799,
    basetoken: serializedTokens.dsg,
    quoteToken: serializedTokens.wmatic,
  },
  */

]

export default bonus
