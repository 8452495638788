import React, { useState } from "react";
import styled from "styled-components";
import {
  Text,
  Skeleton,
  Flex,
  CardRibbon,
  Heading,
  CardHeader,
  CardBody,
  CardFooter,
  ExpandableLabel,
  useTooltip,
  LinkExternal,
  HelpIcon,
  Button,
  MetamaskIcon,
  useModal
} from "@pancakeswap/uikit";
import { BASE_BSC_SCAN_URL } from "config";
import { registerToken } from "utils/wallet";
import { ManualPoolTag } from "components/Tags";
import { useTranslation } from "contexts/Localization";
import { TokenImage } from "components/TokenImage";
import { serializeTokens } from "config/constants/tokens";
import { getBalanceNumber } from "utils/formatBalance";
import Balance from "components/Balance";
import useGetPublicJstlpStakeData from "views/Pools/V2/hooks/useGetPublicJstlpStakeData";
import useGetWalletJstlpStakeData from "views/Pools/V2/hooks/useGetWalletJstlpStakeData";
import { StyledCard } from "../StyledCard";
import Info from "../../StakeTable/JSTLP/Info";
import Actions from "../../StakeTable/JSTLP/Actions";
import RewardsModalJSTLP from "./RewardsModalJSTLP";

interface JstlpCardProps {
  account: string;
  userDataLoaded: boolean;
}

const Wrapper = styled(CardHeader)<{
  isFinished?: boolean;
  background?: string;
}>`
  background: ${({ isFinished, background, theme }) =>
    isFinished
      ? theme.colors.backgroundDisabled
      : theme.colors.gradients[background]};
  border-radius: ${({ theme }) =>
    `${theme.radii.card} ${theme.radii.card} 0 0`};
`;

const ExpandableButtonWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0;
  }
`;

const serializedTokens = serializeTokens();

const JSTLPCard: React.FC<JstlpCardProps> = ({ account, userDataLoaded }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const isFinished = true;

  const publicData = useGetPublicJstlpStakeData();
  const walletData = useGetWalletJstlpStakeData();
  const totalStaked = publicData.TotalStaked;
  const totalStakedBalance = getBalanceNumber(totalStaked);
  const totalHolders = publicData.TotalHolders;
  const showLoading = totalHolders > 0;
  const isMetaMaskInScope = !!window.ethereum?.isMetaMask;
  const inStake = walletData.InStake;

  const [onPresentRewardsModal] = useModal(
    <RewardsModalJSTLP
      account={account}
      walletData={walletData}
      userDataLoaded={userDataLoaded}
    />
  );

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t("You must harvest and compound your earnings from this pool manually."),
    {
      placement: "bottom"
    }
  );

  const {
    targetRef: totalStakedTargetRef,
    tooltip: totalStakedTooltip,
    tooltipVisible: totalStakedTooltipVisible
  } = useTooltip(t("Total amount of JSTLP staked in this pool"), {
    placement: "bottom"
  });

  return (
    <>
      <StyledCard
        isFinished={isFinished}
        ribbon={
          isFinished && (
            <CardRibbon variantColor="textDisabled" text={t("Finished")} />
          )
        }
      >
        <Wrapper isFinished={isFinished} background="bubblegum">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex flexDirection="column">
              <Heading color={isFinished ? "textDisabled" : "body"} scale="md">
                {t("Earn Rewards")}
              </Heading>
              <Text color={isFinished ? "textDisabled" : "textSubtle"}>
                {t("Stake JSTLP")}
              </Text>
            </Flex>
            <TokenImage token={serializedTokens.jstlp} width={64} height={64} />
          </Flex>
        </Wrapper>
        <CardBody>
          <Flex justifyContent="space-between" mb="0.5rem">
            <Text fontFamily="Poppins">{t("Rewards")}: </Text>
            <Text fontFamily="Poppins" color="primary">
              {t("0.1% of txs")}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mb="0.5rem">
            <Text fontFamily="Poppins">{t("Holders")}: </Text>
            {!showLoading ? (
              <Skeleton width="80px" height="16px" />
            ) : (
              <Text fontFamily="Poppins" color="primary">
                {totalHolders}
              </Text>
            )}
          </Flex>
          <Flex justifyContent="space-between" mb="0.5rem">
            <Text fontFamily="Poppins" color="textSubtle" textAlign="left">
              {t("Total staked")}:
            </Text>
            <Flex alignItems="flex-start">
              {totalStaked && totalStaked.gte(0) ? (
                <Balance
                  value={totalStakedBalance}
                  decimals={0}
                  unit=" JSTLP"
                  color="primary"
                />
              ) : (
                <Skeleton width="80px" height="16px" />
              )}
              <span ref={totalStakedTargetRef}>
                <HelpIcon color="primary" width="20px" ml="6px" mt="2px" />
              </span>
              {totalStakedTooltipVisible && totalStakedTooltip}
            </Flex>
          </Flex>
          <Flex mb="1rem" mt="1rem">
            <Info
              userDataLoaded={userDataLoaded}
              publicData={publicData}
              walletData={walletData}
            />
          </Flex>
          <Flex mb="1rem">
            <Actions
              userDataLoaded={userDataLoaded}
              publicData={publicData}
              walletData={walletData}
            />
          </Flex>
          {/* {inStake ? (
            <Flex justifyContent="center">
              <Button variant="tertiary" onClick={onPresentRewardsModal}>
                {t("My Rewards")}
              </Button>
            </Flex>
          ) : (
            ""
          )} */}
        </CardBody>
        <CardFooter>
          <ExpandableButtonWrapper>
            <Flex alignItems="center">
              <ManualPoolTag />
              {tooltipVisible && tooltip}
              <Flex ref={targetRef}>
                <HelpIcon ml="4px" width="20px" height="20px" color="primary" />
              </Flex>
            </Flex>
            <ExpandableLabel
              expanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <Text color="#B34A56" fontFamily="Poppins">
                  {t("Hide")}
                </Text>
              ) : (
                <Text color="#B34A56" fontFamily="Poppins">
                  {t("Details")}
                </Text>
              )}
            </ExpandableLabel>
          </ExpandableButtonWrapper>
          {isExpanded && (
            <>
              <Flex mb="2px" justifyContent="flex-end">
                <LinkExternal
                  href={`/info/token/${serializedTokens.jstlp.address}`}
                  bold={false}
                >
                  {t("See Token Info")}
                </LinkExternal>
              </Flex>
              <Flex mb="2px" justifyContent="flex-end">
                <LinkExternal
                  href={`${BASE_BSC_SCAN_URL}/address/${serializedTokens.jstlp.address}`}
                  bold={false}
                >
                  {t("View Contract")}
                </LinkExternal>
              </Flex>
              {account && isMetaMaskInScope && (
                <Flex mb="2px" justifyContent="flex-end">
                  <Button
                    variant="text"
                    p="0"
                    height="auto"
                    onClick={() =>
                      registerToken(
                        serializedTokens.jstlp.address,
                        serializedTokens.jstlp.symbol,
                        18
                      )
                    }
                  >
                    <Text color="primary">{t("Add to Metamask")}</Text>
                    <MetamaskIcon ml="4px" />
                  </Button>
                </Flex>
              )}
            </>
          )}
        </CardFooter>
      </StyledCard>
    </>
  );
};

export default JSTLPCard;
