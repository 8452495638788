import { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import stakeAbi from "config/abi/JamonVault.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getJstlpVaultAddress } from "utils/addressHelpers";

const useGetPublicJstlpStakeData = () => {
  const address = getJstlpVaultAddress();
  const { slowRefresh } = useRefresh();

  const [state, setState] = useState({      
    TotalHolders: 0,
    TotalStaked: BIG_ZERO,
    LastUpdated: 0
  });

  const fetchJstlpStakeData = useCallback(async () => {
    const stakeCalls = [
      {
        address,
        name: "totalHolders",
      },
     {
        address,
        name: "totalStaked",
      },
       {
        address,
        name: "lastUpdated",
      }
    ];

    const [totalHolders, totalStaked, lastUpdated ] = await multicall(
        stakeAbi,
        stakeCalls
    ); 


    setState((prev) => ({
      ...prev,
      TotalHolders: Number(totalHolders),
      TotalStaked: new BigNumber(totalStaked[0].toString()),
      LastUpdated: Number(lastUpdated)
    }));
  }, [address]);

  useEffect(() => {
    fetchJstlpStakeData();
  }, [fetchJstlpStakeData, slowRefresh]);

  return { ...state, fetchJstlpStakeData }

};

export default useGetPublicJstlpStakeData