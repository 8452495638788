import React from 'react'
import { Box, Text, BoxProps, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'


const BaseLabel = styled.label`
color: ${({ theme }) => theme.colors.text};
display: block;
font-weight: 600;
margin-bottom: 8px;
`

export const Label = styled(BaseLabel)`
font-size: 20px;
`

export const TitleLabel = styled(BaseLabel)`
font-size: 20px;
text-transform: uppercase;
`

export const SubLabel = styled(BaseLabel)`
font-size: 16px;
text-transform: uppercase;
`

export const SecondaryLabel = styled(BaseLabel)`
font-size: 12px;
text-transform: uppercase;
`

export const Container = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 1em;

  & > * {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  } ;
`;

export const Logo = styled.div`
  width: 56px;
  margin-top: 1em;
  margin-left: 1em;
  padding: 0;
  gap: 1em;
`;

export const WrappedImg = styled.img`
  display: flex;
  justify-content: center;
  alt: "";
`;

export const StyledAlert = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 500px;
  margin: 0 auto 32px auto;
`;

export const ExpandingWrapper = styled.div`
  padding: 24px;
  border-top: 2px solid ${({ theme }) => theme.colors.cardBorder};
  overflow: hidden;
`