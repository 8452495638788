import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Trade, TradeType } from "@pancakeswap/sdk";
import { Button, Text, AutoRenewIcon } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { Field } from "state/swap/actions";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionOrderPrice,
  warningSeverity
} from "utils/prices";
import { AutoColumn } from "components/Layout/Column";
import BigNumber from "bignumber.js";
import QuestionHelper from "components/QuestionHelper";
import { AutoRow, RowBetween, RowFixed } from "components/Layout/Row";
import { BIG_ZERO } from "utils/bigNumber";
import FormattedPriceImpact from "./FormattedPriceImpact";
import { StyledBalanceMaxMini, SwapCallbackError } from "./styleds";

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 24px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`;

export default function OrderModalFooter({
  trade,
  targetPrice,
  credits,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  pendingTx
}: {
  trade: Trade;
  targetPrice: number;
  credits: number;
  allowedSlippage: number;
  onConfirm: () => void;
  swapErrorMessage: string | undefined;
  disabledConfirm: boolean;
  pendingTx: boolean;
}) {
  const { t } = useTranslation();
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  const inputAmount = trade.inputAmount
    ? new BigNumber(trade.inputAmount.toSignificant())
    : BIG_ZERO;

  const amount = new BigNumber(targetPrice);
  const slipAmount = amount.times(10000 - allowedSlippage).dividedBy(10000);
  const textMinAmount = slipAmount.toString();

  return (
    <>
      <SwapModalFooterContainer>
        <RowBetween align="center">
          <Text fontSize="14px">{t("Target price")}</Text>
          <Text
            fontSize="14px"
            color="primary"
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              textAlign: "right",
              paddingLeft: "10px"
            }}
          >
            {formatExecutionOrderPrice(trade, showInverted, targetPrice)}
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{t("Minimum received")}</Text>
            <QuestionHelper
              text={t(
                "Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
              )}
              ml="4px"
            />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px" color="primary">
              {textMinAmount ?? "-"}
            </Text>
            <Text fontSize="14px" marginLeft="4px" color="primary">
              {trade.outputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{t("Credits")}</Text>
            <QuestionHelper text={t("Credits used in the order.")} ml="4px" />
          </RowFixed>
          <Text fontSize="14px" color="primary">
            {credits}
          </Text>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{t("Liquidity Provider Fee")}</Text>
            <QuestionHelper
              text={
                <>
                  <Text mb="12px">
                    {t("For each trade a %amount% fee is paid", {
                      amount: "0.30%"
                    })}
                  </Text>
                  <Text>
                    - {t("%amount% to LP token holders", { amount: "0.25%" })}
                  </Text>
                  <Text>
                    - {t("%amount% to the Treasury", { amount: "0.05%" })}
                  </Text>
                  {/*  <Text>- {t('%amount% towards JAMON buyback and burn', { amount: '0.05%' })}</Text> */}
                </>
              }
              ml="4px"
            />
          </RowFixed>
          <Text fontSize="14px" color="primary">
            {realizedLPFee
              ? `${realizedLPFee?.toSignificant(6)} ${
                  trade.inputAmount.currency.symbol
                }`
              : "-"}
          </Text>
        </RowBetween>
      </SwapModalFooterContainer>

      <AutoRow>
        <Button
          variant={severity > 2 ? "danger" : "primary"}
          onClick={onConfirm}
          disabled
          isLoading={pendingTx}
          endIcon={
            pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null
          }
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {severity > 2 ? t("Order Anyway") : t("Confirm Order")}
        </Button>

        {swapErrorMessage ? (
          <SwapCallbackError error={swapErrorMessage} />
        ) : null}
      </AutoRow>
    </>
  );
}
