import { Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'

const BaseCell = styled.div`
  color: black;

  padding: 24px 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CellContent = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  ${Text} {
    line-height: 1;
  }
`

export const StyledCell = styled(BaseCell)<{ isMobile?: boolean }>`
  flex: 5;
  flex-direction: row;
  justify-content: center;
  padding-left: 12px;
  margin-left: ${({ isMobile }) => (isMobile ? '' : '5rem')};
`;

export default BaseCell
