import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Button,
  BalanceInput,
  WarningIcon,
  Flex,
  LinkExternal,
  Box,
  Image,
  useMatchBreakpoints
} from "@pancakeswap/uikit";
import { PublicJstlpStakeData, WalletJstlpStakeData } from "views/Pools/types";
import { useTranslation } from "contexts/Localization";
import { useJamonStakeLPVaultContract, useERC20 } from "hooks/useContract";
import { getBalanceAmount } from "utils/formatBalance";
import ApproveConfirmButtons, {
  ButtonArrangement
} from "components/ApproveConfirmButtons";

import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { DEFAULT_TOKEN_DECIMAL } from "config";

interface Props {
  publicData: PublicJstlpStakeData;
  walletData: WalletJstlpStakeData;
  token: string;
  reStake: boolean;
  userCurrencyBalance: BigNumber;
  onSuccess: (amount: BigNumber) => void;
  onDismiss?: () => void;
}

const multiplierValues = [1];

const StakeJstlpModal: React.FC<Props> = ({
  token,
  userCurrencyBalance,
  onDismiss,
  onSuccess
}) => {
  const contract = useJamonStakeLPVaultContract();
  const [value, setValue] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const valueWithTokenDecimals = new BigNumber(value).times(
    DEFAULT_TOKEN_DECIMAL
  );
  const raisingTokenContract = useERC20(token);

  const getTokenLink = token ? `/swap?outputCurrency=${token}` : "/swap";

  const { isMobile, isTablet } = useMatchBreakpoints();

  const handleUserInput = (input: string) => {
    if (!inputDisabled) {
      setValue(input);
    }
  };

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await raisingTokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        return currentAllowance.eq(valueWithTokenDecimals);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      setInputDisabled(true);
      return raisingTokenContract.approve(
        contract.address,
        valueWithTokenDecimals.toString()
      );
    },
    onConfirm: () => {
      return contract.stake(valueWithTokenDecimals.toString());
    },
    onSuccess: async () => {
      onSuccess(valueWithTokenDecimals);
      setInputDisabled(false);
      onDismiss();
    },
    onApproveSuccess({ state, receipt }) {
      setApproved(true);
    }
  });

  const [approved, setApproved] = useState(isApproved);

  return (
    <Modal
      title={t("Stake JSTLP")}
      onDismiss={onDismiss}
      mt={isMobile || isTablet ? "8rem" : ""}
    >
      <ModalBody maxWidth="320px">
        <Flex justifyContent="center" mb="18px">
          <Box
            border="1px solid #B34A56"
            borderRadius="16px"
            maxWidth="80%"
            padding="8px"
          >
            <Flex justifyContent="center" mb="1rem">
              <WarningIcon color="warning" width={48} />{" "}
            </Flex>
            <Flex justifyContent="center">
              <Text
                color="warning"
                textAlign="center"
                fontFamily="Poppins"
                fontSize="0.7rem"
              >
                {t(
                  " The JSTLP stake has a penalty during the first year of stake of 1% per month, being an initial 12% and reducing 1% per month elapsed."
                )}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mb="8px">
          <Text>{t("Stake")}:</Text>
          <Flex flexGrow={1} justifyContent="flex-end" minWidth="70px">
            <Image
              src="/images/tokens/0x7aC7b850985be712fE0847C61166aC1792E9979E.png"
              width={24}
              height={24}
              alt="JShare Token"
            />
            <Text color="primary" bold ml="0.1rem">
              JSTLP
            </Text>
          </Flex>
        </Flex>
        <BalanceInput
          value={value}
          onUserInput={handleUserInput}
          isWarning={valueWithTokenDecimals.isGreaterThan(userCurrencyBalance)}
          decimals={18}
          mb="8px"
        />
        <Flex justifyContent="end" mb="16px">
          <Text
            color="textSubtle"
            textAlign="right"
            fontSize="12px"
            mr="0.2rem"
          >
            {t("Balance")}:
          </Text>
          <Text color="primary" textAlign="right" fontSize="12px">
            {getBalanceAmount(userCurrencyBalance, 18).toString()}
          </Text>
        </Flex>
        <Flex justifyContent="end" mb="16px">
          {multiplierValues.map((multiplierValue, index) => (
            <Button
              key={multiplierValue}
              scale="xs"
              variant="primary"
              onClick={() =>
                setValue(
                  getBalanceAmount(
                    userCurrencyBalance.times(multiplierValue)
                  ).toString()
                )
              }
              mr={index < multiplierValues.length - 1 ? "8px" : 0}
            >
              {multiplierValue * 100}%
            </Button>
          ))}
        </Flex>
        <ApproveConfirmButtons
          buttonArrangement={ButtonArrangement.SEQUENTIAL}
          isApproveDisabled={
            isConfirmed ||
            isConfirming ||
            approved ||
            valueWithTokenDecimals.isNaN() ||
            userCurrencyBalance.eq(0) ||
            valueWithTokenDecimals.eq(0)
          }
          isApproving={isApproving}
          isConfirmDisabled={
            !approved ||
            isConfirmed ||
            valueWithTokenDecimals.isNaN() ||
            userCurrencyBalance.eq(0) ||
            valueWithTokenDecimals.eq(0)
          }
          isConfirming={isConfirming}
          onApprove={handleApprove}
          onConfirm={handleConfirm}
        />
        <Flex justifyContent="center">
          <LinkExternal href={getTokenLink} width="100%" mt="1rem">
            {t("Get")} JSTLP
          </LinkExternal>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default StakeJstlpModal;
