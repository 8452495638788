import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import jamonVIPAbi from "config/abi/jamonVIP.json";
import { getJamonVIPAddress } from "utils/addressHelpers";
import { WalletNFTData } from "../types";

const useGetWalletNFTData = (): WalletNFTData => {
  const address = getJamonVIPAddress();
  const { account } = useWeb3React();
  const { slowRefresh } = useRefresh();

  const [state, setState] = useState({
    balance: 0,
  });

  const fetchNFTData = useCallback(async () => {
    const vipCalls = [
      {
        address,
        name: "balanceOf",
        params: [account],
      }
    ];

    const [balanceOf] = await multicall(
      jamonVIPAbi,
      vipCalls
    );

    setState((prev) => ({
      ...prev,
      balance: Number(balanceOf[0]),
    }));
  }, [account, address]);

  useEffect(() => {
    if (account) {
      fetchNFTData();
    }
  }, [account, fetchNFTData, slowRefresh]);

  return { ...state, fetchNFTData };
};

export default useGetWalletNFTData;
