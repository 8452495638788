import React from "react";
import { ModalProvider, light, dark } from "@pancakeswap/uikit";
import { Web3ReactProvider } from "@web3-react/core";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useThemeManager } from "state/user/hooks";
import { getLibrary } from "utils/web3React";
import { LanguageProvider } from "contexts/Localization";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { ToastsProvider } from "contexts/ToastsContext";
import { MoralisProvider } from "react-moralis";
import store from "state";

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager();
  return <ThemeProvider theme={isDark ? dark : light} {...props} />;
};

const appId = process.env.REACT_APP_APP_ID;
const serverUrl = process.env.REACT_APP_SERVER_URL;

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <HelmetProvider>
            <ThemeProviderWrapper>
              <LanguageProvider>
                <RefreshContextProvider>
                  <ModalProvider>
                    <MoralisProvider appId={appId} serverUrl={serverUrl}>
                      {children}{" "}
                    </MoralisProvider>
                  </ModalProvider>
                </RefreshContextProvider>
              </LanguageProvider>
            </ThemeProviderWrapper>
          </HelmetProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
