import React from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  Text,
  Flex,
  HelpIcon,
  Button,
  Heading,
  Skeleton,
  useModal,
  Box,
  useTooltip,
  useMatchBreakpoints
} from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useTranslation } from "contexts/Localization";
import { getBalanceNumber } from "utils/formatBalance";
import Balance from "components/Balance";
import { PublicLimitOrderData, WalletLimitOrderData } from "../types";
import CreditsModal from "./CreditsModal";

const StyledCard = styled(Card)`
  width: 100%;
  flex: 1;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 240px;
  }
`;

interface CreditsCardProps {
  publicData: PublicLimitOrderData;
  walletData: WalletLimitOrderData;
}

const CreditsCard: React.FC<CreditsCardProps> = ({
  publicData,
  walletData
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();

  const TooltipComponent = () => (
    <>
      <Text mb="16px">
        {t("Credits are used to pay for gas for the execution of the order.")}
      </Text>
      <Text mb="16px">
        {t(
          "Each credit will be able to keep an order open for a maximum of 7 days, you can increase the time of the order by adding more credits."
        )}
      </Text>
      <Text style={{ fontWeight: "bold" }}>
        {t(
          "75% of LINK tokens are sent to keepers for execution and 25% are used to buy and burn JAMON."
        )}
      </Text>
    </>
  );

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <TooltipComponent />,
    {
      placement: "bottom-end",
      tooltipOffset: [20, 10]
    }
  );

  // Limit Orders
  const { account } = useWeb3React();

  const hasFetchedCreditPrice = publicData.creditPrice
    ? publicData.creditPrice.gte(0)
    : false;
  const CreditPriceToDisplay = hasFetchedCreditPrice
    ? getBalanceNumber(publicData.creditPrice, 18)
    : 0;

  const creditToDisplay = walletData.credits;

  const [onPresentCreditModal] = useModal(
    <CreditsModal
      TooltipComponent={TooltipComponent}
      publicData={publicData}
      walletData={walletData}
    />
  );

  return (
    <>
      {tooltipVisible && tooltip}
      <StyledCard>
        <CardBody>
          <Flex flexDirection="column">
            <Flex
              alignItems="center"
              mb={isMobile || isTablet ? "24px" : "12px"}
              justifyContent={isMobile || isTablet ? "center" : ""}
            >
              <Heading>{t("Order credits")}</Heading>
              <Box ref={targetRef} ml="0.5rem">
                <HelpIcon color="primary" />
              </Box>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={isMobile || isTablet ? "center" : "space-between"}
          >
            <Flex flexDirection="column" mr="12px">
              {account ? (
                <>
                  <Flex flexDirection="row" alignItems="center" mb="0.2rem">
                    <Text
                      color="primary"
                      bold
                      fontFamily="Poppins"
                      fontSize={isMobile || isTablet ? "1rem" : "1.25rem"}
                      mr="0.5rem"
                    >
                      {t("Your balance: ")}
                    </Text>
                    <Balance
                      color="primary"
                      fontSize={isMobile || isTablet ? "1rem" : "1.25rem"}
                      bold
                      value={creditToDisplay}
                      decimals={0}
                      unit={t(" Credits")}
                    />
                  </Flex>
                </>
              ) : (
                ""
              )}

              {hasFetchedCreditPrice ? (
                <>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={isMobile || isTablet ? "center" : ""}
                  >
                    <Text
                      color="binance"
                      bold
                      fontFamily="Poppins"
                      fontSize="0.875rem"
                      mr="0.5rem"
                    >
                      {t("Credit price: ")}
                    </Text>
                    <Balance
                      fontSize="0.875rem"
                      color="binance"
                      value={CreditPriceToDisplay}
                      decimals={4}
                      unit=" LINK"
                    />
                  </Flex>
                </>
              ) : (
                <Skeleton height={20} width={96} mb="2px" />
              )}
            </Flex>
            {isMobile || isTablet ? (
              ""
            ) : (
              <>
                {account ? (
                  <Button
                    onClick={onPresentCreditModal}
                    scale="sm"
                    disabled
                    id="clickClaimVaultBounty"
                  >
                    {t("Buy credits")}
                  </Button>
                ) : (
                  <ConnectWalletButton scale="sm" />
                )}
              </>
            )}
          </Flex>
          {isMobile || isTablet ? (
            <>
              <Flex justifyContent="center" mt="1rem">
                {account ? (
                  <Button
                    onClick={onPresentCreditModal}
                    scale="sm"
                    id="clickClaimVaultBounty"
                  >
                    {t("Buy credits")}
                  </Button>
                ) : (
                  <ConnectWalletButton scale="sm" />
                )}
              </Flex>
            </>
          ) : (
            ""
          )}
        </CardBody>
      </StyledCard>
    </>
  );
};

export default CreditsCard;
