import { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import { multicallv2 } from "utils/multicall";
import presaleAbi from "config/abi/LaunchPresale.json";
import { BIG_ZERO } from "utils/bigNumber";
import { PresaleStatus, PublicPresaleData, RoundData } from "../types";
import { getStatus } from "./helpers";

const formatInfo = (info) => ({
  saleToken: info ? String(info[0]) : null,
  baseToken: info ? String(info[1]) : null,
  max4user: info ? new BigNumber(info[2].toString()) : BIG_ZERO,
  softcap: info ? new BigNumber(info[3].toString()) : BIG_ZERO,
  liquidity: info ? Number(info[4]) : 0,
  listingPrice: info ? new BigNumber(info[5].toString()) : BIG_ZERO,
  lockLength: info ? Number(info[6]) : 0,
  inMatic: info ? Boolean(info[7]) : false
});

const formatVesting = (vesting) => ({
  vesting: vesting ? Boolean(vesting[0]) : false,
  vPercent: vesting ? Number(vesting[1]) : 0,
  vCliff: vesting ? Number(vesting[2]) : 0,
  vPeriods: vesting ? Number(vesting[3]) : 0,
  vPeDuration: vesting ? Number(vesting[4]) : 0
});

const formatStatus = (status) => ({
  whitelist: status ? Boolean(status[0]) : false,
  totalCollected: status ? new BigNumber(status[1].toString()) : BIG_ZERO,
  totalSold: status ? new BigNumber(status[2].toString()) : BIG_ZERO,
  buyers: status ? Number(status[5]) : 0,
  state: status ? Number(status[6]) : 0
});

const formatRound = (round) => ({
  amount: round ? new BigNumber(round[0].toString()) : BIG_ZERO,
  startDate: round ? Number(round[1]) : 0,
  endDate: round ? Number(round[2]) : 0,
  hardcap: round ? new BigNumber(round[3].toString()) : BIG_ZERO,
  price: round ? new BigNumber(round[4].toString()) : BIG_ZERO,
  collected: round ? new BigNumber(round[5].toString()) : BIG_ZERO
});

/**
 * Gets all public data of a Presale
 */
const useGetPublicPresaleData = (address: string): PublicPresaleData => {
  const { fastRefresh, slowRefresh } = useRefresh();

  const [state, setState] = useState({
    saleToken: null,
    baseToken: null,
    status: "NotExist" as PresaleStatus,
    round: 0,
    owner: null,
    whitelist: false,
    list: 0,
    startAt: 0,
    endAt: 0,
    hardcap: BIG_ZERO,
    softcap: BIG_ZERO,
    totalCollected: BIG_ZERO,
    listPrice: BIG_ZERO,
    sold: BIG_ZERO,
    buyers: 0,
    locked: 0,
    lockPeriod: 0,
    max4user: BIG_ZERO,
    inMatic: false,
    vesting: false,
    vPercent: 0,
    vCliff: 0,
    vPeriods: 0,
    vPeDuration: 0,
    currentRound: {
      amount: BIG_ZERO,
      startDate: 0,
      endDate: 0,
      hardcap: BIG_ZERO,
      price: BIG_ZERO,
      collected: BIG_ZERO
    },
    rounds: [],
    loading: true,
    remaining: BIG_ZERO
  });

  let ROUNDS: RoundData[];

  const fetchPresaleData = useCallback(async () => {
    const presaleCalls = [
      {
        address,
        name: "PRESALE_INFO"
      },
      {
        address,
        name: "VESTING"
      },
      {
        address,
        name: "STATUS"
      },
      {
        address,
        name: "totalRounds"
      },
      {
        address,
        name: "getPresaleLength"
      },
      {
        address,
        name: "presaleStatus"
      },
      {
        address,
        name: "totalHardcap"
      },
      {
        address,
        name: "currentRound"
      },
      {
        address,
        name: "currentRoundInfo"
      },
      {
        address,
        name: "getWhitelistedUsersLength"
      },
      {
        address,
        name: "PRESALE_OWNER"
      },
      {
        address,
        name: "remaining4Sale"
      }
    ];

    const [
      INFO,
      VESTING,
      STATUS,
      roundsLegth,
      presaleLength,
      presaleStatus,
      totalHardcap,
      currentRound,
      currentRoundInfo,
      getWhitelistLength,
      OWNER,
      remaining4Sale
    ] = await multicallv2(presaleAbi, presaleCalls);

    const infoFormatted = formatInfo(INFO);
    const vestingFormatted = formatVesting(VESTING);
    const statusFormatted = formatStatus(STATUS);

    const currentStatus = getStatus(
      Number(presaleStatus),
      statusFormatted.state
    );
    const collected = statusFormatted.totalCollected;
    const hardcap = new BigNumber(totalHardcap);
    const totalRounds = Number(roundsLegth[0]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    ROUNDS = [];

    const proccessRound = async (roundCall) => {
      const [roundInfo] = await multicallv2(presaleAbi, roundCall);
      const roundFormatted = formatRound(roundInfo[0]);
      ROUNDS.push(roundFormatted);
    };
    for (let i = 0; i < totalRounds; i++) {
      const roundCalls = [
        {
          address,
          name: "roundInfo",
          params: [i]
        }
      ];
      proccessRound(roundCalls);
    }

    // Calculate the total progress until finished or until start
    const progress = collected.div(hardcap).times(100).toFixed(2);

    setState((prev) => ({
      ...prev,
      saleToken: infoFormatted.saleToken,
      baseToken: infoFormatted.baseToken,
      status: currentStatus,
      round: Number(currentRound[0]),
      owner: String(OWNER[0]),
      whitelist: statusFormatted.whitelist,
      list: Number(getWhitelistLength),
      startAt: Number(presaleLength[0]),
      endAt: Number(presaleLength[1]),
      hardcap,
      softcap: infoFormatted.softcap,
      totalCollected: statusFormatted.totalCollected,
      listPrice: infoFormatted.listingPrice,
      sold: statusFormatted.totalSold,
      buyers: statusFormatted.buyers,
      locked: infoFormatted.liquidity,
      lockPeriod: infoFormatted.lockLength,
      max4user: infoFormatted.max4user,
      inMatic: infoFormatted.inMatic,
      vesting: vestingFormatted.vesting,
      vPercent: vestingFormatted.vPercent,
      vCliff: vestingFormatted.vCliff,
      vPeriods: vestingFormatted.vPeriods,
      vPeDuration: vestingFormatted.vPeDuration,
      currentRound: formatRound(currentRoundInfo[0]),
      rounds: ROUNDS.reverse(),
      loading: false,
      remaining: new BigNumber(remaining4Sale[0].toString())
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    fetchPresaleData();
  }, [fetchPresaleData, slowRefresh]);

  return { ...state, fetchPresaleData };
};

export default useGetPublicPresaleData;
