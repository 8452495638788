import { serializeTokens } from "./tokens";
import { SerializedFarmConfig } from "./types";

const serializedTokens = serializeTokens();

const farmsV2: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0, // 20x
    lpSymbol: "JAMON",
    lpAddresses: {
      80001: "0xBb1388eD79d611210794cd626120710720306B0f",
      137: "0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 2, // 1x
    lpSymbol: "USDC-MATIC LP",
    lpAddresses: {
      80001: "0x3EfBCdB226fe15993DCd95518c143f3702E81738",
      137: "0x7954006f16b4e6c7cb4073bc1d382cd3d32ce4c5"
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 3, // 2x
    lpSymbol: "JSTLP-MATIC LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x908213983a70043fcd6C895258bE4F42F35b32Be"
    },
    token: serializedTokens.jstlp,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 4, // 2x
    lpSymbol: "JSTLP-USDC LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x17F5746c3f08635e1c58342425859200978C64a6"
    },
    token: serializedTokens.jstlp,
    quoteToken: serializedTokens.usdc
  },
  {
    pid: 9, // 1x
    lpSymbol: "MATIC-WBTC LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x118B2F755ff8FCE521254F637193351519eE3469"
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 10, // 1x
    lpSymbol: "MATIC-WETH LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0xB64fDc28c295973eFa58280eCE861dfb3ce4a7F0"
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 15, // 1x
    lpSymbol: "WETH-USDC LP",
    lpAddresses: {
      80001: "0x4E90479ED48620A1F18017b5ea6f96bc42bBB5Ce",
      137: "0x4E90479ED48620A1F18017b5ea6f96bc42bBB5Ce"
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.usdc
  },
  {
    pid: 16, // 1x
    lpSymbol: "WBTC-USDC LP",
    lpAddresses: {
      80001: "0x1Cc94f17992a41485AED368afdc22c91483F08eb",
      137: "0x1Cc94f17992a41485AED368afdc22c91483F08eb"
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.usdc
  },
  {
    pid: 18, // 1x
    lpSymbol: "WETH-WBTC LP",
    lpAddresses: {
      80001: "0x00113d9A907Bd2356c3ec51481A804571F084fDA",
      137: "0x00113d9A907Bd2356c3ec51481A804571F084fDA"
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.weth
  },
  {
    pid: 25, // 200x
    lpSymbol: "FLN-MATIC LP",
    lpAddresses: {
      80001: "0xbA0b900574C7f7133D066AFeCa7737d64FEE9e08",
      137: "0xbA0b900574C7f7133D066AFeCa7737d64FEE9e08"
    },
    token: serializedTokens.falcon,
    quoteToken: serializedTokens.wmatic
  },

  /**
   * DISABLED
   */
  {
    pid: 7, // 50x
    lpSymbol: "JAMON-WBTC LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x56F6F2De33634cB3c77bd3701d60D5a3a5c12d36"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.wbtc
  },
  {
    pid: 8, // 50x
    lpSymbol: "JAMON-WETH LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x8F35B2089e45DF3bf95726C39A0b2208b5997E5c"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.weth
  },
  {
    pid: 24, // 5x
    lpSymbol: "MATIC-ALGB LP",
    lpAddresses: {
      80001: "0x37058311576779Fa22C310409828B1cc16B39046",
      137: "0x37058311576779Fa22C310409828B1cc16B39046"
    },
    token: serializedTokens.algb,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 22, // 5x
    lpSymbol: "MATIC-SHIB LP",
    lpAddresses: {
      80001: "0x0210A31F317aaA87Ea4ee02b59Be79377058aD1a",
      137: "0x0210A31F317aaA87Ea4ee02b59Be79377058aD1a"
    },
    token: serializedTokens.shib,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 23, // 20x
    lpSymbol: "MATIC-JSHARE LP",
    lpAddresses: {
      80001: "0x9178202c98AFDD021C1e7792FD6A7593d245063D",
      137: "0x9178202c98AFDD021C1e7792FD6A7593d245063D"
    },
    token: serializedTokens.jshare,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 21, // 20x
    lpSymbol: "MATIC-DAI LP",
    lpAddresses: {
      80001: "0xaBD03B82fd84221B8Aa6d2C5605446b40c5B0b8A",
      137: "0xaBD03B82fd84221B8Aa6d2C5605446b40c5B0b8A"
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 20, // 5x
    lpSymbol: "MATIC-SAND LP",
    lpAddresses: {
      80001: "0x59bE8033cc556e62E8cb836599CB764E8b05a5b5",
      137: "0x59bE8033cc556e62E8cb836599CB764E8b05a5b5"
    },
    token: serializedTokens.sand,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 19, // 20x
    lpSymbol: "WETH-USDT LP",
    lpAddresses: {
      80001: "0x4520F3FEA6eDeb174869F4Fd13CfE2a3C74bAF6A",
      137: "0x4520F3FEA6eDeb174869F4Fd13CfE2a3C74bAF6A"
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.usdt
  },
  {
    pid: 17, // 10x
    lpSymbol: "USDT-USDC LP",
    lpAddresses: {
      80001: "0x6007A4C6f6d9E33CF27F3Ffd2aBD903E76750682",
      137: "0x6007A4C6f6d9E33CF27F3Ffd2aBD903E76750682"
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc
  },
  {
    pid: 14, // 25x
    lpSymbol: "MATIC-USDT LP",
    lpAddresses: {
      80001: "0xc7822550Dd0F36715e5446986ec2da14e0bAe40d",
      137: "0xc7822550Dd0F36715e5446986ec2da14e0bAe40d"
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 13, // 50x
    lpSymbol: "JAMON-LINKv2 LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0xbc50f3E288aC6eBa868a7f53F784650bAEB01bA5"
    },
    token: serializedTokens.link677,
    quoteToken: serializedTokens.jamonV2
  },
  {
    pid: 11, // 50x
    lpSymbol: "MATIC-JUSD LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0x92de3094455057428010347b11ca2102c7dfb63e"
    },
    token: serializedTokens.jusd,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 12, // 50x
    lpSymbol: "MATIC-JFLU LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0xd913fFcfD475ea12E62D1908Dd29fc830211B70c"
    },
    token: serializedTokens.jflu,
    quoteToken: serializedTokens.wmatic
  },
  {
    pid: 5, // 50x
    lpSymbol: "JAMON-USDC LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0xc4cc91123dfDbb5275AD5931AF0Be44413f42766"
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.jamonV2
  },
  {
    pid: 6, // 50x
    lpSymbol: "JAMON-USDT LP",
    lpAddresses: {
      80001: "0x4BF642d08fd6A3Ec586739A6f58d639b4f9E9985",
      137: "0xfF809F74F1B4Da103019f2338e1F8a9c0461Ee3C"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.usdt
  },
  {
    pid: 1, // 50x
    lpSymbol: "JAMON-MATIC LP",
    lpAddresses: {
      80001: "0x3Db864f337AfE5C19255b1c2e066809920078c33",
      137: "0xAfde692EFf6cCff458e7F04cC9f6bd3A22ACB5F2"
    },
    token: serializedTokens.jamonV2,
    quoteToken: serializedTokens.wmatic
  }
];

export default farmsV2;
