import { useMemo } from "react";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import {
  getConversorContract,
  getConversorV2Contract,
  getJShareVaultContract,
  getJamonVaultContract,
  getJamonStakeLPVaultContract,
  getJamonVestingContract,
  getJShareVestingContract,
  getJSharePresaleContract,
  getBonusContract,
  getJamonLimitContract,
  getJUSDContract,
  getMedianOracleContract,
  getBep20Contract,
  getCakeContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getPancakeRabbitContract,
  getProfileContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getIloContract,
  getPresaleContract,
  getMasterchefContract,
  getMasterchefV2Contract,
  getPointCenterIfoContract,
  getSouschefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  /*  getTradingCompetitionContractV2, */
  getEasterNftContract,
  getErc721Contract,
  getCakeVaultContract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getSouschefV2Contract,
  getLotteryV2Contract,
  getBunnySpecialCakeVaultContract,
  getBunnySpecialPredictionContract,
  getFarmAuctionContract,
  /*  getBunnySpecialLotteryContract,
  getAnniversaryAchievementContract,
  getNftMarketContract,
  getNftSaleContract,
  getPancakeSquadContract, */
  getErc721CollectionContract,
  getLaunchFactoryContract,
  getLaunchVestingContract,
  getLaunchSettingsContract,
  getLaunchLockerContract,
  getJamonLaunchContract,
  getPresaleJamonVIPContract,
  getJamonVIPContract,
  getJTRVaultContract,
  getMigratorContract
} from "utils/contractHelpers";
import { getMulticallAddress } from "utils/addressHelpers";

// Imports below migrated from Exchange useContract.ts
import { Contract } from "@ethersproject/contracts";
import { ChainId, WETH } from "@pancakeswap/sdk";
import { abi as IUniswapV2PairABI } from "@uniswap/v2-core/build/IUniswapV2Pair.json";
import ENS_PUBLIC_RESOLVER_ABI from "../config/abi/ens-public-resolver.json";
import ENS_ABI from "../config/abi/ens-registrar.json";
import { ERC20_BYTES32_ABI } from "../config/abi/erc20";
import ERC20_ABI from "../config/abi/erc20.json";
import WETH_ABI from "../config/abi/weth.json";
import multiCallAbi from "../config/abi/Multicall.json";
import { getContract, getProviderOrSigner } from "../utils";

/**
 * Helper hooks to get specific contracts (by ABI)
 */

// V2 contracts
export const useConversorContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getConversorContract(library.getSigner()), [library]);
};
export const useConversorV2Contract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getConversorV2Contract(library.getSigner()), [library]);
};
export const useJShareVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJShareVaultContract(library.getSigner()), [library]);
};
export const useJamonVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJamonVaultContract(library.getSigner()), [library]);
};
export const useJamonStakeLPVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getJamonStakeLPVaultContract(library.getSigner()),
    [library]
  );
};
export const useJTRVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJTRVaultContract(library.getSigner()), [library]);
};
export const useJamonVestingContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJamonVestingContract(library.getSigner()), [library]);
};
export const useJShareVestingContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getJShareVestingContract(library.getSigner()),
    [library]
  );
};
export const useJSharePresaleContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getJSharePresaleContract(library.getSigner()),
    [library]
  );
};
export const useBonusContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getBonusContract(library.getSigner()), [library]);
};
export const useJamonLimitContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJamonLimitContract(library.getSigner()), [library]);
};
export const useJUSDContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJUSDContract(library.getSigner()), [library]);
};
export const useMediaOracleContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMedianOracleContract(library.getSigner()), [library]);
};
export const useLaunchFactoryContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getLaunchFactoryContract(library.getSigner()),
    [library]
  );
};
export const useLaunchVestingContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getLaunchVestingContract(library.getSigner()),
    [library]
  );
};
export const useLaunchSettingsContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getLaunchSettingsContract(library.getSigner()),
    [library]
  );
};
export const useLaunchLockerContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getLaunchLockerContract(library.getSigner()), [library]);
};
export const useJamonLaunchContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJamonLaunchContract(library.getSigner()), [library]);
};
export const usePresaleJamonVIPContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getPresaleJamonVIPContract(library.getSigner()),
    [library]
  );
};
export const useJamonVIPContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getJamonVIPContract(library.getSigner()), [library]);
};
export const useMigratorContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMigratorContract(library.getSigner()), [library]);
};

export const useIfoV1Contract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getIfoV1Contract(address, library.getSigner()),
    [address, library]
  );
};

export const useIfoV2Contract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getIfoV2Contract(address, library.getSigner()),
    [address, library]
  );
};

export const useIloContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getIloContract(address, library.getSigner()),
    [address, library]
  );
};

export const usePresaleContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getPresaleContract(address, library.getSigner()),
    [address, library]
  );
};

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getBep20Contract(address, library.getSigner()),
    [address, library]
  );
};

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getErc721Contract(address, library.getSigner()),
    [address, library]
  );
};

export const useCake = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getCakeContract(library.getSigner()), [library]);
};

export const useBunnyFactory = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library]);
};

export const usePancakeRabbits = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getPancakeRabbitContract(library.getSigner()),
    [library]
  );
};

export const useProfile = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getProfileContract(library.getSigner()), [library]);
};

export const useLotteryV2Contract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getLotteryV2Contract(library.getSigner()), [library]);
};

export const useMasterchef = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMasterchefContract(library.getSigner()), [library]);
};
export const useMasterchefV2 = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMasterchefV2Contract(library.getSigner()), [library]);
};

export const useSousChef = (id) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getSouschefContract(id, library.getSigner()),
    [id, library]
  );
};

export const useSousChefV2 = (id) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getSouschefV2Contract(id, library.getSigner()),
    [id, library]
  );
};

export const usePointCenterIfoContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getPointCenterIfoContract(library.getSigner()),
    [library]
  );
};

export const useBunnySpecialContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getBunnySpecialContract(library.getSigner()), [library]);
};

export const useClaimRefundContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getClaimRefundContract(library.getSigner()), [library]);
};

export const useTradingCompetitionContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getTradingCompetitionContract(library.getSigner()),
    [library]
  );
};

/* export const useTradingCompetitionContractV2 = (withSignerIfPossible = true) => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getTradingCompetitionContractV2(withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [library, withSignerIfPossible, account],
  )
}
 */
export const useEasterNftContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getEasterNftContract(library.getSigner()), [library]);
};

export const useCakeVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getCakeVaultContract(library.getSigner()), [library]);
};

export const usePredictionsContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getPredictionsContract(library.getSigner()), [library]);
};

export const useChainlinkOracleContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getChainlinkOracleContract(library.getSigner()),
    [library]
  );
};

export const useSpecialBunnyCakeVaultContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getBunnySpecialCakeVaultContract(library.getSigner()),
    [library]
  );
};

export const useSpecialBunnyPredictionContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getBunnySpecialPredictionContract(library.getSigner()),
    [library]
  );
};

/* export const useBunnySpecialLotteryContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialLotteryContract(library.getSigner()), [library])
}

export const useAnniversaryAchievementContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getAnniversaryAchievementContract(library.getSigner()), [library])
}

export const useNftSaleContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftSaleContract(library.getSigner()), [library])
}

export const usePancakeSquadContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPancakeSquadContract(library.getSigner()), [library])
} */

export const useFarmAuctionContract = () => {
  const { account, library } = useActiveWeb3React();
  // This hook is slightly different from others
  // Calls were failing if unconnected user goes to farm auction page
  // Using library instead of library.getSigner() fixes the problem for unconnected users
  // However, this fix is not ideal, it currently has following behavior:
  // - If you visit Farm Auction page coming from some other page there are no errors in console (unconnected or connected)
  // - If you go directly to Farm Auction page
  //   - as unconnected user you don't see any console errors
  //   - as connected user you see `unknown account #0 (operation="getAddress", code=UNSUPPORTED_OPERATION, ...` errors
  //     the functionality of the page is not affected, data is loading fine and you can interact with the contract
  //
  // Similar behavior was also noticed on Trading Competition page.
  return useMemo(
    () => getFarmAuctionContract(account ? library.getSigner() : library),
    [library, account]
  );
};

/* export const useNftMarketContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftMarketContract(library.getSigner()), [library])
} */

export const useErc721CollectionContract = (
  collectionAddress: string,
  withSignerIfPossible = true
) => {
  const { library, account } = useActiveWeb3React();
  return useMemo(() => {
    return getErc721CollectionContract(
      withSignerIfPossible ? getProviderOrSigner(library, account) : null,
      collectionAddress
    );
  }, [account, library, collectionAddress, withSignerIfPossible]);
};

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        withSignerIfPossible ? getProviderOrSigner(library, account) : null
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(
  withSignerIfPossible?: boolean
): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId ? WETH[chainId].address : undefined,
    WETH_ABI,
    withSignerIfPossible
  );
}

export function useENSRegistrarContract(
  withSignerIfPossible?: boolean
): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
        break;
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function usePairContract(
  pairAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}

export function useMulticallContract(): Contract | null {
  return useContract(getMulticallAddress(), multiCallAbi, false);
}
