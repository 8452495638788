import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import stakeAbi from "config/abi/JamonVault.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getJtrVaultAddress } from "utils/addressHelpers";

const useGetWalletJtrRewards = (token: string) => {
  const address = getJtrVaultAddress();
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();

  const [state, setState] = useState({
    PendingReward: BIG_ZERO,
    isPendingTx: false
  });

  const setPendingTx = (status: boolean) =>
    setState((prevState) => ({
      ...prevState,
      isPendingTx: status
    }));

  const fetchJV2Rewards = useCallback(async () => {
    const stakeCalls = [
      {
        address,
        name: "getPendingBal",
        params: [token, account],
      }
    ];

    const [PendingBal] = await multicall(stakeAbi, stakeCalls);

    setState((prev) => ({
      ...prev,
      PendingReward: new BigNumber(PendingBal[0].toString()),
    }));
  }, [address, token, account]);

  useEffect(() => {
    fetchJV2Rewards();
  }, [fetchJV2Rewards, slowRefresh]);

  return { ...state, setPendingTx, fetchJV2Rewards };
};

export default useGetWalletJtrRewards;
