import React from "react";
import styled from "@mui/styled-engine";
import { NavLink } from "react-router-dom";
import { useWalletModal, useMatchBreakpoints } from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import PhishingWarningBanner from "components/PhishingWarningBanner";
import { useCakeBusdPrice } from "hooks/useBUSDPrice";
import UserMenu from "components/NavMenu/UserMenu";
import GlobalSettings from "components/NavMenu/GlobalSettings";
import { languageList } from "config/localization/languages";
import {
  Container,
  Box,
  Menu,
  Drawer,
  MenuItem,
  Link,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  List,
  Grid,
  ListItem,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import MenuIcon from "@mui/icons-material/Menu";
import { Buttons, Colors } from "components/Style";
import LinkIcon from "@mui/icons-material/Link";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "contexts/Localization";
import logo from "img/Menu/logo.png";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import CasinoRoundedIcon from "@mui/icons-material/CasinoRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import coin from "img/coin.png";
import { TokenUpdater } from "state/info/updaters";
import TopMoversTokens from "components/TopMoversTokens";

// Style Menu

const NavBar = styled(AppBar)`
  position: fixed;
  background: #0e0e12;
  border-bottom: 1px solid #b34a56;
  z-index: 50;
`;

const ButtonLink = styled(NavLink)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  color: #abafc4;
  font-size: 1rem;
  font-weight: normal;
  font-family: poppins;
  &:active {
    color: #febf32;
    border-bottom: 1px solid #febf32;
  }
`;

const ButtonLinkExternal = styled(Link)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  color: #abafc4;
  font-size: 1rem;
  font-weight: normal;
  font-family: poppins;
`;

const LinkExternal = styled(Link)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 3rem;
  color: #abafc4;
  font-size: 1rem;
  font-family: poppins;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const ConnectButton = Buttons.ButtonConnect;
const MenuButton = Buttons.ButtonMenu;

// End Style Menu

const MobileMenu: React.VFC = () => {
  const { setLanguage, t } = useTranslation();
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, t);
  const jamonPriceUSD = useCakeBusdPrice();
  const { isMobile, isTablet } = useMatchBreakpoints();

  // State Trade
  const [openTrade, setOpenTrade] = React.useState<null | HTMLElement>(null);
  const [activeTrade, setActiveTrade] = React.useState(false);
  const handleCloseTrade = () => {
    setOpenTrade(null);
  };
  const handleOpenTrade = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenTrade(event.currentTarget);
  };
  const handleActiveTrade = () => {
    setActiveTrade(true);
  };
  const handleDisabledTrade = () => {
    setActiveTrade(false);
  };

  // State Earn
  const [openEarn, setOpenEarn] = React.useState<null | HTMLElement>(null);
  const [activeEarn, setActiveEarn] = React.useState(false);
  const handleCloseEarn = () => {
    setOpenEarn(null);
  };
  const handleOpenEarn = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEarn(event.currentTarget);
  };
  const handleActiveEarn = () => {
    setActiveEarn(true);
  };
  const handleDisabledEarn = () => {
    setActiveEarn(false);
  };

  // State Play
  const [openWin, setOpenWin] = React.useState<null | HTMLElement>(null);
  const [activeWin, setActiveWin] = React.useState(false);
  const handleCloseWin = () => {
    setOpenWin(null);
  };
  const handleOpenWin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenWin(event.currentTarget);
  };
  const handleActiveWin = () => {
    setActiveWin(true);
  };
  const handleDisabledWin = () => {
    setActiveWin(false);
  };

  // State Finance
  const [openFinance, setOpenFinance] = React.useState<null | HTMLElement>(
    null
  );
  const [activeFinance, setActiveFinance] = React.useState(false);
  const handleCloseFinance = () => {
    setOpenFinance(null);
  };
  const handleOpenFinance = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenFinance(event.currentTarget);
  };
  const handleActiveFinance = () => {
    setActiveFinance(true);
  };
  const handleDisabledFinance = () => {
    setActiveFinance(false);
  };

  // State Launchpad
  const [openLaunchpad, setOpenLaunchpad] = React.useState<null | HTMLElement>(
    null
  );
  const [activeLaunchpad, setActiveLaunchpad] = React.useState(false);
  const handleCloseLaunchpad = () => {
    setOpenLaunchpad(null);
  };
  const handleOpenLaunchpad = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenLaunchpad(event.currentTarget);
  };
  const handleActiveLaunchpad = () => {
    setActiveLaunchpad(true);
  };
  const handleDisabledLaunchpad = () => {
    setActiveLaunchpad(false);
  };

  // State Other
  const [openOther, setOpenOther] = React.useState<null | HTMLElement>(null);
  const [activeOther, setActiveOther] = React.useState(false);
  const handleCloseOther = () => {
    setOpenOther(null);
  };
  const handleOpenOther = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOther(event.currentTarget);
  };
  const handleActiveOther = () => {
    setActiveOther(true);
  };
  const handleDisabledOther = () => {
    setActiveOther(false);
  };

  // State Drawer
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  // State Language
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavBar>
        <PhishingWarningBanner />
        <Container disableGutters maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                onClick={() => setDrawerOpen(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#17171A",
                  },
                }}
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
              >
                <Container sx={{ width: "15rem" }}>
                  <NavLink
                    to="/"
                    onClick={() => {
                      setDrawerOpen(false);
                      handleDisabledTrade();
                      handleDisabledEarn();
                      handleDisabledWin();
                      handleDisabledOther();
                      handleDisabledFinance();
                      handleDisabledLaunchpad();
                    }}
                  >
                    <Box component="img" alt="menu-logo" src={logo} />
                  </NavLink>

                  <Box sx={{ mt: "2rem" }}>
                    <MenuButton sx={{ width: "13rem" }}>
                      <NewReleasesIcon sx={{ color: "#FEBF32" }} />
                      <ButtonLink
                        to="/migrate"
                        onClick={() => {
                          setDrawerOpen(false);
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Migration")}
                      </ButtonLink>
                    </MenuButton>
                  </Box>
                  <Box sx={{ mt: "2rem" }}>
                    <MenuButton sx={{ width: "13rem" }}>
                      <ButtonLinkExternal
                        underline="none"
                        href="https://app.multichain.org/#/router?bridgetoken=0x375d3403f8f5f61139adfc23bebc693d6dc75d62&network=137"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkIcon sx={{ marginRight: "0.5rem" }} />{" "}
                        {t("Bridge")}
                      </ButtonLinkExternal>
                    </MenuButton>
                  </Box>

                  {/* <Box sx={{ mt: "1rem" }}>
                    <MenuButton sx={{ width: "13rem" }}>
                      <ButtonLink
                        to="/migrate"
                        onClick={() => {
                          setDrawerOpen(false);
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                      >
                        <LinkExternal
                          href="https://beta.jamonswap.finance"
                          target="_blank"
                        >
                          {t("Auto Router")}
                        </LinkExternal>
                      </ButtonLink>
                    </MenuButton>
                  </Box> */}

                  <Box sx={{ mt: "1rem" }}>
                    <MenuButton
                      sx={
                        activeTrade
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="trade-button"
                      aria-controls={openTrade ? "trade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openTrade ? "true" : undefined}
                      onClick={handleOpenTrade}
                    >
                      <CompareArrowsRoundedIcon
                        sx={
                          activeTrade
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                      {t("Trade")}
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="trade-menu"
                      anchorEl={openTrade}
                      open={Boolean(openTrade)}
                      onClose={handleCloseTrade}
                      MenuListProps={{
                        "aria-labelledby": "trade-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseTrade}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/swap"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Swap")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseTrade}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/liquidity"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Liquidity")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseTrade}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/limitOrder"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Limit Orders")}
                        </ButtonLink>
                      </MenuItem>
                    </Menu>
                  </Box>

                  <Box sx={{ mt: "2rem" }}>
                    <MenuButton
                      sx={
                        activeEarn
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="earn-button"
                      aria-controls={openEarn ? "earn-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openEarn ? "true" : undefined}
                      onClick={handleOpenEarn}
                    >
                      <MonetizationOnRoundedIcon
                        sx={
                          activeEarn
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                      {t("Earn")}
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="earn-menu"
                      anchorEl={openEarn}
                      open={Boolean(openEarn)}
                      onClose={handleCloseEarn}
                      MenuListProps={{
                        "aria-labelledby": "earn-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseEarn}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/bonus"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveEarn();
                            handleDisabledTrade();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Bonus")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseEarn}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/pools"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveEarn();
                            handleDisabledTrade();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Pools")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseEarn}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/farms"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveEarn();
                            handleDisabledTrade();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Farms")}
                        </ButtonLink>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={handleCloseEarn}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLinkExternal
                          underline="none"
                          href="https://delegate.jamonswap.finance/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveEarn();
                            handleDisabledTrade();
                            handleDisabledWin();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                        >
                          {t("Matic Stake")}{" "}
                          <LinkIcon sx={{ marginLeft: "0.5rem" }} />
                        </ButtonLinkExternal>
                      </MenuItem> */}
                    </Menu>
                  </Box>

                  {/* <Box sx={{ mt: "2rem" }}>
                    <MenuButton
                      sx={
                        activeWin
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="win-button"
                      aria-controls={openWin ? "win-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openWin ? "true" : undefined}
                      onClick={handleOpenWin}
                    >
                      <CasinoRoundedIcon
                        sx={
                          activeWin
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                      {t("Play")}
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="win-menu"
                      anchorEl={openWin}
                      open={Boolean(openWin)}
                      onClose={handleCloseWin}
                      MenuListProps={{
                        "aria-labelledby": "win-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseWin}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/lottery"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveWin();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Lottery")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseWin}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLinkExternal
                          underline="none"
                          href="https://cryptopoly.jamonswap.finance"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveWin();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledOther();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                        >
                          {t("Cryptopoly")}{" "}
                          <LinkIcon sx={{ marginLeft: "0.5rem" }} />
                        </ButtonLinkExternal>
                      </MenuItem>
                    </Menu>
                  </Box> */}

                  {/* <Box sx={{ mt: "2rem" }}>
                    <MenuButton
                      sx={
                        activeFinance
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="finance-button"
                      aria-controls={openFinance ? "finance-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openFinance ? "true" : undefined}
                      onClick={handleOpenFinance}
                    >
                      <AccountBalanceRoundedIcon
                        sx={
                          activeFinance
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                      {t("Finance")}
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="finance-menu"
                      anchorEl={openFinance}
                      open={Boolean(openFinance)}
                      onClose={handleCloseFinance}
                      MenuListProps={{
                        "aria-labelledby": "finance-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseFinance}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/stable-coin"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveFinance();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledOther();
                            handleDisabledWin();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("JUSD")}
                        </ButtonLink>
                      </MenuItem>
                    </Menu>
                  </Box> */}

                  {/* <Box sx={{ mt: "2rem" }}>
                    <MenuButton
                      sx={
                        activeLaunchpad
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="launchpad-button"
                      aria-controls={
                        openLaunchpad ? "launchpad-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openLaunchpad ? "true" : undefined}
                      onClick={handleOpenLaunchpad}
                    >
                      <RocketLaunchRoundedIcon
                        sx={
                          activeLaunchpad
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                      {t("Launch")}
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="launchpad-menu"
                      anchorEl={openLaunchpad}
                      open={Boolean(openLaunchpad)}
                      onClose={handleCloseLaunchpad}
                      MenuListProps={{
                        "aria-labelledby": "launchpad-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseLaunchpad}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/launchpad"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveLaunchpad();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledOther();
                            handleDisabledWin();
                            handleDisabledFinance();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Launchpad")}
                        </ButtonLink>
                      </MenuItem>
                    </Menu>
                  </Box> */}

                  <Box sx={{ mt: "2rem" }}>
                    <MenuButton
                      sx={
                        activeOther
                          ? {
                              color: "#FEBF32",
                              borderBottom: "1px solid #FEBF32",
                              width: "13rem",
                            }
                          : { width: "13rem" }
                      }
                      id="other-button"
                      aria-controls={openOther ? "other-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openOther ? "true" : undefined}
                      onClick={handleOpenOther}
                    >
                      <AddCircleIcon
                        sx={
                          activeOther
                            ? { color: "#FEBF32", mr: "1rem" }
                            : { color: "#ABAFC4", mr: "1rem" }
                        }
                      />
                    </MenuButton>
                    <Menu
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          width: "13rem",
                          justifyContent: "center",
                        },
                      }}
                      id="other-menu"
                      anchorEl={openOther}
                      open={Boolean(openOther)}
                      onClose={handleCloseOther}
                      MenuListProps={{
                        "aria-labelledby": "other-button",
                      }}
                    >
                      <MenuItem
                        onClick={handleCloseOther}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/info"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveOther();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Info")}
                        </ButtonLink>
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseOther}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/update"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveOther();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }}
                        >
                          {t("Update")}
                        </ButtonLink>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={handleCloseOther}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLink
                          to="/donations"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleActiveOther();
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                          activeStyle={{
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32"
                          }}
                        >
                          {t("Donations")}
                        </ButtonLink>
                      </MenuItem> */}
                      {/* <MenuItem
                        onClick={handleCloseOther}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLinkExternal
                          underline="none"
                          href="https://www.tally.xyz/governance/eip155:137:0xd7f337d597E4A5d891b7882aBcB4C1d3f7D4Cb97"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                        >
                          {t("Voting")}{" "}
                          <LinkIcon sx={{ marginLeft: "0.5rem" }} />
                        </ButtonLinkExternal>
                      </MenuItem> */}
                      <MenuItem
                        onClick={handleCloseOther}
                        sx={{ justifyContent: "center" }}
                      >
                        <ButtonLinkExternal
                          underline="none"
                          href="https://jamonswap.gitbook.io/docs/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            setDrawerOpen(false);
                            handleDisabledTrade();
                            handleDisabledEarn();
                            handleDisabledWin();
                            handleDisabledFinance();
                            handleDisabledLaunchpad();
                          }}
                        >
                          {t("Docs")} <LinkIcon sx={{ marginLeft: "0.5rem" }} />
                        </ButtonLinkExternal>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Container>
              </Drawer>
            </Box>

            <Link
              href="/swap?outputCurrency=0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2"
              rel="noopener noreferrer"
              underline="none"
              sx={{ color: "#FEBF32" }}
              mr="1rem"
            >
              <Grid container>
                <Grid item xs>
                  <Box
                    component="img"
                    sx={{
                      height: 18,
                      width: 18,
                      mr: 1,
                    }}
                    alt="coin-logo"
                    src={coin}
                  />
                </Grid>
                <Grid item xs mt={0.3} fontSize="0.875rem" color="#FFFFFF">
                  {jamonPriceUSD?.toFixed(6)}
                </Grid>
              </Grid>
            </Link>

            {!account ? (
              <ConnectButton
                onClick={onPresentConnectModal}
                sx={{ p: 0, mr: 1 }}
                isMobile={isMobile}
                isTablet={isTablet}
              >
                {t("Connect Wallet")}
              </ConnectButton>
            ) : (
              <Box sx={{ flexGrow: 0, marginRight: 0.2 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Button
                      sx={{ cursor: "cell" }}
                      id="language-button"
                      aria-controls={open ? "language-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <LanguageIcon
                        sx={{ color: "#ABAFC4", cursor: "pointer" }}
                      />
                    </Button>
                    <Menu
                      id="language-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "language-button",
                      }}
                      PaperProps={{
                        sx: {
                          backgroundColor: "#17171A",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <List
                          sx={{
                            justifyContent: "center",
                            width: "12rem",
                            maxHeight: 600,
                            overflow: "auto",
                          }}
                        >
                          {languageList.map((lang) => (
                            <ListItem
                              component="button"
                              onClick={() => {
                                setLanguage(lang);
                              }}
                              sx={{
                                background: "transparent",
                                border: "none",
                                color: "#ABAFC4",
                                cursor: "pointer",
                                justifyContent: "center",
                                fontSize: "0.875rem",
                              }}
                            >
                              {lang.language}
                            </ListItem>
                          ))}
                        </List>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box>
                    <GlobalSettings />
                  </Box>
                  <Box>
                    <UserMenu />
                  </Box>
                </Grid>
              </Box>
            )}
          </Toolbar>
        </Container>
        <Box
          sx={{
            borderTop: "1px solid #B34A56",
            background: Colors.BACKGROUND1,
          }}
        >
          <TokenUpdater />
          <TopMoversTokens />
        </Box>
      </NavBar>
    </>
  );
};

export default MobileMenu;
