import { ContextApi } from "contexts/Localization/types";
import { FormDetailsState } from "./types";

export const validURL = (url: string) => {
    /* const check = url.match(
      /(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    ); */
    const check = url.match('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$'); // fragment locator
    const checkImage = url.match(/\.(jpeg|jpg|gif|png)$/) != null
    return check !== null && checkImage;
};


export const getFormDetailsErrors = (formData: FormDetailsState, Tokenomic, t: ContextApi["t"]) => {
  const { logo, description, web } = formData;
  const errors: { [key: string]: string[] } = {};

  if(!validURL(logo)){
    errors.logo =  [t("%field% is invalid", { field: "URL Logo" })];
  }

  if(!validURL(web)){
    errors.web =  [t("%fieldWeb% is invalid", { fieldWeb: "URL Web" })];
  }

  if(!description && description===""){
    errors.description =  [t("%fieldDescription% is empty", { fieldDescription: "Description " })];
  }

  if (Tokenomic.length < 2) {
    errors.tokenomic = [
      t("tokenomics must have at least two values"),
    ];
  }
  if (Tokenomic.length > 1) {
    /* let position = 0; */
    for(let i=0; i<Tokenomic.length;i++){
        if (!Tokenomic[i].name || Tokenomic[i].name===""){
            errors.tokenomic = [
                t("Please the name of position %num% is empty", { num: i+1 }),
              ];
        }else if(Tokenomic[i].value<1){
            errors.tokenomic = [
                t("Please the value of position %num% is less than or equal to 0", { num: i+1 }),
              ];
        }
    }
  }

  return errors;
};