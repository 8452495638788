import React, { useMemo, useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import {
  Modal,
  Text,
  Flex,
  Button,
  AutoRenewIcon,
  Link,
  BalanceInput,
  LinkExternal,
} from "@pancakeswap/uikit";
import { getBalanceNumber, getBalanceAmount } from "utils/formatBalance";
import {
  useERC20,
  useJamonLimitContract
} from "hooks/useContract";
import useTheme from "hooks/useTheme";
import useToast from "hooks/useToast";
import { useTranslation } from "contexts/Localization";
import ConnectWalletButton from "components/ConnectWalletButton";
import { ToastDescriptionWithTx } from "components/Toast";
import Balance from "components/Balance";
import { serializeTokens } from "config/constants/tokens";
import useTokenBalance from "hooks/useTokenBalance";
import { getJamonLimitAddress } from "utils/addressHelpers";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";
import useApprove from "../hooks/useApprove";
import { PublicLimitOrderData, WalletLimitOrderData, EnableStatus } from "../types";

const serializedTokens = serializeTokens();

interface CreditsModalProps {
  publicData: PublicLimitOrderData;
  walletData: WalletLimitOrderData;
  onDismiss?: () => void;
  TooltipComponent: React.ElementType;
}

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  height: 1px;
  margin: 16px auto;
  width: 100%;
`;

const CreditsModal: React.FC<CreditsModalProps> = ({
  publicData,
  walletData,
  onDismiss,
  TooltipComponent
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { theme } = useTheme();
  const { toastError, toastSuccess } = useToast();
  const [pendingTx, setPendingTx] = useState(false);

  /// Limit Orders

  const [value, setValue] = useState("1");
  const [enableStatus, setEnableStatus] = useState(EnableStatus.DISABLED);

  const jamonLimitContract = useJamonLimitContract();

  const orderAddress = getJamonLimitAddress();
  const gasPrice = BIG_TEN.times(BIG_TEN.pow(BIG_NINE)).toString();

  const onApprove = useApprove(serializedTokens.link677.address, orderAddress);

  const { balance: userLinkBalance } = useTokenBalance(
    serializedTokens.link677.address
  );

  const hasFetchedCreditPrice = publicData.creditPrice
    ? publicData.creditPrice.gte(0)
    : false;
  const CreditPriceToDisplay = hasFetchedCreditPrice
    ? getBalanceNumber(publicData.creditPrice, 18)
    : 0;

  const linkValue = new BigNumber(publicData.creditPrice).times(value);

  const insufficientFunds = linkValue.isGreaterThan(userLinkBalance);

  const handleApprove = async () => {
    try {
      setEnableStatus(EnableStatus.IS_ENABLING);

      await onApprove();

      setEnableStatus(EnableStatus.ENABLED);
      toastSuccess(t("Successfully Enabled!"), t("You can buy credits."));
    } catch (error) {
      setEnableStatus(EnableStatus.DISABLED);
    }
  };

  const LINKContract = useERC20(serializedTokens.link677.address);

  useEffect(() => {
    const checkAllowance = async () => {
      try {
        const response = await LINKContract.allowance(account, orderAddress);
        const currentAllowance = new BigNumber(response.toString());
        setEnableStatus(
          currentAllowance.lte(0) ? EnableStatus.DISABLED : EnableStatus.ENABLED
        );
      } catch (error) {
        setEnableStatus(EnableStatus.DISABLED);
      }
    };

    if (account) {
      checkAllowance();
    }
  }, [account, orderAddress, LINKContract, setEnableStatus]);

  const handleConfirmClick = async () => {
    setPendingTx(true);
    try {
      const tx = await jamonLimitContract.buyCredits(value, {
        gasPrice
      });
      const receipt = await tx.wait();
      if (receipt.status) {
        toastSuccess(
          t("Purchased credits!"),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t("You already have credit to program orders.")}
          </ToastDescriptionWithTx>
        );
        setPendingTx(false);
        walletData.fetchLimitOrderData();
        onDismiss();
      }
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setPendingTx(false);
    }
  };

  return (
    <Modal
      title={t("Buy credits")}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
      mt="2rem"
      zIndex={100}
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Text color="binance">{t("Credit price")}</Text>
        <Flex flexDirection="column">
          <Balance
            bold
            value={CreditPriceToDisplay}
            decimals={6}
            unit=" LINK"
            color="binance"
          />
          {/* <Text fontSize="12px" color="textSubtle">
            <Balance
              fontSize="12px"
              color="textSubtle"
              value={dollarBountyToDisplay}
              decimals={2}
              unit=" USD"
              prefix="~"
            />
          </Text> */}
        </Flex>
      </Flex>
      <Divider />
      <BalanceInput
        value={value}
        onUserInput={setValue}
        isWarning={insufficientFunds}
        decimals={0}
        placeholder={t("Number of Credits")}
        mb="8px"
      />
      <Flex flexDirection="row" justifyContent="right">
        <Text color="textSubtle" textAlign="right" fontSize="12px" mr="0.2rem">{t("Balance: ")}</Text>
        <Text color="primary" textAlign="right" fontSize="12px">{getBalanceAmount(userLinkBalance, 18).toString()} LINK</Text>
      </Flex>
      <Divider />
      {insufficientFunds ? (
        <Text fontSize="14px" textAlign="center" color="failure" mb="18px">
          {t("Insufficient LINK")}
        </Text>
      ) : (
        <Flex alignItems="center" justifyContent="space-between" mb="24px">
          <Text fontSize="14px" color="textSubtle">
            {t("LINK to spend")}
          </Text>
          <Text fontSize="14px" color="primary">
            <Balance
              value={getBalanceNumber(linkValue, 18)}
              decimals={6}
              color="primary"
              unit=" LINK"
            />
          </Text>
        </Flex>
      )}
      {account ? (
        enableStatus !== EnableStatus.ENABLED ? (
          <Button
            onClick={handleApprove}
            isLoading={enableStatus === EnableStatus.IS_ENABLING}
            mb="28px"
            endIcon={
              enableStatus === EnableStatus.IS_ENABLING ? (
                <AutoRenewIcon spin color="currentColor" />
              ) : null
            }
          >
            {t("Enable LINK")}
          </Button>
        ) : (
          <Button
            isLoading={pendingTx}
            disabled={insufficientFunds}
            endIcon={
              pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null
            }
            onClick={handleConfirmClick}
            mb="28px"
          >
            {pendingTx ? t("Confirming") : t("Confirm")}
          </Button>
        )
      ) : (
        <ConnectWalletButton mb="28px" />
      )}
      <Flex justifyContent="center" alignItems="center">
        <LinkExternal fontSize="16px" bold color="primary" mr="4px" href="/swap?outputCurrency=0xb0897686c545045afc77cf20ec7a532e3120e0f1">
          {t("Buy LINK")}
        </LinkExternal>
      </Flex>
    </Modal>
  );
};

export default CreditsModal;
