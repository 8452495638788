import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const TooltipConfig = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#B34A56",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#17171A",
      border: "1px solid #B34A56",
    },
}));

export default TooltipConfig;

