import { Box } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{ $isDark: boolean; $isExpanded: boolean; isMobile?: boolean, isTablet?: boolean }>`
  padding-top: 36px;
  background: ${({ theme }) => `${theme.colors.backgroundAlt}`};
  width: 100%;
  height: ${({ $isExpanded }) => ($isExpanded ? 'calc(100vh -64px)' : '484px')};
  border: ${({ theme, isMobile, isTablet }) => (isMobile || isTablet ? "" :`1px solid ${theme.colors.cardBorder}`)};
  border-radius: ${({ $isExpanded }) => ($isExpanded ? '0' : '0px')};
  padding-top: 8px;
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
