import React, { useState } from "react";
import BigNumber from "bignumber.js";
import {
  Modal,
  ModalBody,
  Text,
  Checkbox,
  Message,
  Button,
  Flex
} from "@pancakeswap/uikit";
import useTheme from "hooks/useTheme";
import useToast from "hooks/useToast";
import { WalletJV2StakeData } from "views/Pools/types";
import { useERC20, useJamonVaultContract } from "hooks/useContract";
import { useTranslation } from "contexts/Localization";
import { useWeb3React } from "@web3-react/core";

interface Props {
  token: string;
  walletData: WalletJV2StakeData;
  onDismiss?: () => void;
}

const RemoveJV2Modal: React.FC<Props> = ({ token, walletData, onDismiss }) => {
  const { theme } = useTheme();
  const { account } = useWeb3React();
  const { t } = useTranslation();

  // user must accept
  const [confirmed, setConfirmed] = useState(false);

  const { toastSuccess, toastError } = useToast();
  const contract = useJamonVaultContract();

  const raisingTokenContract = useERC20(token);

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  const handleUnStake = async () => {
    try {
      setPendingTx(true);
      const response = await raisingTokenContract.allowance(
        account,
        contract.address
      );
      const currentAllowance = new BigNumber(response.toString());
      if (currentAllowance.gt(0)) {
        const disable = await raisingTokenContract.approve(contract.address, "0");
        await disable.wait();
      }
      const tx = await contract.safeUnStake();
      await tx.wait();

      toastSuccess(t("Success!"), t("You have successfully removed stake."));
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      walletData.fetchJV2StakeData();
      setPendingTx(false);
      onDismiss();
    }
  };

  return (
    <Modal title={t("Remove Stake JAMON")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
        <Message variant="danger">
          <Flex flexDirection="column">
            <Text
              fontSize="20px"
              textAlign="center"
              color={theme.colors.failure}
              mb="16px"
            >
              {t("Important announcement")}
            </Text>
            <Text color={theme.colors.binance} mb="8px">
              {t("If you remove your JAMON now, NO penalty will apply")}.
            </Text>
            <Text bold color={theme.colors.binance} mb="16px">
              {t("If you agree, check the box and remove stake")}.
            </Text>
            <Flex alignItems="center">
              <Checkbox
                name="confirmed"
                type="checkbox"
                checked={confirmed}
                onChange={() => setConfirmed(!confirmed)}
                scale="sm"
              />
              <Text ml="10px" style={{ userSelect: "none" }}>
                {t("I understand")}
              </Text>
            </Flex>
          </Flex>
        </Message>
        <Button
          mt="1rem"
          disabled={!confirmed}
          isLoading={walletData.isPendingTx}
          onClick={() => {
            handleUnStake();
          }}
        >
          {t("Remove Stake")}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default RemoveJV2Modal;
