import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Modal, ModalBody, Text, Flex, Button } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import ApproveConfirmButtons, {
  ButtonArrangement,
} from "components/ApproveConfirmButtons";
import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import {
  useLaunchSettingsContract,
  useJamonVIPContract,
} from "hooks/useContract";
import { SlotsData, WalletNFTData } from "../../types";

interface Props {
  presaleAddress: string;
  slotData: SlotsData;
  walletNFT: WalletNFTData;
  onSuccess: () => void;
  onDismiss?: () => void;
}

const NFTSlotModal: React.FC<Props> = ({
  presaleAddress,
  slotData,
  walletNFT,
  onDismiss,
  onSuccess,
}) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();

  const { balance } = walletNFT;

  const contract = useLaunchSettingsContract();
  const nftContract = useJamonVIPContract();
  const haveNFT = balance > 0;

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm,
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await nftContract.isApprovedForAll(
          account,
          contract.address
        );
        return Boolean(response);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      return nftContract.setApprovalForAll(contract.address, true);
    },
    onConfirm: async () => {
      const id = await nftContract.tokenOfOwnerByIndex(account, 0);
      return contract.rentSlot(presaleAddress, id.toString());
    },
    onSuccess: async () => {
      onSuccess();
      onDismiss();
    },
  });

  return (
    <Modal title={t("Rent Slot with NFT")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
        <Flex justifyContent="center" mb="12px">
          <Text>You have {balance} NFTs</Text>
        </Flex>
        {haveNFT ? (
          <ApproveConfirmButtons
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproveDisabled={isConfirmed || isConfirming || isApproved}
            isApproving={isApproving}
            isConfirmDisabled={!isApproved || isConfirmed}
            isConfirming={isConfirming}
            onApprove={handleApprove}
            onConfirm={handleConfirm}
          />
        ) : (
          <Button disabled>{t("Not have NFT")}</Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default NFTSlotModal;
