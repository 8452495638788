import { ChainId, Token } from "@pancakeswap/sdk";
import { serializeToken } from "state/user/hooks/helpers";
import { SerializedToken } from "./types";

const { MAINNET, TESTNET } = ChainId;

interface TokenList {
  [symbol: string]: Token;
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken;
}

export const mainnetTokens = {
  wmatic: new Token(
    MAINNET,
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://www.polygon.com/"
  ),
  maticf: new Token(
    MAINNET,
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    18,
    "MATIC",
    "MATIC",
    "https://www.polygon.com/"
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(
    MAINNET,
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    18,
    "MATIC",
    "MATIC",
    "https://www.polygon.com/"
  ),
  jamon: new Token(
    MAINNET,
    "0x5FC6485035AE3715920C3d7a55bAf0eB68c9D9ed",
    18,
    "JAMON",
    "JamonSwap Token",
    "https://jamonswap.finance/"
  ),
  jamonV2: new Token(
    MAINNET,
    "0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2",
    18,
    "JAMON",
    "JamonSwap V2",
    "https://jamonswap.finance/"
  ),
  jshare: new Token(
    MAINNET,
    "0x4521b65BB2AC3CA4885947F0c7DFd42b4fA5B18a",
    18,
    "J-SHARE",
    "JamonShare",
    "https://jamonswap.finance/"
  ),
  jstlp: new Token(
    MAINNET,
    "0x7aC7b850985be712fE0847C61166aC1792E9979E",
    18,
    "JSTLP",
    "JamonStakeLP",
    "https://jamonswap.finance/"
  ),
  jusd: new Token(
    MAINNET,
    "0x4cA9700c20EeeF2B6a73Bb63c5eE7775eb7Da54A",
    18,
    "JUSD",
    "Jamon-Peg USD",
    "https://jamonswap.finance/"
  ),
  jflu: new Token(
    MAINNET,
    "0x6C0d20f161775A8321D1cf2F62eB9e6e9F43E576",
    18,
    "JFLU",
    "Jamon Fluid",
    "https://jamonswap.finance/"
  ),
  jtr: new Token(
    MAINNET,
    "0xd81f0CC12f38a33b2AAb78005F1707B44E99a021",
    18,
    "ATR",
    "Acorn Token Reward",
    "https://acorncasino.io/"
  ),
  usdc: new Token(
    MAINNET,
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    6,
    "USDC",
    "USD Coin",
    "https://www.paxos.com/usdc/"
  ),
  usdt: new Token(
    MAINNET,
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    6,
    "USDT",
    "Tether USD",
    "https://tether.to/"
  ),
  ust: new Token(
    MAINNET,
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    6,
    "USDT",
    "Tether USD",
    "https://tether.to/"
  ),
  syrup: new Token(
    MAINNET,
    "0xBc09301E2D5578485Ca52EED5C7B340c51e95dC8",
    18,
    "JRT",
    "Jamon Reward Token",
    "https://jamonswap.finance/"
  ),
  link677: new Token(
    MAINNET,
    "0xb0897686c545045aFc77CF20eC7A532E3120E0F1",
    18,
    "LINKv2",
    "ChainLink ERC677",
    "https://chain.link/"
  ),
  dsg: new Token(
    MAINNET,
    "0x74fAa168E467EBD2E6B24f51d5EdBeaA0841aE65",
    18,
    "DSG",
    "D-Skyscraper Game",
    "https://d-skyscraper.games/"
  ),
  xcre: new Token(
    MAINNET,
    "0xFA3c05C2023918A4324fDE7163591Fe6BEBd1692",
    18,
    "XCRE",
    "Cresio",
    "https://cresio.es"
  ),
  wbtc: new Token(
    MAINNET,
    "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    8,
    "WBTC",
    "Wrapped BTC",
    "https://www.wbtc.network/"
  ),
  weth: new Token(
    MAINNET,
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    18,
    "WETH",
    "Wrapped ETH",
    "https://weth.io/"
  ),
  sff: new Token(
    MAINNET,
    "0xdf9B4b57865B403e08c85568442f95c26b7896b0",
    18,
    "SFF",
    "Sunflower Farm",
    "https://sunflower-farmers.com/"
  ),
  sand: new Token(
    MAINNET,
    "0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683",
    18,
    "SAND",
    "SandBox",
    "https://www.sandbox.game/"
  ),
  dai: new Token(
    MAINNET,
    "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    18,
    "DAI",
    "Dai Stablecoin",
    "https://makerdao.com/es/"
  ),
  shib: new Token(
    MAINNET,
    "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
    18,
    "SHIB",
    "SHIBA INU (PoS)",
    "https://shibatoken.com/"
  ),
  algb: new Token(
    MAINNET,
    "0x0169eC1f8f639B32Eec6D923e24C2A2ff45B9DD6",
    18,
    "ALGB",
    "Algebra",
    "https://algebra.finance/"
  ),
  falcon: new Token(
    MAINNET,
    "0x375D3403F8f5f61139adFc23BEBC693d6Dc75D62",
    18,
    "FLN",
    "Falcon",
    "https://space-dex.io"
  ),
  swc: new Token(
    MAINNET,
    "0x536a6Bc33010976b07C8e7D08189694125188E4e",
    18,
    "SWC",
    "Shiba World Cup",
    "https://shiba-worldsports.io"
  )
};

export const testnetTokens = {
  jamon: new Token(
    TESTNET,
    "0x11171bD06bAd34d9a3967823B1ddeD06ab274645",
    18,
    "JAMON",
    "JamonSwap Token",
    "https://jamonswap.finance/"
  ),
  jamonV2: new Token(
    TESTNET,
    "0xBb1388eD79d611210794cd626120710720306B0f",
    18,
    "JAMON",
    "JamonSwap V2",
    "https://jamonswap.finance/"
  ),
  jshare: new Token(
    TESTNET,
    "0x4f65a5368Bdec2137e7163312A00ef31eA231408",
    18,
    "J-SHARE",
    "JamonShare",
    "https://jamonswap.finance/"
  ),
  jstlp: new Token(
    TESTNET,
    "0x35dd68c8477fBB7558F5d7d3E8b32572A97b02dA",
    18,
    "JSTLP",
    "JamonStakeLP",
    "https://jamonswap.finance/"
  ),
  jusd: new Token(
    TESTNET,
    "0x1911e55683Eb31de03d9477946Af783f692C6208",
    18,
    "JUSD",
    "Jamon-Peg USD",
    "https://jamonswap.finance/"
  ),
  jflu: new Token(
    TESTNET,
    "0x7d5b9d400e097A583008FE00414b2E92024b0A46",
    18,
    "JFLU",
    "Jamon Fluid",
    "https://jamonswap.finance/"
  ),
  jtr: new Token(
    TESTNET,
    "0x35dd68c8477fBB7558F5d7d3E8b32572A97b02dA",
    18,
    "ATR",
    "Acorn Token Reward",
    "https://acorncasino.io/"
  ),
  bnb: new Token(
    TESTNET,
    "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    18,
    "MATIC",
    "MATIC",
    "https://www.polygon.com/"
  ),
  maticf: new Token(
    TESTNET,
    "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    18,
    "MATIC",
    "MATIC",
    "https://www.polygon.com/"
  ),
  usdc: new Token(
    TESTNET,
    "0x356D37618b48220552DE80DC8310eC2760d594EA",
    6,
    "USDC",
    "USD Coin",
    "https://www.paxos.com/usdc/"
  ),
  usdt: new Token(
    TESTNET,
    "0x95e5b27Ae730bE8C9B539B7eA664F4E8f224A0b2",
    6,
    "USDT",
    "Tether USD",
    "https://tether.to/"
  ),
  wmatic: new Token(
    TESTNET,
    "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://www.polygon.com/"
  ),
  ust: new Token(
    TESTNET,
    "0xb8E10fa9C4445B23B7d0163EBa0f4dBe5E9Adccf",
    18,
    "WMATIC",
    "Wrapped MATIC",
    "https://www.polygon.com/"
  ),
  dai: new Token(
    TESTNET,
    "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    18,
    "DAI",
    "Dai Stablecoin",
    "https://makerdao.com/es/"
  ),
  link677: new Token(
    TESTNET,
    "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
    18,
    "LINKv2",
    "ChainLink ERC677",
    "https://chain.link/"
  ),
  wbtc: new Token(
    TESTNET,
    "0xC7Cd3913F06Aed6915b9fD446645E0F96E544c1A",
    8,
    "WBTC",
    "Wrapped BTC",
    "https://www.wbtc.network/"
  ),
  falcon: new Token(
    TESTNET,
    "0xDf62A86e4b84967a4Adee3C52B92492a7D2A10FF",
    18,
    "FLN",
    "Falcon",
    "https://space-dex.io"
  )
};

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID;

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] };
    }, {});
  }

  return mainnetTokens;
};

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens();
  const serializedTokens = Object.keys(unserializedTokens).reduce(
    (accum, key) => {
      return { ...accum, [key]: serializeToken(unserializedTokens[key]) };
    },
    {}
  );

  return serializedTokens;
};

export default tokens();
