import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { getLaunchVestingAddress } from "utils/addressHelpers";
import { multicallv2 } from "utils/multicall";
import vestingAbi from "config/abi/LaunchVesting.json";
import { BIG_ZERO } from "utils/bigNumber";
import BigNumber from "bignumber.js";
import { VestingWalletData } from "views/Bonus/types";

const useGetWalletVestingData = (uid?: any): VestingWalletData => {
  const [state, setState] = useState({
    id: null,
    cliff: 0,
    start: 0,
    amountTotal: BIG_ZERO,
    released: BIG_ZERO,
    available: BIG_ZERO
  });

  const address = getLaunchVestingAddress();

  const fetchVestingData = useCallback(async () => {
    const vestingCalls = [
        {
          address,
          name: "getVestingSchedule",
          params: [uid]
        },
        {
          address,
          name: "computeReleasableAmount",
          params: [uid]
        }
      ];

      const [vesting, releasableAmount] = await multicallv2(vestingAbi, vestingCalls);

    setState((prevState) => ({
      ...prevState,
      id: uid,
      cliff: Number(vesting[0][2]),
      start: Number(vesting[0][3]),
      amountTotal: new BigNumber(vesting[0][6].toString()),
      released: new BigNumber(vesting[0][7].toString()),
      available: new BigNumber(releasableAmount[0].toString())
    }));
  }, [address, uid]);

  useEffect(() => {
    if (uid) {
      fetchVestingData();
    }
  }, [uid, fetchVestingData]);

  return { ...state, fetchVestingData };
};

export default useGetWalletVestingData;
