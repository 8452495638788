import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useMoralisQuery } from "react-moralis";
import { useTranslation } from "contexts/Localization";
import { Flex, Text } from "@pancakeswap/uikit";
import { Token } from "@pancakeswap/sdk";
import { PublicPresaleData } from "../../types";
import LockRow from "./LockRow";

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 13%);
  padding: 0 24px;
  @media screen and (max-width: 1799px) {
    grid-template-columns: 15% 15% 15% 15% 15% 10%;
    & > *:nth-child(1) {
      display: none;
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 18% 18% 18% 18% 18% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 20% 20% 20% 20%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 50% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
`;

const ClickableColumnHeader = styled(Text)`
  cursor: pointer;
`;

const TableWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card.background};
  padding-top: 16px;
  gap: 16px;
`;

interface LockTableProps {
  presaleAddress: string;
  publicData: PublicPresaleData;
  saleToken: Token;
}

const LockTable: React.FC<LockTableProps> = ({
  presaleAddress,
  publicData,
  saleToken
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const [locks, setLocks] = useState(null);

  const { fetch, data, error, isLoading } = useMoralisQuery(
    "LaunchLocker",
    (query) =>
      query
        .fullText("user", account != null ? account : "0xDeaD")
        .notEqualTo("removed", true),
    [account]
  );

  useEffect(() => {
    if (data) {
      let orderData = JSON.stringify(data, null, 2);
      orderData = JSON.parse(orderData);
      setLocks(orderData);
    }
  }, [data]);

  return (
    locks && locks.length > 0 && (
      <>
        <Flex justifyContent="center" marginY="1rem">
          <Text textTransform="uppercase">{t("Locked Tokens")}</Text>
        </Flex>
        <TableWrapper>
          <ResponsiveGrid>
            <ClickableColumnHeader
              color="secondary"
              fontSize="12px"
              bold
              textTransform="uppercase"
            >
              {t("Token")}
            </ClickableColumnHeader>
            <ClickableColumnHeader
              color="secondary"
              fontSize="12px"
              bold
              textTransform="uppercase"
            >
              {t("Amount")}
            </ClickableColumnHeader>
            <ClickableColumnHeader
              color="secondary"
              fontSize="12px"
              bold
              textTransform="uppercase"
            >
              {t("Unlock Date")}
            </ClickableColumnHeader>
          </ResponsiveGrid>
          {locks.map((content, key) => (
            <>
              <LockRow
                uid={content.lockId}
                token={content.lpToken}
                amount={content.amount}
                unlockDate={content.unlockDate}
                fetch={fetch}
              />
            </>
          ))}
        </TableWrapper>
      </>
    )
  );
};

export default LockTable;
