import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "contexts/Localization";
import ConnectWalletButton from "components/ConnectWalletButton";
import { RouteComponentProps, Link } from "react-router-dom";
import { useMoralisQuery } from "react-moralis";
import {
  Flex,
  Box,
  Text,
  Heading,
  Card,
  Skeleton,
  SearchIcon,
  Button,
  CardRibbon,
  Alert,
  Spinner,
  useModal,
  Breadcrumbs,
  LinkExternal
} from "@pancakeswap/uikit";
import { getBscScanLink } from "utils";
import { StyledPageLaunchPad, PageHeader } from "views/Style";
import { getFullDisplayBalance } from "utils/formatBalance";
import { TokenUrlImage } from "components/TokenImage";
import Page from "components/Layout/Page";
import truncateHash from "utils/truncateHash";
import ExpandableSectionButton from "components/ExpandableSectionButton";
import { useToken } from "hooks/Tokens";
import { usePresaleContract } from "hooks/useContract";
import useToast from "hooks/useToast";
import Timer from "../components/timer";
import RoundTimer from "./components/RoundsTimer";
import Tokenomics from "./components/TokenomicsPanel";
import RoundProgress from "./components/RoundProgress";
import { PresaleStatus, PresaleInfo } from "../types";
import {
  TitleLabel,
  SubLabel,
  SecondaryLabel,
  Container,
  Logo,
  WrappedImg,
  StyledAlert,
  ExpandingWrapper
} from "./styles";
import useGetPublicPresaleData from "../hooks/useGetPublicPresaleData";
import useGetWalletPresaleData from "../hooks/useGetWalletPresaleData";
import useGetSlotsData from "../hooks/useGetSlotsData";
import ActionModal from "./components/ActionModal";
import ClaimModal from "./components/ClaimModal";
import OwnerPanel from "./components/OwnerPanel";
import VestingPanel from "./components/VestingPanel";
import RoundDetails from "./components/RoundsDetails";
import SlotPanel from "./components/SlotsPanel";

const getRibbonComponent = (status: PresaleStatus, t: any) => {
  if (status === "ComingSoon") {
    return (
      <CardRibbon
        variantColor="textDisabled"
        ribbonPosition="right"
        text={t("Coming Soon")}
      />
    );
  }

  if (status === "OnSale" || status === "WaitingComplete") {
    return (
      <CardRibbon
        variantColor="primary"
        ribbonPosition="right"
        style={{ textTransform: "uppercase" }}
        text={status === "OnSale" ? `${t("Live")}!` : `${t("Awaiting")}`}
      />
    );
  }
  if (status === "Completed") {
    return (
      <CardRibbon
        variantColor="secondary"
        ribbonPosition="right"
        style={{ textTransform: "uppercase" }}
        text={t("Completed")}
      />
    );
  }
  if (status === "Failed") {
    return (
      <CardRibbon
        variantColor="textDisabled"
        ribbonPosition="right"
        style={{ textTransform: "uppercase" }}
        text={t("Rejected")}
      />
    );
  }

  return null;
};

const day = 86400;
const month = 2629743;

const Presale: React.FC<RouteComponentProps<{ address: string }>> = ({
  match: {
    params: { address: presaleAddress }
  }
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { toastSuccess } = useToast();
  const [showExpandableSection, setShowExpandableSection] = useState(false);
  const [isLoadingCompleting, setLoadingCompleting] = useState(false);
  const [presaleInfo, setPresaleInfo] = useState(null);
  const [showExpandableSlotsSection, setShowExpandableSlotsSection] =
    useState(false);

  const { fetch, data, error, isLoading } = useMoralisQuery(
    "NewPresales",
    (query) => query.fullText("presale", presaleAddress),
    [account]
  );

  useEffect(() => {
    if (data) {
      let presaleData = JSON.stringify(data, null, 2);
      presaleData = JSON.parse(presaleData);
      setPresaleInfo(presaleData[0]);
    }
  }, [data]);

  const presaleData = useGetPublicPresaleData(presaleAddress);
  const walletData = useGetWalletPresaleData(presaleAddress);
  const slotsData = useGetSlotsData(presaleAddress);
  const {
    loading,
    saleToken,
    baseToken,
    status,
    round,
    owner,
    whitelist,
    list,
    startAt,
    endAt,
    softcap,
    hardcap,
    totalCollected,
    listPrice,
    sold,
    buyers,
    locked,
    lockPeriod,
    max4user,
    inMatic,
    vesting,
    vPercent,
    vCliff,
    vPeriods,
    vPeDuration,
    currentRound,
    rounds,
    remaining
  } = presaleData;

  const { spended, purchased, whitelisted } = walletData;

  const saleERC20 = useToken(saleToken);
  const baseERC20 = useToken(baseToken);

  const Ribbon = getRibbonComponent(status, t);
  let baseSymbol = baseERC20 ? baseERC20.symbol : "";
  baseSymbol = baseSymbol === "WMATIC" ? "MATIC" : baseSymbol;
  const saleSymbol = saleERC20 ? saleERC20.symbol : "";

  // Display
  const displayLockMonths = (lockPeriod / month).toFixed();
  const displayListPrice = getFullDisplayBalance(
    listPrice,
    saleERC20?.decimals,
    2
  );
  const displaySoftcap = getFullDisplayBalance(softcap, baseERC20?.decimals, 2);
  const displayHardcap = getFullDisplayBalance(hardcap, baseERC20?.decimals, 2);
  const displayCollected = getFullDisplayBalance(
    totalCollected,
    baseERC20?.decimals,
    2
  );
  const displayRoundCollected = getFullDisplayBalance(
    currentRound.collected,
    baseERC20?.decimals,
    2
  );
  const displayRoundHardcap = getFullDisplayBalance(
    currentRound.hardcap,
    baseERC20?.decimals,
    2
  );

  const displayRoundPrice = getFullDisplayBalance(
    currentRound.price,
    saleERC20?.decimals
  );

  const displaySpended = getFullDisplayBalance(spended, baseERC20?.decimals, 2);
  const displayPurchased = getFullDisplayBalance(
    purchased,
    saleERC20?.decimals,
    2
  );
  const commited = spended.gt(0) && purchased.gt(0);
  const logoUrl =
    presaleInfo && presaleInfo?.tokenLogo ? presaleInfo.tokenLogo : null;
  const tokenomics =
    presaleInfo && presaleInfo?.tokenomics ? presaleInfo.tokenomics : [];

  const isOwner = owner === account;

  const isRemaining = remaining.gt(0);

  const roundName = 
    round === 0 ? t("Early Access") : t(`Round %number%`, { number: round });

  const handleSuccess = (amount: BigNumber) => {
    const displayAmount = getFullDisplayBalance(amount, baseERC20.decimals);
    toastSuccess(
      t("Bought %amount% %sym%", { amount: displayAmount, sym: baseSymbol })
    );
    presaleData.fetchPresaleData()
  };

  const handleClaimSuccess = (amount: string, symbol: string) => {    
    toastSuccess(
      t("Claimed %amount% %sym%", { amount, sym: symbol })
    );
    walletData.fetchPresaleData();
  };

  const [onPresentActionModal] = useModal(
    <ActionModal
      onSuccess={handleSuccess}
      presaleAddress={presaleAddress}
      presaleData={presaleData}
      walletData={walletData}
    />
  );

  const [onPresentClaimModal] = useModal(
    <ClaimModal
      onSuccess={handleClaimSuccess}
      presaleAddress={presaleAddress}
      presaleData={presaleData}
      walletData={walletData}
    />
  );

  const contract = usePresaleContract(presaleAddress);

  const completePresale = async () => {
    setLoadingCompleting(true);
    try {
      const tx = await contract.addLiquidity();
      await tx.wait();
      presaleData.fetchPresaleData();
      setLoadingCompleting(false);
    } catch (err) {
      setLoadingCompleting(false);
      console.error(err);
    }
  };

  const acctionButton = (presaleStatus: PresaleStatus): JSX.Element => {
    if (presaleStatus === "OnSale") {
      if (round === 0) {
        return (
          <Button
            width="100%"
            mb="16px"
            onClick={onPresentActionModal}
            disabled={!slotsData.haveSlot || !isRemaining}
          >
            {slotsData.haveSlot
              ? t("Buy %sym%", { sym: saleSymbol })
              : t("Not have slot")}
          </Button>
        );
      }
      if (whitelist) {
        return (
          <Button
            width="100%"
            mb="16px"
            onClick={onPresentActionModal}
            disabled={!whitelisted || !isRemaining}
          >
            {whitelisted
              ? t("Buy %sym%", { sym: saleSymbol })
              : t("Not allowed")}
          </Button>
        );
      }
      return (
        <Button
          width="100%"
          mb="16px"
          onClick={onPresentActionModal}
          disabled={!isRemaining}
        >
          {t("Buy %sym%", { sym: saleSymbol })}
        </Button>
      );
    }
    if (presaleStatus === "WaitingComplete") {
      return (
        <Button width="100%" mb="16px" onClick={completePresale}>
          {t("Complete")}
        </Button>
      );
    }
    if (presaleStatus === "Completed") {
      return (
        <Button
          width="100%"
          mb="16px"
          disabled={!commited}
          onClick={onPresentClaimModal}
        >
          {t("Claim %sym%", { sym: saleSymbol })}
        </Button>
      );
    }
    if (presaleStatus === "Failed") {
      return (
        <Button
          width="100%"
          mb="16px"
          disabled={!commited}
          onClick={onPresentClaimModal}
        >
          {t("Claim %sym%", { sym: baseSymbol })}
        </Button>
      );
    }
    if (presaleStatus === "ComingSoon") {
      return (
        <Button width="100%" disabled mb="16px">
          {t("Waiting to start")}
        </Button>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <Flex height="450px" alignItems="center" justifyContent="center">
        <Spinner size={80} />
      </Flex>
    );
  }
  return (
    <StyledPageLaunchPad>
      <Box mb="24px">
        <Breadcrumbs>
          <Link to="/">{t("Home")}</Link>
          <Link to="/launchpad">{t("Launchpad")}</Link>
          {saleERC20 && (
            <Text>
              {t("%name% (%addr%)", {
                name: saleERC20?.name,
                addr: truncateHash(saleToken)
              })}
            </Text>
          )}
        </Breadcrumbs>
      </Box>
      <Container mb="24px">
        <Card>
          <Flex>
            <Logo>
              {logoUrl ? (
                <TokenUrlImage url={logoUrl} width={56} height={56} />
              ) : (
                <Skeleton width={56} height={56} />
              )}
            </Logo>
            <Box ml="1em">
              <Heading scale="md" mt="1em">
                {saleERC20 ? (
                  `${saleERC20.name} (${saleERC20.symbol})`
                ) : (
                  <Skeleton width="100%" />
                )}
              </Heading>
              <Flex>
                <LinkExternal
                  mr="8px"
                  href={getBscScanLink(saleToken, "token")}
                >
                  {t("PolygonScan")}
                </LinkExternal>
              </Flex>
            </Box>
          </Flex>
          <hr />
          <Box height="450px" mb={2}>
            <Tokenomics
              presaleAddress={presaleAddress}
              presaleData={presaleData}
              data02={tokenomics}
            />
          </Box>
          <Box>
            <ExpandingWrapper>
              <ExpandableSectionButton
                onClick={() => setShowExpandableSection(!showExpandableSection)}
                expanded={showExpandableSection}
                label={t("Rounds")}
              />
              {showExpandableSection && (
                <RoundDetails
                  rounds={rounds}
                  saleToken={saleERC20}
                  baseToken={baseERC20}
                />
              )}
            </ExpandingWrapper>
          </Box>
        </Card>
        <Card ribbon={Ribbon}>
          <Box p={["16px", "16px", "24px"]}>
            <Flex justifyContent="center" mb={3}>
              <TitleLabel> {t("Overview")}</TitleLabel>
            </Flex>
            <Timer data={presaleData} />
            <Flex justifyContent="center">
              <SecondaryLabel>
                {displayCollected &&
                  displayHardcap &&
                  baseSymbol &&
                  t("%collec%/%hard% %sym%", {
                    collec: displayCollected,
                    hard: displayHardcap,
                    sym: baseSymbol
                  })}
              </SecondaryLabel>
            </Flex>
            <Box mb={5}>
              <RoundProgress collected={totalCollected} hardcap={hardcap} />
              <Flex justifyContent="space-between" mb="1em">
                <Text fontSize="14px" color="secondary">
                  {displaySoftcap &&
                    baseSymbol &&
                    t("Softcap %amount% %sym%", {
                      amount: displaySoftcap,
                      sym: baseSymbol
                    })}{" "}
                </Text>
                <Text fontSize="14px" color="secondary">
                  {displayHardcap &&
                    baseSymbol &&
                    t("Hardcap %amount% %sym%", {
                      amount: displayHardcap,
                      sym: baseSymbol
                    })}{" "}
                </Text>
              </Flex>
              <Flex justifyContent="space-around">
                <Flex justifyContent="center" flexDirection="column" width="33.33%">
                  <Flex justifyContent="center">
                    <WrappedImg src="/images/presale/lock.png" width="68px" />
                  </Flex>
                  <Text color="textSubtle" textAlign="center">
                    {locked / 10}%
                  </Text>
                  <Text textAlign="center">
                    {t("Locked %amount% Months", { amount: displayLockMonths })}
                  </Text>
                </Flex>
                <Flex justifyContent="center" flexDirection="column" width="33.33%">
                  <Flex justifyContent="center">
                    <WrappedImg src="/images/presale/user.png" width="68px" />
                  </Flex>
                  <Text color="textSubtle" textAlign="center">
                    {buyers}
                  </Text>
                  <Text textAlign="center">{t("Users")}</Text>
                </Flex>
                <Flex justifyContent="center" flexDirection="column" width="33.33%">
                  <Flex justifyContent="center">
                    {" "}
                    <WrappedImg src="/images/presale/price.png" width="68px" />
                  </Flex>
                  <Text color="textSubtle" textAlign="center">
                    {displayListPrice}
                  </Text>
                  <Text textAlign="center">{t("List Price")}</Text>
                </Flex>
              </Flex>
            </Box>
            <Flex justifyContent="center">
              <SubLabel> {roundName}</SubLabel>
            </Flex>
            <Flex justifyContent="center">
              <RoundTimer status={status} data={currentRound} />
            </Flex>
            <Box>
              <RoundProgress
                collected={currentRound.collected}
                hardcap={currentRound.hardcap}
              />
              <Flex justifyContent="space-between">
                <Text fontSize="14px" color="secondary">
                  {displayRoundCollected &&
                    baseSymbol &&
                    t("Collected %amount% %sym%", {
                      amount: displayRoundCollected,
                      sym: baseSymbol
                    })}{" "}
                </Text>
                <Text fontSize="14px" color="secondary">
                  {displayRoundHardcap &&
                    baseSymbol &&
                    t("Hardcap %amount% %sym%", {
                      amount: displayRoundHardcap,
                      sym: baseSymbol
                    })}{" "}
                </Text>
              </Flex>
              <Flex justifyContent="center" mt="0.5rem">
                <SecondaryLabel>
                  {displayRoundPrice &&
                    baseSymbol &&
                    saleSymbol &&
                    t("1 %sym% = %price% %sym2%", {
                      price: displayRoundPrice,
                      sym: baseSymbol,
                      sym2: saleSymbol
                    })}
                </SecondaryLabel>
              </Flex>
            </Box>
            <Box mt="22px">
              {commited && (
                <>
                  <Flex mb="24px" justifyContent="space-evenly">
                    <Flex justifyContent="center" flexDirection="column">
                      <Text
                        fontSize="16px"
                        color="textSubtle"
                        textAlign="center"
                      >
                        {displaySpended} {baseSymbol}
                      </Text>
                      <Text textAlign="center">{t("Spent")}</Text>
                    </Flex>
                    <Flex justifyContent="center" flexDirection="column">
                      <Text
                        fontSize="16px"
                        color="textSubtle"
                        textAlign="center"
                      >
                        {displayPurchased} {saleSymbol}
                      </Text>
                      <Text textAlign="center">{t("Owed")}</Text>
                    </Flex>
                  </Flex>
                </>
              )}
              {whitelist && round !== 0 && (
                <StyledAlert>
                  <Alert variant="info" title="Whitelist active">
                    <Text bold mr="14px">
                      {t(
                        "Only %wallets% wallets can buy until the whitelist is deactivated",
                        {
                          wallets: list.toString()
                        }
                      )}
                    </Text>
                  </Alert>
                </StyledAlert>
              )}
              {account ? (
                acctionButton(status)
              ) : (
                <ConnectWalletButton width="100%" type="button" />
              )}
            </Box>
          </Box>
          <Box mt="6px">
            <ExpandingWrapper>
              <ExpandableSectionButton
                onClick={() =>
                  setShowExpandableSlotsSection(!showExpandableSlotsSection)
                }
                expanded={showExpandableSlotsSection}
                label={t("Slots")}
              />
              {showExpandableSlotsSection && (
                <SlotPanel
                  presaleAddress={presaleAddress}
                  publicData={presaleData}
                  walletData={walletData}
                  slotData={slotsData}
                />
              )}
            </ExpandingWrapper>
          </Box>
        </Card>
      </Container>
      {vesting && (
        <VestingPanel
          presaleAddress={presaleAddress}
          publicData={presaleData}
          saleERCToken={saleERC20}
        />
      )}
      {isOwner && (
        <OwnerPanel
          presaleAddress={presaleAddress}
          publicData={presaleData}
          baseToken={baseERC20}
          saleToken={saleERC20}
        />
      )}
    </StyledPageLaunchPad>
  );
};

export default Presale;
