import BigNumber from "bignumber.js";

export interface PublicLimitOrderData {
  totalOrders: number;
  creditPrice: BigNumber;
  fetchLimitOrderData: () => void;
}

export interface WalletLimitOrderData {
  credits: number;
  isPendingTx: boolean
  setPendingTx: (status: boolean) => void
  fetchLimitOrderData: () => void;
}

export enum EnableStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  IS_ENABLING = 'is_enabling',
}

export default EnableStatus