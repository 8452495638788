import BigNumber from "bignumber.js";
import React from "react";
import styled from "styled-components";
import { BIG_ZERO } from "utils/bigNumber";
import { Flex, Text, Box } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { PoolCategory } from "config/constants/types";
import { DeserializedPool } from "state/types";
import HarvestActions from "./HarvestActions";
import StakeActions from "./StakeActions";
import ApprovalAction from "./ApprovalAction";

const InlineText = styled(Text)`
  display: inline;
`;

interface CardActionsProps {
  pool: DeserializedPool;
  stakedBalance: BigNumber;
}

const CardActions: React.FC<CardActionsProps> = ({ pool, stakedBalance }) => {
  const {
    sousId,
    stakingToken,
    earningToken,
    isFinished,
    harvest,
    poolCategory,
    userData,
    earningTokenPrice
  } = pool;
  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE;
  const { t } = useTranslation();
  const allowance = userData?.allowance
    ? new BigNumber(userData.allowance)
    : BIG_ZERO;
  const stakingTokenBalance = userData?.stakingTokenBalance
    ? new BigNumber(userData.stakingTokenBalance)
    : BIG_ZERO;
  const earnings = userData?.pendingReward
    ? new BigNumber(userData.pendingReward)
    : BIG_ZERO;
  const needsApproval = !allowance.gt(0) && !isBnbPool;
  const isStaked = stakedBalance.gt(0);
  const isLoading = !userData;

  return (
    <Flex flexDirection="column" mt="1rem">
      <Flex flexDirection="column">
        {harvest && (
          <>
            <Box display="inline">
              <InlineText
                color="secondary"
                textTransform="uppercase"
                bold
                fontSize="12px"
              >
                {`${earningToken.symbol} `}
              </InlineText>
              <InlineText
                color="textSubtle"
                textTransform="uppercase"
                bold
                fontSize="12px"
              >
                {t("Earned")}
              </InlineText>
            </Box>
            <HarvestActions
              earnings={earnings}
              earningToken={earningToken}
              sousId={sousId}
              earningTokenPrice={earningTokenPrice}
              isBnbPool={isBnbPool}
              isLoading={isLoading}
            />
          </>
        )}
        <Box display="inline" mt="2rem" mb="1.5rem">
          <InlineText
            color={isStaked ? "secondary" : "textSubtle"}
            textTransform="uppercase"
            bold
            fontSize="12px"
          >
            {isStaked ? (
              stakingToken.symbol
            ) : isFinished ? (
              <>{t("Unstake")} </>
            ) : (
              <>{t("Stake")} </>
            )}
          </InlineText>
          <InlineText
            color={isStaked ? "textSubtle" : "secondary"}
            textTransform="uppercase"
            bold
            fontSize="12px"
          >
            {isStaked ? t("Staked") : `${stakingToken.symbol}`}
          </InlineText>
        </Box>
        {needsApproval ? (
          !isFinished && <ApprovalAction pool={pool} isLoading={isLoading} />
        ) : (
          <StakeActions
            isLoading={isLoading}
            pool={pool}
            stakingTokenBalance={stakingTokenBalance}
            stakedBalance={stakedBalance}
            isBnbPool={isBnbPool}
            isStaked={isStaked}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CardActions;
