import { useCallback } from 'react'
import { ethers } from 'ethers'
import { useERC20 } from 'hooks/useContract'

const useApprove = (token: string, spenderAddress: string) => {
  const tokenContract = useERC20(token)
  const onApprove = useCallback(async () => {    
    const tx = await tokenContract.approve(spenderAddress, ethers.constants.MaxUint256)
    await tx.wait()
  }, [spenderAddress, tokenContract])

  return onApprove
}

export default useApprove