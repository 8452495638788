import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";

const ButtonsConfig = {
    ButtonPrimary: styled(Button)<{ isMobile?: boolean, isTablet?: boolean }>`
        border-radius: 0.75rem;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        gap: 0.625rem;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        color: #222531;
        font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "0.7rem" : "0.875rem")};
        font-family: poppins;
    `,
    ButtonSecondary: styled(Button)<{ isMobile?: boolean, isTablet?: boolean }>`
        border-radius: 0.75rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: 0.625rem;
        background-color: #222531;
        color: #FFFFFF;
        font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "0.7rem" : "0.875rem")};
        font-family: poppins;
    `,
    ButtonConnect: styled(Button)<{ isMobile?: boolean, isTablet?: boolean }>`
        display: flex;
        background-color: #222531;
        text-transform: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? "10rem" : "15rem")};
        height: 3rem;
        color: #FFFFFF;
        font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "0.875rem" : "1.125rem")};
        font-family: poppins;
    `,
    ButtonMenu: styled(Button)`
        display: flex;
        text-transform: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 8rem;
        height: 3rem;
        color: #ABAFC4;
        font-size: 1.125rem;
        font-family: poppins;
        cursor: cell;
        &:active{
            color: #FEBF32;
            border-bottom: 1px solid #FEBF32;
        }
    `,
    ButtonListTooltip: styled(Button)`
        color: #ABAFC4;
        text-transform: none;
        width: 8rem;
        font-size: 1rem;
        font-family: poppins;
        text-align: center;
        border: none;
        &:active{
            color: #FEBF32;
            border-bottom: 1px solid #FEBF32;
        }
    `,
}

export default ButtonsConfig;