import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import settingsAbi from "config/abi/LaunchSettings.json";
import { multicallv2 } from "utils/multicall";
import { BIG_ZERO } from "utils/bigNumber";
import { getLaunchSettingsAddress } from "utils/addressHelpers";
import { SlotsData } from "../types";

const useGetSlotsData = (presaleAddress: string): SlotsData => {
  const { slowRefresh } = useRefresh();
  const [state, setState] = useState({
    slotPrice: BIG_ZERO,
    available: 20,
    haveSlot: false,
    inTokens: false
  });

  const { account } = useWeb3React();
  const address = getLaunchSettingsAddress();

  const fetchSlotData = useCallback(async () => {
    const slotsCalls = [
      {
        address,
        name: "slotPrice"
      },
      {
        address,
        name: "presaleJamonSlotsCount",
        params: [presaleAddress]
      }
    ];

    const [slotPrice, SlotsCount] = await multicallv2(settingsAbi, slotsCalls);

    setState((prevState) => ({
      ...prevState,
      slotPrice: new BigNumber(slotPrice[0].toString()),
      available: 20 - Number(SlotsCount[0].toString())
    }));
  }, [address, presaleAddress]);

  const fetchWalletSlotData = useCallback(async () => {
    const slotsCalls2 = [
      {
        address,
        name: "userHaveSlots",
        params: [presaleAddress, account]
      }
    ];

    const [userHaveSlots] = await multicallv2(settingsAbi, slotsCalls2);

    setState((prevState) => ({
      ...prevState,
      haveSlot: Boolean(userHaveSlots[0]),
      inTokens: Boolean(userHaveSlots[1])
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, address]);

  useEffect(() => {
    fetchSlotData();
    if (account) {
      fetchWalletSlotData();
    }
  }, [account, fetchSlotData, fetchWalletSlotData, slowRefresh]);

  return { ...state, fetchSlotData, fetchWalletSlotData };
};

export default useGetSlotsData;
