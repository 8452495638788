import React from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { Modal, ModalBody, Text, Flex, Button } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { getBalanceAmount, getBalanceNumber } from "utils/formatBalance";
import ApproveConfirmButtons, {
  ButtonArrangement,
} from "components/ApproveConfirmButtons";
import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { useLaunchSettingsContract, useCake } from "hooks/useContract";
import { useGetCakeBalance } from "hooks/useTokenBalance";
import { SlotsData } from "../../types";

interface Props {
  presaleAddress: string;
  slotData: SlotsData;
  onSuccess: () => void;
  onDismiss?: () => void;
}

const SlotModal: React.FC<Props> = ({
  presaleAddress,
  slotData,
  onDismiss,
  onSuccess,
}) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();

  const { slotPrice } = slotData;

  const { balance: userBalance } = useGetCakeBalance();
  const contract = useLaunchSettingsContract();
  const tokenContract = useCake();
  const userCurrencyBalance = new BigNumber(userBalance.toString());
  const haveTokens = userCurrencyBalance.gte(slotPrice);

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm,
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await tokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        return currentAllowance.gt(0);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      return tokenContract.approve(
        contract.address,
        ethers.constants.MaxUint256
      );
    },
    onConfirm: () => {
      return contract.buySlot(presaleAddress);
    },
    onSuccess: async () => {
      onSuccess();
      onDismiss();
    },
  });

  return (
    <Modal title={t("Buy Slot")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
        <Flex justifyContent="space-between" mb="12px">
          <Text>{t("Slot price")}:</Text>
          <Text>{getBalanceNumber(slotPrice).toString()} JAMON</Text>
        </Flex>
        <Text color="textSubtle" textAlign="right" fontSize="12px" mb="16px">
          {t("Balance: %balance% JAMON", {
            balance: getBalanceAmount(userCurrencyBalance).toString(),
          })}
        </Text>
        {haveTokens ? (
          <ApproveConfirmButtons
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproveDisabled={isConfirmed || isConfirming || isApproved}
            isApproving={isApproving}
            isConfirmDisabled={!isApproved || isConfirmed}
            isConfirming={isConfirming}
            onApprove={handleApprove}
            onConfirm={handleConfirm}
          />
        ) : (
          <Button disabled>{t("Insufficient balance")}</Button>
        )}

        <Button
          mt="8px"
          as="a"
          href="/swap?outputCurrency=0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2"
          variant="text"
        >
          {t("Get JAMON")}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default SlotModal;
