import React from "react";
import styled from "@emotion/styled";
import { useMatchBreakpoints, Flex, Text } from "@pancakeswap/uikit";
import { Typography } from "@mui/material";
import { PageHeader } from "views/Style";
import { Colors, Fonts } from "components/Style";
import { useTranslation } from "contexts/Localization";
import useGetPublicLimitOrdersData from "../../hooks/useGetPublicLimitOrdersData";
import useGetWalletLimitOrderData from "../../hooks/useGetWalletLimitOrderData";
import CreditsCard from "../CreditsCard";


const Title = styled(Typography)<{ isMobile?: boolean; isTablet?: boolean }>`
  align-items: center;
  font-size: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? Fonts.H3 : Fonts.H2};
  font-family: Poppins;
  font-weight: bold;
  text-decoration: 1px underline ${Colors.BORDER1};
  color: ${Colors.TITLE};
  margin-top: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? "2rem" : ""};
`;

const Header = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const publicData = useGetPublicLimitOrdersData();
  const walletData = useGetWalletLimitOrderData();

  return (
    <PageHeader isMobile={isMobile} isTablet={isTablet}>
      <Flex
          justifyContent="space-between"
          flexDirection={["column", null, null, "row"]}
        >
        <Flex
          flex="1"
          flexDirection="column"
          mr={["8px", 0]}
          alignItems="center"
        >
          <Title isMobile={isMobile} isTablet={isTablet}>
            {t("Limit Orders")}
          </Title>
          <Text
            fontFamily="Poppins"
            fontSize={isMobile || isTablet ? Fonts.SUBTITLE : Fonts.H6}
            color={Colors.TURQUOISE7}
            textAlign="center"
          >
            {t("Program swaps at a certain price.")}
          </Text>
          <Text
            fontFamily="Poppins"
            fontSize={isMobile || isTablet ? Fonts.SUBTITLE : Fonts.H6}
            color={Colors.TURQUOISE7}
            textAlign="center"
          >
            {t("Decentralized with Chainlink Keepers.")}
          </Text>
        </Flex>
        <Flex
            height="fit-content"
            justifyContent="center"
            alignItems="center"
            mt={["24px", null, "12px"]}
        >
          {/*  <HelpButton /> */}
          <CreditsCard publicData={publicData} walletData={walletData} />
        </Flex>
      </Flex>
    </PageHeader>
  );
};

export default Header;
