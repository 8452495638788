export default {
  masterChef: {
    80001: "0x64C44D96b19BEcb8ac54B1C9963C1D801cF9dD5e",
    137: "0x64C44D96b19BEcb8ac54B1C9963C1D801cF9dD5e"
  },
  masterChefV2: {
    80001: "0x418F264B2D623a3eBb3Ac2e47440021ba7ce6d3B",
    137: "0x4D9E580B86cFD6Eb2f518b89545b8C76722Bc626"
  },
  sousChef: {
    80001: "0x18E128B9492b4D02e2bFbA07868F4cef2993Eb55",
    137: "0x18E128B9492b4D02e2bFbA07868F4cef2993Eb55"
  },
  lotteryV2: {
    80001: "0x9D8A80A051DEbA7EBC685E06015Ee6021805E155",
    137: "0x88Aa773655DbC7e643C240a67D0889Ac6626da1E"
  },
  multiCall: {
    137: "0x406837df92C4Fe8e824Ab558F7292B31D955A611",
    80001: "0xF2e8cE0B41aee041Cc4C7C1Cf7194aBdFF5BD601"
  },
  pancakeProfile: {
    137: "0x36509Fba402B27504A58492B5A7D4106c74cE56B",
    80001: "0x36509Fba402B27504A58492B5A7D4106c74cE56B"
  },
  pancakeRabbits: {
    137: "0xb73EC01cb5Fb19cC2ab3109b9Bdf7bC27b1FB67f",
    80001: "0xb73EC01cb5Fb19cC2ab3109b9Bdf7bC27b1FB67f"
  },
  bunnyFactory: {
    137: "0xAEA3274d9649Dff66A6fCF50234d83f95794c3DE",
    80001: "0xAEA3274d9649Dff66A6fCF50234d83f95794c3DE"
  },
  claimRefund: {
    137: "0x2461aFEca2D0dbC241A6A7E0E78ee07562bb01D4",
    80001: "0x2461aFEca2D0dbC241A6A7E0E78ee07562bb01D4"
  },
  pointCenterIfo: {
    137: "0x2a4fD0afB1e4008E3E2b8a32a06951D9DeAB2356",
    80001: "0x2a4fD0afB1e4008E3E2b8a32a06951D9DeAB2356"
  },
  bunnySpecial: {
    137: "0xFee8A195570a18461146F401d6033f5ab3380849",
    80001: "0xFee8A195570a18461146F401d6033f5ab3380849"
  },
  tradingCompetition: {
    137: "0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA",
    80001: "0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA"
  },
  easterNft: {
    137: "0xf0607DcCdfF91654252BC2bfDd2D366e52506490",
    80001: "0xf0607DcCdfF91654252BC2bfDd2D366e52506490"
  },
  dexTokenVault: {
    137: "0x0b951A329795AFe359A622E0a5B8A0d1C70B48e8",
    80001: "0x0b951A329795AFe359A622E0a5B8A0d1C70B48e8"
  },
  predictions: {
    137: "0xc78Bda784F84649329C006930e8811178d1899aa",
    80001: "0xc78Bda784F84649329C006930e8811178d1899aa"
  },
  chainlinkOracle: {
    137: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
    80001: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526"
  },
  bunnySpecialCakeVault: {
    137: "0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4",
    80001: "0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4"
  },
  bunnySpecialPrediction: {
    137: "0x342c99e9aC24157657095eC69CB04b73257e7A9C",
    80001: "0x342c99e9aC24157657095eC69CB04b73257e7A9C"
  },
  farmAuction: {
    137: "0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2",
    80001: "0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2"
  },
  governor: {
    137: "0xd7f337d597E4A5d891b7882aBcB4C1d3f7D4Cb97",
    80001: "0x6A696cacAd8A871d0868F4D536f9DCd49acd93E8"
  },
  conversor: {
    137: "0x018281E1A345061e69a7dFe5c59f8c257D0d59cf",
    80001: "0x7B2724Eb09336F014124F9B5e0A651437A3343De"
  },
  conversorV2: {
    137: "0x00c68A24De5035b07240fd67B7cDCE2d6C3D6274",
    80001: "0xE185AeBbA136F973c40aacCc988E119DE1a2779e"
  },
  jamonShareVault: {
    137: "0x2aE5c21DA40e933B629Dfe085086aB7812a736Cf",
    80001: "0xf312ff7ae7b27104D02d5F1Ad3fea74BA32337A2"
  },
  jamonVault: {
    137: "0xBcE5B2EB71fAe34213aE71403dd81cc3a4400954",
    80001: "0x1A6B828fF76A1705fa51C797282be94d3d29233c"
  },
  jamonStakeLpVault: {
    137: "0xaF5EcB7C090eF793aBF59aF8F82867ff907f01A7",
    80001: "0x7906B98c6Df581348D56726e3eA7D2a1cF3678f7"
  },
  jamonTokenRewardVault: {
    137: "0x7690FA65e42f18908bA22483F0000ed1c5fF7529",
    80001: "0x7906B98c6Df581348D56726e3eA7D2a1cF3678f7"
  },
  jamonVestingOld: {
    137: "0xbc33aC6011ae19364959D11153Bcf64598F3c630",
    80001: "0x5b0B40E684AdC4fe8405Bca11A9243EDf7Aef3EF"
  },
  jamonVesting: {
    137: "0xE499A70aBAc2b26b1b0338c1a971bbF06238dd4a",
    80001: "0xe2AAe42f256ea0E210db139796fA7f25117d6CF1"
  },
  jamonShareVesting: {
    137: "0x3450090cBB632e15c4CD75A6f3Fb1c38555cB2C8",
    80001: "0xEd69F0DcB9eeA88aB2e5839073981AC5492Bf238"
  },
  jamonSharePresale: {
    137: "0xCA213ec7f83e680760E0A1985346a7633F1EbF5A",
    80001: "0x17580fe109fF8cC207bb89caE273b9E0dfF8a2d2"
  },
  bonus: {
    137: "0x079D9a4b290e709355D86613b2312e8875f2e588",
    80001: "0x510069e635e6C35CE08d80aE2a80D60c0bE7cBB3"
  },
  jamonLimit: {
    137: "0xa2C2E50e7851A31b4154fcb77677cbbFA735708A",
    80001: "0x363e836Ce3110dCbaa9B0e57fF5D02338a18DDb0"
  },
  /*  AnniversaryAchievement: {
    137: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    80001: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    137: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    80001: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    137: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    80001: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    137: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    80001: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  tradingCompetitionV2: {
    137: '0x9b872C05C38A3563393668839659bA336Cc969B2',
    80001: '',
  },
  bunnySpecialLottery: {
    137: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    80001: '0x382cB480001110F398F0f152cae82821476AE51c9cF',
  }, */
  medianOracle: {
    137: "0x6478de1e3E00211feC256c26866f8236E068b9ee",
    80001: "0xbd5Eaf0823A78A16A64614Fb0fCDC649D202115b"
  },
  JUSD: {
    137: "0x4cA9700c20EeeF2B6a73Bb63c5eE7775eb7Da54A",
    80001: "0x1911e55683Eb31de03d9477946Af783f692C6208"
  },
  JUSDView: {
    137: "0x113E069819761CD973F89c94FCfBa44114F91aAE",
    80001: "0x014f84cBE7D2bAe5Bb270477C30347e74C89a0BD"
  },
  LaunchFactory: {
    137: "0x85ab2551523Fb19AEb435a91162D7580255f7C10",
    /* 80001: '0x71B76F702A1773E6b766F0b4e03f3ee99da515FA' */
    80001: "0x986fB7888Fc87AAEDA7A2e6595Eb7f6Cdf0b6470"
  },
  LaunchVesting: {
    137: "0x88b977A3906c74a63C973c4d6c418B98eEE43340",
    /* 80001: '0x320f46Df5E0CD363e4C7d4cbd87E131Ec4a55102' */
    80001: "0x156D6B9f48c7f167868adf3E6f4681DB8Ff93f71"
  },
  LaunchSettings: {
    137: "0x25d8aec88Aa2CfBf808A20d4585512d58EBb3f7f",
    /* 80001: '0xd78937fd3fCD7aaBB6dA31255C11A82d3aF78F37' */
    80001: "0x480852404caE532378dB926994cfE3111172EAb9"
  },
  LaunchLocker: {
    137: "0xC75cC85334c90e81de03c7CaB2C5b04434774095",
    /* 80001: '0xB20506282021AC8efBA7Efd8ebf90d6b015F1785' */
    80001: "0xDa5acB7484c553005Ece1dCdcA590839a67A449A"
  },
  JamonLaunch: {
    137: "0x7aEe83ed57928FF2258A343fc896862CE32F1D23",
    /*  80001: '0x8A0913dFE859881FcF8988e2EcD1D19C5B8f7006' */
    80001: "0x93281F08f311208513a6ec477cDcB30F454cfa90"
  },
  JamonVIP: {
    137: "0x15a728e5d93371Be6437428ce65c2720F346EdfD",
    80001: "0x443845DB39f1e32487Ed3413125cb93F90a7223a"
  },
  PresaleJamonVIP: {
    137: "0xd48a7176509EF3A280c3525f444f592Ebd40B457",
    80001: "0xdAa6A6501A201666Af767201FD2A4A9e6282E382"
  },
  Migrator: {
    137: "0x8Be4792aD332a1B893DcF26b6b3dD433f199A035",
    80001: "0x3982e4079284c35eAD802fB8697241F02Cd1B151"
  }
};
