import styled from '@emotion/styled'
import { Box, Container, Typography, Grid } from '@mui/material'
import { Colors, Fonts } from "components/Style";

export const StyledSwapContainer = styled(Box)`
  flex-shrink: 0;
  height: fit-content;
  padding: 0 24px;
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 328px;
`

export const PageHeader = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center
  z-index: 1;
`
export const StyledPage = styled(Container)<{ isMobile?: boolean, isTablet?: boolean, isExpertMode?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  justify-content: center;
  padding-top: 10rem;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "3rem")};
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "20rem" : "5rem")};
  padding-bottom: ${({ isExpertMode }) => (isExpertMode ?  "22rem" : "0")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const StyledPageAddLiquidity = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 10rem;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "5rem")};
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "15rem" : "5rem")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const StyledPageRemoveLiquidity = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 10rem;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "5rem")};
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "15rem" : "5rem")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const StyledPagePools = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 10rem;
  text-align: center;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "5rem")};
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "15rem" : "5rem")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const StyledPageLimitOrders = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 10rem;
  text-align: center;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "5rem")};
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "15rem" : "5rem")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const StyledPageLaunchPad = styled(Container)<{ isMobile?: boolean, isTablet?: boolean }>`
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 10rem;
  text-align: center;
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "5rem")};
  min-height: calc(100vh - 64px);
  background: ${Colors.BACKGROUND1};
  z-index: 1;
`

export const AppBody = styled(Box)<{ isMobile?: boolean, isTablet?: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "22rem" : "35rem")};
  height: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "25rem" : "35rem")};
  max-width: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "25rem" : "35rem")};
  max-height: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "35rem" : "35rem")};
  z-index: 1;
`

export const Title = styled(Grid)<{ isMobile?: boolean, isTablet?: boolean }>`
  color: ${Colors.TITLE};
  font-family: Poppins;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  Fonts.H4 : Fonts.H3)};
`

export const TitleFindPool = styled(Grid)<{ isMobile?: boolean, isTablet?: boolean }>`
  color: ${Colors.TITLE};
  font-family: Poppins;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  Fonts.H4 : Fonts.H3)};
  text-align: center;
`

export const TitleAddPool = styled(Grid)<{ isMobile?: boolean, isTablet?: boolean }>`
  color: ${Colors.TITLE};
  font-family: Poppins;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  Fonts.H5 : Fonts.H3)};
`

export const TitleRemovePool = styled(Grid)<{ isMobile?: boolean, isTablet?: boolean }>`
  color: ${Colors.TITLE};
  font-family: Poppins;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  Fonts.H6 : Fonts.H6)};
`

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  font-family: Poppins;
  color: ${Colors.TEXT2};
`