import React from "react";
import styled from "styled-components";
import { Modal, ModalBody, useMatchBreakpoints } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { serializeTokens } from "config/constants/tokens";
import { WalletJstlpStakeData } from "views/Pools/types";
import TokenRow from "../../StakeTable/JTR/TokenRow";

interface Props {
  account: string;
  walletData: WalletJstlpStakeData;
  userDataLoaded: boolean;
  onDismiss?: () => void;
}

const StyledRewards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

`;

const serializedTokens = serializeTokens();

const RewardsModalJTR: React.FC<Props> = ({
  account,
  walletData,
  userDataLoaded,
  onDismiss
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const inStake = walletData.InStake;

  return (
    <Modal title={t("Rewards Pool ATR")} onDismiss={onDismiss} zIndex={100}>
      <ModalBody maxWidth={isMobile || isTablet ? "320px" : "1000px"} >
        <StyledRewards>
          {inStake && (
            <>
              <TokenRow account={account} walletData={walletData} userDataLoaded={userDataLoaded} token={serializedTokens.jamonV2} />{" "}
            </>
          )}
        </StyledRewards>
      </ModalBody>
    </Modal>
  );
};

export default RewardsModalJTR;