import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CurrencyAmount, JSBI, Token, Trade } from "@pancakeswap/sdk";
import { Box, Grid, Divider } from "@mui/material";
import { Colors } from "components/Style";
import {
  Button,
  Text,
  ArrowDownIcon,
  useModal,
  IconButton,
  BottomDrawer,
  useMatchBreakpoints,
  NotificationDot,
  HistoryIcon
} from "@pancakeswap/uikit";
import GlobalSettings from "components/NavMenu/GlobalSettings";
import TransactionsModal from "components/App/Transactions/TransactionsModal";
import { useIsTransactionUnsupported } from "hooks/Trades";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "contexts/Localization";
import SwapWarningTokens from "config/constants/swapWarningTokens";
import UnsupportedCurrencyFooter from "components/UnsupportedCurrencyFooter";
import { serializeTokens } from "config/constants/tokens";
import { useERC20 } from "hooks/useContract";
import BigNumber from "bignumber.js";
import AddressInputPanel from "./components/AddressInputPanel";
import AdvancedSwapDetailsDropdown from "./components/AdvancedSwapDetailsDropdown";
import { GreyCard } from "../../components/Card";
import Column, { AutoColumn } from "../../components/Layout/Column";
import ConfirmSwapModal from "./components/ConfirmSwapModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import { AutoRow, RowBetween } from "../../components/Layout/Row";
import confirmPriceImpactWithoutFee from "./components/confirmPriceImpactWithoutFee";
import { ArrowWrapper, SwapCallbackError, Wrapper } from "./components/styleds";
import TradePrice from "./components/TradePrice";
import ImportTokenWarningModal from "./components/ImportTokenWarningModal";
import ProgressSteps from "./components/ProgressSteps";
/* import { AppBody } from '../../components/App' */
import ConnectWalletButton from "../../components/ConnectWalletButton";

import { INITIAL_ALLOWED_SLIPPAGE } from "../../config/constants";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useCurrency, useAllTokens } from "../../hooks/Tokens";
import {
  ApprovalState,
  useApproveCallbackFromTrade
} from "../../hooks/useApproveCallback";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { Field } from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  useSingleTokenSwapInfo
} from "../../state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager
} from "../../state/user/hooks";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity
} from "../../utils/prices";
import CircleLoader from "../../components/Loader/CircleLoader";
import SwapWarningModal from "./components/SwapWarningModal";
import PriceChartContainer from "./components/Chart/PriceChartContainer";
import { StyledPage, AppBody, Title, Label } from "../Style";

const serializedTokens = serializeTokens();

export default function Swap({ history }: RouteComponentProps) {
  const loadedUrlParams = useDefaultsFromURLSearch();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const [isChartExpanded, setIsChartExpanded] = useState(false);
  const [userChartPreference, setUserChartPreference] =
    useExchangeChartManager(isMobile);
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference);
  const [expertMode] = useExpertModeManager();
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />);

  useEffect(() => {
    setUserChartPreference(isChartDisplayed);
  }, [isChartDisplayed, setUserChartPreference]);

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ];
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens();
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens);
    });

  const { account } = useActiveWeb3React();

  // for expert mode
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo();

  // Price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId }
  } = useSwapState();

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const singleTokenPrice = useSingleTokenSwapInfo();

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState
  ] = useState<{
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ""
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const [singleHopOnly] = useUserSingleHopOnly();

  const raisingTokenContract = useERC20(serializedTokens.jamonV2.address);

  const handleSwap = useCallback(async () => {
    const response = await raisingTokenContract.allowance(
      account,
      "0xBcE5B2EB71fAe34213aE71403dd81cc3a4400954"
    );
    const currentAllowance = new BigNumber(response.toString());
    if (currentAllowance.gt(0)) {
      const disable = await raisingTokenContract.approve(
        "0xBcE5B2EB71fAe34213aE71403dd81cc3a4400954",
        "0"
      );
      await disable.wait();
    }
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      swapErrorMessage: undefined,
      txHash: undefined
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: undefined,
          txHash: hash
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        });
      });
  }, [raisingTokenContract, account, priceImpactWithoutFee, t, swapCallback, tradeToConfirm]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn
    });
  }, [attemptingTxn, swapErrorMessage, trade, txHash]);

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null);
  const [onPresentSwapWarningModal] = useModal(
    <SwapWarningModal swapCurrency={swapWarningCurrency} />
  );

  const shouldShowSwapWarning = (swapCurrency) => {
    const isWarningToken = Object.entries(SwapWarningTokens).find(
      (warningTokenConfig) => {
        const warningTokenData = warningTokenConfig[1];
        return swapCurrency.address === warningTokenData.address;
      }
    );
    return Boolean(isWarningToken);
  };

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency]);

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      const showSwapWarning = shouldShowSwapWarning(inputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(inputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      const showSwapWarning = shouldShowSwapWarning(outputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(outputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },

    [onCurrencySelection]
  );

  const swapIsUnsupported = useIsTransactionUnsupported(
    currencies?.INPUT,
    currencies?.OUTPUT
  );

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal
      tokens={importTokensNotInDefault}
      onCancel={() => history.push("/swap/")}
    />
  );

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length]);

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    "confirmSwapModal"
  );

  return (
    <StyledPage
      isMobile={isMobile}
      isTablet={isTablet}
      isExpertMode={isExpertMode}
    >
      {/* {!isMobile && (
        <PriceChartContainer
          inputCurrencyId={inputCurrencyId}
          inputCurrency={currencies[Field.INPUT]}
          outputCurrencyId={outputCurrencyId}
          outputCurrency={currencies[Field.OUTPUT]}
          isChartExpanded={isChartExpanded}
          setIsChartExpanded={setIsChartExpanded}
          isChartDisplayed={isChartDisplayed}
          currentSwapPrice={singleTokenPrice}
        />
      )}
      <BottomDrawer
        content={
          <PriceChartContainer
            inputCurrencyId={inputCurrencyId}
            inputCurrency={currencies[Field.INPUT]}
            outputCurrencyId={outputCurrencyId}
            outputCurrency={currencies[Field.OUTPUT]}
            isChartExpanded={isChartExpanded}
            setIsChartExpanded={setIsChartExpanded}
            isChartDisplayed={isChartDisplayed}
            currentSwapPrice={singleTokenPrice}
            isMobile
          />
        }
        isOpen={isChartDisplayed}
        setIsOpen={setIsChartDisplayed}
      /> */}
      <AppBody isMobile={isMobile} isTablet={isTablet}>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          marginBottom="0.5rem"
        >
          <Grid item xs={9} md={10}>
            <Title isMobile={isMobile} isTablet={isTablet}>
              {t("Swap")}
            </Title>
          </Grid>
          <Grid item xs={0} md={0}>
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
          </Grid>
          <Grid item xs={0} md={0}>
            <Button
              variant="text"
              p={0}
              onClick={onPresentTransactionsModal}
              ml="16px"
            >
              <HistoryIcon color="textSubtle" width="24px" />
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ background: Colors.BORDER1, mb: "1rem" }} />
        <Wrapper id="swap-page">
          <AutoColumn gap="xl">
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap && trade
                  ? t("From (estimated)")
                  : t("From")
              }
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />

            <AutoColumn justify="space-between">
              <AutoRow
                justify={isExpertMode ? "space-between" : "center"}
                style={{ padding: "0 1rem" }}
              >
                <IconButton variant="subtle" scale="sm">
                  <ArrowDownIcon
                    width="16px"
                    onClick={() => {
                      setApprovalSubmitted(false); // reset 2 step UI for approvals
                      onSwitchTokens();
                    }}
                    color={
                      currencies[Field.INPUT] && currencies[Field.OUTPUT]
                        ? "primary"
                        : "text"
                    }
                  />
                </IconButton>
                {recipient === null && !showWrap && isExpertMode ? (
                  <Button
                    variant="text"
                    id="add-recipient-button"
                    onClick={() => onChangeRecipient("")}
                  >
                    {t("+ Add a send (optional)")}
                  </Button>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !showWrap && trade
                  ? t("To (estimated)")
                  : t("To")
              }
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />

            {isExpertMode && recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: "0 1rem" }}>
                  <ArrowWrapper clickable={false}>
                    <IconButton
                      variant="subtle"
                      scale="sm"
                      disabled
                      style={{ cursor: "default", background: "#ABAFC4" }}
                    >
                      <ArrowDownIcon width="16px" />
                    </IconButton>
                  </ArrowWrapper>
                  <Button
                    variant="text"
                    id="remove-recipient-button"
                    onClick={() => onChangeRecipient(null)}
                  >
                    {t("- Remove send")}
                  </Button>
                </AutoRow>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}

            {showWrap ? null : (
              <AutoColumn gap="2px" style={{ padding: "0 16px" }}>
                {Boolean(trade) && (
                  <RowBetween align="center">
                    <Label>{t("Price")}</Label>
                    <TradePrice
                      price={trade?.executionPrice}
                      showInverted={showInverted}
                      setShowInverted={setShowInverted}
                    />
                  </RowBetween>
                )}
                {allowedSlippage === INITIAL_ALLOWED_SLIPPAGE && (
                  <RowBetween align="center">
                    <Label>{t("Slippage Tolerance")}</Label>
                    <Text
                      bold
                      color="primary"
                      fontSize={isMobile || isTablet ? "12px" : ""}
                    >
                      {allowedSlippage / 100}%
                    </Text>
                  </RowBetween>
                )}
              </AutoColumn>
            )}
          </AutoColumn>

          <Box mt="1rem">
            <Divider sx={{ background: Colors.BORDER1, mb: "1rem" }} />
            {swapIsUnsupported ? (
              <Button width="100%" disabled mb="4px">
                {t("Unsupported Asset")}
              </Button>
            ) : !account ? (
              <ConnectWalletButton width="100%" />
            ) : showWrap ? (
              <Button
                width="100%"
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
              >
                {wrapInputError ??
                  (wrapType === WrapType.WRAP
                    ? "Wrap"
                    : wrapType === WrapType.UNWRAP
                    ? "Unwrap"
                    : null)}
              </Button>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: "center" }}>
                <Text color="textSubtle" mb="4px">
                  {t("Insufficient liquidity for this trade.")}
                </Text>
                {singleHopOnly && (
                  <Text color="textSubtle" mb="4px">
                    {t("Try enabling multi-hop trades.")}
                  </Text>
                )}
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <Button
                  variant={
                    approval === ApprovalState.APPROVED ? "success" : "primary"
                  }
                  onClick={approveCallback}
                  disabled={
                    approval !== ApprovalState.NOT_APPROVED || approvalSubmitted
                  }
                  width="48%"
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t("Enabling")} <CircleLoader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted &&
                    approval === ApprovalState.APPROVED ? (
                    t("Enabled")
                  ) : (
                    t("Enable %asset%", {
                      asset: currencies[Field.INPUT]?.symbol ?? ""
                    })
                  )}
                </Button>
                <Button
                  variant={
                    isValid && priceImpactSeverity > 2 ? "danger" : "primary"
                  }
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap();
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        txHash: undefined
                      });
                      onPresentConfirmModal();
                    }
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !isValid ||
                    approval !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode)
                  }
                >
                  {priceImpactSeverity > 3 && !isExpertMode
                    ? t("Price Impact High")
                    : priceImpactSeverity > 2
                    ? t("Swap Anyway")
                    : t("Swap")}
                </Button>
              </RowBetween>
            ) : (
              <Button
                variant={
                  isValid && priceImpactSeverity > 2 && !swapCallbackError
                    ? "danger"
                    : "primary"
                }
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      txHash: undefined
                    });
                    onPresentConfirmModal();
                  }
                }}
                id="swap-button"
                width="100%"
                disabled={
                  !isValid ||
                  (priceImpactSeverity > 3 && !isExpertMode) ||
                  !!swapCallbackError
                }
              >
                {swapInputError ||
                  (priceImpactSeverity > 3 && !isExpertMode
                    ? t("Price Impact Too High")
                    : priceImpactSeverity > 2
                    ? t("Swap Anyway")
                    : t("Swap"))}
              </Button>
            )}
            {!swapIsUnsupported ? (
              trade && <AdvancedSwapDetailsDropdown trade={trade} />
            ) : (
              <UnsupportedCurrencyFooter
                currencies={[currencies.INPUT, currencies.OUTPUT]}
              />
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: "1rem" }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
            {isExpertMode && swapErrorMessage ? (
              <SwapCallbackError error={swapErrorMessage} />
            ) : null}
          </Box>
        </Wrapper>
      </AppBody>
    </StyledPage>
  );
}
