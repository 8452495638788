import React from "react";
import { useTranslation } from "contexts/Localization";
import { getBscScanLink } from "utils";
import {
  Flex,
  Link,
  PocketWatchIcon,
  Text,
  Skeleton,
} from "@pancakeswap/uikit";
import useCurrentBlockTimestamp from "hooks/useCurrentBlockTimestamp";
import getTimePeriods from "utils/getTimePeriods";
import { RoundData, PresaleStatus } from "../../types";

interface TimerProps {
  status: PresaleStatus;
  data: RoundData;
}

const RoundTimer: React.FC<TimerProps> = ({ status, data }) => {
  const { t } = useTranslation();
  const { startDate, endDate } = data;

  const blockTimestamp = useCurrentBlockTimestamp();
  const now = () => {
    if (blockTimestamp) return blockTimestamp.toNumber() * 1000;
    return Date.now();
  };

  const secondsUntilStart = (startDate * 1000) - now();
  const secondsUntilEnd = now() - (endDate * 1000);

  const countdownToUse =
    status === "ComingSoon" ? secondsUntilStart/1000 : secondsUntilEnd/1000;
  const timeUntil = getTimePeriods(countdownToUse);
  const suffix = status === "ComingSoon" ? t("Round starts in") : t("Ends in");
  return (
    <Flex justifyContent="center" mb="8px">
      {status === "NotExist" ? (
        <Skeleton animation="pulse" variant="rect" width="100%" height="48px" />
      ) : status !== "Failed" && status !== "Completed" && status !== "ComingSoon" ? (
        <>          
          {countdownToUse !== 0 ? (
            <Flex alignItems="center">
              <Text bold mr="6px">
                {suffix}:
              </Text>
              <Text>
                {t("%day%d %hour%h %minute%m", {
                  day: timeUntil.days,
                  hour: timeUntil.hours,
                  minute: timeUntil.minutes,
                })}
              </Text>
            </Flex>
          ) : (
            <Text>{t("Starting")}</Text>
          )}
        </>
      ) : (
        ""
      )}
    </Flex>
  );
};

export default RoundTimer;
