import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { useTranslation } from "contexts/Localization";
import truncateHash from "utils/truncateHash";
import {
  Flex,
  Box,
  Heading,
  Card,
  CardBody,
  CardHeader,
  Button,
  AutoRenewIcon,
  Text,
  IconButton,
  MinusIcon,
  AddIcon,
  Input,
  BalanceInput
} from "@pancakeswap/uikit";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import useToast from "hooks/useToast";
import { useWeb3React } from "@web3-react/core";
import { getBalanceNumber } from "utils/formatBalance";
import { Token } from "@pancakeswap/sdk";
import { ToastDescriptionWithTx } from "components/Toast";
import { useMoralisQuery } from "react-moralis";
import { BIG_TEN } from "utils/bigNumber";
import { useLaunchVestingContract } from "hooks/useContract";
import useGetWalletVestingData from "../../hooks/useGetWalletVestingData"
import { PublicPresaleData } from "../../types";

const day = 86400;
const month = 2629743;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: repeat(6, 13%) 0%;
  padding: 0 24px;
  @media screen and (max-width: 1799px) {
    grid-template-columns: 15% 15% 15% 15% 15% 10%;
    & > *:nth-child(1) {
      display: none;
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 18% 18% 18% 18% 18% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 20% 20% 20% 20%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 50% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
`;

const CellInner = styled.div`
  padding: 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`;

const CellActionInner = styled.div`
  padding: 24px 0px 0px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 18px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`;

const StyledTr = styled.tr`
  cursor: pointer;
  border-bottom: 2px solid ${({ theme }) => theme.colors.cardBorder};
`;

const ContentContainer = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
`;
const Label = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textSubtle};
  text-align: left;
`;
const ClickableColumnHeader = styled(Text)`
  cursor: pointer;
`;

const TableWrapper = styled(Flex)`
  width: 100%;
  padding-top: 16px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card.background};
`;

interface CellLayoutProps {
  label?: string;
}

const CellLayout: React.FC<CellLayoutProps> = ({ label = "", children }) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <ContentContainer>{children}</ContentContainer>
    </div>
  );
};

interface VestingPanelProps {
  presaleAddress: string;
  publicData: PublicPresaleData;
  saleERCToken: Token;
}

const VestingPanel: React.FC<VestingPanelProps> = ({
  presaleAddress,
  publicData,
  saleERCToken
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { toastError, toastSuccess } = useToast();
  const [locks, setLocks] = useState(null);
  const [pendingTx, setPendingTx] = useState(false);
  const { status, vCliff, vPeDuration, vPercent, vPeriods, saleToken } =
    publicData;

  const contract = useLaunchVestingContract();

  const { fetch, data, error, isLoading } = useMoralisQuery(
    "LaunchVesting",
    (query) =>
      query
        .fullText("beneficiary", account != null ? account : "0xDeaD")
        .equalTo("token", saleToken.toLowerCase()).notEqualTo("removed", true),
    [account]
  );

  useEffect(() => {
    if (data) {
      let orderData = JSON.stringify(data, null, 2);
      orderData = JSON.parse(orderData);
      setLocks(orderData);
    }
  }, [data]);

  const vestingData = useGetWalletVestingData(locks !== null ? locks[0]?.uid : null) ;

  const canClaim = vestingData.available.gt(0);

  const handleClaimClick = async () => {
    setPendingTx(true);
    try {
      const tx =
        await contract.release(vestingData.id);
      const receipt = await tx.wait();
      if (receipt.status) {
        toastSuccess(
          t("Tokens claimed!"),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t("The tokens have been sent to your wallet.")}
          </ToastDescriptionWithTx>
        );
        await fetch()
        vestingData.fetchVestingData()
        setPendingTx(false);
      }
    } catch (err) {
      console.error(err)
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      await fetch()
      vestingData.fetchVestingData()
      setPendingTx(false);
    }
  };

  return (
    <Box mb="24px">
      <Card marginBottom={20}>
        <CardHeader>
          <Heading as="h3" scale="md">
            {t("Vesting Info")}
          </Heading>
        </CardHeader>
        <CardBody>
          <Flex>
            {t(
              "The %a%% of the tokens purchased by the user is blocked, there will be a period of %b% days until the distribution begins. The distribution will be made %c% times, one every %d% days.",
              {
                a: vPercent / 10,
                b: (vCliff / day).toFixed(0),
                c: vPeriods,
                d: (vPeDuration / day).toFixed(0)
              }
            )}
          </Flex>
          {vestingData && vestingData.id && (
            <Flex mt="16px">
              <TableWrapper>
                <ResponsiveGrid>
                  <Text
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("ID")}
                  </Text>
                  <ClickableColumnHeader
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("Start")}
                  </ClickableColumnHeader>
                  <ClickableColumnHeader
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("Cliff")}
                  </ClickableColumnHeader>
                  <ClickableColumnHeader
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("Vested")}
                  </ClickableColumnHeader>
                  <ClickableColumnHeader
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("Released")}
                  </ClickableColumnHeader>
                  <ClickableColumnHeader
                    color="secondary"
                    fontSize="12px"
                    bold
                    textTransform="uppercase"
                  >
                    {t("Available")}
                  </ClickableColumnHeader>
                </ResponsiveGrid>
                <StyledTr>
                  <ResponsiveGrid>
                    <td>
                      <CellInner>
                        {vestingData.id && truncateHash(vestingData.id)} 
                      </CellInner>
                    </td>
                    <td>
                      <CellInner>
                      {formatDistanceToNowStrict(vestingData.start * 1000, {
                      addSuffix: true
                    })} 
                      </CellInner>
                    </td>
                    <td>
                      <CellInner>
                      {formatDistanceToNowStrict(vestingData.cliff * 1000, {
                      addSuffix: true
                    })} 
                      </CellInner>
                    </td>
                    <td>
                      <CellInner>
                        {getBalanceNumber(vestingData.amountTotal, saleERCToken?.decimals).toFixed(2)}
                      </CellInner>
                    </td>
                    <td>
                      <CellInner>
                       {getBalanceNumber(vestingData.released, saleERCToken?.decimals).toFixed(2)} 
                      </CellInner>
                    </td>
                    <td>
                      <CellInner>
                       {getBalanceNumber(vestingData.available, saleERCToken?.decimals).toFixed(2)} 
                      </CellInner>
                    </td>
                    <td>
                      <CellActionInner>
                        <CellLayout>
                          <Button
                            isLoading={pendingTx}
                            disabled={!canClaim}
                            onClick={handleClaimClick}
                            endIcon={
                              pendingTx ? (
                                <AutoRenewIcon spin color="currentColor" />
                              ) : null
                            }
                            marginBottom="20%"
                          >
                            {t("Claim")}
                          </Button>
                        </CellLayout>
                      </CellActionInner>
                    </td>
                  </ResponsiveGrid>
                </StyledTr>
              </TableWrapper>
            </Flex>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default VestingPanel;
