import { ChainId, Token } from '@pancakeswap/sdk'
import { serializePair } from 'state/user/hooks/helpers'
import { SerializedPair } from './types'

const { MAINNET, TESTNET } = ChainId

interface PairList {
  [symbol: string]: Token
}

interface SerializedPairList {
  [symbol: string]: SerializedPair
}

export const mainnetPairs = {  
  maticJamonOld: new Token(
    MAINNET,
    '0x3fd61e13d70594d73a40b57af141db7ed7b5d780',
    18,
    'MATIC-JAMON',
  ),
  usdcJamonOld: new Token(
    MAINNET,
    '0x282a299e14E7458dD06EbFFF7f75027d02899aBa',
    18,
    'USDC-JAMON',
  ),
  link677Matic: new Token(
    MAINNET,
    '0xb9AE91d2Bf26525DeAd47dD7fAb041F8836EbEeE',
    18,
    'LINKv2-MATIC',
  ),
  maticJamon: new Token(
    MAINNET,
    '0xAfde692EFf6cCff458e7F04cC9f6bd3A22ACB5F2',
    18,
    'MATIC-JAMON',
  ),
  usdcJamon: new Token(
    MAINNET,
    '0xc4cc91123dfDbb5275AD5931AF0Be44413f42766',
    18,
    'USDC-JAMON',
  ),
  link677Jamon: new Token(
    MAINNET,
    '0xbc50f3E288aC6eBa868a7f53F784650bAEB01bA5',
    18,
    'LINKv2-JAMON',
  ),
  usdcMatic: new Token(
    MAINNET,
    '0x7954006f16b4E6C7CB4073bc1d382cd3d32CE4c5',
    18,
    'USDC-MATIC',
  ),
  dsgMatic: new Token(
    MAINNET,
    '0x410C91C4a0f30Aa2CD20B796f8fAE8f92095b46c',
    18,
    'DSG-MATIC',
  ),
  jshareMatic: new Token(
    MAINNET,
    '0x9178202c98AFDD021C1e7792FD6A7593d245063D',
    18,
    'JSHARE-MATIC',
  ),
  wbtcJamon: new Token(
    MAINNET,
    '0x56F6F2De33634cB3c77bd3701d60D5a3a5c12d36',
    18,
    'WBTC-JAMON',
  ),
  wethJamon: new Token(
    MAINNET,
    '0x8F35B2089e45DF3bf95726C39A0b2208b5997E5c',
    18,
    'WETH-JAMON',
  ),
  usdtJamon: new Token(
    MAINNET,
    '0xfF809F74F1B4Da103019f2338e1F8a9c0461Ee3C',
    18,
    'USDT-JAMON',
  ),
  jusdMatic: new Token(
    MAINNET,
    '0x92De3094455057428010347B11cA2102C7dFB63E',
    18,
    'JUSD-MATIC',
  ),
}

export const testnetPairs = {
  maticJamonOld: new Token(
    TESTNET,
    '0xd3c7b92c0145b7191d458a0ae6986c072e820407',
    18,
    'MATIC-JAMON',
  ),
  usdcJamonOld: new Token(
    TESTNET,
    '0xd931453d080bAeA042D9b42f4cc03E354b9F7131',
    18,
    'USDC-JAMON',
  ),
  maticJamon: new Token(
    TESTNET,
    '0x3Db864f337AfE5C19255b1c2e066809920078c33',
    18,
    'MATIC-JAMON',
  ),
  usdcJamon: new Token(
    TESTNET,
    '0x301495B8F5E021CC3fa8E058d966448E13Db3bcA',
    18,
    'USDC-JAMON',
  ),
  usdtJamon: new Token(
    TESTNET,
    '0xFB41334F124324BF1FC5c901e49e926b769827C9',
    18,
    'USDT-JAMON',
  ),
}

const pairs = (): PairList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetPairs).reduce((accum, key) => {
      return { ...accum, [key]: testnetPairs[key] || mainnetPairs[key] }
    }, {})
  }

  return mainnetPairs
}

export const serializePairs = (): SerializedPairList => {
  const unserializedPairs = pairs()
  const serializedPairs = Object.keys(unserializedPairs).reduce((accum, key) => {
    return { ...accum, [key]: serializePair(unserializedPairs[key]) }
  }, {})

  return serializedPairs
}

export default pairs()
