import { Token } from '@pancakeswap/sdk'
import { SerializedToken, SerializedPair } from 'config/constants/types'
import { parseUnits } from 'ethers/lib/utils'
import { GSNApiResponse } from '../types'

export const getGSNApi = async (): Promise<GSNApiResponse> => {
  const res = await fetch("https://gasstation-mainnet.matic.network")
  if (res.ok) {
    const json = await res.json()
    return {
      safeLow: json.safeLow,
      standard: json.standard,
      fast: json.fast,
      fastest: json.fastest,
      blockTime: json.blockTime,
      blockNumber: json.blockNumber
    }
  }

  console.error(`API: Can't fetch Gastation API`, res.status)
  return null
}

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
    projectLink: token.projectLink,
  }
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  return new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name,
    serializedToken.projectLink,
  )
}

export function serializePair(pair: Token): SerializedPair {
  return {
    chainId: pair.chainId,
    address: pair.address,
    decimals: pair.decimals,
    symbol: pair.symbol,
  }
}

export function deserializePair(serializedPair: SerializedPair): Token {
  return new Token(
    serializedPair.chainId,
    serializedPair.address,
    serializedPair.decimals,
    serializedPair.symbol,
  )
}

export enum GAS_PRICE {
  default = '40',
  fast = '50',
  instant = '80',
  testnet = '10',
}

export const GAS_PRICE_GWEI = {
  default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
  fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
  instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
  testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
}
