import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import presaleAbi from "config/abi/LaunchPresale.json";
import { multicallv2 } from "utils/multicall";
import { BIG_ZERO } from "utils/bigNumber";
import { WalletPresaleData } from "../types";

const useGetWalletPresaleData = (address: string): WalletPresaleData => {
  const { fastRefresh, slowRefresh } = useRefresh();
  const [state, setState] = useState({
    spended: BIG_ZERO,
    purchased: BIG_ZERO,
    whitelisted: false,
    slotUsed: false
  });

  const { account } = useWeb3React();

  const fetchPresaleData = useCallback(async () => {
    const presaleCalls = [
      {
        address,
        name: "BUYERS",
        params: [account]
      },
      {
        address,
        name: "getUserWhitelistStatus",
        params: [account]
      },
      {
        address,
        name: "userHasUsedSlot",
        params: [account]
      }
    ];

    const [userInfo, whitelisted, slotUsed] = await multicallv2(presaleAbi, presaleCalls);

    setState((prevState) => ({
      ...prevState,
      spended: new BigNumber(userInfo[0].toString()),
      purchased: new BigNumber(userInfo[1].toString()),
      whitelisted: Boolean(whitelisted[0]),
      slotUsed: Boolean(slotUsed[0])
    }));
  }, [account, address]);

  useEffect(() => {
    if (account) {
      fetchPresaleData();
    }
  }, [account, fetchPresaleData, slowRefresh]);

  return { ...state, fetchPresaleData };
};

export default useGetWalletPresaleData;
