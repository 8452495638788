import React from 'react'
import { Text, Flex, Skeleton } from '@pancakeswap/uikit'
import { PublicJShareStakeData, WalletJShareStakeData } from 'views/Pools/types'
import { useWeb3React } from '@web3-react/core'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import Moment from "react-moment";


import { ActionContainer, ActionTitles, ActionContent } from '../styles'

/* import CollectModal from '../../PoolCard/Modals/CollectModal' */

interface HarvestActionProps {
  userDataLoaded: boolean
  publicData: PublicJShareStakeData
  walletData: WalletJShareStakeData
}

function getPenalty(startDate:number) {
  const now = (Math.floor(Date.now() / 1000));
  const accumulated = now - startDate;
  const year = 31556926;
  const month = 2629743;
  let minPercent = 12;
  if (accumulated >= year) {
    return 0;
  }
  if (accumulated < month) {
    return minPercent;
  }
  for (let m = 1; m < 12; m++) {
    if (accumulated >= month * m && accumulated < month * (m + 1)) {
      minPercent -= m;
      return minPercent;
    }
  }
  return 0;
}

const Info: React.FunctionComponent<HarvestActionProps> = ({
  walletData,
  userDataLoaded,
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()


  const totalStaked = walletData.StakedBalance
  const totalStakedBalance = getBalanceNumber(totalStaked);
  const hasStaked = totalStaked.gt(0)

  const actionTitle = (
    <>
      <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
        Stake
      </Text>
      <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
        {t(' Info')}
      </Text>
    </>
  )

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Text textAlign="center" color='textDisabled' bold fontSize='1.2rem'>0</Text>
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>{actionTitle}</ActionTitles>
      <ActionContent>
        <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
          <>
            {hasStaked ? (
              <>
              <Flex justifyContent="space-between" mb="0.5rem">
                <Text fontSize='0.875rem'>{t("Started")}: </Text>
                <Text color='primary' fontSize='0.875rem'><Moment fromNow ago>{new Date(walletData.StartTime * 1000)}</Moment></Text>
              </Flex>
              {/* <Flex justifyContent="space-between"  mb="0.5rem">
                <Text fontSize='0.875rem'>{t("Penalty")}:</Text>
                <Text fontSize='0.875rem' color='primary'> {getPenalty(walletData.StartTime)}%</Text>
              </Flex> */}
              <Flex justifyContent="space-between">
                <Text fontSize='0.875rem'>{t("Staked")}:</Text>
                <Balance color='primary' bold fontSize="0.875rem" decimals={2} unit=' J-SHARE' value={totalStakedBalance} />
              </Flex>
              </>
            ) : ( 
              <>
                <Text color="textDisabled" fontSize='1.2rem' bold>0</Text>
              </>
             )} 
          </>
        </Flex>
        {/* <Button disabled>
          {t('Harvest All')}
        </Button> */}
      </ActionContent>
    </ActionContainer>
  )
}

export default Info
