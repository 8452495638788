/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react";
import { request, gql } from "graphql-request";
import { INFO_CLIENT } from "config/constants/endpoints";
import { getDeltaTimestamps } from "views/Info/utils/infoQueryHelpers";
import { useBlocksFromTimestamps } from "views/Info/hooks/useBlocksFromTimestamps";
import { PoolData, SinglePoolData } from "state/info/types";
import {
  getChangeForPeriod,
  getLpFeesAndApr,
  getPercentChange
} from "views/Info/utils/infoDataHelpers";

interface PoolFields {
  id: string;
  reserve0: string;
  reserve1: string;
  reserveUSD: string;
  volumeUSD: string;
  token0Price: string;
  token1Price: string;
  token0: {
    id: string;
    symbol: string;
    name: string;
  };
  token1: {
    id: string;
    symbol: string;
    name: string;
  };
  totalSupply: string;
}

interface FormattedPoolFields
  extends Omit<
    PoolFields,
    | "volumeUSD"
    | "reserveUSD"
    | "reserve0"
    | "reserve1"
    | "token0Price"
    | "token1Price"
  > {
  volumeUSD: number;
  reserveUSD: number;
  reserve0: number;
  reserve1: number;
  token0Price: number;
  token1Price: number;
}

interface PoolsQueryResponse {
  now: PoolFields[];
  oneDayAgo: PoolFields[];
  twoDaysAgo: PoolFields[];
  oneWeekAgo: PoolFields[];
  twoWeeksAgo: PoolFields[];
}

interface PoolQueryResponse {
  current: PoolFields;
}

/**
 * Data for displaying pool tables (on multiple pages, used throughout the site)
 * Note: Don't try to refactor it to use variables, server throws error if blocks passed as undefined variable
 * only works if its hard-coded into query string
 */
const POOL_AT_BLOCK = (block: number | null, pools: string[]) => {
  const blockString = block ? `block: {number: ${block}}` : ``;
  const addressesString = `["${pools.join('","')}"]`;
  return `pairs(
    where: { id_in: ${addressesString} }
    ${blockString}
    orderBy: trackedReserveBNB
    orderDirection: desc
  ) {
    id
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    token0Price
    token1Price
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
    totalSupply
  }`;
};

const POOL = (pool: string) => {
  return `pairs(
    where: { id: "${pool}" }
  ) {
    id
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    token0Price
    token1Price
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
    totalSupply
  }`;
};

const fetchPoolData = async (
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  poolAddresses: string[]
) => {
  try {
    const query = gql`
      query pools {
        now: ${POOL_AT_BLOCK(null, poolAddresses)}
        oneDayAgo: ${POOL_AT_BLOCK(block24h, poolAddresses)}
        twoDaysAgo: ${POOL_AT_BLOCK(block48h, poolAddresses)}
        oneWeekAgo: ${POOL_AT_BLOCK(block7d, poolAddresses)}
        twoWeeksAgo: ${POOL_AT_BLOCK(block14d, poolAddresses)}
      }
    `;
    const data = await request<PoolsQueryResponse>(INFO_CLIENT, query);
    return { data, error: false };
  } catch (error) {
    console.error("Failed to fetch pool data", error);
    return { error: true };
  }
};

const fetchSinglePoolData = async (poolAddresses: string) => {
  try {
    const query = gql`
      query pools {
        current: ${POOL(poolAddresses)}
      }
    `;
    const data = await request<PoolQueryResponse>(INFO_CLIENT, query);
    return { data, error: false };
  } catch (error) {
    console.error("Failed to fetch pool data", error);
    return { error: true };
  }
};

// Transforms pools into "0xADDRESS: { ...PoolFields }" format and cast strings to numbers
const parsePoolData = (pairs?: PoolFields[]) => {
  if (!pairs) {
    return {};
  }
  return pairs.reduce(
    (accum: { [address: string]: FormattedPoolFields }, poolData) => {
      const {
        volumeUSD,
        reserveUSD,
        reserve0,
        reserve1,
        token0Price,
        token1Price
      } = poolData;
      accum[poolData.id] = {
        ...poolData,
        volumeUSD: parseFloat(volumeUSD),
        reserveUSD: parseFloat(reserveUSD),
        reserve0: parseFloat(reserve0),
        reserve1: parseFloat(reserve1),
        token0Price: parseFloat(token0Price),
        token1Price: parseFloat(token1Price)
      };
      return accum;
    },
    {}
  );
};

interface PoolDatas {
  error: boolean;
  data?: {
    [address: string]: PoolData;
  };
}

interface SinglePoolDatas {
  error: boolean;
  data?: SinglePoolData;
}

/**
 * Fetch top pools by liquidity
 */
const usePoolDatas = (poolAddresses: string[]): PoolDatas => {
  const [fetchState, setFetchState] = useState<PoolDatas>({ error: false });
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps();
  const { blocks, error: blockError } = useBlocksFromTimestamps([
    t24h,
    t48h,
    t7d,
    t14d
  ]);
  const [block24h, block48h, block7d, block14d] = blocks ?? [];

  useEffect(() => {
    const fetch = async () => {
      const { error, data } = await fetchPoolData(
        block24h.number,
        block48h.number,
        block7d.number,
        block14d.number,
        poolAddresses
      );
      if (error) {
        setFetchState({ error: true });
      } else {
        const formattedPoolData = parsePoolData(data?.now);
        const formattedPoolData24h = parsePoolData(data?.oneDayAgo);
        const formattedPoolData48h = parsePoolData(data?.twoDaysAgo);
        const formattedPoolData7d = parsePoolData(data?.oneWeekAgo);
        const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo);

        // Calculate data and format
        const formatted = poolAddresses.reduce(
          (accum: { [address: string]: PoolData }, address) => {
            // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
            const current: FormattedPoolFields | undefined =
              formattedPoolData[address];
            const oneDay: FormattedPoolFields | undefined =
              formattedPoolData24h[address];
            const twoDays: FormattedPoolFields | undefined =
              formattedPoolData48h[address];
            const week: FormattedPoolFields | undefined =
              formattedPoolData7d[address];
            const twoWeeks: FormattedPoolFields | undefined =
              formattedPoolData14d[address];

            const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
              current?.volumeUSD,
              oneDay?.volumeUSD,
              twoDays?.volumeUSD
            );
            const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
              current?.volumeUSD,
              week?.volumeUSD,
              twoWeeks?.volumeUSD
            );

            const liquidityUSD = current ? current.reserveUSD : 0;
            const totalSupply = current ? current.totalSupply : null;

            const liquidityUSDChange = getPercentChange(
              current?.reserveUSD,
              oneDay?.reserveUSD
            );

            const liquidityToken0 = current ? current.reserve0 : 0;
            const liquidityToken1 = current ? current.reserve1 : 0;

            const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } =
              getLpFeesAndApr(volumeUSD, volumeUSDWeek, liquidityUSD);

            if (current) {
              accum[address] = {
                address,
                token0: {
                  address: current.token0.id,
                  name: current.token0.name,
                  symbol: current.token0.symbol
                },
                token1: {
                  address: current.token1.id,
                  name: current.token1.name,
                  symbol: current.token1.symbol
                },
                token0Price: current.token0Price,
                token1Price: current.token1Price,
                volumeUSD,
                volumeUSDChange,
                volumeUSDWeek,
                volumeUSDChangeWeek,
                totalFees24h,
                totalFees7d,
                lpFees24h,
                lpFees7d,
                lpApr7d,
                liquidityUSD,
                liquidityUSDChange,
                liquidityToken0,
                liquidityToken1,
                totalSupply
              };
            }

            return accum;
          },
          {}
        );
        setFetchState({ data: formatted, error: false });
      }
    };

    const allBlocksAvailable =
      block24h?.number &&
      block48h?.number &&
      block7d?.number &&
      block14d?.number;
    if (poolAddresses.length > 0 && allBlocksAvailable && !blockError) {
      fetch();
    }
  }, [poolAddresses, block24h, block48h, block7d, block14d, blockError]);

  return fetchState;
};

export function useSinglePoolData(poolAddresses: string): SinglePoolDatas {
  const [fetchState, setFetchState] = useState<SinglePoolDatas>({
    error: false
  });

  useEffect(() => {
    const fetch = async () => {
      const { error, data } = await fetchSinglePoolData(poolAddresses);
      if (error) {
        setFetchState({ error: true });
      } else {
        const current = data?.current[0]

        const formatted: SinglePoolData = {
          address: poolAddresses,
          liquidityUSD: current?.reserveUSD,
          totalSupply: current?.totalSupply
        };

        setFetchState({ data: formatted, error: false });
      }
    };

    if (poolAddresses !== null) {
      fetch();
    }
  }, [poolAddresses]);

  return fetchState;
}

export default usePoolDatas;
