import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Image,
  Button,
  BalanceInput,
  Flex,
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { getBalanceAmount, getFullDisplayBalance } from "utils/formatBalance";
import { getAddress } from "utils/addressHelpers";
import ApproveConfirmButtons, {
  ButtonArrangement,
} from "components/ApproveConfirmButtons";
import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { DEFAULT_TOKEN_DECIMAL } from "config";
import { useERC20, usePresaleContract } from "hooks/useContract";
import useTokenBalance, { useGetBnbBalance } from "hooks/useTokenBalance";
import { useToken } from "hooks/Tokens";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";
import { PublicPresaleData, WalletPresaleData } from "../../types";

interface Props {
  presaleAddress: string;
  presaleData: PublicPresaleData;
  walletData: WalletPresaleData;
  onSuccess: (amount: string, symbol: string) => void;
  onDismiss?: () => void;
}

const multiplierValues = [0.1, 0.25, 0.5, 0.75, 1];

const ClaimModal: React.FC<Props> = ({
  presaleAddress,
  presaleData,
  walletData,
  onDismiss,
  onSuccess,
}) => {
  const { baseToken, saleToken, status } = presaleData;
  const [value, setValue] = useState("");
  const { account } = useWeb3React();
  const raisingTokenContract = useERC20(baseToken);
  const raisingToken = useToken(baseToken);
  const buyToken = useToken(saleToken);
  const contract = usePresaleContract(presaleAddress);
  const { t } = useTranslation();

  const useMatic = presaleData.inMatic;

  const isSuccess = status === "Completed";
  const raisingSymbol =
    raisingToken.symbol === "WMATIC" ? "MATIC" : raisingToken.symbol;

  const claimSymbol = isSuccess ? buyToken.symbol : raisingSymbol;
  const claimDecimals = isSuccess ? buyToken.decimals : raisingToken.decimals;

  const tokensToClaim = isSuccess ? walletData.purchased : walletData.spended;

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm,
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      return true;
    },
    onApprove: () => {
      return null;
    },
    onConfirm: () => {
      if (isSuccess) {
        return contract.userWithdrawTokens();
      }
      return contract.userWithdrawBaseTokens();
    },
    onSuccess: async () => {
      const displayAmount = getFullDisplayBalance(tokensToClaim, claimDecimals);
      onSuccess(displayAmount, claimSymbol);
      onDismiss();
    },
  });

  return (
    <Modal
      title={t("Claim %symbol%", { symbol: claimSymbol })}
      onDismiss={onDismiss}
    >
      <ModalBody maxWidth="320px">
        <Text color="textSubtle" textAlign="center" fontSize="16px" mb="16px">
          {t("You have %balance% %sym% to claim", {
            balance: getBalanceAmount(
                tokensToClaim,
                claimDecimals
            ).toString(), sym: claimSymbol
          })}
        </Text>
        <ApproveConfirmButtons
          isApproveDisabled
          buttonArrangement={ButtonArrangement.SEQUENTIAL}
          isApproving={isApproving}
          isConfirmDisabled={isConfirmed}
          isConfirming={isConfirming}
          onApprove={handleApprove}
          onConfirm={handleConfirm}
        />
      </ModalBody>
    </Modal>
  );
};

export default ClaimModal;
