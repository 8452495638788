import React from 'react'
import styled from 'styled-components'
import useLastTruthy from 'hooks/useLast'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean, isMobile?: boolean, isTablet?: boolean }>`
  margin-top: ${({ show }) => (show ? '26px' : 0)};
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  max-width: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "25rem" : "35rem")};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.invertedContrast};

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)
  const { isMobile, isTablet } = useMatchBreakpoints();

  return (
    <AdvancedDetailsFooter show={Boolean(trade)} isMobile={isMobile} isTablet={isTablet}>
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
