import React, { useState, ChangeEvent } from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { useTranslation } from "contexts/Localization";
import {
  Flex,
  Box,
  Heading,
  Card,
  CardBody,
  CardHeader,
  Button,
  AutoRenewIcon,
  Text,
  IconButton,
  MinusIcon,
  AddIcon,
  Input,
  BalanceInput
} from "@pancakeswap/uikit";
import useToast from "hooks/useToast";
import { ToastDescriptionWithTx } from "components/Toast";
import { useLaunchLockerContract } from "hooks/useContract";
import { useToken } from "hooks/Tokens";
import { getFullDisplayBalance } from "utils/formatBalance";
import useGetWalletLocksData from "views/Launchpad/hooks/useGetWalletLocksData";


const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 13%);
  padding: 0 24px;
  @media screen and (max-width: 1799px) {
    grid-template-columns: 15% 15% 15% 15% 15% 10%;
    & > *:nth-child(1) {
      display: none;
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 18% 18% 18% 18% 18% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 20% 20% 20% 20%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 50% 10%;
    & > *:nth-child(1) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
`;

const CellInner = styled.div`
  padding: 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`;

const CellActionInner = styled.div`
  padding: 24px 0px 0px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 18px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`;

const StyledTr = styled.tr`
  cursor: pointer;
  border-bottom: 2px solid ${({ theme }) => theme.colors.cardBorder};
  justify-content: center;
`;

const ContentContainer = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
`;
const Label = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textSubtle};
  text-align: left;
`;

interface CellLayoutProps {
  label?: string;
}

const CellLayout: React.FC<CellLayoutProps> = ({ label = "", children }) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <ContentContainer>{children}</ContentContainer>
    </div>
  );
};

interface LockRowProps {
  uid: string;
  amount: string;
  token: string;
  unlockDate: string;
  fetch: () => void;
}

const LockRow: React.FC<LockRowProps> = ({
  uid,
  amount,
  token,
  unlockDate,
  fetch
}) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = useToast();
  const [pendingTx, setPendingTx] = useState(false);
  const contract = useLaunchLockerContract();

  const tokenERC20 = useToken(token);
 /*  const lockData = useGetWalletLocksData(uid); */

  const bigAmount = new BigNumber(amount);

  const displayAmount = getFullDisplayBalance(bigAmount, 18, 6)
  const symbol = tokenERC20?.symbol === "Jamon-LP" ? "Liquidity" : tokenERC20?.symbol;
  const unlockDatetime = new Date(Number(unlockDate) * 1000);

  const isAvailable = unlockDatetime < new Date();

  const handleClaimLock = async () => {
    setPendingTx(true);
    try {
      const tx =
        await contract.withdraw(uid);
      const receipt = await tx.wait();
      if (receipt.status) {
        toastSuccess(
          t("Tokens claimed!"),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t("The tokens have been sent to your wallet.")}
          </ToastDescriptionWithTx>
        );
        fetch()
        setPendingTx(false);
      }
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      fetch()
      setPendingTx(false);
    }
  };

  return (
    <StyledTr>
      <ResponsiveGrid>
        <td>
          <CellInner>
            {symbol} 
          </CellInner>
        </td>
        <td>
          <CellInner>
            {displayAmount}
          </CellInner>
        </td>
        <td>
          <CellInner>
            {unlockDatetime.toLocaleString()}
          </CellInner>
        </td>
        <td>
          <CellActionInner>
            <CellLayout>
              <Button
                scale="sm"
                isLoading={pendingTx}
                disabled={!isAvailable}
                onClick={handleClaimLock}
                endIcon={
                  pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null
                }
                marginBottom="20%"
              >
                {t("Unlock")}
              </Button>
            </CellLayout>
          </CellActionInner>
        </td>
      </ResponsiveGrid>
    </StyledTr>
  );
};

export default LockRow;
