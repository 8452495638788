import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  CurrencyAmount,
  JSBI,
  Token,
  Trade
} from "@pancakeswap/sdk";
import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  Card,
  Skeleton,
  IconButton,
  BottomDrawer,
  useMatchBreakpoints,
  Heading
} from "@pancakeswap/uikit";
import ChartCard from 'views/Info/components/InfoCharts/ChartCard'
import { usePoolDatas, usePoolChartData, usePoolTransactions } from 'state/info/hooks'
import { Colors, Fonts, Buttons } from "components/Style";
import { PageHeader, StyledPageLimitOrders } from "views/Style";
import { basisPointsToPercent } from "utils";
import { useIsTransactionUnsupported } from "hooks/Trades";
import { useMoralisQuery } from "react-moralis";
import UnsupportedCurrencyFooter from "components/UnsupportedCurrencyFooter";
import Footer from "components/Menu/Footer";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "contexts/Localization";
import BigNumber from "bignumber.js";
import SwapWarningTokens from "config/constants/swapWarningTokens";
/* import PageHeader from "components/PageHeader"; */
import Header from "./components/Header";
import { GreyCard } from "../../components/Card";
import Column, { AutoColumn } from "../../components/Layout/Column";
import ConfirmOrderModal from "./components/ConfirmOrderModal";
import CurrencyInputPanel, {
  CurrencyOutputPanel,
  TargePriceInput,
  CreditsInput
} from "../../components/CurrencyInputPanel";
import { AutoRow, RowBetween } from "../../components/Layout/Row";
import AdvancedSwapDetailsDropdown from "./components/AdvancedSwapDetailsDropdown";
import confirmPriceImpactWithoutFee from "./components/confirmPriceImpactWithoutFee";
import { SwapCallbackError, Wrapper } from "./components/styleds";
import  { OrderPrice } from "./components/TradePrice";
import ImportTokenWarningModal from "./components/ImportTokenWarningModal";
import ProgressSteps from "./components/ProgressSteps";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import useGetPublicLimitOrdersData from "./hooks/useGetPublicLimitOrdersData";
import useGetWalletLimitOrderData from "./hooks/useGetWalletLimitOrderData";

import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useCurrency, useAllTokens } from "../../hooks/Tokens";
import {
  ApprovalState,
  useApproveCallbackFromOrder
} from "../../hooks/useApproveCallback";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { Field } from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  useSingleTokenSwapInfo
} from "../../state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager
} from "../../state/user/hooks";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity
} from "../../utils/prices";
import CircleLoader from "../../components/Loader/CircleLoader";
import OrdersTable from "./components/OrdersTable";
import SwapWarningModal from "./components/SwapWarningModal";
import PriceChartContainer from "./components/Chart/PriceChartContainer";
import CurrencyInputHeader from "./components/CurrencyInputHeader";


const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`;

const ChartCardsContainer = styled(Flex)<{ isMobile?: boolean, isTablet?: boolean }>`
  justify-content: center;
  flex-direction: column;
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "100%" : "70%")};
  padding: 0;
  gap: 1em;

  
`
const CardsContainer = styled(Flex)<{ isMobile?: boolean, isTablet?: boolean }>`
  justify-content: center;
  flex-direction: column;
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "100%" : "30%")};
  padding: 0;
  gap: 1em;
  margin-right: 1rem;
  margin-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ?  "2rem" : "")};
`
const TitleBackground = styled(Box)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`


export default function LimitOrder({ history }: RouteComponentProps) {
  const loadedUrlParams = useDefaultsFromURLSearch();
  const { t } = useTranslation();
  const { isMobile, isDesktop, isTablet } = useMatchBreakpoints();
  const [isChartExpanded, setIsChartExpanded] = useState(false);
  const [userChartPreference, setUserChartPreference] =
    useExchangeChartManager(isMobile);
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference);

  useEffect(() => {
    setUserChartPreference(isChartDisplayed);
  }, [isChartDisplayed, setUserChartPreference]);

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ];
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens();
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens);
    });

  const { account } = useActiveWeb3React();

  // for expert mode
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo();

  // Price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId }
  } = useSwapState();

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const singleTokenPrice = useSingleTokenSwapInfo();

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT ?  parsedAmount : trade?.outputAmount
      };

  const { onSwitchTokens, onCurrencySelection, onUserInput } =
    useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState
  ] = useState<{
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ""
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromOrder(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const [singleHopOnly] = useUserSingleHopOnly();

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      swapErrorMessage: undefined,
      txHash: undefined
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: undefined,
          txHash: hash
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        });
      });
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn
    });
  }, [attemptingTxn, swapErrorMessage, trade, txHash]);

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null);
  const [onPresentSwapWarningModal] = useModal(
    <SwapWarningModal swapCurrency={swapWarningCurrency} />
  );

  const shouldShowSwapWarning = (swapCurrency) => {
    const isWarningToken = Object.entries(SwapWarningTokens).find(
      (warningTokenConfig) => {
        const warningTokenData = warningTokenConfig[1];
        return swapCurrency.address === warningTokenData.address;
      }
    );
    return Boolean(isWarningToken);
  };

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency]);

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      const showSwapWarning = shouldShowSwapWarning(inputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(inputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      const showSwapWarning = shouldShowSwapWarning(outputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(outputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },

    [onCurrencySelection]
  );

  const swapIsUnsupported = useIsTransactionUnsupported(
    currencies?.INPUT,
    currencies?.OUTPUT
  );

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal
      tokens={importTokensNotInDefault}
      onCancel={() => history.push("/swap/")}
    />
  );

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length]);

  // Limit Orders
  const [targetPrice, setTargetPrice] = useState("0");
  const [inputamount, setinputamount] = useState("0");
  const [outputamount, setoutputamount] = useState("0");
  const [credits, setCredits] = useState(1);
  const [orders, setOrders] = useState("");

  const publicData = useGetPublicLimitOrdersData();
  const walletData = useGetWalletLimitOrderData();

  const { fetch, data, error, isLoading } = useMoralisQuery(
    "LimitOrderCreated",
    (query) => query.fullText("user", account != null ? account : "0xDeaD"),
    [account]
  );

  useEffect(() => {
    if (data) {
      let orderData = JSON.stringify(data, null, 2);
      orderData = JSON.parse(orderData);
      setOrders(orderData);
    }
  }, [data]);

  useEffect(() => {
    if (trade) {
      if (
        trade?.inputAmount.toSignificant() !== inputamount ||
        trade?.outputAmount.toSignificant() !== outputamount
      ) {
        const pct = basisPointsToPercent(900000)
        let minPrice = new BigNumber(trade?.outputAmount.toSignificant());
        const oneplus = new BigNumber(
          trade?.minimumAmountOut(pct).toSignificant()
        )
        minPrice = minPrice.plus(oneplus)
        setTargetPrice(minPrice.toString());
        setinputamount(trade?.inputAmount.toSignificant());
        setoutputamount(trade?.outputAmount.toSignificant());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trade, trade?.inputAmount, trade?.outputAmount]);

  const handleTypeTargetPrice = useCallback(
    (value: string) => {
      setTargetPrice(value);
    },
    [setTargetPrice]
  );

  const handleCredits = useCallback(
    (value: number) => {
      setCredits(value);
    },
    [setCredits]
  );

  const tPrice: number = +targetPrice;
  const cPrice = new BigNumber(trade?.outputAmount.toSignificant());

  const lowPrice = cPrice.gte(tPrice);
  const lowCredit = walletData.credits < credits || credits === 0;

  const [onPresentConfirmModal] = useModal(
    <ConfirmOrderModal
      trade={trade}
      targetPrice={Number(targetPrice)}
      credits={credits}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
      fetchOrder={fetch}
    />,
    true,
    true,
    "confirmSwapModal"
  );

  /* const address = routeAddress.toLowerCase()
  const chartData = usePoolChartData(address) */

  // Section Create
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openCreateActive, setOpenCreateActive] = React.useState(true);
  const handleCloseCreate = () => { setOpenCreate(false);};
  const handleOpenCreate = () => { setOpenCreate(true);};
  const handleCloseCreateActive = () => { setOpenCreateActive(false);};
  const handleOpenCreateActive = () => { setOpenCreateActive(true);};

  // Section Orders
  const [openOrders, setOpenOrders] = React.useState(false);
  const [openOrdersActive, setOpenOrdersActive] = React.useState(false);
  const handleCloseOrders = () => { setOpenOrders(false);};
  const handleOpenOrders = () => { setOpenOrders(true);};
  const handleCloseOrdersActive = () => { setOpenOrdersActive(false);};
  const handleOpenOrdersActive = () => { setOpenOrdersActive(true);};

  return (
    <>
      
      <StyledPageLimitOrders isMobile={isMobile} isTablet={isTablet}>
        <Header />
        <Flex flexDirection="row" justifyContent={isMobile || isTablet ? "space-around": "center"} mb="2rem">
            <Button
              variant={openCreateActive ? "primary" : "tertiary"}
              scale="sm"
              mr={isMobile || isTablet ? "" : "2rem"}
              onClick={() => {handleOpenCreate();handleCloseOrders();handleOpenCreateActive();handleCloseOrdersActive();}}
            >
              {t("Create Order")}
            </Button>
            {orders.length>0 ? 
              <Button
                variant={openOrdersActive ? "primary" : "tertiary"}
                scale="sm"
                onClick={() => {handleOpenOrders();handleCloseCreate();handleOpenOrdersActive();handleCloseCreateActive();}}
              >
                {t("My Orders")}
              </Button>
              :
              ""
            }
        </Flex>
        {openOrders && openCreate===false ? 
          <Flex justifyContent="center">
            {orders.length > 0 ? (
              <Flex width={isDesktop ? '120%' : '100%'} justify-content="center" mb={5}>
                <OrdersTable orders={orders} fecthOrder={fetch} />
              </Flex>
            ) : (
              ""
            )}
          </Flex>
          :
          <>
          <Flex flexDirection={isMobile || isTablet ? "column" : "row"} justifyContent={isMobile || isTablet ? "center": isChartDisplayed ? "space-between" : "center"}>
              <CardsContainer isMobile={isMobile} isTablet={isTablet} >
                <Card>
                <CurrencyInputHeader
                    title={t("New Order")}
                    setIsChartDisplayed={setIsChartDisplayed}
                    isChartDisplayed={isChartDisplayed}
                  />
                <Wrapper id="swap-page">
                    <AutoColumn gap="md">
                      <CurrencyInputPanel
                        label={
                          independentField === Field.OUTPUT &&
                          !showWrap &&
                          trade
                            ? t("From (estimated)")
                            : t("From")
                        }
                        value={formattedAmounts[Field.INPUT]}
                        showMaxButton={!atMaxAmountInput}
                        currency={currencies[Field.INPUT]}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        id="swap-currency-input"
                      />
                      <AutoColumn justify="space-between">
                        <AutoRow justify="center" style={{ padding: "0 1rem" }}>
                          <IconButton variant="subtle" scale="sm">
                            <ArrowDownIcon
                              width="16px"

                              onClick={() => {
                                setApprovalSubmitted(false); // reset 2 step UI for approvals
                                /*  onSwitchTokens(); */
                              }}
                              color={
                                currencies[Field.INPUT] &&
                                currencies[Field.OUTPUT]
                                  ? "primary"
                                  : "text"
                              }
                            />
                          </IconButton>
                        </AutoRow>
                      </AutoColumn>
                      <CurrencyOutputPanel
                        value={formattedAmounts[Field.OUTPUT]}
                        onUserInput={handleTypeOutput}
                        label={
                          independentField === Field.INPUT && !showWrap && trade
                            ? t("To (estimated)")
                            : t("To")
                        }
                        showMaxButton={false}
                        currency={currencies[Field.OUTPUT]}
                        onCurrencySelect={handleOutputSelect}
                        otherCurrency={currencies[Field.INPUT]}
                        id="swap-currency-output"
                      />
                      

                      <TargePriceInput
                        value={targetPrice}
                        onUserInput={handleTypeTargetPrice}
                        id="target-price"
                      />
                      {Boolean(trade) && (
                        <RowBetween align="center">
                          <Label>{t("Current price")}</Label>
                          <OrderPrice
                            price={trade?.nextMidPrice}
                            targetPrice={trade?.outputAmount}
                            showInverted={!showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                    </AutoColumn>
                    
                    <CreditsInput
                      value={credits}
                      onUserInput={handleCredits}
                      id="credits"
                    />

                    {!swapIsUnsupported ? (
                       trade && <AdvancedSwapDetailsDropdown trade={trade} targetPrice={targetPrice} />
                    ) : (
                      <UnsupportedCurrencyFooter
                        currencies={[currencies.INPUT, currencies.OUTPUT]}
                      />
                    )}

                    <Box mt="1rem">
                      {swapIsUnsupported ? (
                        <Button width="100%" disabled mb="4px">
                          {t("Unsupported Asset")}
                        </Button>
                      ) : !account ? (
                        <ConnectWalletButton width="100%" />
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                        <GreyCard style={{ textAlign: "center" }}>
                          <Text color="textSubtle" mb="4px">
                            {t("Insufficient liquidity for this trade.")}
                          </Text>
                          {singleHopOnly && (
                            <Text color="textSubtle" mb="4px">
                              {t("Try enabling multi-hop trades.")}
                            </Text>
                          )}
                        </GreyCard>
                      ) : showApproveFlow ? (
                        <RowBetween>
                          <Button
                            variant={
                              approval === ApprovalState.APPROVED
                                ? "success"
                                : "primary"
                            }
                            onClick={approveCallback}
                            disabled={
                              approval !== ApprovalState.NOT_APPROVED ||
                              approvalSubmitted
                            }
                            width="48%"
                          >
                            {approval === ApprovalState.PENDING ? (
                              <AutoRow gap="6px" justify="center">
                                {t("Enabling")} <CircleLoader stroke="white" />
                              </AutoRow>
                            ) : approvalSubmitted &&
                              approval === ApprovalState.APPROVED ? (
                              t("Enabled")
                            ) : (
                              t("Enable %asset%", {
                                asset: currencies[Field.INPUT]?.symbol ?? ""
                              })
                            )}
                          </Button>
                          <Button
                            variant={
                              isValid && priceImpactSeverity > 2
                                ? "danger"
                                : "primary"
                            }
                            onClick={() => {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                txHash: undefined
                              });
                              onPresentConfirmModal();
                            }}
                            width="48%"
                            id="swap-button"
                            disabled={
                              !isValid ||
                              lowCredit ||
                              lowPrice ||
                              approval !== ApprovalState.APPROVED ||
                              (priceImpactSeverity > 3 && !isExpertMode)
                            }
                          >
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? t("Price Impact High")
                              : lowPrice
                              ? t("Target Price Too Low")
                              : lowCredit
                              ? t("Credits Too Low")
                              : priceImpactSeverity > 2
                              ? t("Order Anyway")
                              : t("Create order")}
                          </Button>
                        </RowBetween>
                      ) : (
                        <Button
                          variant={
                            isValid &&
                            priceImpactSeverity > 2 &&
                            !swapCallbackError
                              ? "danger"
                              : "primary"
                          }
                          onClick={() => {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              txHash: undefined
                            });
                            onPresentConfirmModal();
                          }}
                          id="swap-button"
                          width="100%"
                          disabled={
                            !isValid ||
                            lowPrice ||
                            lowCredit ||
                            (priceImpactSeverity > 3 && !isExpertMode) ||
                            !!swapCallbackError
                          }
                        >
                          {swapInputError ||
                            (priceImpactSeverity > 3 && !isExpertMode
                              ? t("Price Impact Too High")
                              : lowPrice
                              ? t("Target Price Too Low")
                              : lowCredit
                              ? t("Credits Too Low")
                              : priceImpactSeverity > 2
                              ? t("Order Anyway")
                              : t("Create order"))}
                        </Button>
                      )}
                      {showApproveFlow && (
                        <Column style={{ marginTop: "1rem" }}>
                          <ProgressSteps
                            steps={[approval === ApprovalState.APPROVED]}
                          />
                        </Column>
                      )}
                      {isExpertMode && swapErrorMessage ? (
                        <SwapCallbackError error={swapErrorMessage} />
                      ) : null}
                    </Box>
                  </Wrapper>
                </Card>
            </CardsContainer>
            {isChartDisplayed &&
            <ChartCardsContainer isMobile={isMobile} isTablet={isTablet}>
                <Card>
                  <TitleBackground p={['16px', '16px', '24px']} >
                    <Heading>
                      {t('Price Chart')}
                    </Heading>
                  </TitleBackground>
                    <PriceChartContainer
                      inputCurrencyId={inputCurrencyId}
                      inputCurrency={currencies[Field.INPUT]}
                      outputCurrencyId={outputCurrencyId}
                      outputCurrency={currencies[Field.OUTPUT]}
                      isChartExpanded={isChartExpanded}
                      setIsChartExpanded={setIsChartExpanded}
                      isChartDisplayed={isChartDisplayed}
                      currentSwapPrice={singleTokenPrice}
                    />
                </Card>
            </ChartCardsContainer>
            }
          </Flex>
        </>
        }
      </StyledPageLimitOrders>
    </>
  );
}
