import { serializeTokens } from './tokens'
import { Ilo } from './types'

const serializedTokens = serializeTokens()

const ilos: Ilo[] = [
  {
    id: 0,
    address: '0x0A48C867f795a6F65ae30d34D55bEA0B428F92ba',
    tokenAddress: '0xfa3c05c2023918a4324fde7163591fe6bebd1692',
    isActive: true,
    inMatic: true,
    name: 'Cresio',
    symbol: 'XCRE',
    currency: serializedTokens.maticf,
    token: serializedTokens.xcre,   
    price: '40 XCRE',
    price2: '20 XCRE',
    plist: '16.5 XCRE',
    info: {
      ofSupply: 20,
      saleAmount: '5,000,000 XCRE',
      lock: 100,
      lockTime: '2 Years',
      tokenomics: 5,
      twitter: 'CRESIOcoin',
      tg: 'Cresio',
      web: 'https://cresio.es',
    },
  },
 /*  {
    id: 2,
    address: '0x3bb239bB5d1b2D2907778d35027c3C55fd2FD72A',
    isActive: false,
    name: 'Cresio',
    symbol: 'XCRE',
    currency: tokens.busd,
    token: tokens.dsg,
    startAt: 1638888195,
    endAt: 1638974595,    
    price: '1,000,000 XCRE',
    plist: '100,000 XCRE',
    info: {
      ofSupply: 20,
      saleAmount: '20,000,000 XCRE',
      lock: 100,
      lockTime: '5 Years',
      tokenomics: 5,
      twitter: 'CRESIOcoin',
      tg: 'Cresio',
      web: 'https://cresio.es',
    },
  }   */
]

export default ilos