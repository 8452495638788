import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  Modal,
  ModalBody,
  useMatchBreakpoints,
  ArrowForwardIcon,
  ArrowBackIcon,
  Text,
  Button,
  Flex
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { serializeTokens } from "config/constants/tokens";
import poolRewards from "config/constants/poolRewards";
import { SerializedPoolRewadsConfig } from "config/constants/types";
import { WalletJShareStakeData } from "views/Pools/types";
import useGetWalletJShareRewards from "views/Pools/V2/hooks/useGetWalletJShareRewards";
import TokenRow from "../../StakeTable/JShare/TokenRow";
import PairRow from "../../StakeTable/JShare/PairRow";


interface Props {
  account: string;
  walletData: WalletJShareStakeData;
  userDataLoaded: boolean;
  onDismiss?: () => void;
}

const StyledRewards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  margin-bottom: 1.2em;
`;

const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`;

const ITEMS_PER_INFO_TABLE_PAGE = 5;


const serializedTokens = serializeTokens();

const RewardsModalJShare: React.FC<Props> = ({
  account,
  walletData,
  userDataLoaded,
  onDismiss
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const lprewards: SerializedPoolRewadsConfig[] = poolRewards;

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const sortedTransactions = useMemo(() => {
    return lprewards.length > 0
      ? lprewards.slice(
          ITEMS_PER_INFO_TABLE_PAGE * (page - 1),
          page * ITEMS_PER_INFO_TABLE_PAGE
        )
      : [];
  }, [lprewards, page]);

  useEffect(() => {
    if (lprewards) {
      if (lprewards.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
        setMaxPage(Math.floor(lprewards.length / ITEMS_PER_INFO_TABLE_PAGE));
      } else {
        setMaxPage(
          Math.floor(lprewards.length / ITEMS_PER_INFO_TABLE_PAGE) + 1
        );
      }
    }
  }, [lprewards]);

  // Section Tokens
  const [openTokens, setOpenTokens] = React.useState(true);
  const [openTokensActive, setOpenTokensActive] = React.useState(true);
  const handleCloseTokens = () => {
    setOpenTokens(false);
  };
  const handleOpenTokens = () => {
    setOpenTokens(true);
  };
  const handleCloseTokensActive = () => {
    setOpenTokensActive(false);
  };
  const handleOpenTokensActive = () => {
    setOpenTokensActive(true);
  };

  // Section LPs
  const [openLPs, setOpenLPs] = React.useState(false);
  const [openLPsActive, setOpenLPsActive] = React.useState(false);
  const handleCloseLPs = () => {
    setOpenLPs(false);
  };
  const handleOpenLPs = () => {
    setOpenLPs(true);
  };
  const handleCloseLPsActive = () => {
    setOpenLPsActive(false);
  };
  const handleOpenLPsActive = () => {
    setOpenLPsActive(true);
  };

  return (
    <Modal title={t("Rewards Pool JShare")} onDismiss={onDismiss} zIndex={100}>
      <ModalBody maxWidth={isMobile || isTablet ? "320px" : "1000px"}>
        <StyledRewards>
        <Flex flexDirection="row" justifyContent={isMobile || isTablet ? "space-around": "center"} mb="2rem">
          <Button
            variant={openTokensActive ? "primary" : "tertiary"}
            scale="sm"
            mr={isMobile || isTablet ? "" : "2rem"}
            onClick={() => {
              handleOpenTokens();
              handleCloseLPs();
              handleOpenTokensActive();
              handleCloseLPsActive();
            }}
          >
            {t("Tokens")}
          </Button>
          <Button
            variant={openLPsActive ? "primary" : "tertiary"}
            scale="sm"
            mr={isMobile || isTablet ? "" : "2rem"}
            onClick={() => {
              handleOpenLPs();
              handleCloseTokens();
              handleOpenLPsActive();
              handleCloseTokensActive();
            }}
          >
            {t("LPs")}
          </Button>
          </Flex>
          {openTokens && openLPs === false ? (
            <>
              <TokenRow
                account={account}
                walletData={walletData}
                userDataLoaded={userDataLoaded}
                token={serializedTokens.jamonV2}
              />
              <TokenRow
                account={account}
                walletData={walletData}
                userDataLoaded={userDataLoaded}
                token={serializedTokens.wmatic}
              />
              <TokenRow
                account={account}
                walletData={walletData}
                userDataLoaded={userDataLoaded}
                token={serializedTokens.jstlp}
              />
            </>
          ) : (
            <>
              {sortedTransactions.map((row) => {
                return (
                  <PairRow
                    key={row.lpToken.address}
                    account={account}
                    walletData={walletData}
                    userDataLoaded={userDataLoaded}
                    config={row}
                  />
                );
              })}

              <PageButtons>
                <Arrow
                  onClick={() => {
                    setPage(page === 1 ? page : page - 1);
                  }}
                >
                  <ArrowBackIcon
                    color={page === 1 ? "textDisabled" : "primary"}
                  />
                </Arrow>

                <Text>{t("Page %page% of %maxPage%", { page, maxPage })}</Text>
                <Arrow
                  onClick={() => {
                    setPage(page === maxPage ? page : page + 1);
                  }}
                >
                  <ArrowForwardIcon
                    color={page === maxPage ? "textDisabled" : "primary"}
                  />
                </Arrow>
              </PageButtons>
            </>
          )}
        </StyledRewards>
      </ModalBody>
    </Modal>
  );
};

export default RewardsModalJShare;
