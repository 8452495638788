import React, { useMemo } from "react";
import { Trade, TradeType } from "@pancakeswap/sdk";
import { Button, Text, ErrorIcon, ArrowDownIcon, IconButton } from "@pancakeswap/uikit";
import { Field } from "state/swap/actions";
import { useTranslation } from "contexts/Localization";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity
} from "utils/prices";
import { AutoColumn } from "components/Layout/Column";
import { CurrencyLogo } from "components/Logo";
import BigNumber from "bignumber.js";
import { RowBetween, RowFixed } from "components/Layout/Row";
import truncateHash from "utils/truncateHash";
import { BIG_ZERO } from "utils/bigNumber";
import { TruncatedText, SwapShowAcceptChanges } from "./styleds";

export default function OrderModalHeader({
  trade,
  targetPrice,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  trade: Trade;
  targetPrice: number;
  allowedSlippage: number;
  recipient: string | null;
  showAcceptChanges: boolean;
  onAcceptChanges: () => void;
}) {
  const { t } = useTranslation();
  const inputAmount = trade.inputAmount
    ? new BigNumber(trade.inputAmount.toSignificant())
    : BIG_ZERO;

  const { priceImpactWithoutFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  const amount = new BigNumber(targetPrice);
  const slipAmount = amount.times(10000 - allowedSlippage).dividedBy(10000);
  const textMinAmount = slipAmount.toString();
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT
      ? trade.outputAmount.currency.symbol
      : trade.inputAmount.currency.symbol;

  const tradeInfoText = t(
    "Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.",
    {
      amount: textMinAmount,
      symbol
    }
  );

  const [estimatedText, transactionRevertText] = tradeInfoText.split(
    `${textMinAmount} ${symbol}`
  );


  return (
    <AutoColumn gap="md">
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo
            currency={trade.inputAmount.currency}
            size="24px"
            style={{ marginRight: "12px" }}
          />
          <TruncatedText fontSize="24px" color="text">
            {trade.inputAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {trade.inputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <IconButton variant="subtle" scale="sm" disabled style={{cursor: "default", background: "#ABAFC4"}}>
          <ArrowDownIcon width="16px" ml="4px" />
        </IconButton>
      </RowFixed>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo
            currency={trade.outputAmount.currency}
            size="24px"
            style={{ marginRight: "12px" }}
          />
          <TruncatedText
            fontSize="24px"
            color={priceImpactSeverity > 2 ? "failure" : "text"}
          >
            {targetPrice}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {trade.outputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <AutoColumn
        justify="flex-start"
        gap="sm"
        style={{ padding: "24px 0 0 0px" }}
      >
        <Text
          small
          color="binance"
          textAlign="left"
          style={{ width: "100%" }}
        >
          {estimatedText}
          <b>
            {textMinAmount} {symbol}
          </b>
          {transactionRevertText}
        </Text>
      </AutoColumn>
    </AutoColumn>
  );
}
