import React from 'react'
import BigNumber from 'bignumber.js'
import { Progress, ProgressProps } from '@pancakeswap/uikit'

interface RoundProgressProps extends ProgressProps {
  collected: BigNumber
  hardcap: BigNumber
}

const RoundProgress: React.FC<RoundProgressProps> = ({ collected, hardcap, ...props }) => {
  const progress = collected.div(hardcap).times(100).toFixed(2)

  return <Progress primaryStep={Number(progress)} {...props} />
}

export default RoundProgress
