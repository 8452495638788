import { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import multicall from "utils/multicall";
import tokenAbi from "config/abi/lpToken.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getLaunchLockerAddress } from "utils/addressHelpers";
import { PublicPresaleData } from "../types";


const useGetLockedLaunchData = (presaleAddress: string, presaleData: PublicPresaleData ) => {

  const [state, setState] = useState({
    presaleLocked: BIG_ZERO,
    launchLocked: BIG_ZERO,
    totalSupply: BIG_ZERO,
  });

  const address = presaleData.saleToken;
  const lockerAddress = getLaunchLockerAddress();

  const fetchLaunchData = useCallback(async () => {
    const LaunchCalls = [
      {
        address,
        name: "balanceOf",
        params: [presaleAddress],
      },
      {
        address,
        name: "balanceOf",
        params: [lockerAddress],
      },
      {
        address,
        name: "totalSupply",
      },
    ];

    const [presaleLocked, launchLocked, totalSupply] = await multicall(tokenAbi, LaunchCalls);

    setState((prev) => ({
      ...prev,
      presaleLocked: new BigNumber(presaleLocked[0].toString()),
      launchLocked: new BigNumber(launchLocked[0].toString()),
      totalSupply: new BigNumber(totalSupply[0].toString())
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, presaleAddress]);

  useEffect(() => {
    fetchLaunchData();
  }, [fetchLaunchData]);

  return { ...state, fetchLaunchData };
};

export default useGetLockedLaunchData;
