import React, { lazy } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { ResetCSS, useMatchBreakpoints } from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useEagerConnect from "hooks/useEagerConnect";
import useUserAgent from "hooks/useUserAgent";
import useScrollOnRouteChange from "hooks/useScrollOnRouteChange";
import NavMenu from "components/NavMenu";
import MobileMenu from "components/NavMenu/MobileMenu";
import Footer from "components/Footer";
import FooterMobile from "components/Footer/FooterMobile";
import { usePollBlockNumber } from "state/block/hooks";
import { usePollCoreFarmData } from "state/farmsV2/hooks";
import { DatePickerPortal } from "components/DatePicker";
import { nftsBaseUrl } from "views/Nft/market/constants";
import RedirectInvalidPresale from "views/Launchpad/redirects";
import GlobalStyle from "./style/Global";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import { ToastListener } from "./contexts/ToastsContext";
import PageLoader from "./components/Loader/PageLoader";
import EasterEgg from "./components/EasterEgg";
import GlobalCheckClaimStatus from "./components/GlobalCheckClaimStatus";
import history from "./routerHistory";
// Views included in the main bundle
import Pools from "./views/Pools";
import Swap from "./views/Swap";
import LimitOrder from "./views/LimitOrder";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from "./views/AddLiquidity/redirects";
import RedirectOldRemoveLiquidityPathStructure from "./views/RemoveLiquidity/redirects";
import { RedirectPathToSwapOnly, RedirectToSwap } from "./views/Swap/redirects";
import { useInactiveListener } from "./hooks/useInactiveListener";
import Migrate from "./views/Migrate";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import("./views/Home"));
const Farms = lazy(() => import("./views/FarmsV2"));
const FarmAuction = lazy(() => import("./views/FarmAuction"));
const Lottery = lazy(() => import("./views/Lottery"));
const Ifos = lazy(() => import("./views/Ifos"));
const Ilos = lazy(() => import("./views/Ilos"));
const Upgrade = lazy(() => import("./views/Upgrade"));
const Bonus = lazy(() => import("./views/Bonus"));
/* const Vesting = lazy(() => import('./views/Vesting')) */
const NotFound = lazy(() => import("./views/NotFound"));
const Teams = lazy(() => import("./views/Teams"));
const Team = lazy(() => import("./views/Teams/Team"));
const TradingCompetition = lazy(() => import("./views/TradingCompetition"));
const Predictions = lazy(() => import("./views/Predictions"));
const PredictionsLeaderboard = lazy(
  () => import("./views/Predictions/Leaderboard")
);
const Voting = lazy(() => import("./views/Voting"));
const Proposal = lazy(() => import("./views/Voting/Proposal"));
const CreateProposal = lazy(() => import("./views/Voting/CreateProposal"));
const AddLiquidity = lazy(() => import("./views/AddLiquidity"));
const Liquidity = lazy(() => import("./views/Pool"));
const PoolFinder = lazy(() => import("./views/PoolFinder"));
const RemoveLiquidity = lazy(() => import("./views/RemoveLiquidity"));
const Info = lazy(() => import("./views/Info"));
const NftMarket = lazy(() => import("./views/Nft/market"));
const ProfileCreation = lazy(() => import("./views/ProfileCreation"));
const PancakeSquad = lazy(() => import("./views/PancakeSquad"));
const JUSD = lazy(() => import("./views/JUSD"));
const Launchpad = lazy(() => import("./views/Launchpad"));
const LaunchFactory = lazy(() => import("./views/Launchpad/LaunchFactory"));
const LaunchLocker = lazy(() => import("./views/Launchpad/Locker"));
const Donations = lazy(() => import("./views/Donations"));

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  const { account } = useWeb3React();
  const { isMobile, isTablet } = useMatchBreakpoints();
  usePollBlockNumber();
  useEagerConnect();
  // useFetchProfile()
  usePollCoreFarmData();
  useScrollOnRouteChange();
  useUserAgent();
  useInactiveListener();

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      {isMobile || isTablet ? <MobileMenu /> : <NavMenu />}
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route exact path="/farms/auction">
            <FarmAuction />
          </Route>
          <Route path="/farms">
            <Farms />
          </Route>
          <Route path="/pools">
            <Pools />
          </Route>
          <Route path="/lottery">
            <Lottery />
          </Route>
          <Route path="/ifo">
            <Ifos />
          </Route>
          <Route path="/ilo">
            <Ilos />
          </Route>
          <Route path="/update">
            <Upgrade />
          </Route>
          <Route path="/bonus">
            <Bonus />
          </Route>
          <Route path="/donations">
            <Donations />
          </Route>
          <Route exact path="/teams">
            <Teams />
          </Route>
          <Route path="/teams/:id">
            <Team />
          </Route>
          <Route path="/create-profile">
            <ProfileCreation />
          </Route>
          <Route path="/competition">
            <TradingCompetition />
          </Route>
          <Route exact path="/prediction">
            <Predictions />
          </Route>
          <Route path="/prediction/leaderboard">
            <PredictionsLeaderboard />
          </Route>
          <Route exact path="/voting">
            <Voting />
          </Route>
          <Route exact path="/voting/proposal/create">
            <CreateProposal />
          </Route>
          <Route path="/voting/proposal/:id">
            <Proposal />
          </Route>

          {/* NFT */}
          <Route path="/nfts">
            <NftMarket />
          </Route>

          <Route path="/pancake-squad">
            <PancakeSquad />
          </Route>

          {/* Info pages */}
          <Route path="/info">
            <Info />
          </Route>

          {/* Stable coins pages */}
          {/* <Route path="/stable-coin">
            <JUSD />
          </Route> */}

          {/* Launchpad pages */}
          <Route exact path="/launchpad/new">
            <LaunchFactory />
          </Route>
          <Route exact path="/launchpad/locker">
            <LaunchLocker />
          </Route>
          <Route
            exact
            path={["/launchpad/presale/:address"]}
            component={RedirectInvalidPresale}
          />
          <Route path="/launchpad">
            <Launchpad />
          </Route>

          <Route path="/migrate">
            <Migrate />
          </Route>

          {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
          <Route exact strict path="/swap" component={Swap} />
          <Route exact strict path="/limitOrder" component={LimitOrder} />
          <Route
            exact
            strict
            path="/swap/:outputCurrency"
            component={RedirectToSwap}
          />
          <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
          <Route exact strict path="/find" component={PoolFinder} />
          <Route exact strict path="/liquidity" component={Liquidity} />
          <Route
            exact
            strict
            path="/create"
            component={RedirectToAddLiquidity}
          />
          <Route exact path="/add" component={AddLiquidity} />
          <Route
            exact
            path="/add/:currencyIdA"
            component={RedirectOldAddLiquidityPathStructure}
          />
          <Route
            exact
            path="/add/:currencyIdA/:currencyIdB"
            component={RedirectDuplicateTokenIds}
          />
          <Route exact path="/create" component={AddLiquidity} />
          <Route
            exact
            path="/create/:currencyIdA"
            component={RedirectOldAddLiquidityPathStructure}
          />
          <Route
            exact
            path="/create/:currencyIdA/:currencyIdB"
            component={RedirectDuplicateTokenIds}
          />
          <Route
            exact
            strict
            path="/remove/:tokens"
            component={RedirectOldRemoveLiquidityPathStructure}
          />
          <Route
            exact
            strict
            path="/remove/:currencyIdA/:currencyIdB"
            component={RemoveLiquidity}
          />

          {/* Redirect */}
          <Route path="/pool">
            <Redirect to="/liquidity" />
          </Route>
          <Route path="/staking">
            <Redirect to="/pools" />
          </Route>
          <Route path="/syrup">
            <Redirect to="/pools" />
          </Route>
          <Route path="/collectibles">
            <Redirect to="/nfts" />
          </Route>
          <Route path="/profile">
            <Redirect
              to={`${nftsBaseUrl}/profile/${account?.toLowerCase() || ""}`}
            />
          </Route>

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />

      {isMobile || isTablet ? <FooterMobile /> : <Footer />}
    </Router>
  );
};

export default React.memo(App);
