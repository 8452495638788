import React, { useCallback, useMemo, useState } from "react";
import { currencyEquals, Trade } from "@pancakeswap/sdk";
import { InjectedModalProps } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { useJamonLimitContract } from "hooks/useContract";
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from "components/OrderConfirmationModal";
import BigNumber from "bignumber.js";
import { getDecimalAmount } from "utils/formatBalance";
import { ToastDescriptionWithTx } from "components/Toast";
import { serializeTokens } from "config/constants/tokens";
import useToast from "hooks/useToast";
import OrderModalFooter from "./OrderModalFooter";
import OrderModalHeader from "./OrderModalHeader";

const serializedTokens = serializeTokens();

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !currencyEquals(
      tradeA.outputAmount.currency,
      tradeB.outputAmount.currency
    ) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  );
}
interface ConfirmSwapModalProps {
  trade?: Trade;
  targetPrice: number;
  credits: number;
  originalTrade?: Trade;
  attemptingTxn: boolean;
  txHash?: string;
  recipient: string | null;
  allowedSlippage: number;
  onAcceptChanges: () => void;
  onConfirm: () => void;
  swapErrorMessage?: string;
  customOnDismiss?: () => void;
  fetchOrder: () => void;
}

const ConfirmOrderModal: React.FC<InjectedModalProps & ConfirmSwapModalProps> =
  ({
    trade,
    targetPrice,
    credits,
    originalTrade,
    onAcceptChanges,
    allowedSlippage,
    onConfirm,
    onDismiss,
    customOnDismiss,
    recipient,
    swapErrorMessage,
    attemptingTxn,
    txHash,
    fetchOrder
  }) => {
    const showAcceptChanges = useMemo(
      () =>
        Boolean(
          trade &&
            originalTrade &&
            tradeMeaningfullyDiffers(trade, originalTrade)
        ),
      [originalTrade, trade]
    );

    const { t } = useTranslation();
    const { toastError, toastSuccess } = useToast();
    const [pendingTx, setPendingTx] = useState(false);

    const jamonLimitContract = useJamonLimitContract();

    const handleConfirmClick = useCallback(async () => {
      setPendingTx(true);
      try {
        const tokenIn = trade?.route.path[0].address;
        const amountIn = getDecimalAmount(
          new BigNumber(trade?.inputAmount.toSignificant()),
          trade?.inputAmount.currency.decimals
        ).toString();
        const price = getDecimalAmount(
          new BigNumber(
            targetPrice 
          ),
          trade?.outputAmount.currency.decimals
        ).toString();
        const path =
          trade?.route.path.length > 2
            ? [
                trade?.route.path[0].address,
                trade?.route.path[1].address,
                trade?.route.path[2].address
              ]
            : [trade?.route.path[0].address, trade?.route.path[1].address];

        if (tokenIn === serializedTokens.wmatic.address) {
          const tx = await jamonLimitContract.createOrder(
            price,
            amountIn,
            path,
            allowedSlippage,
            credits,
            { value: amountIn }
          );
          const receipt = await tx.wait();
          if (receipt.status) {
            toastSuccess(
              t("Order Created!"),
              <ToastDescriptionWithTx txHash={receipt.transactionHash}>
                {t("Your order has been included in the queue.")}
              </ToastDescriptionWithTx>
            );
            setPendingTx(false);
            fetchOrder();
            onDismiss();
          }
        } else {
          const tx = await jamonLimitContract.createOrder(
            price,
            amountIn,
            path,
            allowedSlippage,
            credits,
            { value: 0 }
          );
          const receipt = await tx.wait();
          if (receipt.status) {
            toastSuccess(
              t("Order Created!"),
              <ToastDescriptionWithTx txHash={receipt.transactionHash}>
                {t("Your order has been included in the queue.")}
              </ToastDescriptionWithTx>
            );
            setPendingTx(false);
            fetchOrder();
            onDismiss();
          }
        }
      } catch (error) {
        console.log(error)
        toastError(
          t("Error"),
          t(
            "Please try again. Confirm the transaction and make sure you are paying enough gas!"
          )
        );
        setPendingTx(false);
      }
    }, [
      onDismiss,
      targetPrice,
      trade,
      t,
      toastError,
      toastSuccess,
      fetchOrder,
      jamonLimitContract,
      allowedSlippage,
      credits
    ]);

    const modalHeader = useCallback(() => {
      return trade ? (
        <OrderModalHeader
          trade={trade}
          targetPrice={targetPrice}
          allowedSlippage={allowedSlippage}
          recipient={recipient}
          showAcceptChanges={showAcceptChanges}
          onAcceptChanges={onAcceptChanges}
        />
      ) : null;
    }, [
      allowedSlippage,
      onAcceptChanges,
      recipient,
      showAcceptChanges,
      trade,
      targetPrice
    ]);

    const modalBottom = useCallback(() => {
      return trade ? (
        <OrderModalFooter
          onConfirm={handleConfirmClick}
          trade={trade}
          targetPrice={targetPrice}
          credits={credits}
          disabledConfirm={showAcceptChanges}
          swapErrorMessage={swapErrorMessage}
          allowedSlippage={allowedSlippage}
          pendingTx={pendingTx}
        />
      ) : null;
    }, [
      allowedSlippage,
      showAcceptChanges,
      swapErrorMessage,
      trade,
      targetPrice,
      credits,
      pendingTx,
      handleConfirmClick
    ]);

    // text to show while loading
    const pendingText = t(
      "Creating order for %amountA% %symbolA% to %amountB% %symbolB% at price %price%",
      {
        amountA: trade?.inputAmount?.toSignificant(6) ?? "",
        symbolA: trade?.inputAmount?.currency?.symbol ?? "",
        amountB: trade?.outputAmount?.toSignificant(6) ?? "",
        symbolB: trade?.outputAmount?.currency?.symbol ?? "",
        price: trade?.nextMidPrice.toSignificant() ?? ""
      }
    );

    const confirmationContent = useCallback(
      () =>
        swapErrorMessage ? (
          <TransactionErrorContent
            onDismiss={onDismiss}
            message={swapErrorMessage}
          />
        ) : (
          <ConfirmationModalContent
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        ),
      [onDismiss, modalBottom, modalHeader, swapErrorMessage]
    );

    return (
      <TransactionConfirmationModal
        title={t("Confirm Order")}
        onDismiss={onDismiss}
        customOnDismiss={customOnDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={confirmationContent}
        pendingText={pendingText}
        currencyToAdd={trade?.outputAmount.currency}
      />
    );
  };

export default ConfirmOrderModal;
