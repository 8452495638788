import styled from 'styled-components'

export const ActionContainer = styled.div<{ isAutoVault?: boolean }>`
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 16px;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 0;
    height: ${({ isAutoVault }) => (isAutoVault ? '130px' : 'auto')};
  }
}
`

export const ActionTitles = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-family: Poppins;
  margin-bottom: 0.5rem;
`

export const ActionContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
