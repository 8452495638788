import React from "react";
import styled from "styled-components";
import {
  Text,
  useMatchBreakpoints,
  Skeleton,
  Flex,
  Box,
  Button
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { SerializedToken } from "config/constants/types";
import { useTranslation } from "contexts/Localization";
import { TokenImage } from "components/TokenImage";
import useToast from "hooks/useToast";
import { getBalanceNumber, getBalanceAmount } from "utils/formatBalance";
import { useERC20, useJTRVaultContract } from "hooks/useContract";
import { useCakeBusdPrice } from "hooks/useBUSDPrice";
import { WalletJstlpStakeData } from "views/Pools/types";
import { BIG_ZERO } from "utils/bigNumber";
import Balance from "components/Balance";
import useGetWalletJtrRewards from "views/Pools/V2/hooks/useGetWalletJtrRewards";
import { serializeTokens } from "config/constants/tokens";

import {
  CellContent,
  StyledCell
} from "../../../../components/PoolsTable/Cells/BaseCell";

const serializedTokens = serializeTokens();

interface JamonRowProps {
  account: string;
  walletData: WalletJstlpStakeData;
  token: SerializedToken;
  userDataLoaded: boolean;
}

const StyledRow = styled.div`
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`;

const AprLabelContainer = styled(Flex)`
  &:hover {
    opacity: 0.5;
  }
`;

const TokenRow: React.FC<JamonRowProps> = ({
  account,
  walletData,
  token,
  userDataLoaded
}) => {
  const { isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const contract = useJTRVaultContract();

  const rewards = useGetWalletJtrRewards(token.address);
  const hasEarnings = rewards.PendingReward.gt(0);
  const jamonPrice = useCakeBusdPrice();
  const cakePrice = jamonPrice
    ? new BigNumber(jamonPrice.toSignificant())
    : BIG_ZERO;

  let earnings = BIG_ZERO;
  let earningsBusd = 0;

  if (hasEarnings) {
    earnings = getBalanceAmount(rewards.PendingReward);
    earningsBusd = earnings.multipliedBy(cakePrice).toNumber();
  }

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  const raisingTokenContract = useERC20(serializedTokens.jtr.address);

  const handleHarvest = async () => {
    try {
      setPendingTx(true);
      const response = await raisingTokenContract.allowance(
        account,
        contract.address
      );
      const currentAllowance = new BigNumber(response.toString());
      if (currentAllowance.gt(0)) {
        const disable = await raisingTokenContract.approve(
          contract.address,
          "0"
        );
        await disable.wait();
      }
      const tx = await contract.harvestToken(token.address);
      await tx.wait();

      toastSuccess(t("Success!"), t("You have successfully harvest."));
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      setPendingTx(false);
    }
  };

  return (
    <>
      <StyledRow role="row">
        <StyledCell role="cell" isMobile={isMobile}>
          {isMobile ? (
            ""
          ) : (
            <TokenImage token={token} height={32} width={32} mr="16px" />
          )}
          <CellContent>
            <Text bold={!isMobile} small={isMobile}>
              {t("%symbol%", { symbol: token.symbol })}
            </Text>
          </CellContent>
        </StyledCell>
        <StyledCell role="cell" isMobile={isMobile}>
          <CellContent>
            <Text fontSize="12px" color="textSubtle" textAlign="left">
              {t("Earned")}
            </Text>
            {!userDataLoaded && account ? (
              <Skeleton width="80px" height="16px" />
            ) : (
              <>
                <Flex>
                  <Box mr="8px" height="32px">
                    <Balance
                      mt="4px"
                      bold={!isMobile}
                      fontSize={isMobile ? "12px" : "16px"}
                      color={hasEarnings ? "primary" : "textDisabled"}
                      decimals={hasEarnings ? 4 : 1}
                      value={getBalanceNumber(rewards.PendingReward)}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </CellContent>
        </StyledCell>
        {isMobile ? (
          ""
        ) : (
          <StyledCell role="cell" isMobile={isMobile}>
            <CellContent>
              <Text fontSize="12px" color="textSubtle" textAlign="left">
                {t("USD")}
              </Text>
              <AprLabelContainer
                alignItems="center"
                justifyContent="space-between" /* {...props} */
              >
                {!hasEarnings ? (
                  <Skeleton width="80px" height="16px" />
                ) : (
                  <Balance
                    mt="4px"
                    bold={!isMobile}
                    fontSize={isMobile ? "12px" : "16px"}
                    color={hasEarnings ? "primary" : "textDisabled"}
                    decimals={hasEarnings ? 4 : 1}
                    value={earningsBusd}
                  />
                )}
              </AprLabelContainer>
            </CellContent>
          </StyledCell>
        )}
        <StyledCell isMobile={isMobile}>
          {isMobile ? (
            <Button
              mr={4}
              width={80}
              height={40}
              disabled={!hasEarnings}
              onClick={handleHarvest}
            >
              {t("Harvest")}
            </Button>
          ) : (
            <Button disabled={!hasEarnings} onClick={handleHarvest}>
              {t("Harvest")}
            </Button>
          )}
        </StyledCell>
      </StyledRow>
    </>
  );
};

export default TokenRow;
