import { ChainId } from "@pancakeswap/sdk";
import addresses from "config/constants/contracts";
import { Address } from "config/constants/types";

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET];
};

// V2 contracts
export const getConversorAddress = () => {
  return getAddress(addresses.conversor);
};
export const getConversorV2Address = () => {
  return getAddress(addresses.conversorV2);
};
export const getJShareVaultAddress = () => {
  return getAddress(addresses.jamonShareVault);
};
export const getJamonVaultAddress = () => {
  return getAddress(addresses.jamonVault);
};
export const getJstlpVaultAddress = () => {
  return getAddress(addresses.jamonStakeLpVault);
};
export const getJtrVaultAddress = () => {
  return getAddress(addresses.jamonTokenRewardVault);
};
export const getJamonVestingOldAddress = () => {
  return getAddress(addresses.jamonVestingOld);
};
export const getJamonVestingddress = () => {
  return getAddress(addresses.jamonVesting);
};
export const getJShareVesingAddress = () => {
  return getAddress(addresses.jamonShareVesting);
};
export const getMediaOracleAddress = () => {
  return getAddress(addresses.medianOracle);
};
export const getJUSDAddress = () => {
  return getAddress(addresses.JUSD);
};
export const getJUSDViewAddress = () => {
  return getAddress(addresses.JUSDView);
};
export const getJSharePresaleAddress = () => {
  return getAddress(addresses.jamonSharePresale);
};
export const getBonusAddress = () => {
  return getAddress(addresses.bonus);
};
export const getJamonLimitAddress = () => {
  return getAddress(addresses.jamonLimit);
};
export const getLaunchFactoryAddress = () => {
  return getAddress(addresses.LaunchFactory);
};
export const getLaunchVestingAddress = () => {
  return getAddress(addresses.LaunchVesting);
};
export const getLaunchSettingsAddress = () => {
  return getAddress(addresses.LaunchSettings);
};
export const getLaunchLockerAddress = () => {
  return getAddress(addresses.LaunchLocker);
};
export const getJamonLaunchAddress = () => {
  return getAddress(addresses.JamonLaunch);
};
export const getPresaleJamonVIPAddress = () => {
  return getAddress(addresses.PresaleJamonVIP);
};
export const getJamonVIPAddress = () => {
  return getAddress(addresses.JamonVIP);
};
export const getMigratorAddress = () => {
  return getAddress(addresses.Migrator);
};

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef);
};
export const getMasterChefV2Address = () => {
  return getAddress(addresses.masterChefV2);
};
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall);
};
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2);
};
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile);
};
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits);
};
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory);
};
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund);
};
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo);
};
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial);
};
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition);
};
/* export const getTradingCompetitionAddressV2 = () => {
  return getAddress(addresses.tradingCompetitionV2)
} */
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft);
};
export const getCakeVaultAddress = () => {
  return getAddress(addresses.dexTokenVault);
};
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions);
};
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle);
};
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault);
};
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction);
};
/* export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
} */
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction);
};
/* export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
} */
