import React from "react";
import {
  Flex,
  Text,
  Button,
  Heading,
  useModal,
  Skeleton
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { Token } from "@pancakeswap/sdk";
import { useTranslation } from "contexts/Localization";
import {
  getFullDisplayBalance,
  getBalanceNumber,
  formatNumber
} from "utils/formatBalance";
import Balance from "components/Balance";
import CollectModal from "../Modals/CollectModal";

interface HarvestActionsProps {
  earnings: BigNumber;
  earningToken: Token;
  sousId: number;
  earningTokenPrice: number;
  isBnbPool: boolean;
  isLoading?: boolean;
}

const HarvestActions: React.FC<HarvestActionsProps> = ({
  earnings,
  earningToken,
  sousId,
  isBnbPool,
  earningTokenPrice,
  isLoading = false
}) => {
  const { t } = useTranslation();
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals);
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3);

  const earningTokenDollarBalance = getBalanceNumber(
    earnings.multipliedBy(earningTokenPrice),
    earningToken.decimals
  );

  const fullBalance = getFullDisplayBalance(earnings, earningToken.decimals);
  const hasEarnings = earnings.toNumber() > 0;
  const isCompoundPool = sousId === 0;

  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={earningTokenDollarBalance}
      sousId={sousId}
      isBnbPool={isBnbPool}
      isCompoundPool={isCompoundPool}
    />
  );

  return (
    <Flex justifyContent="space-between" alignItems="center" mb="16px">
      <Flex flexDirection="column">
        {isLoading ? (
          <Skeleton width="80px" height="48px" />
        ) : (
          <>
            {hasEarnings ? (
              <>
                <Balance
                  bold
                  fontSize="20px"
                  color="primary"
                  decimals={5}
                  value={earningTokenBalance}
                  mt="1rem"
                />
                {earningTokenPrice > 0 && (
                  <Balance
                    display="inline"
                    fontSize="12px"
                    color="primary"
                    decimals={2}
                    prefix="~"
                    value={earningTokenDollarBalance}
                    unit=" USD"
                  />
                )}
              </>
            ) : (
              <>
                <Text fontSize="12px" color="textDisabled">
                  0 USD
                </Text>
              </>
            )}
          </>
        )}
      </Flex>
      <Button disabled={!hasEarnings} onClick={onPresentCollect} mt="1rem">
        {t("Harvest")}
      </Button>
    </Flex>
  );
};

export default HarvestActions;
