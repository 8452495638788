import React from "react";
import {
  Text,
  Flex,
  Skeleton,
  Heading
} from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import { PublicJV2StakeData, WalletJV2StakeData } from "views/Pools/types";
import { getBalanceNumber } from "utils/formatBalance";
import { useTranslation } from "contexts/Localization";
import Balance from "components/Balance";
import Moment from "react-moment";

import { ActionContainer, ActionTitles, ActionContent } from "../styles";

/* import CollectModal from '../../PoolCard/Modals/CollectModal' */

interface HarvestActionProps {
  userDataLoaded: boolean;
  publicData: PublicJV2StakeData;
  walletData: WalletJV2StakeData;
}

function getPenalty(startDate: number) {
  const now = Math.floor(Date.now() / 1000);
  const accumulated = now - startDate;
  const year = 31556926;
  const month = 2629743;
  let minPercent = 12;
  if (accumulated >= year) {
    return 0;
  }
  if (accumulated < month) {
    return minPercent;
  }
  for (let m = 1; m < 12; m++) {
    if (accumulated >= month * m && accumulated < month * (m + 1)) {
      minPercent -= m;
      return minPercent;
    }
  }
  return 0;
}

const Info: React.FunctionComponent<HarvestActionProps> = ({
  userDataLoaded,
  walletData
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();

  const totalStaked = walletData.StakedBalance;
  const totalStakedBalance = getBalanceNumber(totalStaked);
  const hasStaked = totalStaked.gt(0);

  const actionTitle = (
    <>
      <Text
        fontSize="12px"
        bold
        color="secondary"
        as="span"
        textTransform="uppercase"
      >
        Stake
      </Text>
      <Text
        fontSize="12px"
        bold
        color="textSubtle"
        as="span"
        textTransform="uppercase"
      >
        {t(" Info")}
      </Text>
    </>
  );

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Heading>0</Heading>
        </ActionContent>
      </ActionContainer>
    );
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    );
  }

  return (
    <ActionContainer>
      <ActionTitles>{actionTitle}</ActionTitles>
      <ActionContent>
        <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
          <>
            {hasStaked ? (
              <>
              <Flex justifyContent="space-between">
                <Text fontSize="14px" mb="1rem" fontFamily="Poppins" bold>
                {t("Started")}:{" "}
                </Text>
                <Text fontSize="14px" mb="1rem" fontFamily="Poppins" color="warning">
                  <Moment fromNow ago>
                    {new Date(walletData.StartTime * 1000)}
                  </Moment>
                </Text>
              </Flex>
               {/*  <Text fontSize="12px">
                {t("Penalty")}: {getPenalty(walletData.StartTime)}%
                </Text> */}
              <Flex justifyContent="space-between">
                <Text fontSize="14px" fontFamily="Poppins" bold>
                {t("Staked")}:{" "}
                </Text>
                <Text fontSize="14px" fontFamily="Poppins" color="warning">
                  <Balance
                    lineHeight="1"
                    bold
                    fontSize="12px"
                    decimals={2}
                    unit=" JAMON"
                    value={totalStakedBalance}
                    color="warning"
                  />
                </Text>
              </Flex>
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
              </>
            )}
          </>
        </Flex>
      </ActionContent>
    </ActionContainer>
  );
};

export default Info;
