import { getConversorContract } from "utils/contractHelpers";
import multicall from "utils/multicall";
import { simpleRpcProvider } from "utils/providers";
import BigNumber from "bignumber.js";
import { serializePairs } from "config/constants/pairs";
import { serializeTokens } from "config/constants/tokens";
import { getConversorAddress } from "utils/addressHelpers";
import erc20ABI from 'config/abi/erc20.json'

const serializedPairs = serializePairs();
const serializedTokens = serializeTokens();

export const fetchConversorAllowance = async (account) => {
  const calls = [
    {
      address: serializedPairs.maticJamonOld.address,
      name: "allowance",
      params: [account, getConversorAddress()]
    },
    {
      address: serializedPairs.usdcJamonOld.address,
      name: "allowance",
      params: [account, getConversorAddress()]
    },
    {
      address: serializedTokens.jamon.address,
      name: "allowance",
      params: [account, getConversorAddress()]
    }
  ];

   const [allowanceMatic, allowanceUSDC, allowanceJamon] = await multicall(erc20ABI, calls)
    return [allowanceMatic, allowanceUSDC, allowanceJamon];
};
