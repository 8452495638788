import React from "react";
import {
  Flex,
  Text,
  Button,
  IconButton,
  AddIcon,
  MinusIcon,
  useModal,
  Skeleton,
  useTooltip
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { useTranslation } from "contexts/Localization";
import { getBalanceNumber } from "utils/formatBalance";
import { DeserializedPool } from "state/types";
import Balance from "components/Balance";
import NotEnoughTokensModal from "../Modals/NotEnoughTokensModal";
import StakeModal from "../Modals/StakeModal";

interface StakeActionsProps {
  pool: DeserializedPool;
  stakingTokenBalance: BigNumber;
  stakedBalance: BigNumber;
  isBnbPool: boolean;
  isStaked: ConstrainBoolean;
  isLoading?: boolean;
}

const StakeAction: React.FC<StakeActionsProps> = ({
  pool,
  stakingTokenBalance,
  stakedBalance,
  isBnbPool,
  isStaked,
  isLoading = false
}) => {
  const {
    stakingToken,
    stakingTokenPrice,
    stakingLimit,
    isFinished,
    userData
  } = pool;
  const { t } = useTranslation();
  const stakedTokenBalance = getBalanceNumber(
    stakedBalance,
    stakingToken.decimals
  );
  const stakedTokenDollarBalance = getBalanceNumber(
    stakedBalance.multipliedBy(stakingTokenPrice),
    stakingToken.decimals
  );
  

  const [onPresentTokenRequired] = useModal(
    <NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />
  );

  const [onPresentStake] = useModal(
    <StakeModal
      isBnbPool={isBnbPool}
      pool={pool}
      stakingTokenBalance={stakingTokenBalance}
      stakingTokenPrice={stakingTokenPrice}
    />
  );

  const [onPresentUnstake] = useModal(
    <StakeModal
      stakingTokenBalance={stakingTokenBalance}
      isBnbPool={isBnbPool}
      pool={pool}
      stakingTokenPrice={stakingTokenPrice}
      isRemovingStake
    />
  );

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t("You’ve already staked the maximum amount you can stake in this pool!"),
    { placement: "bottom" }
  );

  const reachStakingLimit =
    stakingLimit.gt(0) && userData.stakedBalance.gte(stakingLimit);

  const renderStakeAction = () => {
    return isStaked ? (
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <>
            <Balance
              bold
              color="primary"
              fontSize="20px"
              decimals={3}
              value={stakedTokenBalance}
            />
            {stakingTokenPrice !== 0 && (
              <Text fontSize="12px" color="primary">
                <Balance
                  fontSize="12px"
                  color="primary"
                  decimals={2}
                  value={stakedTokenDollarBalance}
                  prefix="~"
                  unit=" USD"
                />
              </Text>
            )}
          </>
        </Flex>
        <Flex>
          <IconButton variant="tertiary" onClick={onPresentUnstake} mr="6px">
            <MinusIcon color="primary" width="24px" />
          </IconButton>
          {reachStakingLimit ? (
            <span ref={targetRef}>
              <IconButton variant="secondary" disabled>
                <AddIcon color="textDisabled" width="24px" height="24px" />
              </IconButton>
            </span>
          ) : (
            <IconButton
              variant="tertiary"
              onClick={
                stakingTokenBalance.gt(0)
                  ? onPresentStake
                  : onPresentTokenRequired
              }
              disabled={isFinished}
            >
              <AddIcon color="primary" width="24px" height="24px" />
            </IconButton>
          )}
        </Flex>
        {tooltipVisible && tooltip}
      </Flex>
    ) : (
      <Button
        disabled={isFinished}
        onClick={
          stakingTokenBalance.gt(0) ? onPresentStake : onPresentTokenRequired
        }
        mt="1rem"
      >
        {t("Stake")}
      </Button>
    );
  };

  return (
    <Flex flexDirection="column">
      {isLoading ? (
        <Skeleton width="100%" height="52px" />
      ) : (
        renderStakeAction()
      )}
    </Flex>
  );
};

export default StakeAction;
