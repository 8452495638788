import React, { useState, } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Image,
  Button,
  BalanceInput,
  Flex,
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import { getBalanceAmount, getBalanceNumber } from "utils/formatBalance";
import { getAddress } from "utils/addressHelpers";
import ApproveConfirmButtons, {
  ButtonArrangement,
} from "components/ApproveConfirmButtons";
import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { DEFAULT_TOKEN_DECIMAL } from "config";
import { useERC20, usePresaleContract } from "hooks/useContract";
import useTokenBalance, { useGetBnbBalance } from "hooks/useTokenBalance";
import { useToken } from "hooks/Tokens";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";
import { PublicPresaleData, WalletPresaleData } from "../../types";

interface Props {
  presaleAddress: string;
  presaleData: PublicPresaleData;
  walletData: WalletPresaleData;
  onSuccess: (amount: BigNumber) => void;
  onDismiss?: () => void;
}

const multiplierValues = [0.1, 0.25, 0.5, 0.75, 1];

const ActionModal: React.FC<Props> = ({
  presaleAddress,
  presaleData,
  walletData,
  onDismiss,
  onSuccess,
}) => {
  const { baseToken, saleToken } = presaleData;
  const [value, setValue] = useState("");
  const { account } = useWeb3React();
  const raisingTokenContract = useERC20(baseToken);
  const raisingToken = useToken(baseToken);
  const buyTokenContract = useToken(saleToken);
  const contract = usePresaleContract(presaleAddress);
  const { t } = useTranslation();

  const { balance: tokenBalance } = useTokenBalance(presaleData.baseToken);
  const { balance: maticBalance } = useGetBnbBalance();
  const useMatic = presaleData.inMatic;

  const raisingSymbol =
    raisingToken.symbol === "WMATIC" ? "MATIC" : raisingToken.symbol;

  const userCurrencyBalance = useMatic
    ? new BigNumber(maticBalance.toString())
    : tokenBalance;

    
  const valueWithTokenDecimals = new BigNumber(value).times(
    BIG_TEN.pow(raisingToken.decimals)
  );

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm,
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await raisingTokenContract.allowance(
          account,
          presaleAddress
        );
        const currentAllowance = new BigNumber(response.toString());
        return useMatic ? true : currentAllowance.gt(0);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      return raisingTokenContract.approve(
        presaleAddress,
        ethers.constants.MaxUint256
      );
    },
    onConfirm: () => {
      return contract.buyTokens(valueWithTokenDecimals.toString(), {
        value: useMatic ? valueWithTokenDecimals.toString() : "0",
      });
    },
    onSuccess: async () => {
      onSuccess(valueWithTokenDecimals);
      onDismiss();
    },
  });
  const limitBuy = presaleData.max4user;

  const maximumCommitable = (() => {
    if (limitBuy.isGreaterThan(0)) {
      return limitBuy.minus(walletData.spended).lte(userCurrencyBalance)
        ? limitBuy.minus(walletData.spended)
        : userCurrencyBalance;
    }
    return userCurrencyBalance;
  })();

  return (
    <Modal
      title={t("Buy %symbol%", { symbol: buyTokenContract.symbol })}
      onDismiss={onDismiss}
    >
      <ModalBody maxWidth="320px">
        {limitBuy.isGreaterThan(0) && (
          <>
            <Flex justifyContent="space-between">
              <Text>
                {t("Max pre wallet")}
              </Text>
              <Text>{getBalanceNumber(limitBuy, raisingToken.decimals).toString()} {raisingSymbol}</Text>
            </Flex>
            <Flex justifyContent="space-between" mb="12px">
              <Text>
                {t("Your available")}
              </Text>
              <Text>{getBalanceNumber(maximumCommitable, raisingToken.decimals).toString()} {raisingSymbol}</Text>
            </Flex>
          </>
        )}
        <BalanceInput
          value={value}
          onUserInput={setValue}
          isWarning={valueWithTokenDecimals.isGreaterThan(maximumCommitable)}
          decimals={raisingTokenContract.decimals}
          mb="8px"
        />
        <Text color="textSubtle" textAlign="right" fontSize="12px" mb="16px">
          {t("Balance: %balance%", {
            balance: getBalanceAmount(
              userCurrencyBalance,
              raisingToken.decimals
            ).toString(),
          })}
        </Text>
        <Flex justifyContent="space-between" mb="16px">
          {multiplierValues.map((multiplierValue, index) => (
            <Button
              key={multiplierValue}
              scale="xs"
              variant="tertiary"
              onClick={() =>
                setValue(
                  getBalanceAmount(
                    maximumCommitable.times(multiplierValue),
                    raisingToken.decimals
                  ).toString()
                )
              }
              mr={index < multiplierValues.length - 1 ? "8px" : 0}
            >
              {multiplierValue * 100}%
            </Button>
          ))}
        </Flex>
        {useMatic ? (
          <ApproveConfirmButtons
            isApproveDisabled
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproving={isApproving}
            isConfirmDisabled={
              isConfirmed ||
              valueWithTokenDecimals.isNaN() ||
              valueWithTokenDecimals.eq(0)
            }
            isConfirming={isConfirming}
            onApprove={handleApprove}
            onConfirm={handleConfirm}
          />
        ) : (
          <ApproveConfirmButtons
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproveDisabled={isConfirmed || isConfirming || isApproved}
            isApproving={isApproving}
            isConfirmDisabled={
              !isApproved ||
              isConfirmed ||
              valueWithTokenDecimals.isNaN() ||
              valueWithTokenDecimals.eq(0)
            }
            isConfirming={isConfirming}
            onApprove={handleApprove}
            onConfirm={handleConfirm}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ActionModal;
