export const MINIMUM_SEARCH_CHARACTERS = 2;

export const WEEKS_IN_YEAR = 52.1429;

export const TOTAL_FEE = 0.0025;
export const LP_HOLDERS_FEE = 0.0017;
export const TREASURY_FEE = 0.0003;
export const BUYBACK_FEE = 0.0005;

export const PCS_V2_START = 21641650; // April 23, 2021, 12:00:00 AM
export const ONE_DAY_UNIX = 86400; // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600;

export const ITEMS_PER_INFO_TABLE_PAGE = 10;

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume
export const TOKEN_BLACKLIST = [
  // Old JAMON token
  "0x5fc6485035ae3715920c3d7a55baf0eb68c9d9ed",
  "0xd048349424735d4b28054c5abd7142448e95b56a",
  "0xa822cfd3acbc0eb1a1aba073b3355acaf756ef7f",
  "0x37d39950f9c753d62529dbf68fcb4dca4004fbfd"
];
