import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import { Flex, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import { StyledPagePools } from "views/Style";
import { Colors, Fonts } from "components/Style";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import { useTranslation } from "contexts/Localization";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import {
  useFetchPublicPoolsData,
  usePools,
  useFetchUserPools
} from "state/poolsV2/hooks";
import { usePollFarmsPublicData } from "state/farmsV2/hooks";
import { latinise } from "utils/latinise";
import FlexLayout from "components/Layout/Flex";
import SearchInput from "components/SearchInput";
import Select, { OptionProps } from "components/Select/Select";
import { DeserializedPool } from "state/types";
import { useUserPoolStakedOnly } from "state/user/hooks";
import Loading from "components/Loading";
import PoolCard from "./components/PoolCard";
import PoolTabButtons from "./components/PoolTabButtons";
import { getAprData } from "./helpers";
import JShareCard from "./V2/components/StakeCard/JShare/JShareCard";
import JSTLPCard from "./V2/components/StakeCard/JSTLP/JSTLPCard";
import JTRCard from "./V2/components/StakeCard/JTR/JTRCard";
import JV2Card from "./V2/components/StakeCard/JV2/JV2Card";

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`;

const PoolControls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 32px;
    margin-bottom: 0;
  }
`;

const FilterContainer = styled.div<{ isMobile?: boolean; isTablet?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  margin-bottom: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? "" : "14px"};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`;

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`;

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`;

const Title = styled(Text)`
  align-items: center;
  font-size: ${Fonts.H2};
  font-family: Poppins;
  font-weight: bold;
  text-decoration: 1px underline ${Colors.BORDER1};
  color: ${Colors.TITLE};
`;

const SubTitle = styled(Text)`
  align-items: center;
  font-size: ${Fonts.H6};
  font-family: Poppins;
  color: ${Colors.TURQUOISE7};
  margin-bottom: 2rem;
  text-align: center;
`;

const NUMBER_OF_POOLS_VISIBLE = 12;

const Pools: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const { account } = useWeb3React();
  const { pools /* : poolsWithoutAutoVault , userDataLoaded */ } = usePools();
  const [stakedOnly, setStakedOnly] = useUserPoolStakedOnly();
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(
    NUMBER_OF_POOLS_VISIBLE
  );
  const { observerRef, isIntersecting } = useIntersectionObserver();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("hot");
  const chosenPoolsLength = useRef(0);

  const userDataLoaded = true;
  /*  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault() */
  /*   const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100 */

  /* const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    const cakeAutoVault = { ...cakePool, isAutoVault: false }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault]) */

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(
    () => partition(pools, (pool) => pool.isFinished),
    [pools]
  );
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        return (
          pool.userData &&
          new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
        );
      }),
    [finishedPools]
  );
  const stakedOnlyOpenPools = useMemo(
    () =>
      openPools.filter((pool) => {
        return (
          pool.userData &&
          new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
        );
      }),
    [openPools]
  );
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0;

  usePollFarmsPublicData();
  useFetchPublicPoolsData();
  useFetchUserPools(account);

  useEffect(() => {
    if (isIntersecting) {
      setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
        if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
          return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE;
        }
        return poolsCurrentlyVisible;
      });
    }
  }, [isIntersecting]);

  const showFinishedPools = true; /* location.pathname.includes("history"); */

  const handleChangeSearchQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value);
  };

  const sortPools = (poolsToSort: DeserializedPool[]) => {
    switch (sortOption) {
      case "apr":
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) =>
            pool.apr ? getAprData(pool, 0.1).apr : 0,
          "desc"
        );
      case "earned":
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0;
            }
            return pool.userData.pendingReward
              .times(pool.earningTokenPrice)
              .toNumber();
          },
          "desc"
        );
      case "totalStaked":
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            let totalStaked = Number.NaN;
            if (pool.totalStaked?.isFinite() && pool.stakingTokenPrice) {
              totalStaked =
                +formatUnits(
                  ethers.BigNumber.from(pool.totalStaked.toString()),
                  pool.stakingToken.decimals
                ) * pool.stakingTokenPrice;
            }
            return Number.isFinite(totalStaked) ? totalStaked : 0;
          },
          "desc"
        );
      default:
        return poolsToSort;
    }
  };

  let chosenPools;
  if (showFinishedPools) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools;
  } else {
    chosenPools = stakedOnly ? stakedOnlyOpenPools : openPools;
  }

  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase());
    if (lowercaseQuery !== "rewards" && lowercaseQuery !== "jamon") {
      chosenPools = chosenPools.filter((pool) =>
        latinise(pool.earningToken.symbol.toLowerCase()).includes(
          lowercaseQuery
        )
      );
    }
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible);
  chosenPoolsLength.current = chosenPools.length;

  const cardLayout = (
    <CardLayout>
      {!showFinishedPools && !hasStakeInFinishedPools && <> </>}
      {chosenPools.map(
        (pool) => (
          /*   pool.isAutoVault ? (
          <CakeVaultCard key="auto-cake" pool={pool} showStakedOnly={stakedOnly} />
        ) : ( */
          <PoolCard key={pool.sousId} pool={pool} account={account} />
        )
        /*   ), */
      )}
      {showFinishedPools && (
        <>
          <JV2Card userDataLoaded account={account} />
          <JSTLPCard userDataLoaded account={account} />{" "}
          <JShareCard userDataLoaded account={account} />{" "}
          <JTRCard userDataLoaded account={account} />{" "}
        </>
      )}
    </CardLayout>
  );

  /* const tableLayout = <PoolsTable pools={chosenPools} account={account} userDataLoaded={userDataLoaded} /> */

  return (
    <>
      <StyledPagePools>
        <Title>{t("Acorn Pools")}</Title>
        <SubTitle>
          {t("Just stake some tokens to earn.")}
          <br />
          {t("High APR, low risk.")}
        </SubTitle>
        {/* <Flex justifyContent="center"><Image src={Alert} width={100} height={100}/></Flex>
      <Text textAlign="center" color='primary'>{t("The JAMON profit generated from the AUTO JAMON, MANUAL JAMON and EARN JAMON(dsg) Pools after 09/02/2022 21:00 UTC cannot be converted to JAMON V2.")}</Text>
      <Text textAlign="center" color='primary'>{t("Claim the generated JAMON profit from the EARN JAMON pool (dsg) before 09/02/2022 21:00 UTC")}</Text> */}
        <PoolControls>
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            /* viewMode={viewMode} */
            /* setViewMode={setViewMode} */
          />
          <FilterContainer isMobile={isMobile} isTablet={isTablet}>
            <LabelWrapper>
              <Text
                fontSize="12px"
                bold
                color="textSubtle"
                textTransform="uppercase"
              >
                {t("Sort by")}
              </Text>
              <ControlStretch>
                <Select
                  options={[
                    {
                      label: t("Hot"),
                      value: "hot"
                    },
                    {
                      label: t("APR"),
                      value: "apr"
                    },
                    {
                      label: t("Earned"),
                      value: "earned"
                    },
                    {
                      label: t("Total staked"),
                      value: "totalStaked"
                    }
                  ]}
                  onOptionChange={handleSortOptionChange}
                />
              </ControlStretch>
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text
                fontSize="12px"
                bold
                color="textSubtle"
                textTransform="uppercase"
              >
                {t("Search")}
              </Text>
              <SearchInput
                onChange={handleChangeSearchQuery}
                placeholder="Search Pools"
              />
            </LabelWrapper>
          </FilterContainer>
        </PoolControls>
        {showFinishedPools && (
          <>
            <Flex justifyContent="center">
              <Text fontSize="20px" color="primary" pb="32px">
                {t(
                  "These pools are no longer distributing rewards. Please unstake your tokens."
                )}
              </Text>
            </Flex>
          </>
        )}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {cardLayout}
        <div ref={observerRef} />
        {/* <Image
          mx="auto"
          mt="12px"
          src="/images/decorations/3d-syrup-bunnies.png"
          alt="Pancake illustration"
          width={192}
          height={184.5}
        /> */}
      </StyledPagePools>
    </>
  );
};

export default Pools;
