import BigNumber from "bignumber.js";
import React from "react";
import { CardBody, Flex, Text, CardRibbon, useMatchBreakpoints } from "@pancakeswap/uikit";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useTranslation } from "contexts/Localization";
import { BIG_ZERO } from "utils/bigNumber";
import { DeserializedPool } from "state/types";
import Apr from "../PoolsTable/Apr";
import { StyledCard } from "./StyledCard";
import CardFooter from "./CardFooter";
import StyledCardHeader from "./StyledCardHeader";
import CardActions from "./CardActions";

const PoolCard: React.FC<{ pool: DeserializedPool; account: string }> = ({
  pool,
  account
}) => {
  const { sousId, stakingToken, earningToken, isFinished, userData } = pool;
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const stakedBalance = userData?.stakedBalance
    ? new BigNumber(userData.stakedBalance)
    : BIG_ZERO;
  const accountHasStakedBalance = stakedBalance.gt(0);

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      ribbon={
        isFinished && (
          <CardRibbon variantColor="textDisabled" text={t("Finished")} />
        )
      }
    >
      <StyledCardHeader
        isStaking={accountHasStakedBalance}
        earningToken={earningToken}
        stakingToken={stakingToken}
        isFinished={isFinished && sousId !== 0}
      />
      <CardBody>
        
        <Flex justifyContent="space-between" mb="1rem">
          <Text fontFamily="Poppins">{t("Apr")}: </Text>
          <Text fontFamily="Poppins" color="primary">
            <Apr pool={pool} showIcon={!isMobile} stakedBalance={stakedBalance} />
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb="1rem">
          <Text fontFamily="Poppins">{t("Rewards")}: </Text>
          <Text fontFamily="Poppins" color="primary">
            {t("JSTLP")}
          </Text>
        </Flex>
        <Flex mt="24px" flexDirection="column">
          {account ? (
            <CardActions pool={pool} stakedBalance={stakedBalance} />
          ) : (
            <>
              <Text
                mb="10px"
                textTransform="uppercase"
                fontSize="12px"
                color="textSubtle"
                bold
              >
                {t("Start earning")}
              </Text>
              <ConnectWalletButton />
            </>
          )}
        </Flex>
      </CardBody>
      <CardFooter pool={pool} account={account} />
    </StyledCard>
  );
};

export default PoolCard;
