import React from 'react'
import { useTranslation } from 'contexts/Localization'
import { Text } from '@pancakeswap/uikit'

const SafemoonWarning = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text>{t('To trade Jamon V1, you must:')} </Text>
      <Text>• {t('The tokens you buy cannot be converted to the V2 version.')}</Text>
    </>
  )
}

export default SafemoonWarning
