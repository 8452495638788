import { serializeTokens } from './tokens'
import { serializePairs } from './pairs'
import { SerializedPoolRewadsConfig } from './types'

const serializedTokens = serializeTokens()
const serializedPairs = serializePairs()

const poolRewards: SerializedPoolRewadsConfig[] = [  
  {
    lpToken: serializedPairs.maticJamon,
    basetoken: serializedTokens.wmatic,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    lpToken: serializedPairs.usdtJamon,
    basetoken: serializedTokens.usdt,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    lpToken: serializedPairs.jusdMatic,
    basetoken: serializedTokens.jusd,
    quoteToken: serializedTokens.wmatic,
  },
  {
    lpToken: serializedPairs.usdcJamon,
    basetoken: serializedTokens.usdc,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    lpToken: serializedPairs.link677Jamon,
    basetoken: serializedTokens.link677,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    lpToken: serializedPairs.usdcMatic,
    basetoken: serializedTokens.usdc,
    quoteToken: serializedTokens.wmatic,
  },
  {
    lpToken: serializedPairs.dsgMatic,
    basetoken: serializedTokens.dsg,
    quoteToken: serializedTokens.wmatic,
  },
  {
    lpToken: serializedPairs.link677Matic,
    basetoken: serializedTokens.link677,
    quoteToken: serializedTokens.wmatic,
  },
  {
    lpToken: serializedPairs.jshareMatic,
    basetoken: serializedTokens.jshare,
    quoteToken: serializedTokens.wmatic,
  },
  {
    lpToken: serializedPairs.wbtcJamon,
    basetoken: serializedTokens.wbtc,
    quoteToken: serializedTokens.jamonV2,
  },
  {
    lpToken: serializedPairs.wethJamon,
    basetoken: serializedTokens.weth,
    quoteToken: serializedTokens.jamonV2,
  },
 

]

export default poolRewards
