import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Text,
  Checkbox, 
  Message,
  Flex
} from "@pancakeswap/uikit";
import useTheme from 'hooks/useTheme'
import useToast from "hooks/useToast";
import { WalletJstlpStakeData } from "views/Pools/types";
import { useERC20, useJTRVaultContract } from "hooks/useContract";
import { useTranslation } from "contexts/Localization";
import ApproveConfirmButtons, {
  ButtonArrangement
} from "components/ApproveConfirmButtons";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";

interface Props {
  token: string;
  walletData: WalletJstlpStakeData;
  onDismiss?: () => void;
}



const RemoveJstlpModal: React.FC<Props> = ({ token, walletData, onDismiss}) => {

  const { account } = useWeb3React();
  const { theme } = useTheme()
  const { t } = useTranslation();

  const raisingTokenContract = useERC20(token);

  // user must accept
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setisConfirming] = useState(false)

  const { toastSuccess, toastError } = useToast();
  const contract = useJTRVaultContract();

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  
    const handleUnStake = async () => {
      try {
        setisConfirming(true)
        setPendingTx(true)
        const response = await raisingTokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        if (currentAllowance.gt(0)) {
          const disable = await raisingTokenContract.approve(contract.address, "0");
          await disable.wait();
        }
          const tx = await contract.safeUnStake()
          await tx.wait()
          
        toastSuccess(t('Success!'), t('You have successfully removed stake.'))
      } catch (error) {
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        console.error(error)
      } finally {
        setPendingTx(false)
        onDismiss();
      }
    }




  return (
    <Modal title={t("Remove Stake ATR")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
      <Message variant="danger" mb="1rem">
            <Flex flexDirection="column">
              <Text fontSize="20px" textAlign="center" color={theme.colors.failure} mb="16px">
                {t('Important announcement')}
              </Text>
              <Text color={theme.colors.binance} mb="8px">
                {t( 'If you remove your ATR now, NO penalty will apply' )}.
              </Text>
              <Text bold color={theme.colors.binance} mb="16px">
                {t ('If you agree, check the box and remove stake')}.
              </Text>
              <Flex alignItems="center">
                <Checkbox
                  name="confirmed"
                  type="checkbox"
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  scale="sm"
                />
                <Text ml="10px" style={{ userSelect: 'none' }}>
                  {t('I understand')}
                </Text>
              </Flex>
            </Flex>
          </Message>
          <ApproveConfirmButtons 
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproveDisabled
            isApproving={false}
            isConfirmDisabled={!confirmed}
            isConfirming={isConfirming}
            onApprove={handleUnStake}
            onConfirm={handleUnStake}
          />
      </ModalBody>
    </Modal>
  );
};

export default RemoveJstlpModal;
