import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'JAMON',
    lpAddresses: {
      80001: '',
      137: '0x5FC6485035AE3715920C3d7a55bAf0eB68c9D9ed',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 1,
    lpSymbol: 'JAMON-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x3fD61e13D70594d73A40b57af141dB7eD7B5d780',
    },
    token: serializedTokens.jamon,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 5,
    lpSymbol: 'JAMON-USDC LP',
    lpAddresses: {
      80001: '',
      137: '0x282a299e14E7458dD06EbFFF7f75027d02899aBa',
    },
    token: serializedTokens.jamon,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 2,
    lpSymbol: 'USDC-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x7954006f16b4e6c7cb4073bc1d382cd3d32ce4c5',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 8,
    lpSymbol: 'WBTC-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x118B2F755ff8FCE521254F637193351519eE3469',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wmatic,
  }, 
  {
    pid: 9,
    lpSymbol: 'WETH-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xB64fDc28c295973eFa58280eCE861dfb3ce4a7F0',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wmatic,
  }, 
  {
    pid: 10,
    lpSymbol: 'USDT-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xc7822550Dd0F36715e5446986ec2da14e0bAe40d',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wmatic,
  }, 
  {
    pid: 12,
    lpSymbol: 'DAI-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xaBD03B82fd84221B8Aa6d2C5605446b40c5B0b8A',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.wmatic,
  }, 
  {
    pid: 11,
    lpSymbol: 'SAND-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x59bE8033cc556e62E8cb836599CB764E8b05a5b5',
    },
    token: serializedTokens.sand,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 4,
    lpSymbol: 'LINKv2-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xb9AE91d2Bf26525DeAd47dD7fAb041F8836EbEeE',
    },
    token: serializedTokens.link677,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 3,
    lpSymbol: 'DSG-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x410C91C4a0f30Aa2CD20B796f8fAE8f92095b46c',
    },
    token: serializedTokens.dsg,
    quoteToken: serializedTokens.wmatic,
  },
  {
    pid: 7,
    lpSymbol: 'SFF-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xd4E05be228A5fa535D1839A6523A2dcf7133FfbE',
    },
    token: serializedTokens.sff,
    quoteToken: serializedTokens.wmatic,
  }, 

  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
 

]

export default farms
