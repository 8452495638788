import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import stakeAbi from "config/abi/JamonVault.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getJamonVaultAddress } from "utils/addressHelpers";

const useGetWalletJV2StakeData = () => {
  const address = getJamonVaultAddress();
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();

  const [state, setState] = useState({
    InStake: false,
    StakedBalance: BIG_ZERO,
    StartTime: 0,
    isPendingTx: false
  });

  const setPendingTx = (status: boolean) =>
    setState((prevState) => ({
      ...prevState,
      isPendingTx: status
    }));

  const fetchJV2StakeData = useCallback(async () => {
    const stakeCalls = [
      {
        address,
        name: "isInStake",
        params: [account]
      },
      {
        address,
        name: "getWalletInfo",
        params: [account]
      }
    ];

    const [isInStake, getWalletInfo] = await multicall(stakeAbi, stakeCalls);

    setState((prev) => ({
      ...prev,
      InStake: Boolean(isInStake[0]),
      StakedBalance: new BigNumber(getWalletInfo[0].toString()),
      StartTime: Number(getWalletInfo[1])
    }));
  }, [address, account]);

  useEffect(() => {
    if (account) {
      fetchJV2StakeData();
    }
  }, [account, fetchJV2StakeData, slowRefresh]);

  return { ...state, setPendingTx, fetchJV2StakeData };
};

export default useGetWalletJV2StakeData;
