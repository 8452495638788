import React, { useState } from "react";
import { useTranslation } from "contexts/Localization";
import {
  Flex,
  Box,
  Text,
  Button,
  useModal,
} from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import useToast from "hooks/useToast";
import Balance from "components/Balance";
import { getBalanceNumber } from "utils/formatBalance";
import { useLaunchSettingsContract } from "hooks/useContract";
import useGetWalletNFTData from "views/Launchpad/hooks/useGetWalletNFTData";
import SlotModal from "./SlotsModal";
import NFTSlotModal from "./NFTSlotModal";
import { SubLabel } from "../styles";
import { PublicPresaleData, WalletPresaleData, SlotsData } from "../../types";

interface SlotsPanelProps {
  presaleAddress: string;
  publicData: PublicPresaleData;
  walletData: WalletPresaleData;
  slotData: SlotsData;
}

const SlotPanel: React.FC<SlotsPanelProps> = ({
  presaleAddress,
  publicData,
  walletData,
  slotData,
}) => {
  const { t } = useTranslation();
  const { toastSuccess } = useToast();
  const { account } = useWeb3React();
  const [isLoadingCancel, setLoadingCancel] = useState(false);
  const [isLoadingReturn, setLoadingReturn] = useState(false);

  const { status, round } = publicData;
  const { slotUsed } = walletData;
  const { slotPrice, available, haveSlot, inTokens } = slotData;
  const walletNFT = useGetWalletNFTData();

  const haveNFT = walletNFT.balance > 0;


  const contract = useLaunchSettingsContract();

  const withdrawnSlot = async () => {
    setLoadingReturn(true)
    try {
      const tx = await contract.returnSlot(presaleAddress, account);
      await tx.wait();
      slotData.fetchSlotData();
      slotData.fetchWalletSlotData();
      setLoadingReturn(false)
    } catch (error) {
      setLoadingReturn(false)
      console.error(error);
    }
  };

  const cancelSlot = async () => {
    setLoadingCancel(true);
    try {
      const tx = await contract.cancelSlot(presaleAddress, account);
      await tx.wait();
      slotData.fetchSlotData();
      slotData.fetchWalletSlotData();
      setLoadingCancel(false);
    } catch (error) {
      setLoadingCancel(false);
      console.error(error);
    }
  };

  const handleSuccess = () => {    
    slotData.fetchSlotData();
    slotData.fetchWalletSlotData();
    toastSuccess(t("Slot bought"));
  };

  const handleSuccessRent = () => {
    slotData.fetchSlotData();
    slotData.fetchWalletSlotData();
    toastSuccess(t("Slot rented with NFT"));
  };

  const [onPresentBuyModal] = useModal(
    <SlotModal
      onSuccess={handleSuccess}
      presaleAddress={presaleAddress}
      slotData={slotData}
    />
  );

  const [onPresentRentModal] = useModal(
    <NFTSlotModal
      onSuccess={handleSuccessRent}
      presaleAddress={presaleAddress}
      walletNFT={walletNFT}
      slotData={slotData}
    />
  );

  const isAvailable = available !== 0;

  const acctionButton = (): JSX.Element => {
    if (status === "OnSale" || status === "ComingSoon") {
      if (!haveSlot && round === 0) {
        return (
          <>
            <Button
              width="100%"
              mr="5px"
              mb="16px"
              onClick={onPresentBuyModal}
              disabled={!isAvailable}
            >
              {t("Buy slot")}
            </Button>
            <Button
              width="100%"
              ml="5px"
              mb="16px"
              disabled={!haveNFT}
              onClick={onPresentRentModal}
            >
              {t("Rent with NFT")}
            </Button>
          </>
        );
      }
      if (haveSlot) {
        return inTokens ? (
          <Button
            isLoading={isLoadingCancel}
            mb="16px"
            onClick={cancelSlot}
            disabled={slotUsed}
          >
            {t("Cancel slot")}
          </Button>
        ) : (
          <Button
            isLoading={isLoadingReturn}
            mb="16px"
            onClick={withdrawnSlot}
            disabled={status === "OnSale"}
          >
            {t("Withdrawn NFT")}
          </Button>
        );
      }
    }
    if (status === "Failed" || status === "Completed") {
      if (haveSlot) {
        return inTokens ? (
          <Button
            isLoading={isLoadingCancel}
            mb="16px"
            onClick={cancelSlot}
            disabled={status === "Completed"}
          >
            {t("Cancel slot")}
          </Button>
        ) : (
          <Button mb="16px" onClick={withdrawnSlot} isLoading={isLoadingReturn}>
            {t("Withdrawn NFT")}
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <Box mb="14px" mt="14px">
      <Flex justifyContent="space-evenly" mb="16px">
        <Flex justifyContent="center" flexDirection="column">
          <Flex justifyContent="center" mb={-10}>
            <SubLabel> {t("Slot price")}</SubLabel>
          </Flex>
          <Balance
            value={getBalanceNumber(slotPrice)}
            prefix="~"
            unit=" JAMON"
          />
        </Flex>
        <Flex justifyContent="center" flexDirection="column">
          <Flex justifyContent="center">
            <SubLabel> {t("AVAILABLE")}</SubLabel>
          </Flex>
          <Flex justifyContent="center" mt={-2}>
            <Text>{available}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="space-evenly">{acctionButton()}</Flex>
    </Box>
  );
};

export default SlotPanel;
