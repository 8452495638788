import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import {
  Button,
  useModal,
  AutoRenewIcon,
  Skeleton,
  Text
} from "@pancakeswap/uikit";
import { PublicJstlpStakeData, WalletJstlpStakeData } from "views/Pools/types";
import useStakeApprove from "views/Pools/V2/hooks/useStakeApprove";
import { getJtrVaultAddress } from "utils/addressHelpers";
import { serializeTokens } from "config/constants/tokens";
import useTokenBalance from "hooks/useTokenBalance";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useWeb3React } from "@web3-react/core";
import { useERC20, useJamonStakeLPVaultContract } from "hooks/useContract";
import useToast from "hooks/useToast";
import { useTranslation } from "contexts/Localization";
import StakeJstlpModal from "./StakeJstlpModal";
import RemoveJstlpModal from "./RemoveJstlpModal";
import { EnableStatus } from "../../types";
import { ActionContainer, ActionTitles, ActionContent } from "../styles";

const serializedTokens = serializeTokens();

interface StackedActionProps {
  userDataLoaded: boolean;
  publicData: PublicJstlpStakeData;
  walletData: WalletJstlpStakeData;
}

const Actions: React.FunctionComponent<StackedActionProps> = ({
  userDataLoaded,
  publicData,
  walletData
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const vaultAddress = getJtrVaultAddress();
  const { toastSuccess, toastError } = useToast();
  const [enableStatus, setEnableStatus] = useState(EnableStatus.DISABLED);

  const contract = useJamonStakeLPVaultContract();

  const onApprove = useStakeApprove(serializedTokens.jtr.address, vaultAddress);

  const { balance: userBalance } = useTokenBalance(
    serializedTokens.jtr.address
  );

  const handleStakeSuccess = async (amount: BigNumber) => {
    await Promise.all([
      publicData.fetchJstlpStakeData(),
      walletData.fetchJstlpStakeData()
    ]);
    toastSuccess(t("Success!"), t("Staked successful"));
  };

  const handleUnStakeSuccess = async (amount: BigNumber) => {
    await Promise.all([
      publicData.fetchJstlpStakeData(),
      walletData.fetchJstlpStakeData()
    ]);
    toastSuccess(t("Success!"), t("You have successfully removed stake."));
  };

  const [onPresentStakeModal] = useModal(
    <StakeJstlpModal
      publicData={publicData}
      walletData={walletData}
      reStake={false}
      token={serializedTokens.jtr.address}
      onSuccess={handleStakeSuccess}
      userCurrencyBalance={new BigNumber(userBalance.toString())}
    />,
    false
  );
  const [onPresentRemoveModal] = useModal(
    <RemoveJstlpModal
      token={serializedTokens.jtr.address}
      walletData={walletData}
    />,
    false
  );

  const handleApprove = async () => {
    try {
      setEnableStatus(EnableStatus.IS_ENABLING);

      await onApprove();

      setEnableStatus(EnableStatus.ENABLED);
      toastSuccess(
        t("Successfully Enabled!"),
        t("You can now stake your Jamon.")
      );
    } catch (error) {
      setEnableStatus(EnableStatus.DISABLED);
    }
  };

  const setPendingTx = (isPending: boolean) =>
    walletData.setPendingTx(isPending);

  const handleUnStake = async () => {
    try {
      setPendingTx(true);
      const tx = await contract.unStake();
      await tx.wait();

      toastSuccess(t("Success!"), t("You have successfully removed stake."));
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      setPendingTx(false);
    }
  };

  const JSContract = useERC20(serializedTokens.jtr.address);

  useEffect(() => {
    const checkAllowance = async () => {
      try {
        const response = await JSContract.allowance(account, vaultAddress);
        const currentAllowance = new BigNumber(response.toString());
        setEnableStatus(
          currentAllowance.lte(0) ? EnableStatus.DISABLED : EnableStatus.ENABLED
        );
      } catch (error) {
        setEnableStatus(EnableStatus.DISABLED);
      }
    };

    if (account) {
      checkAllowance();
    }
  }, [account, vaultAddress, JSContract, setEnableStatus]);

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text
            fontSize="12px"
            bold
            color="textSubtle"
            as="span"
            textTransform="uppercase"
          >
            {t("Start staking")}
          </Text>
        </ActionTitles>
        <ActionContent>
          <ConnectWalletButton width="100%" />
        </ActionContent>
      </ActionContainer>
    );
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text
            fontSize="12px"
            bold
            color="textSubtle"
            as="span"
            textTransform="uppercase"
          >
            {t("Start staking")}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    );
  }

  /*  if (needsApproval) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Enable pool')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Button width="100%" disabled={requestedApproval} onClick={handleApprove} variant="secondary">
            {t('Enable')}
          </Button>
        </ActionContent>
      </ActionContainer>
    )
  } */

  // Wallet connected, user data loaded and approved
  /* if (isNotVaultAndHasStake || isVaultWithShares) {
    return (
      <ActionContainer isAutoVault={isAutoVault}>
        <ActionTitles>
          <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
            {stakingToken.symbol}{' '}
          </Text>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {isAutoVault ? t('Staked (compounding)') : t('Staked')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
            <Balance
              lineHeight="1"
              bold
              fontSize="20px"
              decimals={5}
              value={isAutoVault ? cakeAsNumberBalance : stakedTokenBalance}
            />
            <Balance
              fontSize="12px"
              display="inline"
              color="textSubtle"
              decimals={2}
              value={isAutoVault ? stakedAutoDollarValue : stakedTokenDollarBalance}
              unit=" USD"
              prefix="~"
            />
          </Flex>
          <IconButtonWrapper>
            <IconButton variant="secondary" onClick={onUnstake} mr="6px">
              <MinusIcon color="primary" width="14px" />
            </IconButton>
            {reachStakingLimit ? (
              <span ref={targetRef}>
                <IconButton variant="secondary" disabled>
                  <AddIcon color="textDisabled" width="24px" height="24px" />
                </IconButton>
              </span>
            ) : (
              <IconButton
                variant="secondary"
                onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
                disabled={isFinished}
              >
                <AddIcon color="primary" width="14px" />
              </IconButton>
            )}
          </IconButtonWrapper>
          {tooltipVisible && tooltip}
        </ActionContent>
      </ActionContainer>
    )
  }
 */

  return (
    <ActionContainer>
      <ActionTitles>
        <Text
          fontSize="12px"
          bold
          color="secondary"
          as="span"
          textTransform="uppercase"
        >
          {t("Stake")}{" "}
        </Text>
        <Text
          fontSize="12px"
          bold
          color="textSubtle"
          as="span"
          textTransform="uppercase"
        >
          ATR
        </Text>
      </ActionTitles>
      <ActionContent>
        {/* {enableStatus !== EnableStatus.ENABLED && account && (
          <Button
            width="100%"
            onClick={handleApprove}
            isLoading={enableStatus === EnableStatus.IS_ENABLING}
            endIcon={
              enableStatus === EnableStatus.IS_ENABLING ? (
                <AutoRenewIcon spin color="currentColor" />
              ) : null
            }
          >
            {t("Enable")}
          </Button>
        )} */}
        {/* {account && (
          <Button
            width="100%"
            onClick={onPresentStakeModal}
            style={{ margin: 5 }}
            variant="primary"
          >
            {t("Stake")}
          </Button>
        )} */}

        {walletData.InStake && account && (
          <Button
            width="100%"
            isLoading={walletData.isPendingTx}
            onClick={onPresentRemoveModal}
          >
            {t("Remove Stake")}
          </Button>
        )}
      </ActionContent>
    </ActionContainer>
  );
};

export default Actions;
