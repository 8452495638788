import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Button,
  BalanceInput,
  Box,
  Flex,
  AutoRenewIcon,
  Message
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import useToast from "hooks/useToast";
import { getBalanceAmount, getBalanceNumber } from "utils/formatBalance";
import { DatePicker } from "components/DatePicker";
import ApproveConfirmButtons, {
  ButtonArrangement
} from "components/ApproveConfirmButtons";
import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import {
  useCake,
  useLaunchLockerContract,
  useTokenContract
} from "hooks/useContract";
import useTokenBalance from "hooks/useTokenBalance";
import Balance from "components/Balance";
import { BIG_TEN } from "utils/bigNumber";
import { Token } from "@pancakeswap/sdk";
import useGetPublicLaunchSettingsData from "views/Launchpad/hooks/useGetPublicLaunchSettingsData";
import { addDays } from "date-fns";

enum EnableStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
  IS_ENABLING = "is_enabling"
}

interface Props {
  token: Token;
  onSuccess: (amount: string) => void;
  onDismiss?: () => void;
}

const LockModal: React.FC<Props> = ({ token, onDismiss, onSuccess }) => {
  const minDate = addDays(new Date(), 365);
  const { toastSuccess } = useToast();
  const [enableStatus, setEnableStatus] = useState(EnableStatus.DISABLED);
  const [value, setValue] = useState("");
  const [dateTime, setdateTime] = useState(minDate);
  const { account } = useWeb3React();
  const contract = useLaunchLockerContract();
  const tokenContract = useTokenContract(token.address);
  const jamonContract = useCake();
  const { t } = useTranslation();

  const { balance: userCurrencyBalance } = useTokenBalance(token.address);

  const valueWithTokenDecimals = new BigNumber(value).times(
    BIG_TEN.pow(token.decimals)
  );

  const { slotPrice } = useGetPublicLaunchSettingsData();

  const handleApproveJamon = async () => {
    try {
      setEnableStatus(EnableStatus.IS_ENABLING);

      const tx = jamonContract.approve(
        contract.address,
        ethers.constants.MaxUint256
      );
      await tx.wait();
      setEnableStatus(EnableStatus.ENABLED);
      toastSuccess(t("Successfully Enabled!"));
    } catch (error) {
      setEnableStatus(EnableStatus.DISABLED);
    }
  };

  useEffect(() => {
    const checkAllowance = async () => {
      try {
        const response = await jamonContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        setEnableStatus(
          currentAllowance.lte(0) ? EnableStatus.DISABLED : EnableStatus.ENABLED
        );
      } catch (error) {
        setEnableStatus(EnableStatus.DISABLED);
      }
    };

    if (account) {
      checkAllowance();
    }
  }, [account, contract.address, jamonContract, setEnableStatus]);

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await tokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        return currentAllowance.gt(0);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      return tokenContract.approve(
        contract.address,
        ethers.constants.MaxUint256
      );
    },
    onConfirm: () => {
      return contract.lockLPToken(
        token.address,
        valueWithTokenDecimals.toString(),
        Math.ceil(dateTime.getTime() / 1000),
        account
      );
    },
    onSuccess: async () => {
      onSuccess(value);
      onDismiss();
    }
  });

  const handleDateChange = (val: Date) => {
    setdateTime(val);
  };

  return (
    <Modal
      title={t("Lock %symbol%", { symbol: token.symbol })}
      onDismiss={onDismiss}
    >
      <ModalBody maxWidth="320px">
        <Message variant="danger" mb="24px">
          <Box>
            <Text fontWeight="bold" color="failure">
              {t("Tokens with Fee")}
            </Text>
            <Text as="p">{t("Do not use if the token have transaction fee")}.</Text>
          </Box>
        </Message>
        <Flex justifyContent="space-between" mb="8px">
          <Text>{t("Unlock Date")}</Text>
        </Flex>
        <Flex justifyContent="center">
          <DatePicker
            name="lockDate"
            dateFormat="dd/MM/yyyy, p"
            onChange={handleDateChange}
            minDate={minDate}
            placeholderText="DD/MM/YYYY"
            value={dateTime.toLocaleString()}
            withPortal
          />
        </Flex>
        <Flex justifyContent="space-between" mb="8px" mt="24px">
          <Text>{t("Commit")}</Text>
          <Text>{token.symbol}</Text>
        </Flex>
        <BalanceInput
          value={value}
          onUserInput={setValue}
          isWarning={valueWithTokenDecimals.isGreaterThan(userCurrencyBalance)}
          decimals={token.decimals}
          mb="8px"
        />
        <Text color="textSubtle" textAlign="right" fontSize="12px" mb="16px">
          {t("Balance: %balance%", {
            balance: getBalanceAmount(
              userCurrencyBalance,
              token.decimals
            ).toString()
          })}
        </Text>
        <Flex justifyContent="center" mb="16px">
          <Balance
            value={getBalanceNumber(slotPrice)}
            unit=" JAMON"
            prefix={t("Lock price: ")}
            decimals={2}
          />
        </Flex>
        {enableStatus === EnableStatus.ENABLED ? (
          <ApproveConfirmButtons
            buttonArrangement={ButtonArrangement.SEQUENTIAL}
            isApproveDisabled={isConfirmed || isConfirming || isApproved}
            isApproving={isApproving}
            isConfirmDisabled={
              !isApproved ||
              isConfirmed ||
              valueWithTokenDecimals.isNaN() ||
              valueWithTokenDecimals.eq(0)
            }
            isConfirming={isConfirming}
            onApprove={handleApprove}
            onConfirm={handleConfirm}
          />
        ) : (
          <Button
            width="100%"
            onClick={handleApproveJamon}
            isLoading={enableStatus === EnableStatus.IS_ENABLING}
            endIcon={
              enableStatus === EnableStatus.IS_ENABLING ? (
                <AutoRenewIcon spin color="currentColor" />
              ) : null
            }
          >
            {t("Enable JAMON")}
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LockModal;
