import { ethers } from "ethers";
import { simpleRpcProvider } from "utils/providers";
import { poolsConfig } from "config/constants";
import { PoolCategory } from "config/constants/types";
import tokens from "config/constants/tokens";

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getMasterChefV2Address,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getCakeVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialCakeVaultAddress,
  getBunnySpecialPredictionAddress,
  /* getBunnySpecialLotteryAddress, */
  getFarmAuctionAddress,
  /* getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getPancakeSquadAddress,
  getTradingCompetitionAddressV2, */
  getConversorAddress,
  getConversorV2Address,
  getJShareVaultAddress,
  getJamonVaultAddress,
  getJstlpVaultAddress,
  getJamonVestingddress,
  getJShareVesingAddress,
  getJSharePresaleAddress,
  getBonusAddress,
  getJamonLimitAddress,
  getMediaOracleAddress,
  getJUSDAddress,
  getJUSDViewAddress,
  getLaunchFactoryAddress,
  getLaunchVestingAddress,
  getLaunchSettingsAddress,
  getLaunchLockerAddress,
  getJamonLaunchAddress,
  getPresaleJamonVIPAddress,
  getJamonVIPAddress,
  getJtrVaultAddress,
  getMigratorAddress
} from "utils/addressHelpers";

// NEW
import profileABI from "config/abi/pancakeProfile.json";
import conversorABI from "config/abi/conversor.json";
import conversorV2ABI from "config/abi/conversorV2.json";
import jshareVaultABI from "config/abi/JamonShareVault.json";
import jamonVaultABI from "config/abi/JamonVault.json";
import jamonVestingABI from "config/abi/JamonVesting.json";
import jshareVestingABI from "config/abi/JamonShareVesting.json";
import jsharePresaleABI from "config/abi/JamonSharePresale.json";
import bonusABI from "config/abi/Bonus.json";
import jamonLimitABI from "config/abi/JamonSpotLimit.json";
import medianOracleABI from "config/abi/MedianOracle.json";
import jusdABI from "config/abi/JUSD.json";
import jusdViewABI from "config/abi/JUSDView.json";
import LaunchFactoryABI from "config/abi/LaunchFactory.json";
import LaunchVestingABI from "config/abi/LaunchVesting.json";
import LaunchSettingsABI from "config/abi/LaunchSettings.json";
import LaunchLockerABI from "config/abi/LaunchLocker.json";
import JamonLaunchABI from "config/abi/JamonLaunch.json";
import PresaleJamonVIPABI from "config/abi/PresaleJamonVIP.json";
import jamonVIPABI from "config/abi/jamonVIP.json";
import migratorABI from "config/abi/Migrator.json";

// OLD
import pancakeRabbitsAbi from "config/abi/pancakeRabbits.json";
import bunnyFactoryAbi from "config/abi/bunnyFactory.json";
import bunnySpecialAbi from "config/abi/bunnySpecial.json";
import bep20Abi from "config/abi/erc20.json";
import erc721Abi from "config/abi/erc721.json";
import lpTokenAbi from "config/abi/lpToken.json";
import cakeAbi from "config/abi/cake.json";
import ifoV1Abi from "config/abi/ifoV1.json";
import ifoV2Abi from "config/abi/ifoV2.json";
import iloAbi from "config/abi/ilo.json";
import presaleAbi from "config/abi/LaunchPresale.json";
import pointCenterIfo from "config/abi/pointCenterIfo.json";
import lotteryV2Abi from "config/abi/lotteryV2.json";
import masterChef from "config/abi/masterchef.json";
import masterChefV2 from "config/abi/JamonStakeLpFarm.json";
import sousChef from "config/abi/sousChef.json";
import sousChefV2 from "config/abi/sousChefV2.json";
import sousChefBnb from "config/abi/sousChefBnb.json";
import claimRefundAbi from "config/abi/claimRefund.json";
import tradingCompetitionAbi from "config/abi/tradingCompetition.json";
// import tradingCompetitionV2Abi from 'config/abi/tradingCompetitionV2.json'
import easterNftAbi from "config/abi/easterNft.json";
import cakeVaultAbi from "config/abi/cakeVault.json";
import predictionsAbi from "config/abi/predictions.json";
import chainlinkOracleAbi from "config/abi/chainlinkOracle.json";
import MultiCallAbi from "config/abi/Multicall.json";
import bunnySpecialCakeVaultAbi from "config/abi/bunnySpecialCakeVault.json";
import bunnySpecialPredictionAbi from "config/abi/bunnySpecialPrediction.json";
// import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import farmAuctionAbi from "config/abi/farmAuction.json";
/* import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import pancakeSquadAbi from 'config/abi/pancakeSquad.json' */
import erc721CollectionAbi from "config/abi/erc721collection.json";
import {
  ChainLinkOracleContract,
  FarmAuctionContract,
  PancakeProfileContract,
  PredictionsContract
} from "./types";

// V2 Contracts
export const getConversorContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(conversorABI, getConversorAddress(), signer);
};
export const getConversorV2Contract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(conversorV2ABI, getConversorV2Address(), signer);
};
export const getJShareVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jshareVaultABI, getJShareVaultAddress(), signer);
};
export const getJamonVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jamonVaultABI, getJamonVaultAddress(), signer);
};
export const getJamonStakeLPVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jshareVaultABI, getJstlpVaultAddress(), signer);
};
export const getJTRVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jshareVaultABI, getJtrVaultAddress(), signer);
};
export const getJamonVestingContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jamonVestingABI, getJamonVestingddress(), signer);
};
export const getJShareVestingContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jshareVestingABI, getJShareVesingAddress(), signer);
};
export const getJSharePresaleContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jsharePresaleABI, getJSharePresaleAddress(), signer);
};
export const getBonusContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bonusABI, getBonusAddress(), signer);
};
export const getJamonLimitContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jamonLimitABI, getJamonLimitAddress(), signer);
};
export const getMedianOracleContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(medianOracleABI, getMediaOracleAddress(), signer);
};
export const getJUSDContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jusdABI, getJUSDAddress(), signer);
};
export const getJUSDViewContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jusdViewABI, getJUSDViewAddress(), signer);
};
export const getLaunchFactoryContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(LaunchFactoryABI, getLaunchFactoryAddress(), signer);
};
export const getLaunchVestingContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(LaunchVestingABI, getLaunchVestingAddress(), signer);
};
export const getLaunchSettingsContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(LaunchSettingsABI, getLaunchSettingsAddress(), signer);
};
export const getLaunchLockerContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(LaunchLockerABI, getLaunchLockerAddress(), signer);
};
export const getJamonLaunchContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(JamonLaunchABI, getJamonLaunchAddress(), signer);
};
export const getPresaleJamonVIPContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(PresaleJamonVIPABI, getPresaleJamonVIPAddress(), signer);
};
export const getJamonVIPContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(jamonVIPABI, getJamonVIPAddress(), signer);
};
export const getMigratorContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(migratorABI, getMigratorAddress(), signer);
};

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getBep20Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, address, signer);
};
export const getErc721Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(erc721Abi, address, signer);
};
export const getLpContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(lpTokenAbi, address, signer);
};
export const getIfoV1Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(ifoV1Abi, address, signer);
};
export const getIfoV2Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(ifoV2Abi, address, signer);
};
export const getIloContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(iloAbi, address, signer);
};
export const getPresaleContract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(presaleAbi, address, signer);
};
export const getSouschefContract = (
  id: number,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const config = poolsConfig.find((pool) => pool.sousId === id);
  const abi =
    config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef;
  return getContract(abi, getAddress(config.contractAddress), signer);
};
export const getSouschefV2Contract = (
  id: number,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const config = poolsConfig.find((pool) => pool.sousId === id);
  return getContract(sousChefV2, getAddress(config.contractAddress), signer);
};
export const getPointCenterIfoContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer);
};
export const getCakeContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(cakeAbi, tokens.jamonV2.address, signer);
};
export const getProfileContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    profileABI,
    getPancakeProfileAddress(),
    signer
  ) as PancakeProfileContract;
};
export const getPancakeRabbitContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer);
};
export const getBunnyFactoryContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer);
};
export const getBunnySpecialContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer);
};
export const getLotteryV2Contract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer);
};
export const getMasterchefContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(masterChef, getMasterChefAddress(), signer);
};
export const getMasterchefV2Contract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(masterChefV2, getMasterChefV2Address(), signer);
};
export const getClaimRefundContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer);
};
export const getTradingCompetitionContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    tradingCompetitionAbi,
    getTradingCompetitionAddress(),
    signer
  );
};

/* export const getTradingCompetitionContractV2 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionV2Abi, getTradingCompetitionAddressV2(), signer)
} */
export const getEasterNftContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer);
};
export const getCakeVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer);
};

export const getPredictionsContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    predictionsAbi,
    getPredictionsAddress(),
    signer
  ) as PredictionsContract;
};

export const getChainlinkOracleContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    chainlinkOracleAbi,
    getChainlinkOracleAddress(),
    signer
  ) as ChainLinkOracleContract;
};
export const getMulticallContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer);
};
export const getBunnySpecialCakeVaultContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    bunnySpecialCakeVaultAbi,
    getBunnySpecialCakeVaultAddress(),
    signer
  );
};
export const getBunnySpecialPredictionContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    bunnySpecialPredictionAbi,
    getBunnySpecialPredictionAddress(),
    signer
  );
};
/* export const getBunnySpecialLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialLotteryAbi, getBunnySpecialLotteryAddress(), signer)
} */
export const getFarmAuctionContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    farmAuctionAbi,
    getFarmAuctionAddress(),
    signer
  ) as FarmAuctionContract;
};
/* export const getAnniversaryAchievementContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(anniversaryAchievementAbi, getAnniversaryAchievement(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getNftSaleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSaleAbi, getNftSaleAddress(), signer)
}
export const getPancakeSquadContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeSquadAbi, getPancakeSquadAddress(), signer)
} */
export const getErc721CollectionContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
  address?: string
) => {
  return getContract(erc721CollectionAbi, address, signer);
};
