import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { useWalletModal } from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import PhishingWarningBanner from "components/PhishingWarningBanner";
import { useCakeBusdPrice } from "hooks/useBUSDPrice";
import UserMenu from "components/NavMenu/UserMenu";
import GlobalSettings from "components/NavMenu/GlobalSettings";
import { languageList } from "config/localization/languages";
import {
  Container,
  Box,
  Link,
  AppBar,
  Toolbar,
  List,
  Grid,
  ListItem,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LinkIcon from "@mui/icons-material/Link";
import LanguageIcon from "@mui/icons-material/Language";
import { Buttons, Tooltips, Colors } from "components/Style";
import { useTranslation } from "contexts/Localization";
import logo from "img/Menu/logo.png";
import coin from "img/coin.png";
import { TokenUpdater } from "state/info/updaters";
import TopMoversTokens from "components/TopMoversTokens";

// Style Menu

const NavBar = styled(AppBar)`
  position: fixed;
  background: ${Colors.BACKGROUND2};
  border-bottom: 1px solid #b34a56;
  z-index: 50;
`;

const ButtonLink = styled(NavLink)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 9rem;
  height: 3rem;
  color: ${Colors.TEXT1};
  font-size: 1rem;
  font-weight: normal;
  font-family: poppins;
  &:active {
    color: #febf32;
    border-bottom: 1px solid #febf32;
  }
`;

const ButtonLinkExternal = styled(Link)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  color: ${Colors.TEXT1};
  font-size: 1rem;
  font-weight: normal;
  font-family: poppins;
`;

const LinkExternal = styled(Link)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 3rem;
  color: #abafc4;
  font-size: 1.125rem;
  font-family: poppins;
  cursor: pointer;
  text-decoration: none;
  font-weight: normal;
  &:hover {
    text-decoration: none;
    background-color: #0e1219;
  }
`;

const ConnectButton = Buttons.ButtonConnect;
const MenuButton = Buttons.ButtonMenu;
const MenuTooltip = Tooltips;

// End Style Menu

const NavMenu: React.VFC = () => {
  const { setLanguage, t } = useTranslation();
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, t);
  const jamonPriceUSD = useCakeBusdPrice();

  // State Trade
  const [openTrade, setOpenTrade] = React.useState(false);
  const [activeTrade, setActiveTrade] = React.useState(false);
  const handleCloseTrade = () => {
    setOpenTrade(false);
  };
  const handleOpenTrade = () => {
    setOpenTrade(true);
  };
  const handleActiveTrade = () => {
    setActiveTrade(true);
  };
  const handleDisabledTrade = () => {
    setActiveTrade(false);
  };

  // State Earn
  const [openEarn, setOpenEarn] = React.useState(false);
  const [activeEarn, setActiveEarn] = React.useState(false);
  const handleCloseEarn = () => {
    setOpenEarn(false);
  };
  const handleOpenEarn = () => {
    setOpenEarn(true);
  };
  const handleActiveEarn = () => {
    setActiveEarn(true);
  };
  const handleDisabledEarn = () => {
    setActiveEarn(false);
  };

  // State Play
  const [openWin, setOpenWin] = React.useState(false);
  const [activeWin, setActiveWin] = React.useState(false);
  const handleCloseWin = () => {
    setOpenWin(false);
  };
  const handleOpenWin = () => {
    setOpenWin(true);
  };
  const handleActiveWin = () => {
    setActiveWin(true);
  };
  const handleDisabledWin = () => {
    setActiveWin(false);
  };

  // State Finance
  const [openFinance, setOpenFinance] = React.useState(false);
  const [activeFinance, setActiveFinance] = React.useState(false);
  const handleCloseFinance = () => {
    setOpenFinance(false);
  };
  const handleOpenFinance = () => {
    setOpenFinance(true);
  };
  const handleActiveFinance = () => {
    setActiveFinance(true);
  };
  const handleDisabledFinance = () => {
    setActiveFinance(false);
  };

  // State Launchpad
  const [openLaunchpad, setOpenLaunchpad] = React.useState(false);
  const [activeLaunchpad, setActiveLaunchpad] = React.useState(false);
  const handleCloseLaunchpad = () => {
    setOpenLaunchpad(false);
  };
  const handleOpenLaunchpad = () => {
    setOpenLaunchpad(true);
  };
  const handleActiveLaunchpad = () => {
    setActiveLaunchpad(true);
  };
  const handleDisabledLaunchpad = () => {
    setActiveLaunchpad(false);
  };

  // State Other
  const [openOther, setOpenOther] = React.useState(false);
  const [activeOther, setActiveOther] = React.useState(false);
  const handleCloseOther = () => {
    setOpenOther(false);
  };
  const handleOpenOther = () => {
    setOpenOther(true);
  };
  const handleActiveOther = () => {
    setActiveOther(true);
  };
  const handleDisabledOther = () => {
    setActiveOther(false);
  };

  return (
    <>
      <NavBar>
        <PhishingWarningBanner />
        <Container disableGutters maxWidth={false}>
          <Toolbar disableGutters>
            <NavLink
              to="/"
              onClick={() => {
                handleDisabledTrade();
                handleDisabledEarn();
                handleDisabledWin();
                handleDisabledOther();
                handleDisabledFinance();
                handleDisabledLaunchpad();
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: 150,
                  mr: 10,
                }}
                alt="menu-logo"
                src={logo}
              />
            </NavLink>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <MenuTooltip
                open={openTrade}
                onClose={handleCloseTrade}
                onMouseOver={handleOpenTrade}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/swap"
                        onClick={() => {
                          handleCloseTrade();
                          handleActiveTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenTrade}
                        onMouseLeave={handleCloseTrade}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Swap")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/liquidity"
                        onClick={() => {
                          handleCloseTrade();
                          handleActiveTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenTrade}
                        onMouseLeave={handleCloseTrade}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Liquidity")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/limitOrder"
                        onClick={() => {
                          handleCloseTrade();
                          handleActiveTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenTrade}
                        onMouseLeave={handleCloseTrade}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Limit Orders")}
                      </ButtonLink>
                    </ListItem>
                  </List>
                }
              >
                <MenuButton
                  sx={
                    activeTrade
                      ? { color: "#FEBF32", borderBottom: "1px solid #FEBF32" }
                      : {}
                  }
                >
                  {t("Trade")}
                </MenuButton>
              </MenuTooltip>

              <MenuTooltip
                open={openEarn}
                onMouseOver={handleOpenEarn}
                onClose={handleCloseEarn}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/bonus"
                        onClick={() => {
                          handleCloseEarn();
                          handleActiveEarn();
                          handleDisabledTrade();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenEarn}
                        onMouseLeave={handleCloseEarn}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Bonus")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/pools"
                        onClick={() => {
                          handleCloseEarn();
                          handleActiveEarn();
                          handleDisabledTrade();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenEarn}
                        onMouseLeave={handleCloseEarn}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Pools")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/farms"
                        onClick={() => {
                          handleCloseEarn();
                          handleActiveEarn();
                          handleDisabledTrade();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenEarn}
                        onMouseLeave={handleCloseEarn}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Farms")}
                      </ButtonLink>
                    </ListItem>
                    {/* <ListItem disablePadding>
                      <ButtonLinkExternal
                        underline="none"
                        href="https://delegate.jamonswap.finance/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleCloseEarn();
                          handleActiveEarn();
                          handleDisabledTrade();
                          handleDisabledWin();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenEarn}
                        onMouseLeave={handleCloseEarn}
                      >
                        {t("Matic Stake")} <LinkIcon />
                      </ButtonLinkExternal>
                    </ListItem> */}
                  </List>
                }
              >
                <MenuButton
                  sx={
                    activeEarn
                      ? { color: "#FEBF32", borderBottom: "1px solid #FEBF32" }
                      : {}
                  }
                >
                  {t("Earn")}
                </MenuButton>
              </MenuTooltip>

              {/* <MenuTooltip
                open={openWin}
                onMouseOver={handleOpenWin}
                onClose={handleCloseWin}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/lottery"
                        onClick={() => {
                          handleCloseWin();
                          handleActiveWin();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenWin}
                        onMouseLeave={handleCloseWin}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Lottery")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLinkExternal
                        underline="none"
                        href="https://cryptopoly.jamonswap.finance"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleCloseWin();
                          handleActiveWin();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledOther();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenWin}
                        onMouseLeave={handleCloseWin}
                      >
                        {t("Cryptopoly")} <LinkIcon />
                      </ButtonLinkExternal>
                    </ListItem>
                  </List>
                }
              >
                <MenuButton
                  sx={
                    activeWin
                      ? { color: "#FEBF32", borderBottom: "1px solid #FEBF32" }
                      : {}
                  }
                >
                  {t("Play")}
                </MenuButton>
              </MenuTooltip> */}

              {/* <MenuTooltip
                open={openFinance}
                onMouseOver={handleOpenFinance}
                onClose={handleCloseFinance}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/stable-coin"
                        onClick={() => {
                          handleCloseFinance();
                          handleActiveFinance();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledOther();
                          handleDisabledWin();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenFinance}
                        onMouseLeave={handleCloseFinance}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("JUSD")}
                      </ButtonLink>
                    </ListItem>
                  </List>
                }
              >
                <MenuButton
                  sx={
                    activeFinance
                      ? { color: "#FEBF32", borderBottom: "1px solid #FEBF32" }
                      : {}
                  }
                >
                  {t("Finance")}
                </MenuButton>
              </MenuTooltip> */}

              {/* <MenuTooltip
                open={openLaunchpad}
                onMouseOver={handleOpenLaunchpad}
                onClose={handleCloseLaunchpad}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/launchpad"
                        onClick={() => {
                          handleCloseLaunchpad();
                          handleActiveLaunchpad();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledOther();
                          handleDisabledWin();
                          handleDisabledFinance();
                        }}
                        onMouseOver={handleOpenLaunchpad}
                        onMouseLeave={handleCloseLaunchpad}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Launchpad")}
                      </ButtonLink>
                    </ListItem>
                  </List>
                }
              >
                <MenuButton
                  sx={
                    activeLaunchpad
                      ? { color: "#FEBF32", borderBottom: "1px solid #FEBF32" }
                      : {}
                  }
                >
                  {t("Launch")}
                </MenuButton>
              </MenuTooltip> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LinkExternal
                  href="https://beta.jamonswap.finance"
                  target="_blank"
                >
                  {t("Auto Router")}
                </LinkExternal>
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ButtonLink
                  to="/migrate"
                  onClick={() => {
                    handleDisabledLaunchpad();
                    handleDisabledTrade();
                    handleDisabledEarn();
                    handleDisabledOther();
                    handleDisabledWin();
                    handleDisabledFinance();
                  }}
                  activeStyle={{
                    color: "#FEBF32",
                    borderBottom: "1px solid #FEBF32",
                  }}
                  style={{ gap: "0.5rem" }}
                >
                  {t("Migration")}
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#ffbf00",
                      fontStyle: "italic",
                    }}
                  >
                    {t("NEW")}
                  </div>
                </ButtonLink>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ButtonLinkExternal
                  underline="none"
                  href="https://app.multichain.org/#/router?bridgetoken=0x375d3403f8f5f61139adfc23bebc693d6dc75d62&network=137"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Bridge")}
                </ButtonLinkExternal>
              </div>

              <MenuTooltip
                open={openOther}
                onMouseOver={handleOpenOther}
                onClose={handleCloseOther}
                title={
                  <List>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/info"
                        onClick={() => {
                          handleCloseOther();
                          handleActiveOther();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenOther}
                        onMouseLeave={handleCloseOther}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Info")}
                      </ButtonLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <ButtonLink
                        to="/update"
                        onClick={() => {
                          handleCloseOther();
                          handleActiveOther();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenOther}
                        onMouseLeave={handleCloseOther}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32",
                        }}
                      >
                        {t("Update")}
                      </ButtonLink>
                    </ListItem>
                    {/* <ListItem disablePadding>
                      <ButtonLink
                        to="/donations"
                        onClick={() => {
                          handleCloseOther();
                          handleActiveOther();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenOther}
                        onMouseLeave={handleCloseOther}
                        activeStyle={{
                          color: "#FEBF32",
                          borderBottom: "1px solid #FEBF32"
                        }}
                      >
                        {t("Donations")}
                      </ButtonLink>
                    </ListItem> */}
                    {/* <ListItem disablePadding>
                      <ButtonLinkExternal
                        underline="none"
                        href="https://www.tally.xyz/governance/eip155:137:0xd7f337d597E4A5d891b7882aBcB4C1d3f7D4Cb97"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleCloseOther();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenOther}
                        onMouseLeave={handleCloseOther}
                      >
                        {t("Voting")} <LinkIcon />
                      </ButtonLinkExternal>
                    </ListItem> */}
                    <ListItem disablePadding>
                      <ButtonLinkExternal
                        underline="none"
                        href="https://jamonswap.gitbook.io/docs/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleCloseOther();
                          handleDisabledTrade();
                          handleDisabledEarn();
                          handleDisabledWin();
                          handleDisabledFinance();
                          handleDisabledLaunchpad();
                        }}
                        onMouseOver={handleOpenOther}
                        onMouseLeave={handleCloseOther}
                      >
                        {t("Docs")} <LinkIcon />
                      </ButtonLinkExternal>
                    </ListItem>
                  </List>
                }
              >
                <MenuButton>
                  <AddCircleIcon
                    sx={
                      activeOther
                        ? {
                            color: "#FEBF32",
                            borderBottom: "1px solid #FEBF32",
                          }
                        : { color: "#ABAFC4" }
                    }
                  />
                </MenuButton>
              </MenuTooltip>
            </Box>
            <Box sx={{ flexGrow: 0, marginRight: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ marginRight: "1rem", color: "#FEBF32" }}>
                  <Link
                    href="/swap?outputCurrency=0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2"
                    rel="noopener noreferrer"
                    underline="none"
                    sx={{ color: "#FEBF32" }}
                  >
                    <Grid container>
                      <Grid item xs>
                        <Box
                          component="img"
                          sx={{
                            height: 18,
                            width: 18,
                            mr: 1,
                          }}
                          alt="coin-logo"
                          src={coin}
                        />
                      </Grid>
                      <Grid item mt={0.3} color="#FFFFFF">
                        {jamonPriceUSD?.toFixed(6)}
                      </Grid>
                    </Grid>
                  </Link>
                </Box>
                <Box sx={{ marginRight: "1rem" }}>
                  <MenuTooltip
                    title={
                      <List
                        sx={{
                          justifyContent: "center",
                          width: "12rem",
                          maxHeight: 600,
                          overflow: "auto",
                        }}
                      >
                        {languageList.map((lang) => (
                          <ListItem
                            component="button"
                            onClick={() => {
                              setLanguage(lang);
                            }}
                            sx={{
                              background: "transparent",
                              border: "none",
                              color: "#ABAFC4",
                              cursor: "pointer",
                              justifyContent: "center",
                              fontSize: "0.875rem",
                            }}
                          >
                            {lang.language}
                          </ListItem>
                        ))}
                      </List>
                    }
                  >
                    <LanguageIcon
                      sx={{ color: "#ABAFC4", cursor: "pointer" }}
                    />
                  </MenuTooltip>
                </Box>
                <Box sx={{ marginRight: "1rem" }}>
                  <GlobalSettings />
                </Box>
                {!account ? (
                  <ConnectButton
                    onClick={onPresentConnectModal}
                    sx={{ p: 0, mr: 2 }}
                  >
                    {t("Connect Wallet")}
                  </ConnectButton>
                ) : (
                  <Box>
                    <UserMenu />
                  </Box>
                )}
              </Grid>
            </Box>
          </Toolbar>
        </Container>
        <Box
          sx={{
            borderTop: "1px solid #B34A56",
            background: Colors.BACKGROUND1,
          }}
        >
          <TokenUpdater />
          <TopMoversTokens />
        </Box>
      </NavBar>
    </>
  );
};

export default NavMenu;
