import React, { useState } from "react";
import {
  Modal,
  Text,
  Button,
  Heading,
  Flex,
  AutoRenewIcon,
  ButtonMenu,
  ButtonMenuItem,
  HelpIcon,
  useTooltip
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import useTheme from "hooks/useTheme";
import useToast from "hooks/useToast";
import { Token } from "@pancakeswap/sdk";
import { formatNumber } from "utils/formatBalance";
import { useWeb3React } from "@web3-react/core";
import { serializeTokens } from "config/constants/tokens";
import { useERC20, useJamonVaultContract } from "hooks/useContract";
import BigNumber from "bignumber.js";
import useHarvestPool from "../../../hooks/useHarvestPool";
import useStakePool from "../../../hooks/useStakePool";

const serializedTokens = serializeTokens();

interface CollectModalProps {
  formattedBalance: string;
  fullBalance: string;
  earningToken: Token;
  earningsDollarValue: number;
  sousId: number;
  isBnbPool: boolean;
  isCompoundPool?: boolean;
  onDismiss?: () => void;
}

const CollectModal: React.FC<CollectModalProps> = ({
  formattedBalance,
  fullBalance,
  earningToken,
  earningsDollarValue,
  sousId,
  isBnbPool,
  isCompoundPool = false,
  onDismiss
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { account } = useWeb3React();
  const { toastSuccess, toastError } = useToast();
  const { onReward } = useHarvestPool(sousId, isBnbPool);
  const { onStake } = useStakePool(sousId, isBnbPool);
  const [pendingTx, setPendingTx] = useState(false);
  const [shouldCompound, setShouldCompound] = useState(isCompoundPool);
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <>
      <Text mb="12px" color="primary">
        {t("Compound: collect and restake JAMON into pool.")}
      </Text>
      <Text color="primary">
        {t("Harvest: collect JSTLP and send to wallet")}
      </Text>
    </>,
    { placement: "bottom-end", tooltipOffset: [20, 10] }
  );
  const contract = useJamonVaultContract();
  const raisingTokenContract = useERC20(serializedTokens.jamonV2.address);

  const handleHarvestConfirm = async () => {
    setPendingTx(true);
    // harvesting
    try {
      const response = await raisingTokenContract.allowance(
        account,
        contract.address
      );
      const currentAllowance = new BigNumber(response.toString());
      if (currentAllowance.gt(0)) {
        const disable = await raisingTokenContract.approve(
          contract.address,
          "0"
        );
        await disable.wait();
      }
      await onReward();
      toastSuccess(
        `${t("Harvested")}!`,
        t("Your %symbol% earnings have been sent to your wallet!", {
          symbol: earningToken.symbol
        })
      );
      setPendingTx(false);
      onDismiss();
    } catch (e) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(e);
      setPendingTx(false);
    }
  };

  return (
    <Modal
      title={`${earningToken.symbol} ${t("Harvest")}`}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Text>{t("Harvesting")}:</Text>
        <Flex flexDirection="column">
          <Text fontFamily="Poppins" fontSize="1rem" bold color="primary">
            {formattedBalance} {earningToken.symbol}
          </Text>
          {earningsDollarValue > 0 && (
            <Text fontSize="12px" color="textSubtle">{`~${formatNumber(
              earningsDollarValue
            )} USD`}</Text>
          )}
        </Flex>
      </Flex>

      <Button
        mt="8px"
        onClick={handleHarvestConfirm}
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
      >
        {pendingTx ? t("Confirming") : t("Confirm")}
      </Button>
      <Button variant="secondary" onClick={onDismiss} pb="0px" mt="0.5rem">
        {t("Close")}
      </Button>
    </Modal>
  );
};

export default CollectModal;
