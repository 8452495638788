const FontsConfig = {

  // HEADERS

  H1: "6rem",
  H2: "3.75rem",
  H3: "3rem",
  H4: "2rem",
  H5: "1.5rem",
  H6: "1.25rem",

  // TITLES

  TITLE: "1.125rem",
  SUBTITLE: "1rem",

  // TEXT

  TEXT1: "0.875rem",
  TEXT2: "1rem",
  TEXTCARD: "1.2rem"
  
  
};

export default FontsConfig;
