import { ChainId } from "@pancakeswap/sdk";
import BigNumber from "bignumber.js/bignumber";
import { BIG_TEN } from "utils/bigNumber";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
});

export const BSC_BLOCK_TIME = 2;

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: "https://polygonscan.com",
  [ChainId.TESTNET]: "https://mumbai.polygonscan.com"
};

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 28;
export const CAKE_PER_BLOCKV2 = 3.8;
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365; // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR;
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2 * BLOCKS_PER_YEAR;
export const BASE_URL = "https://jamonswap.finance";
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`;
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET];
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const DEFAULT_GAS_LIMIT = 600000;
export const AUCTION_BIDDERS_TO_FETCH = 500;
export const RECLAIM_AUCTIONS_TO_FETCH = 500;
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500;
export const IPFS_GATEWAY = "https://ipfs.io/ipfs";
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000;

// JAMON_PER_BLOCK details
// 40 JAMON is minted per block
// 20 JAMON per block is sent to Burn pool (A farm just for burning cake)
// 10 JAMON per block goes to JAMON syrup pool
// 9 JAMON per block goes to Yield farms and lottery
// JAMON_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// JAMON/Block in src/views/Home/components/CakeDataRow.tsx = 19 (40 - Amount sent to burn pool)
/* export const JAMON_PER_BLOCK = new BigNumber(28)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const JAMON_PER_YEAR = JAMON_PER_BLOCK.times(BLOCKS_PER_YEAR)
export const BASE_URL = 'https://jamonswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_URL}/pool`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 400000
export const DEFAULT_GAS_PRICE = 50
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs' */
