import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { ConversionState, AppThunk } from 'state/types'
import {
  fetchConversorAllowance
} from './fetchConversorUser'

const initialState: ConversionState = {
  data: {
    CompletedLP: false,
    endTime: 0,
    TotalOldMaticLP: null,
    TotalNewMaticLP: null,
    TotalOldUsdcLP: null,
    TotalNewUsdcLP: null,
    loading: true
  },
  userDataLoaded: false
};

// Thunks

export const updateConversorUserAllowance =
  (id: number, account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchConversorAllowance(account)
    dispatch(updateConversorUserData({ id, field: 'allowance', value: allowances[id] }))
  }


export const UpdateSlice = createSlice({
  name: 'Conversor',
  initialState,
  reducers: {
   /*  setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    }, */
    updateConversorUserData: (state, action) => {
      const { field, value, id } = action.payload
        state.data = { ...state.data, [field]: value } }
      
    },
  /* extraReducers: (builder) => {
    // Vault public data that updates frequently
    builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<CakeVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<VaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<VaultUser>) => {
      const userData = action.payload
      userData.isLoading = false
      state.cakeVault = { ...state.cakeVault, userData }
    })
  }, */
})

// Actions
export const { /* setPoolsPublicData, setPoolsUserData, */ updateConversorUserData } = UpdateSlice.actions

export default UpdateSlice.reducer
