import React from "react";
import { Trade, TradeType } from "@pancakeswap/sdk";
import { Text } from "@pancakeswap/uikit";
import { Field } from "state/swap/actions";
import { useTranslation } from "contexts/Localization";
import { useUserSlippageTolerance } from "state/user/hooks";
import BigNumber from "bignumber.js";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown
} from "utils/prices";
import { AutoColumn } from "components/Layout/Column";
import QuestionHelper from "components/QuestionHelper";
import { RowBetween, RowFixed } from "components/Layout/Row";
import FormattedPriceImpact from "./FormattedPriceImpact";
import SwapRoute from "./SwapRoute";

function TradeSummary({
  trade,
  targetPrice,
  allowedSlippage
}: {
  trade: Trade;
  targetPrice: string;
  allowedSlippage: number;
}) {
  const { t } = useTranslation();
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(trade);
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage
  );
  const tPrice: number = +targetPrice;
  const minReceived = new BigNumber(trade.inputAmount.toSignificant()).times(
    tPrice
  );

  return (
    <AutoColumn style={{ padding: "0 16px" }}>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t("Slippage Tolerance")}
          </Text>
        </RowFixed>
        <Text bold color="primary">
          {allowedSlippage / 100}%
        </Text>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t("Price Impact")}
          </Text>
          <QuestionHelper
            text={t(
              "The difference between the market price and estimated price due to trade size."
            )}
            ml="4px"
            placement="top-start"
          />
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <Text fontSize="14px" color="textSubtle">
            {t("Liquidity Provider Fee")}
          </Text>
        </RowFixed>
        <Text fontSize="14px" color="primary">
          {realizedLPFee
            ? `${realizedLPFee.toSignificant(4)} ${
                trade.inputAmount.currency.symbol
              }`
            : "-"}
        </Text>
      </RowBetween>
    </AutoColumn>
  );
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade;
  targetPrice?: string;
}

export function AdvancedSwapDetails({
  trade,
  targetPrice
}: AdvancedSwapDetailsProps) {
  const { t } = useTranslation();
  const [allowedSlippage] = useUserSlippageTolerance();

  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <AutoColumn gap="0px">
      {trade && (
        <>
          <TradeSummary
            trade={trade}
            targetPrice={targetPrice}
            allowedSlippage={allowedSlippage}
          />
          {showRoute && (
            <>
              <RowBetween style={{ padding: "0 16px" }}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Text fontSize="14px" color="textSubtle">
                    {t("Route")}
                  </Text>
                  <QuestionHelper
                    text={t(
                      "Routing through these tokens resulted in the best price for your trade."
                    )}
                    ml="4px"
                    placement="top-start"
                  />
                </span>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
        </>
      )}
    </AutoColumn>
  );
}
