import React, { useMemo, useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { formatDistanceToNowStrict } from "date-fns";

import styled from "styled-components";
import {
  Modal,
  Text,
  Flex,
  Button,
  LinkExternal,
  ArrowForwardIcon,
  AutoRenewIcon,
  BalanceInput
} from "@pancakeswap/uikit";
import { getBscScanLink } from "utils";
import truncateHash from "utils/truncateHash";
import { useCurrency } from "hooks/Tokens";
import { getBalanceNumber, getBalanceAmount } from "utils/formatBalance";
import { useERC20, useJamonLimitContract } from "hooks/useContract";
import useTheme from "hooks/useTheme";
import useToast from "hooks/useToast";
import { useTranslation } from "contexts/Localization";
import ConnectWalletButton from "components/ConnectWalletButton";
import { ToastDescriptionWithTx } from "components/Toast";
import Balance from "components/Balance";
import { serializeTokens } from "config/constants/tokens";
import useTokenBalance from "hooks/useTokenBalance";
import { getJamonLimitAddress } from "utils/addressHelpers";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";
import useApprove from "../hooks/useApprove";
import {
  PublicLimitOrderData,
  WalletLimitOrderData,
  EnableStatus
} from "../types";

const serializedTokens = serializeTokens();

interface DetailsModalProps {
  transactionHash: string;
  orderId: string;
  targetPrice: BigNumber;
  executionPrice: BigNumber;
  amountIn: BigNumber;
  tokenIn: string;
  tokenOut: string;
  deadline: number;
  status: number;
  cancelOrder: (orderId: string) => void;
  onDismiss?: () => void;
}

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  height: 1px;
  margin: 16px auto;
  width: 100%;
`;

const OrderDetailsModal: React.FC<DetailsModalProps> = ({
  onDismiss,
  transactionHash,
  orderId,
  targetPrice,
  executionPrice,
  amountIn,
  tokenIn,
  tokenOut,
  deadline,
  status,
  cancelOrder
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { toastError, toastSuccess } = useToast();
  const [pendingTx, setPendingTx] = useState(false);
  const gasPrice = BIG_TEN.times(BIG_TEN.pow(BIG_NINE)).toString();

  const contract = useJamonLimitContract();

  const currencyIn = useCurrency(tokenIn);
  const currencyOut = useCurrency(tokenOut);
  let statusLabel: string;
  let canCancel: boolean;

  switch (status) {
    case 1:
      statusLabel = "Filled";
      break;
    case 2:
      statusLabel = "Expired";
      break;
    case 3:
      statusLabel = "Cancelled";
      break;
    case 4:
      statusLabel = "Failed";
      break;
    default:
      statusLabel = "Open";
      canCancel = true;
      break;
  }


  return (
    <Modal
      title={t("Order Details")}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
      mt="12rem"
    >
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Order ID")}: </Text>
        <Flex flexDirection="column">
          <LinkExternal href={getBscScanLink(transactionHash, "transaction")}>
            <Text color="primary">{truncateHash(orderId)}</Text>
          </LinkExternal>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Amount")}: </Text>
        <Flex flexDirection="column">
          <Text color="primary">
            {getBalanceNumber(amountIn, currencyIn?.decimals)}{" "}
            {currencyIn?.symbol}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Swap")}: </Text>
        <Flex flexDirection="column">
          <Text color="primary">
            {currencyIn?.symbol} <ArrowForwardIcon marginY={-1} />{" "}
            {currencyOut?.symbol}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Target Price")}: </Text>
        <Flex flexDirection="column">
          <Text color="primary">
            {getBalanceNumber(targetPrice, currencyOut?.decimals).toFixed(6)}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Execution Price")}: </Text>
        <Flex flexDirection="column">
          <Text color="primary">{executionPrice
            ? getBalanceNumber(executionPrice, currencyOut?.decimals).toFixed(6)
            : " - "}</Text>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Deadline")}: </Text>
        <Flex flexDirection="column">
        {canCancel ?<Text color="primary">
            {formatDistanceToNowStrict(
              parseInt(deadline.toString(), 10) * 1000
            )} </Text> : <Text color="primary"> - </Text>}
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt="2em">
        <Text>{t("Status")}: </Text>
        <Flex flexDirection="column">
          <Text color="primary">{t(statusLabel)}</Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex justifyContent="center" alignItems="center" mt={10}>
        {canCancel ? <Button onClick={() => cancelOrder(orderId)}>{t("Remove order")}</Button> : " "}
      </Flex>
    </Modal>
  );
};

export default OrderDetailsModal;
