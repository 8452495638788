import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Modal,
  Text,
  Flex,
  Image,
  Button,
  Slider,
  BalanceInput,
  AutoRenewIcon,
  Link,
  CalculateIcon,
  IconButton,
  Skeleton,
  LinkExternal
} from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import useTheme from "hooks/useTheme";
import useToast from "hooks/useToast";
import BigNumber from "bignumber.js";
import RoiCalculatorModal from "components/RoiCalculatorModal";
import {
  getFullDisplayBalance,
  formatNumber,
  getDecimalAmount
} from "utils/formatBalance";
import { DeserializedPool } from "state/types";
import { EnableStatus } from "views/Pools/V2/components/types";
import { getInterestBreakdown } from "utils/compoundApyHelpers";
import { useERC20 } from "hooks/useContract";
import { serializeTokens } from "config/constants/tokens";
import { getJamonVaultAddress } from "utils/addressHelpers";
import { useStakeDisable } from "views/Pools/V2/hooks/useStakeApprove";
import { useWeb3React } from "@web3-react/core";
import PercentageButton from "./PercentageButton";
import useStakePool from "../../../hooks/useStakePool";
import useUnstakePool from "../../../hooks/useUnstakePool";

const serializedTokens = serializeTokens();

interface StakeModalProps {
  isBnbPool: boolean;
  pool: DeserializedPool;
  stakingTokenBalance: BigNumber;
  stakingTokenPrice: number;
  isRemovingStake?: boolean;
  onDismiss?: () => void;
}

const StyledLink = styled(Link)`
  width: 100%;
`;

const AnnualRoiContainer = styled(Flex)`
  cursor: pointer;
`;

const AnnualRoiDisplay = styled(Text)`
  width: 72px;
  max-width: 72px;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
`;

const StakeModal: React.FC<StakeModalProps> = ({
  isBnbPool,
  pool,
  stakingTokenBalance,
  stakingTokenPrice,
  isRemovingStake = false,
  onDismiss
}) => {
  const {
    sousId,
    stakingToken,
    earningTokenPrice,
    apr,
    userData,
    stakingLimit,
    earningToken
  } = pool;
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { theme } = useTheme();
  const { onStake } = useStakePool(sousId, isBnbPool);
  const { onUnstake } = useUnstakePool(sousId, pool.enableEmergencyWithdraw);
  const { toastSuccess, toastError } = useToast();
  const [pendingTx, setPendingTx] = useState(false);
  const [stakeAmount, setStakeAmount] = useState("");
  const [hasReachedStakeLimit, setHasReachedStakedLimit] = useState(false);
  const [percent, setPercent] = useState(0);
  const [showRoiCalculator, setShowRoiCalculator] = useState(false);
  const getCalculatedStakingLimit = () => {
    if (isRemovingStake) {
      return userData.stakedBalance;
    }
    return stakingLimit.gt(0) && stakingTokenBalance.gt(stakingLimit)
      ? stakingLimit
      : stakingTokenBalance;
  };
  const fullDecimalStakeAmount = getDecimalAmount(
    new BigNumber(stakeAmount),
    stakingToken.decimals
  );
  const userNotEnoughToken = isRemovingStake
    ? userData.stakedBalance.lt(fullDecimalStakeAmount)
    : userData.stakingTokenBalance.lt(fullDecimalStakeAmount);

  const usdValueStaked = new BigNumber(stakeAmount).times(stakingTokenPrice);
  const formattedUsdValueStaked =
    !usdValueStaked.isNaN() && formatNumber(usdValueStaked.toNumber());

  const interestBreakdown = getInterestBreakdown({
    principalInUSD: !usdValueStaked.isNaN() ? usdValueStaked.toNumber() : 0,
    apr,
    earningTokenPrice
  });
  const annualRoi = interestBreakdown[3] * pool.earningTokenPrice;
  const formattedAnnualRoi = formatNumber(
    annualRoi,
    annualRoi > 10000 ? 0 : 2,
    annualRoi > 10000 ? 0 : 2
  );

  const getTokenLink = stakingToken.address
    ? `/swap?outputCurrency=${stakingToken.address}`
    : "/swap";

  useEffect(() => {
    if (stakingLimit.gt(0) && !isRemovingStake) {
      setHasReachedStakedLimit(
        fullDecimalStakeAmount.plus(userData.stakedBalance).gt(stakingLimit)
      );
    }
  }, [
    stakeAmount,
    stakingLimit,
    userData,
    stakingToken,
    isRemovingStake,
    setHasReachedStakedLimit,
    fullDecimalStakeAmount
  ]);

  const handleStakeInputChange = (input: string) => {
    if (input) {
      const convertedInput = getDecimalAmount(
        new BigNumber(input),
        stakingToken.decimals
      );
      const percentage = Math.floor(
        convertedInput
          .dividedBy(getCalculatedStakingLimit())
          .multipliedBy(100)
          .toNumber()
      );
      setPercent(Math.min(percentage, 100));
    } else {
      setPercent(0);
    }
    setStakeAmount(input);
  };

  const handleChangePercent = (sliderPercent: number) => {
    if (sliderPercent > 0) {
      const percentageOfStakingMax = getCalculatedStakingLimit()
        .dividedBy(100)
        .multipliedBy(sliderPercent);
      const amountToStake = getFullDisplayBalance(
        percentageOfStakingMax,
        stakingToken.decimals,
        stakingToken.decimals
      );
      setStakeAmount(amountToStake);
    } else {
      setStakeAmount("");
    }
    setPercent(sliderPercent);
  };

  const handleConfirmClick = async () => {
    setPendingTx(true);
    try {
      if (isRemovingStake) {
        // unstaking
        await onUnstake(stakeAmount, stakingToken.decimals);
        toastSuccess(
          `${t("Unstaked")}!`,
          t("Your %symbol% earnings have also been harvested to your wallet!", {
            symbol: earningToken.symbol
          })
        );
      } else {
        // staking
        await onStake(stakeAmount, stakingToken.decimals);
        toastSuccess(
          `${t("Staked")}!`,
          t("Your %symbol% funds have been staked in the pool!", {
            symbol: stakingToken.symbol
          })
        );
      }
      setPendingTx(false);
      onDismiss();
    } catch (e) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setPendingTx(false);
    }
  };

  const [requireDisable, setRequireDisable] = useState(EnableStatus.DISABLED);

  const JSContract = useERC20(serializedTokens.jamonV2.address);
  const vaultAddress = getJamonVaultAddress();

  const onDisable = useStakeDisable(
    serializedTokens.jamonV2.address,
    vaultAddress
  );

  useEffect(() => {
    const checkAllowance = async () => {
      try {
        const response = await JSContract.allowance(account, vaultAddress);
        const currentAllowance = new BigNumber(response.toString());
        setRequireDisable(
          currentAllowance.gt(0) ? EnableStatus.ENABLED : EnableStatus.DISABLED
        );
      } catch (error) {
        setRequireDisable(EnableStatus.DISABLED);
      }
    };

    if (account) {
      checkAllowance();
    }
  }, [account, vaultAddress, JSContract]);

  const handleDisable = async () => {
    try {
      setRequireDisable(EnableStatus.IS_ENABLING);

      await onDisable();

      setRequireDisable(EnableStatus.DISABLED);
    } catch (error) {
      setRequireDisable(EnableStatus.DISABLED);
    }
  };

  if (showRoiCalculator) {
    return (
      <RoiCalculatorModal
        earningTokenPrice={earningTokenPrice}
        stakingTokenPrice={stakingTokenPrice}
        apr={apr}
        linkLabel={t("Get %symbol%", { symbol: stakingToken.symbol })}
        linkHref={getTokenLink}
        stakingTokenBalance={userData.stakedBalance.plus(stakingTokenBalance)}
        stakingTokenSymbol={stakingToken.symbol}
        earningTokenSymbol={earningToken.symbol}
        onBack={() => setShowRoiCalculator(false)}
        initialValue={stakeAmount}
      />
    );
  }

  return (
    <Modal
      minWidth="346px"
      title={isRemovingStake ? t("Unstake") : t("Stake Jamon")}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      {stakingLimit.gt(0) && !isRemovingStake && (
        <Text
          color="secondary"
          bold
          mb="24px"
          style={{ textAlign: "center" }}
          fontSize="16px"
        >
          {t("Max stake for this pool: %amount% %token%", {
            amount: getFullDisplayBalance(
              stakingLimit,
              stakingToken.decimals,
              0
            ),
            token: stakingToken.symbol
          })}
        </Text>
      )}
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>{isRemovingStake ? t("Unstake") : t("Stake")}:</Text>
        <Flex alignItems="center" minWidth="80px">
          <Image
            src="/images/tokens/0x2594C5B25EdAb3eBe937e27650c02922D9A62BF2.png"
            width={24}
            height={24}
            alt="Jamon Token"
          />
          <Text ml="4px" bold color="primary">
            {stakingToken.symbol}
          </Text>
        </Flex>
      </Flex>
      <BalanceInput
        value={stakeAmount}
        onUserInput={handleStakeInputChange}
        isWarning={hasReachedStakeLimit || userNotEnoughToken}
        decimals={stakingToken.decimals}
      />
      {hasReachedStakeLimit && (
        <Text
          color="failure"
          fontSize="12px"
          style={{ textAlign: "right" }}
          mt="4px"
        >
          {t("Maximum total stake: %amount% %token%", {
            amount: getFullDisplayBalance(
              new BigNumber(stakingLimit),
              stakingToken.decimals,
              0
            ),
            token: stakingToken.symbol
          })}
        </Text>
      )}
      {userNotEnoughToken && (
        <Text
          color="failure"
          fontSize="12px"
          style={{ textAlign: "right" }}
          mt="4px"
        >
          {t("Insufficient %symbol% balance", {
            symbol: stakingToken.symbol
          })}
        </Text>
      )}
      <Flex justifyContent="end" mt="0.5rem">
        <Text color="textSubtle" textAlign="right" fontSize="12px" mr="0.2rem">
          {t("Balance")}:
        </Text>
        <Text color="primary" textAlign="right" fontSize="12px">
          {getFullDisplayBalance(
            getCalculatedStakingLimit(),
            stakingToken.decimals
          )}
        </Text>
      </Flex>
      {/* <Slider
        min={0}
        max={100}
        value={percent}
        onValueChanged={handleChangePercent}
        name="stake"
        valueLabel={`${percent}%`}
        step={1}
      /> */}
      <Flex alignItems="center" justifyContent="right" mt="8px">
        <PercentageButton onClick={() => handleChangePercent(100)}>
          {t("Max")}
        </PercentageButton>
      </Flex>
      {!isRemovingStake && (
        <Flex mt="24px" alignItems="center" justifyContent="space-between">
          <Text mr="8px" color="textSubtle">
            {t("Annual ROI at current rates")}:
          </Text>
          {Number.isFinite(annualRoi) ? (
            <AnnualRoiContainer
              alignItems="center"
              onClick={() => {
                setShowRoiCalculator(true);
              }}
            >
              <AnnualRoiDisplay>${formattedAnnualRoi}</AnnualRoiDisplay>
              <IconButton variant="text" scale="sm">
                <CalculateIcon color="textSubtle" width="18px" />
              </IconButton>
            </AnnualRoiContainer>
          ) : (
            <Skeleton width={60} />
          )}
        </Flex>
      )}
      {requireDisable !== EnableStatus.DISABLED && account && (
          <Button
            width="100%"
            mt="24px"
            onClick={handleDisable}
            isLoading={requireDisable === EnableStatus.IS_ENABLING}
            endIcon={
              requireDisable === EnableStatus.IS_ENABLING ? (
                <AutoRenewIcon spin color="currentColor" />
              ) : null
            }
          >
            {isRemovingStake ? t("Disable") : t("Enable")}
          </Button>
        )} 
      {requireDisable === EnableStatus.DISABLED &&  <Button
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
        onClick={handleConfirmClick}
        disabled={
          !stakeAmount ||
          parseFloat(stakeAmount) === 0 ||
          hasReachedStakeLimit ||
          userNotEnoughToken
        }
        mt="24px"
      >
        {pendingTx ? t("Confirming") : t("Confirm")}
      </Button> }
      {!isRemovingStake && (
        <Flex justifyContent="center">
          <LinkExternal href={getTokenLink} width="100%" mt="1rem">
            {t("Get %symbol%", { symbol: stakingToken.symbol })}
          </LinkExternal>
        </Flex>
      )}
    </Modal>
  );
};

export default StakeModal;
