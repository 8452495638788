import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'JamonSwap',
  description:
    'The most popular AMM on Polygon by user count! Earn JAMON through yield farming or win it in the Lottery, then stake it in Acorn Pools to earn more tokens! Initial Farm Offerings, NFTs, and more, on a platform you can trust.',
  image: 'https://jamonswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('JamonSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('JamonSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('JamonSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('JamonSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('JamonSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('JamonSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('JamonSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('JamonSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('JamonSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('JamonSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('JamonSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('JamonSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('JamonSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('JamonSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('JamonSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('JamonSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('JamonSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('JamonSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('JamonSwap Info & Analytics')}`,
        description: 'View statistics for JamonSwap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('JamonSwap Info & Analytics')}`,
        description: 'View statistics for JamonSwap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('JamonSwap Info & Analytics')}`,
        description: 'View statistics for JamonSwap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('JamonSwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('JamonSwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('JamonSwap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Jamon Squad')} | ${t('JamonSwap')}`,
      }
    default:
      return null
  }
}
