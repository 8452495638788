const  ColorsConfig = {
        TITLE:"#FFFFFF",
        BACKGROUND1:"#320711",
        BACKGROUND2:"#0E0E12",
        BACKGROUND3:"#961726",
        TEXT1: "#ABAFC4",
        TEXT2: "#FFFFFF",
        BORDER1: "#B34A56",
        BLUE1: "#E2F0FF",
        BLUE2: "#C5DFFF",
        BLUE3: "#A9CDFF",
        BLUE4: "#93BDFF",
        BLUE5: "#5F97FF",
        BLUE6: "#517DDB",
        BLUE7: "#385BB7",
        BLUE8: "#233E93",
        GREEN1: "#EEFDE2",
        GREEN2: "#D8FCC5",
        GREEN3: "#BCF6A6",
        GREEN4: "#A0ED8D",
        GREEN5: "#76E268",
        GREEN6: "#50C24C",
        GREEN7: "#34A239",
        GREEN8: "#21832D",
        YELLOW1: "#FEFAE5",
        YELLOW2: "#FEF4C6",
        YELLOW3: "#FEE793",
        YELLOW4: "#FED861",
        YELLOW5: "#FEC62d",
        YELLOW6: "#DAA320",
        YELLOW7: "#B68316",
        YELLOW8: "#93640E",
        RED1: "#FDEDED",
        RED2: "#FBDADC",
        RED3: "#F5A3A7",
        RED4: "#EF6B72",
        RED5: "#EA3943",
        RED6: "#E8212B",
        RED7: "#CB151E",
        RED8: "#941016",
        TURQUOISE1: "#DAFEE9",
        TURQUOISE2: "#B5FDDB",
        TURQUOISE3: "#8FFAD2",
        TURQUOISE4: "#72F6D1",
        TURQUOISE5: "#45F0D1",
        TURQUOISE6: "#32CEC0",
        TURQUOISE7: "#FFFFFF",
        TURQUOISE8: "#167F8B",
        GOLD1: "#FEF7D5",
        GOLD2: "#FEEDAD",
        GOLD3: "#FEE083",
        GOLD4: "#FED365",
        GOLD5: "#FEBF32",
        GOLD6: "#DA9C24",
        GOLD7: "#B67C19",
        GOLD8: "#935E0F",
        GRADIENT1: "linear-gradient(90deg, rgba(172,14,14,1) 0%, rgba(215,18,18,1) 20%, rgba(172,38,9,1) 40%, rgba(209,98,11,1) 60%, rgba(255,179,0,1) 82%, rgba(173,158,11,1) 100%);",
        GRADIENT2: "linear-gradient(90deg, rgba(172,14,14,1) 0%, rgba(215,18,18,1) 15%, rgba(172,38,9,1) 30%, rgba(209,98,11,1) 45%, rgba(255,179,0,1) 60%, rgba(255,179,0,1) 75%, rgba(173,158,11,1) 90%, rgba(255,231,0,1) 100%)",
        GRADIENT3: "linear-gradient(90deg, rgba(189,13,13,1) 0%, rgba(231,137,13,1) 15%, rgba(223,203,4,1) 30%);",
        GRADIENT4: "linear-gradient(90deg, rgba(189,13,13,1) 20%, rgba(231,137,13,1) 40%, rgba(223,203,4,1) 60%);",
        GRADIENT5: "linear-gradient(90deg, rgba(13,0,32,1) 0%, rgba(36,1,97,1) 20%, rgba(50,4,136,1) 40%, rgba(50,4,136,1) 60%, rgba(36,1,97,1) 80%, rgba(13,0,32,1) 100%);",
        GRADIENT6: "linear-gradient(90deg, rgba(190,4,0,1) 30%, rgba(207,112,1,1) 50%, rgba(200,175,2,1) 70%);",
        GRADIENT7: "linear-gradient(90deg, rgba(115,2,1,1) 10%, rgba(175,6,2,1) 25%, rgba(196,107,4,1) 50%, rgba(196,112,6,1) 70%, rgba(199,190,4,1) 100%);",
};

export default ColorsConfig;