import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import {
  Modal,
  ModalBody,
  Text,
  Button,
  BalanceInput,
  Flex,
  LinkExternal,
  Image
} from "@pancakeswap/uikit";
import {
  PublicJShareStakeData,
  WalletJShareStakeData
} from "views/Pools/types";
import { useTranslation } from "contexts/Localization";
import { useJShareVaultContract, useERC20 } from "hooks/useContract";
import { getBalanceAmount } from "utils/formatBalance";
import ApproveConfirmButtons, {
  ButtonArrangement
} from "components/ApproveConfirmButtons";

import useApproveConfirmTransaction from "hooks/useApproveConfirmTransaction";
import { DEFAULT_TOKEN_DECIMAL } from "config";
import { BIG_NINE, BIG_TEN } from "utils/bigNumber";

interface Props {
  publicData: PublicJShareStakeData;
  walletData: WalletJShareStakeData;
  token: string;
  reStake: boolean;
  userCurrencyBalance: BigNumber;
  onSuccess: (amount: BigNumber) => void;
  onDismiss?: () => void;
}

const multiplierValues = [1];

// Default value for transaction setting, tweak based on BSC network congestion.
const gasPrice = BIG_TEN.times(BIG_TEN.pow(BIG_NINE)).toString();

const StakeJSModal: React.FC<Props> = ({
  token,
  userCurrencyBalance,
  onDismiss,
  onSuccess
}) => {
  const contract = useJShareVaultContract();
  const [value, setValue] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const valueWithTokenDecimals = new BigNumber(value).times(
    DEFAULT_TOKEN_DECIMAL
  );
  const raisingTokenContract = useERC20(token);

  const getTokenLink = token ? `/swap?outputCurrency=${token}` : "/swap";

  const handleUserInput = (input: string) => {
    if(!inputDisabled)  {
      setValue(input)
    }
  }

  const {
    isApproving,
    isApproved,
    isConfirmed,
    isConfirming,
    handleApprove,
    handleConfirm
  } = useApproveConfirmTransaction({
    onRequiresApproval: async () => {
      try {
        const response = await raisingTokenContract.allowance(
          account,
          contract.address
        );
        const currentAllowance = new BigNumber(response.toString());
        return currentAllowance.eq(valueWithTokenDecimals);
      } catch (error) {
        return false;
      }
    },
    onApprove: () => {
      setInputDisabled(true);
      return raisingTokenContract.approve(
        contract.address,
        valueWithTokenDecimals.toString()
      );
    },
    onConfirm: () => {
      return contract.stake(valueWithTokenDecimals.toString());
    },
    onSuccess: async () => {
      onSuccess(valueWithTokenDecimals);
      setInputDisabled(false);
      onDismiss();
    },
    onApproveSuccess({ state, receipt }) {
      setApproved(true);
    }
  });

  const [approved, setApproved] = useState(isApproved);

  return (
    <Modal title={t("Stake JSHARE")} onDismiss={onDismiss}>
      <ModalBody maxWidth="320px">
        <Flex justifyContent="space-between" mb="8px">
          <Text>{t("Stake")}:</Text>
          <Flex flexGrow={1} justifyContent="flex-end" minWidth="70px">
            <Image
              src="/images/tokens/0x4521b65BB2AC3CA4885947F0c7DFd42b4fA5B18a.png"
              width={24}
              height={24}
              alt="JShare Token"
            />
            <Text color="primary" bold ml="0.1rem">
              JSHARE
            </Text>
          </Flex>
        </Flex>
        <BalanceInput
          value={value}
          onUserInput={handleUserInput}
          isWarning={valueWithTokenDecimals.isGreaterThan(userCurrencyBalance)}
          decimals={18}
          mb="8px"
        />
        <Flex justifyContent="end" mb="16px">
          <Text
            color="textSubtle"
            textAlign="right"
            fontSize="12px"
            mr="0.2rem"
          >
            {t("Balance")}:
          </Text>
          <Text color="primary" textAlign="right" fontSize="12px">
            {getBalanceAmount(userCurrencyBalance, 18).toString()}
          </Text>
        </Flex>
        <Flex justifyContent="end" mb="16px">
          {multiplierValues.map((multiplierValue, index) => (
            <Button
              key={multiplierValue}
              scale="xs"
              variant="primary"
              onClick={() =>
                setValue(
                  getBalanceAmount(
                    userCurrencyBalance.times(multiplierValue)
                  ).toString()
                )
              }
              mr={index < multiplierValues.length - 1 ? "8px" : 0}
            >
              {multiplierValue * 100}%
            </Button>
          ))}
        </Flex>
        <ApproveConfirmButtons
          buttonArrangement={ButtonArrangement.SEQUENTIAL}
          isApproveDisabled={
            isConfirmed ||
            isConfirming ||
            approved ||
            valueWithTokenDecimals.isNaN() ||
            userCurrencyBalance.eq(0) ||
            valueWithTokenDecimals.eq(0)
          }
          isApproving={isApproving}
          isConfirmDisabled={
            !approved ||
            isConfirmed ||
            valueWithTokenDecimals.isNaN() ||
            userCurrencyBalance.eq(0) ||
            valueWithTokenDecimals.eq(0)
          }
          isConfirming={isConfirming}
          onApprove={handleApprove}
          onConfirm={handleConfirm}
        />
        <Flex justifyContent="center">
          <LinkExternal href={getTokenLink} width="100%" mt="1rem">
            {t("Get")} JSHARE
          </LinkExternal>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default StakeJSModal;
