import { PresaleStatus } from "../types"

export const getStatus = (status: number, state: number): PresaleStatus => {
  if (state === 0) {
    return 'NotExist'
  }
  if (state === 1) {
    if(status === 0) {
      return 'ComingSoon'
    }
    if(status === 1) {
      return 'OnSale'
    }
    if(status === 2) {
      return 'WaitingComplete'
    }
    if(status === 3) {
      return 'Failed'
    }
  }
  if (state === 2) {
    return 'Completed'
  }
  if (state === 3) {
    return 'Failed'
  }

  return 'NotExist'
}

export default null
