import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "contexts/Localization";
import {
    Link,
    Button,
    Grid,
    List,
    ListItem,
    Menu,
    MenuItem
    } from "@mui/material";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Fonts, Colors, Tooltips } from "components/Style";
import { useMatchBreakpoints } from "@pancakeswap/uikit";

function TwitterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5.659 15c6.79 0 10.507-5.766 10.507-10.763 0-.16 0-.32-.01-.49A7.578 7.578 0 0018 1.79c-.663.3-1.376.5-2.127.6a3.824 3.824 0 001.63-2.1c-.713.44-1.503.75-2.352.92A3.6 3.6 0 0012.46 0C10.419 0 8.76 1.699 8.76 3.787c0 .3.039.58.098.86-3.064-.15-5.786-1.669-7.61-3.957A3.858 3.858 0 00.75 2.598c0 1.31.654 2.469 1.64 3.148a3.638 3.638 0 01-1.669-.47v.05c0 1.83 1.278 3.368 2.956 3.708-.312.09-.634.13-.976.13-.234 0-.468-.02-.692-.07.468 1.509 1.834 2.598 3.453 2.628a7.284 7.284 0 01-4.585 1.62c-.293 0-.595-.01-.878-.05A10.206 10.206 0 005.659 15z" />
    </SvgIcon>
  );
}

function TelegramIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10 0C4.478 0 0 4.478 0 9.99 0 15.511 4.478 20 10 20s10-4.488 10-10.01C20 4.477 15.522 0 10 0zm4.925 6.28c-.064.927-1.78 7.856-1.78 7.856s-.107.406-.48.416a.644.644 0 01-.49-.192c-.395-.33-1.29-.97-2.132-1.556a.953.953 0 01-.107.096c-.192.17-.48.416-.789.714a10.7 10.7 0 00-.373.352l-.01.01a2.214 2.214 0 01-.193.171c-.415.341-.458.053-.458-.096l.224-2.441v-.021l.01-.022c.011-.032.033-.043.033-.043s4.36-3.88 4.477-4.296c.01-.021-.021-.042-.074-.021-.288.096-5.31 3.273-5.864 3.625-.032.02-.128.01-.128.01l-2.441-.8s-.288-.117-.192-.383c.021-.053.053-.107.17-.181.544-.384 10-3.785 10-3.785s.267-.085.427-.032c.074.032.117.064.16.17.01.043.021.128.021.224 0 .054-.01.118-.01.224z" />
      </SvgIcon>
  );
}

function RedditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.09 13.412c-.448.447-1.408.607-2.09.607-.682 0-1.642-.16-2.09-.607a.27.27 0 00-.383 0 .27.27 0 000 .383c.714.704 2.068.757 2.473.757.405 0 1.76-.053 2.473-.767a.27.27 0 000-.384.28.28 0 00-.383.01zM8.753 11.034c0-.576-.47-1.045-1.045-1.045-.576 0-1.045.47-1.045 1.045 0 .576.47 1.045 1.045 1.045.576 0 1.045-.47 1.045-1.045z" />
      <path d="M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.467 10-10c-.01-5.522-4.478-10-10-10zm5.8 11.333c.02.138.032.287.032.437 0 2.25-2.612 4.062-5.832 4.062-3.22 0-5.832-1.813-5.832-4.062 0-.15.011-.299.032-.437A1.447 1.447 0 013.337 10 1.463 1.463 0 015.81 8.945c1.013-.736 2.41-1.194 3.966-1.237 0-.021.736-3.486.736-3.486 0-.064.042-.128.096-.16a.263.263 0 01.202-.043l2.42.523c.17-.342.523-.587.928-.587A1.04 1.04 0 0115.203 5a1.04 1.04 0 01-1.045 1.045 1.034 1.034 0 01-1.034-.992l-2.175-.469-.661 3.124c1.535.053 2.91.522 3.902 1.237a1.454 1.454 0 012.473 1.044 1.48 1.48 0 01-.863 1.344z" />
      <path d="M12.292 10c-.576 0-1.045.47-1.045 1.045s.47 1.045 1.045 1.045c.576 0 1.045-.47 1.045-1.045A1.06 1.06 0 0012.292 10z" />
    </SvgIcon>
  );
}

function InstagramIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9 1.625c2.407 0 2.685.01 3.641.052.874.04 1.358.185 1.666.308.422.165.72.36 1.04.669.318.319.514.617.668 1.039.123.318.267.792.308 1.666.042.946.052 1.234.052 3.641s-.01 2.685-.052 3.641c-.04.874-.185 1.358-.308 1.666-.165.422-.36.72-.669 1.04a2.66 2.66 0 01-1.039.668c-.318.123-.792.267-1.666.308-.946.042-1.234.052-3.641.052s-2.685-.01-3.641-.052c-.874-.04-1.358-.185-1.666-.308a2.911 2.911 0 01-1.04-.669 2.659 2.659 0 01-.668-1.039c-.123-.318-.267-.792-.308-1.666-.042-.946-.052-1.234-.052-3.641s.01-2.685.052-3.641c.04-.874.185-1.358.308-1.666.165-.422.36-.72.669-1.04a2.658 2.658 0 011.039-.668c.318-.123.792-.267 1.666-.308.956-.042 1.234-.052 3.641-.052zM9 0C6.552 0 6.254.01 5.287.051c-.957.052-1.615.196-2.18.422a4.311 4.311 0 00-1.595 1.039A4.311 4.311 0 00.473 3.106c-.226.566-.37 1.224-.422 2.18C.011 6.255 0 6.553 0 9s.01 2.746.051 3.713c.042.957.196 1.615.422 2.18.226.597.535 1.091 1.039 1.595.504.504.998.813 1.594 1.039.576.226 1.224.37 2.18.422.957.04 1.266.051 3.714.051s2.746-.01 3.713-.051c.957-.042 1.615-.196 2.18-.422a4.311 4.311 0 001.595-1.039 4.311 4.311 0 001.039-1.594c.226-.576.37-1.224.422-2.18.04-.957.051-1.266.051-3.714s-.01-2.746-.051-3.713c-.042-.957-.196-1.615-.422-2.18a4.312 4.312 0 00-1.039-1.595A4.311 4.311 0 0014.894.473c-.576-.226-1.224-.37-2.18-.422C11.745.011 11.447 0 9 0z" />
      <path d="M9 4.382a4.618 4.618 0 100 9.236 4.618 4.618 0 000-9.236zm0 7.621A3.007 3.007 0 015.997 9 3.007 3.007 0 019 5.997 3.007 3.007 0 0112.003 9 3.007 3.007 0 019 12.003zM13.803 5.277a1.08 1.08 0 100-2.16 1.08 1.08 0 000 2.16z" />
    </SvgIcon>
  );
}

function GithubIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9.97 0C4.464 0 0 4.465 0 9.97c0 4.403 2.875 8.134 6.789 9.48.49.06.673-.245.673-.49v-1.712c-2.753.611-3.364-1.346-3.364-1.346-.428-1.162-1.101-1.468-1.101-1.468-.917-.611.061-.611.061-.611.979.06 1.53 1.04 1.53 1.04.917 1.528 2.323 1.1 2.874.856a2.13 2.13 0 01.611-1.346c-2.201-.245-4.526-1.1-4.526-4.954 0-1.101.367-1.957 1.04-2.691-.061-.184-.428-1.223.122-2.569 0 0 .857-.245 2.753 1.04.795-.245 1.651-.306 2.507-.306.857 0 1.713.122 2.508.306 1.896-1.285 2.752-1.04 2.752-1.04.55 1.346.184 2.385.123 2.63a3.956 3.956 0 011.04 2.691c0 3.853-2.325 4.648-4.527 4.893.367.306.673.918.673 1.835v2.752c0 .245.184.55.673.49A9.98 9.98 0 0020 9.97C19.939 4.464 15.474 0 9.97 0z" />
    </SvgIcon>
  );
}


const ButtonLink = styled(Link)`
  display: flex;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${Colors.TEXT2};
  font-size: ${Fonts.TEXT1};
  font-weight: normal;
  font-family: poppins;
`;

const MenuTooltip = Tooltips;

export default function SocialIcons() {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useMatchBreakpoints();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <Grid container>
            <Grid item xs={isMobile || isTablet ? 1.5 :0.2} mt={1} ml={isMobile || isTablet ? 2 : 0}>
                <Link href="https://twitter.com/jamonswap" rel="noopener noreferrer" target="_blank"><TwitterIcon sx={{color: Colors.TEXT2}}/></Link>
            </Grid>
            <Grid item xs={isMobile || isTablet ? 3 : 0.55}>
            {isMobile || isTablet ? 
              <>
              <Button 
                sx={{cursor:"cell"}}
                id="telegram-button"
                aria-controls={open ? 'telegram-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <TelegramIcon sx={{color: Colors.TEXT2}}/>
              </Button>
              <Menu
                  id="telegram-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'telegram-button',
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#17171A",
                      justifyContent:"center",
                    }
                  }}
                >
                <MenuItem onClick={handleClose} ><ButtonLink href="https://t.me/+4TWEeg5uQX02YTlk" rel="noopener noreferrer" target="_blank" underline="none">{t("English")}</ButtonLink></MenuItem>
                <MenuItem onClick={handleClose}><ButtonLink href="https://t.me/jamonswap" rel="noopener noreferrer" target="_blank" underline="none">{t("Spanish")}</ButtonLink></MenuItem>
              </Menu>
              </>
                    : 
              <MenuTooltip  title={
                <List>
                    <ListItem>
                        <ButtonLink href="https://t.me/+4TWEeg5uQX02YTlk" rel="noopener noreferrer" target="_blank" underline="none">{t("English")}</ButtonLink>
                    </ListItem>
                    <ListItem>
                        <ButtonLink href="https://t.me/jamonswap" rel="noopener noreferrer" target="_blank" underline="none">{t("Spanish")}</ButtonLink>
                    </ListItem>
                </List>
              }>
                <Button sx={{cursor:"cell"}}>
                    <TelegramIcon sx={{color: Colors.TEXT2}}/>
                </Button>
              </MenuTooltip>
            }
            
            </Grid>
            <Grid item xs={isMobile || isTablet ? 2.5 : 0.4} mt={isMobile || isTablet ? 0.8 : 0.6}>
                <Link href="https://www.reddit.com/r/JamonSwap/" rel="noopener noreferrer" target="_blank"><RedditIcon sx={{color: Colors.TEXT2}}/></Link>
            </Grid>
            <Grid item xs={isMobile || isTablet ? 2.5 : 0.38} mt={isMobile || isTablet ? 0.9 : 0.7}>
                <Link href="https://www.instagram.com/jamonswap/" rel="noopener noreferrer" target="_blank"><InstagramIcon sx={{color: Colors.TEXT2}}/></Link>
            </Grid>
            <Grid item mt={isMobile || isTablet ? 0.8 : 0.6}>
                <Link href="https://github.com/jamonswap/" rel="noopener noreferrer" target="_blank"><GithubIcon sx={{color: Colors.TEXT2}}/></Link>
            </Grid>
        </Grid>
    );
  }