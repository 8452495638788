import { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import useRefresh from "hooks/useRefresh";
import multicall from "utils/multicall";
import LaunchSettingsAbi from "config/abi/LaunchSettings.json";
import { BIG_ZERO } from "utils/bigNumber";
import { getLaunchSettingsAddress } from "utils/addressHelpers";

const useGetPublicLaunchSettingsData = () => {
  const address = getLaunchSettingsAddress();
  const { slowRefresh } = useRefresh();

  const [state, setState] = useState({
    slotPrice: BIG_ZERO,
    launchPrice: BIG_ZERO
  });

  const fetchLaunchSettingsData = useCallback(async () => {
    const LaunchSettingsCalls = [
      {
        address,
        name: "slotPrice"
      },
      {
        address,
        name: "feePrice"
      }
    ];

    const [slotPrice, feePrice] = await multicall(
      LaunchSettingsAbi,
      LaunchSettingsCalls
    );

    setState((prev) => ({
      ...prev,
      slotPrice: new BigNumber(slotPrice[0].toString()),
      launchPrice: new BigNumber(feePrice[0].toString())
    }));
  }, [address]);

  useEffect(() => {
    fetchLaunchSettingsData();
  }, [fetchLaunchSettingsData, slowRefresh]);

  return { ...state, fetchLaunchSettingsData };
};

export default useGetPublicLaunchSettingsData;
