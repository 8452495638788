import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Box, Divider } from "@mui/material";
import {
  useMatchBreakpoints,
  Button,
  Text,
  Flex,
  ButtonMenu,
  ButtonMenuItem
} from "@pancakeswap/uikit";
import { StyledPage, AppBody, Title } from "views/Style";
import { useTranslation } from "contexts/Localization";
import useTokenBalance from "hooks/useTokenBalance";
import { serializeTokens } from "config/constants/tokens";
import { BIG_ZERO } from "utils/bigNumber";
import { getFullDisplayBalance } from "utils/formatBalance";
import { getMigratorAddress } from "utils/addressHelpers";
import useTokenAllowance from "hooks/useTokenAllowance";
import { useERC20, useMigratorContract } from "hooks/useContract";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { DEFAULT_TOKEN_DECIMAL } from "config";
import useToast from "hooks/useToast";
import { RowBetween } from "../../components/Layout/Row";
import { Input as NumericalInput } from "../../components/CurrencyInputPanel/NumericalInput";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import { Colors } from "../../components/Style";

const serializedTokens = serializeTokens();

enum EnableStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
  IS_ENABLING = "is_enabling"
}

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) =>
    selected ? "0.75rem 0.5rem 0.75rem 1rem" : "0.75rem 0.75rem 0.75rem 1rem"};
`;

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: "20px";
  z-index: 1;
`;

const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`;

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
`;

const DataContent = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: #191326;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  margin-top: 1rem;
`;

const Migrate = () => {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();
  const { toastSuccess, toastError } = useToast();

  const migratorAddress = getMigratorAddress();
  const migratorContract = useMigratorContract();
  const [enabledJamon, setEnabledJamon] = useState(EnableStatus.DISABLED);
  const [enabledJstlp, setEnabledJstlp] = useState(EnableStatus.DISABLED);
  const [enabledJshare, setEnabledJshare] = useState(EnableStatus.DISABLED);
  const [submiting, setSubmiting] = useState(false);

  const [value, setValue] = useState("");
  const [activeToken, setActiveToken] = useState(0);
  const { balance: jamonBalance } = useTokenBalance(
    serializedTokens.jamonV2.address
  );
  const { balance: jstlpBalance } = useTokenBalance(
    serializedTokens.jstlp.address
  );
  const { balance: jshareBalance } = useTokenBalance(
    serializedTokens.jshare.address
  );

  const valueWithTokenDecimals = new BigNumber(value).times(
    DEFAULT_TOKEN_DECIMAL
  );

  const jamonContract = useERC20(serializedTokens.jamonV2.address);
  const jstlpContract = useERC20(serializedTokens.jstlp.address);
  const jshareContract = useERC20(serializedTokens.jshare.address);

  useEffect(() => {
    const checkAllowance = async () => {
      try {
        const response = await jamonContract.allowance(
          account,
          migratorAddress
        );
        const currentAllowance = new BigNumber(response.toString());
        setEnabledJamon(
          currentAllowance.lte(0) ? EnableStatus.DISABLED : EnableStatus.ENABLED
        );
        const response2 = await jstlpContract.allowance(
          account,
          migratorAddress
        );
        const currentAllowance2 = new BigNumber(response2.toString());
        setEnabledJstlp(
          currentAllowance2.lte(0)
            ? EnableStatus.DISABLED
            : EnableStatus.ENABLED
        );
        const response3 = await jshareContract.allowance(
          account,
          migratorAddress
        );
        const currentAllowance3 = new BigNumber(response3.toString());
        setEnabledJshare(
          currentAllowance3.lte(0)
            ? EnableStatus.DISABLED
            : EnableStatus.ENABLED
        );
      } catch (error) {
        setEnabledJamon(EnableStatus.DISABLED);
        setEnabledJstlp(EnableStatus.DISABLED);
        setEnabledJshare(EnableStatus.DISABLED);
      }
    };

    if (account) {
      checkAllowance();
    }
  }, [account, jamonContract, migratorAddress, jstlpContract, jshareContract]);

  const approveJamon = async () => {
    setEnabledJamon(EnableStatus.IS_ENABLING);
    try {
      const tx = await jamonContract.approve(
        migratorAddress,
        ethers.constants.MaxUint256
      );
      await tx.wait();
      toastSuccess(t("Success!"), t("Approved JAMON."));
      setEnabledJamon(EnableStatus.ENABLED);
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setEnabledJamon(EnableStatus.DISABLED);
    }
  };

  const approveJstlp = async () => {
    setEnabledJstlp(EnableStatus.IS_ENABLING);
    try {
      const tx = await jstlpContract.approve(
        migratorAddress,
        ethers.constants.MaxUint256
      );
      await tx.wait();
      toastSuccess(t("Success!"), t("Approved JSTLP."));
      setEnabledJstlp(EnableStatus.ENABLED);
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setEnabledJstlp(EnableStatus.DISABLED);
    }
  };

  const approveJshare = async () => {
    setEnabledJshare(EnableStatus.IS_ENABLING);
    try {
      const tx = await jshareContract.approve(
        migratorAddress,
        ethers.constants.MaxUint256
      );
      await tx.wait();
      toastSuccess(t("Success!"), t("Approved J-SHARE."));
      setEnabledJshare(EnableStatus.ENABLED);
    } catch (error) {
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      setEnabledJshare(EnableStatus.DISABLED);
    }
  };

  let activeBalance = BIG_ZERO;
  let activeSymbol = "JAMON";
  let activeRatio = 0.6;
  let activeTokenAddress = serializedTokens.jamonV2.address;
  let enableStatus = enabledJamon;
  let approveAction = approveJamon;
  switch (activeToken) {
    case 0:
      activeBalance = jamonBalance;
      activeSymbol = "JAMON";
      activeRatio = 0.6;
      activeTokenAddress = serializedTokens.jamonV2.address;
      enableStatus = enabledJamon;
      approveAction = approveJamon;
      break;
    case 1:
      activeBalance = jstlpBalance;
      activeSymbol = "JSTLP";
      activeRatio = 0.4;
      activeTokenAddress = serializedTokens.jstlp.address;
      enableStatus = enabledJstlp;
      approveAction = approveJstlp;
      break;
    case 2:
      activeBalance = jshareBalance;
      activeSymbol = "J-SHARE";
      activeRatio = 0.6;
      activeTokenAddress = serializedTokens.jshare.address;
      enableStatus = enabledJshare;
      approveAction = approveJshare;
      break;

    default:
      activeBalance = jamonBalance;
      activeSymbol = "JAMON";
      activeRatio = 0.6;
      activeTokenAddress = serializedTokens.jamonV2.address;
      enableStatus = enabledJamon;
      approveAction = approveJamon;
      break;
  }

  const onUserChange = (index: number) => {
    setActiveToken(index);
    setValue("");
  };

  const onUserInput = (val: string) => {
    setValue(val);
  };

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(activeBalance);
  }, [activeBalance]);

  const onMax = () => {
    setValue(fullBalance);
  };

  const isInvalid =
    valueWithTokenDecimals.eq(0) ||
    !value ||
    value === "" ||
    valueWithTokenDecimals.gt(activeBalance) ||
    submiting;

  const renderTextButton = () => {
    if (valueWithTokenDecimals.eq(0) || !value || value === "") {
      return `${t("Enter amount")}`;
    }
    if (valueWithTokenDecimals.gt(activeBalance)) {
      return `${t("Insufficient balance")}`;
    }
    if (submiting) {
      return `${t("Migrating...")}`;
    }
    return `${t("Migrate")} ${activeSymbol}`;
  };

  const handleMigrate = async () => {
    try {
      setSubmiting(true);
      const tx = await migratorContract.migrate(
        activeTokenAddress,
        valueWithTokenDecimals.toString()
      );
      await tx.wait();
      toastSuccess(t("Success!"), t("You have successfully migrated."));
    } catch (error) {
      setSubmiting(false);
      toastError(
        t("Error"),
        t(
          "Please try again. Confirm the transaction and make sure you are paying enough gas!"
        )
      );
      console.error(error);
    } finally {
      setSubmiting(false);
    }
  };

  return (
    <StyledPage isMobile={isMobile} isTablet={isTablet}>
      <AppBody isMobile={isMobile} isTablet={isTablet}>
        <Title isMobile={isMobile} isTablet={isTablet} textAlign="center">
          {t("Migrate")}
        </Title>
        <Divider sx={{ background: Colors.BORDER1, mb: "1rem" }} />
        <Flex justifyContent="center" alignItems="center" marginY="1rem">
          <ButtonMenu
            activeIndex={activeToken}
            scale="sm"
            variant="primary"
            onItemClick={(index) => onUserChange(index)}
          >
            <ButtonMenuItem id="jamon-button" as="button">
              JAMON
            </ButtonMenuItem>
            <ButtonMenuItem id="jstlp-button" as="button">
              JSTLP
            </ButtonMenuItem>
            <ButtonMenuItem id="jshare-button" as="button">
              J-SHARE
            </ButtonMenuItem>
          </ButtonMenu>
        </Flex>
        <InputPanel>
          <Container>
            <LabelRow>
              <RowBetween>
                <NumericalInput
                  className="token-amount-input"
                  value={value}
                  onUserInput={(val) => {
                    onUserInput(val);
                  }}
                />
              </RowBetween>
            </LabelRow>
            <InputRow selected={false}>
              <RowBetween>
                <Text fontSize={isMobile ? "12px" : "16px"}>
                  {`${t("Balance")}: ${getFullDisplayBalance(
                    activeBalance
                  )} ${activeSymbol}`}
                </Text>
                <Button onClick={onMax} scale="xs" variant="primary">
                  MAX
                </Button>
              </RowBetween>
            </InputRow>
          </Container>
        </InputPanel>
        {value !== "" && (
          <DataContent>
            <RowBetween>
              <Text fontSize={isMobile ? "12px" : "16px"}>{t("Received")}</Text>
              <Text fontSize={isMobile ? "12px" : "16px"}>
                {(Number(value) * activeRatio).toString()} FALCON
              </Text>
            </RowBetween>
            <RowBetween>
              <Text fontSize={isMobile ? "12px" : "16px"}>{t("Route")}</Text>
              <Text fontSize={isMobile ? "12px" : "16px"}>
                {activeSymbol}
                {" ->"} FALCON
              </Text>
            </RowBetween>
          </DataContent>
        )}
        <Box marginTop="1rem">
          {account ? (
            enableStatus === EnableStatus.ENABLED ? (
              <Button width="100%" disabled={isInvalid} onClick={handleMigrate}>
                {renderTextButton()}
              </Button>
            ) : (
              <Button
                width="100%"
                disabled={enableStatus === EnableStatus.IS_ENABLING}
                onClick={approveAction}
              >
                {enableStatus === EnableStatus.DISABLED ? (
                  <>
                    {t("Enable")} {activeSymbol}
                  </>
                ) : (
                  t("Enabling...")
                )}
              </Button>
            )
          ) : (
            <ConnectWalletButton width="100%" />
          )}
        </Box>
      </AppBody>
    </StyledPage>
  );
};

export default Migrate;
