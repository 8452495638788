import React from "react";
import styled from "styled-components";
import {
  Text,
  Flex,
  useMatchBreakpoints
} from "@pancakeswap/uikit";
import {
  Link,
  Button,
  Typography
} from "@mui/material";
import { useTranslation } from "contexts/Localization";
/* import { usePhishingBannerManager } from "state/user/hooks"; */
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  align-items: center;
  background: linear-gradient(139.73deg, #320711 0%, #4f0b1a 100%);
  }
`;

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SpeechBubble = styled.div`
  background: #222531;
  border-radius: 16px;
  padding: 8px;
  width: 60%;
  height: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & ${Text} {
    flex-shrink: 0;
    margin-right: 4px;
  }
`;

const PhishingWarningBanner: React.FC = () => {
  const { t } = useTranslation();
  /* const [, hideBanner] = usePhishingBannerManager(); */
  const { isMobile, isMd } = useMatchBreakpoints();
  const warningText = t(
    "please make sure you're visiting https://jamonswap.finance - check the URL carefully."
  );
  const warningLaw = t(
    "Investing in crypto assets is not regulated, may not be suitable for retail investors, and the entire amount invested may be lost."
  );
  const warningLaw2 = t(
    "It is important to read and understand the risks of this investment which are explained in detail at this:"
  );
  const warningTextAsParts = warningText.split(
    /(https:\/\/jamonswap.finance)/g
  );

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const warningTextComponent = (
    <>
      {/* <Text
        as="span"
        color="warning"
        fontSize="0.7rem"
        fontFamily= "Poppins" 
        bold
        textTransform="uppercase"
      >
        {t("Phishing warning: ")}
      </Text>
      {warningTextAsParts.map((text, i) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          fontSize="0.7rem"
          fontFamily= "Poppins" 
          as="span"

          color={text === "https://jamonswap.finance" ? "warning" : "#FFFFFF"}
        >
          {text}
        </Text>
      ))} */}
      <Flex flexDirection="row" alignItems="center">
        <Text fontSize="0.7rem" fontFamily= "Poppins" color="#FFFFFF">{warningLaw}</Text>
      </Flex>
      <Flex flexDirection="row" alignItems="center">
        <Text fontSize="0.7rem" fontFamily= "Poppins" color="#FFFFFF">{warningLaw2}</Text>
        <Link
          href="https://jamonswap.exchange/wp-content/uploads/2022/05/INCLUIR_WEB.pdf"
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Flex flexDirection="row" alignItems="center">
            <Text fontSize="0.7rem" fontFamily= "Poppins" color="#FEBF32">
              {t("Link")}
            </Text>
            <LaunchRoundedIcon sx={{ color: "#FEBF32", width: "1rem" }} />
          </Flex>
        </Link>
      </Flex>
    </>
  );
  return (
    <>
      {open ? (
        <Container>
          {isMobile || isMd ? (
            <>
              <InnerContainer>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "0.6rem",
                    ml: "1rem",
                    color: "#FFFFFF"
                  }}
                >
                  {/* {t(
                    "Please make sure you're visiting https://jamonswap.finance"
                    )}
                  <br />
                  <br /> */}
                  {t(
                    "Investing in crypto assets is not regulated, may not be suitable for retail investors, and the entire amount invested may be lost."
                  )}{" "}
                  <br/>
                  {t("It is important to read and understand the risks of this investment which are explained in detail at this:")}{" "}
                  <Link
                    href="https://jamonswap.com/wp-content/uploads/2022/04/BOE-A-2022-666.pdf"
                    underline="none"
                    target="_blank"
                    rel="noopener noreferrer"
                    fontFamily= "Poppins"
                    fontSize= "0.6rem"
                    color="#FEBF32"
                  >
                    {t("Link")}
                    <LaunchRoundedIcon
                      sx={{ color: "#FEBF32", width: "1rem", fontSize: "0.6rem", mb: "-0.1rem" }}
                    />
                  </Link>
                </Typography>
              </InnerContainer>
              <Button onClick={handleClose}>
                <CloseFullscreenRoundedIcon sx={{ color: "#FEBF32" }} />
              </Button>
            </>
          ) : (
            <>
              <InnerContainer>
                {/* <picture>
                <source type="image/webp" srcSet="/images/decorations/phishing-warning-bunny.webp" />
                <source type="image/png" srcSet="/images/decorations/phishing-warning-bunny.png" />
                <img src="/images/decorations/phishing-warning-bunny.png" alt="phishing-warning" width="92px" />
              </picture> */}
                <SpeechBubble>{warningTextComponent}</SpeechBubble>
              </InnerContainer>
              <Button onClick={handleClose}>
                <CloseFullscreenRoundedIcon sx={{ color: "#FEBF32" }} />
              </Button>
            </>
          )}
        </Container>
      ) : (
        <Container>
          {isMobile || isMd ? (
            <>
              <InnerContainer>
                <ReportRoundedIcon sx={{ color: "#DAA320" }} />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "0.875rem",
                    ml: "1rem",
                    color: "#DAA320"
                  }}
                >
                  {t("Warning")}
                </Typography>
              </InnerContainer>
              <Button onClick={handleOpen}>
                <OpenInFullRoundedIcon sx={{ color: "#FEBF32" }} />
              </Button>
            </>
          ) : (
            <>
              <InnerContainer>
                <ReportRoundedIcon sx={{ color: "#DAA320"}} />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    ml: "1rem",
                    color: "#DAA320"
                  }}
                >
                  {t("Warning")}
                </Typography>
              </InnerContainer>
              <Button onClick={handleOpen}>
                <OpenInFullRoundedIcon
                  sx={{ color: "#FEBF32"}}
                />
              </Button>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default PhishingWarningBanner;
