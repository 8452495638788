import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  LabelList,
  Tooltip,
} from "recharts";
import BigNumber from "bignumber.js";
import useTheme from "hooks/useTheme";
import { formatAmount } from "views/Info/utils/formatInfoNumbers";
import { CandleChartLoader } from "views/Info/components/ChartLoaders";
import { useTranslation } from "contexts/Localization";
import useGetLockedLaunchData from "views/Launchpad/hooks/useGetLockedLaunchData";
import { PublicPresaleData } from "../../types";

export type TokenomicsProps = {
  presaleAddress: string;
  presaleData: PublicPresaleData;
  data02: any[];
  height?: string;
  chartHeight?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const COLORS = ["#00C49F", "#FF8042"];
const COLORS2 = [
  "#056150",
  "#005F73",
  "#0a9396",
  "#94d2bd",
  "#e9d8a6",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
  "#ae2012",
  "#9b2226",
];

/* const data01 = [
  { name: "Locked", value: 400 },
  { name: "Unlocked", value: 300 }
]; */

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


const renderCustomizedLabel2 = ({ name }) => {
  return name;
};

const Tokenomics = ({
  presaleAddress,
  presaleData,
  data02,
}: TokenomicsProps) => {
  const { t } = useTranslation();

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [data01, setData01] = useState([
    { name: "Locked", value: 0 },
    { name: "Unlocked", value: 0 },
  ]);

  const lockedData = useGetLockedLaunchData(presaleAddress, presaleData);

  useEffect(() => {
    const { presaleLocked, launchLocked, totalSupply } = lockedData;
    const totalLocked = presaleLocked.plus(launchLocked); 
    const totalUnlocked = totalSupply.minus(totalLocked);
    const lockedPercent = totalLocked.div(totalSupply).times(100);
    const unlockedPercent = totalUnlocked.div(totalSupply).times(100);
    if (isInitialRender && totalSupply.gt(0)) {
      setIsInitialRender(false);
      setData01([
        { name: "Locked", value: lockedPercent.toNumber() },
        { name: "Unlocked", value: unlockedPercent.toNumber() },
      ]);
    }
  }, [lockedData, isInitialRender]);

  if (!data01 || data01.length === 0) {
    return <CandleChartLoader />;
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={450} height={450}>
        <Pie
          data={data01}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={60}
          label={renderCustomizedLabel}
          isAnimationActive={false}
          labelLine={false}
          fill="#8884d8"
        >
          {data01.map((entry, index) => (
            <Cell key="cell" fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Pie
          data={data02}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          isAnimationActive={false}
          fill="#82ca9d"
          label={renderCustomizedLabel2}
        >
          {data02.map((entry, index) => (
            <Cell key="cell2" fill={COLORS2[index % COLORS2.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Tokenomics;
